import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/system';
import { Stack, Divider, Typography, IconButton } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';

import { colors } from 'src/utils/colors';

import { fetchBrands } from 'src/server/slice/brandSlice';
import { fetchCategories } from 'src/server/slice/categorySlice';
import { fetchCollections } from 'src/server/slice/collectionSlice';
import {
  getBrandSate,
  getCategoriesState,
  getCollectionState,
} from 'src/server/selectors/selector';

import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';

import BrandSlider from './subItem/brand-slider';
import CategorySlider from './subItem/category-slider';
import CollectionSlider from './subItem/collection-slider';

const IconCarousal = ({ groupDetail }) => {
  const getText = useLocalizedText();
  const { isMobile } = useScreenWidth();
  const rtl = useLayoutStyle();
  const dispatch = useDispatch();

  const { collections } = useSelector(getCollectionState);
  const { categories } = useSelector(getCategoriesState);
  const { brands } = useSelector(getBrandSate);

  const translatedTitle = useTranslatedText(groupDetail?.title);
  const brandData = groupDetail?.brands?.filter((item) => item?.logo?.url);
  const categoryData = groupDetail?.categories;
  const data =
    groupDetail?.type === 'COLLECTIONS' ? groupDetail?.collections : groupDetail?.special_sales;

  useEffect(() => {
    if (groupDetail?.is_live && groupDetail?.type === 'BRANDS') {
      const credentials = {
        page: 1,
        search: '',
        limit: 10,
        state: {
          filter_data: {
            is_deleted: false,
            is_active: true,
          },
          sort_data: {
            priority: 1,
          },
        },
      };
      dispatch(fetchBrands(credentials));
    }
  }, [dispatch, groupDetail]);

  useEffect(() => {
    if (groupDetail?.is_live && groupDetail?.type === 'CATEGORIES') {
      const credentials = {
        page: 1,
        search: '',
        limit: 10,
        state: {
          filter_data: {
            is_deleted: false,
            is_active: true,
          },
          sort_data: {
            priority: 1,
          },
        },
      };
      dispatch(fetchCategories(credentials));
    }
  }, [dispatch, groupDetail]);

  useEffect(() => {
    if (groupDetail?.is_live && groupDetail?.type === 'COLLECTIONS') {
      const credentials = {
        page: 1,
        search: '',
        limit: 10,
        state: {
          filter_data: {
            is_deleted: false,
            is_active: true,
          },
          sort_data: {
            priority: 1,
          },
        },
      };
      dispatch(fetchCollections(credentials));
    }
  }, [dispatch, groupDetail]);

  const handleBrandData = () => {
    if (groupDetail?.is_live && groupDetail?.type === 'BRANDS') {
      return brands?.brands;
    }
    return brandData;
  };

  const handleCategoryData = () => {
    if (groupDetail?.is_live && groupDetail?.type === 'CATEGORIES') {
      return categories?.categories;
    }
    return categoryData;
  };

  const handleData = () => {
    if (groupDetail?.is_live && groupDetail?.type === 'COLLECTIONS') {
      return collections?.collections;
    }
    return data;
  };

  return (
    <Box>
      {translatedTitle && (
        <OspLink href={groupDetail?.link} sx={{ '&:hover': { textDecoration: 'none' } }}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <Box position="relative">
              <Typography variant="body1" fontSize={isMobile ? 18 : 20} color="black">
                {translatedTitle}
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.themeSecondary,
                  height: 2,
                  width: 150,
                  position: 'absolute',
                  bottom: -9,
                }}
              />
            </Box>

            {groupDetail?.link && (
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                gap={2}
                color="text.disabled"
              >
                {getText('view_all')}
                <IconButton
                  sx={{
                    backgroundColor: colors.themePrimary,
                    borderRadius: '50%',
                    p: isMobile ? '5px' : 0.5,
                    '&:hover': {
                      backgroundColor: colors.themePrimary,
                    },
                  }}
                >
                  <Iconify
                    icon={`solar:alt-arrow-${rtl ? 'left' : 'right'}-outline`}
                    width={isMobile ? 12 : 20}
                    color="white"
                  />
                </IconButton>
              </Typography>
            )}
          </Stack>
          <Divider sx={{ mb: 2, mt: 1 }} />
        </OspLink>
      )}
      {groupDetail?.type === 'BRANDS' && <BrandSlider handleData={handleBrandData} />}
      {groupDetail?.type === 'CATEGORIES' && <CategorySlider handleData={handleCategoryData} />}
      {(groupDetail?.type === 'COLLECTIONS' || groupDetail?.type === 'SPECIAL_SALES') && (
        <CollectionSlider handleData={handleData} groupDetail={groupDetail} />
      )}
    </Box>
  );
};

export default IconCarousal;

IconCarousal.displayName = 'IconCarousal';

IconCarousal.propTypes = {
  groupDetail: PropTypes.any,
};
