import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import StarIcon from '@mui/icons-material/Star';
import SendIcon from '@mui/icons-material/Send';
import { Box, Stack, Container } from '@mui/system';
import WidgetsIcon from '@mui/icons-material/Widgets';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Grid, Card, Tooltip, Typography, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter, usePathname } from 'src/routes/hooks';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';
import Storage from 'src/utils/localStorage';

import { logOutUser } from 'src/server/slice/authentication';
import { updateAccountTab } from 'src/server/slice/settingsSlice';
import { getProfileState, getSettingsState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import MyAccountProfileDialog from 'src/sections/myAccount/account/accountPopup';

// ----------------------------------------------------------------------

export default function AccountLayout({ children }) {
  const { isMobile, screenWidth } = useScreenWidth();
  const pathname = usePathname();
  const location = useLocation();
  const navigate = useNavigate();
  const getText = useLocalizedText();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const route = useRouter();
  const isGuest = Storage.get('is_guest');
  const isOrderDetails = pathname.startsWith('/my-account/orders/');

  const { user, logoutLoading } = useSelector(getProfileState);
  const { accountTab } = useSelector(getSettingsState);

  const [openProfilePopup, setOpenProfilePopup] = useState(false);

  const handleLogOut = () => {
    dispatch(logOutUser({ enqueueSnackbar, dispatch, route }));
  };

  useEffect(() => {
    const path = location?.search?.split('?tab=')[1];
    if (path) {
      dispatch(updateAccountTab(path));
    }
  }, [dispatch, location?.search]);

  const linkData = [
    { icon: <WidgetsIcon />, text: `${getText('orders')}`, path: 'orders' },
    {
      icon: <FavoriteIcon />,
      text: `${getText('wishlist')}`,
      path: 'wishlist',
    },
    { icon: <StarIcon />, text: `${getText('reviews')}`, path: 'reviews' },
    { icon: <SendIcon />, text: `${getText('address')}`, path: 'address' },

    // {
    //   icon: <LogoutIcon />,
    //   text: `${getText('logout')}`,
    //   path: '',
    //   onClick: handleLogOut,
    // },
  ];

  const handleOpenProfilePopup = () => {
    setOpenProfilePopup(true);
  };

  const getScreenValue = () => {
    if (isMobile) {
      return 0;
    }
    if (screenWidth < 900) {
      return 18;
    }
    if (screenWidth < 1200) {
      return 8;
    }
    return 0;
  };

  const handleClickTabView = (item) => {
    if (isOrderDetails) {
      navigate(`${paths.private.my_account.root}?tab=${item.path}`);
    } else {
      dispatch(updateAccountTab(item.path));
    }
  };

  return (
    <>
      {openProfilePopup && (
        <MyAccountProfileDialog setOpen={setOpenProfilePopup} open={openProfilePopup} />
      )}

      <Container maxWidth="xl">
        <Grid container spacing={2} p={isMobile ? 0 : 2} mt={getScreenValue()}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack
              p={1.5}
              px={isMobile ? 0 : 1.5}
              direction={isMobile ? 'row' : 'row'}
              justifyContent="space-between"
              alignItems={isMobile ? 'start' : 'center'}
              borderRadius="12px"
              gap={isMobile ? 2 : ''}
              sx={{ border: isMobile ? '' : '1px solid #e9e9e9' }}
            >
              <Stack
                direction="row"
                width="100%"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Stack>
                  <Image
                    alt="account-logo"
                    src="/assets/images/default/male.jpg"
                    sx={{}}
                    imageStyle={{
                      objectFit: 'contain',
                      aspectRatio: 1 / 1,
                      width: '85px',
                      borderRadius: '10px',
                    }}
                  />
                </Stack>
                <Stack width="100%" spacing={2}>
                  <Stack>
                    <Typography variant="subtitle2">{getText('hello')},</Typography>
                    {isGuest ? (
                      <Typography variant="subtitle2">{getText('guest_user')}</Typography>
                    ) : (
                      <>
                        {user?.name ? (
                          <Stack
                            flexDirection={isMobile ? 'column' : 'row'}
                            alignItems={isMobile ? 'start' : 'center'}
                            spacing={1}
                          >
                            <Typography variant="subtitle2">{user?.name}</Typography>
                            <Tooltip title={getText('edit_profile')} arrow>
                              <Box
                                p={0}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  // '&:hover': {
                                  //   backgroundColor: 'grey.300',
                                  // },
                                }}
                              >
                                <Iconify
                                  width={10}
                                  icon="fluent:edit-16-filled"
                                  className="caption"
                                  onClick={handleOpenProfilePopup}
                                  sx={{ color: 'grey.500' }}
                                />
                              </Box>
                            </Tooltip>
                          </Stack>
                        ) : (
                          <Typography
                            variant="subtitle2"
                            onClick={handleOpenProfilePopup}
                            color="darkblue"
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {getText('edit_profile')}
                          </Typography>
                        )}
                      </>
                    )}
                  </Stack>
                  {user?.loyalty_point_balance > 0 && (
                    <Typography variant="body2">
                      {getText('level')}: {user?.loyalty_point_balance}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              {!isGuest && (
                <Stack sx={{ alignSelf: 'center !important' }}>
                  <Stack gap={2} direction="row" justifyContent="end">
                    <Tooltip title={getText('logout')} arrow>
                      <Box
                        onClick={handleLogOut}
                        py={0.5}
                        px={1.5}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '12px',
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: 'grey.300',
                          },
                          gap: '10px',
                        }}
                      >
                        <Typography variant="body2">{getText('logout')}</Typography>
                        <Iconify
                          width={18}
                          icon="mdi-light:logout"
                          className="caption"
                          onClick={handleOpenProfilePopup}
                          sx={{ color: colors.themeBlack }}
                        />
                      </Box>
                    </Tooltip>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{ borderTop: isMobile ? '1px solid #f4f3f3' : '' }}
          >
            {isMobile ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
                sx={{ border: '1px solid #E5E5E5', borderRadius: '12px', padding: '10px' }}
              >
                {/* ?.filter((i) => i.path !== '') */}
                {linkData?.map((item, index) => (
                  <Box
                    sx={{
                      color: accountTab === item.path ? 'white' : 'text.disabled',
                      borderRadius: '12px',
                    }}
                    key={index}
                    onClick={() => handleClickTabView(item)}
                  >
                    <Card
                      sx={{
                        borderRadius: '12px',
                        p: '8px 12px',
                        boxShadow: 'unset',
                        justifyContent: 'space-between',
                        display: 'flex',
                        backgroundColor: accountTab === item.path ? '#2875f026' : '',
                        '&:hover': {
                          backgroundColor: '#2875f026',
                          color: colors.themePrimary,
                        },
                        color: accountTab === item.path ? colors.themePrimary : 'text.disabled',
                      }}
                    >
                      <Stack
                        direction="column"
                        spacing={0.5}
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item.icon}
                        <Typography
                          sx={{
                            '&:hover': { color: colors.themePrimary },
                          }}
                          variant="body2"
                        >
                          {item.text}
                        </Typography>

                        {item.text === `${getText('logout')}` && logoutLoading && (
                          <CircularProgress size={16} sx={{ marginLeft: 1 }} />
                        )}
                      </Stack>
                    </Card>
                  </Box>
                ))}
              </Stack>
            ) : (
              <Card
                sx={{
                  borderRadius: '12px',
                  boxShadow: 'none',
                  border: '1px solid rgba(229, 229, 229, 0.50)',
                }}
              >
                {linkData?.map((item, index) => (
                  <Box
                    sx={{
                      cursor: 'pointer',
                      color: accountTab === item.path ? 'white' : 'text.disabled',
                    }}
                    key={index}
                    onClick={() => handleClickTabView(item)}
                  >
                    <Stack
                      p={2}
                      direction="row"
                      spacing={3}
                      color={accountTab === item.path ? colors.themePrimary : 'text.disabled'}
                      sx={{
                        borderBottom: '1px solid #f4f3f3',
                        // backgroundColor: accountTab === item.path ? colors.themeSecondary : '',
                        '&:hover': { color: colors.themePrimary },
                      }}
                    >
                      {item.icon}
                      <Typography
                        sx={
                          {
                            // '&:hover': { color: 'white' },
                          }
                        }
                      >
                        {item.text}
                      </Typography>
                      {item.text === `${getText('logout')}` && logoutLoading && (
                        <CircularProgress size={16} sx={{ marginLeft: 1 }} />
                      )}
                    </Stack>
                  </Box>
                ))}
              </Card>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Stack width="100%" sx={{ minHeight: '70vh' }} pb={2} pl={isMobile ? 0 : 1.5}>
              {children}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

AccountLayout.propTypes = {
  children: PropTypes.node,
};
