import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/system';
import { Dialog, IconButton, DialogContent } from '@mui/material';

import { CloseIcon } from 'src/assets/icon/CloseIcon';
import { getAuthState } from 'src/server/selectors/selector';
import { triggerLoginPopup } from 'src/server/slice/authentication';

import LoginView from './login';

function LoginPopup() {
  const dispatch = useDispatch();

  const { loginPopup } = useSelector(getAuthState);

  const handleClose = () => {
    dispatch(triggerLoginPopup({ state: false }));
  };

  return (
    <Dialog maxWidth="sm" open={loginPopup} onClose={handleClose}>
      <DialogContent dividers>
        <Stack
          sx={{
            p: 3,
            px: 0,
            pb: 0,
            maxWidth: '350px',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 6,
              right: 6,
            }}
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <LoginView type="popup" />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default LoginPopup;
