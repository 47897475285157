import PropTypes from 'prop-types';

export const UserActiveIcon = ({ width, height, className }) => (
  <svg
    className={className}
    width={width || 20}
    height={height || 20}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.44629 18.7773C1.89941 18.7773 1.46973 18.6536 1.15723 18.4062C0.844727 18.1589 0.688477 17.8203 0.688477 17.3906C0.688477 16.7591 0.877279 16.0983 1.25488 15.4082C1.639 14.7181 2.18913 14.0703 2.90527 13.4648C3.62793 12.8594 4.49707 12.3678 5.5127 11.9902C6.52832 11.6126 7.66764 11.4238 8.93066 11.4238C10.2002 11.4238 11.3428 11.6126 12.3584 11.9902C13.374 12.3678 14.2399 12.8594 14.9561 13.4648C15.6787 14.0703 16.2321 14.7181 16.6162 15.4082C17.0003 16.0983 17.1924 16.7591 17.1924 17.3906C17.1924 17.8203 17.0361 18.1589 16.7236 18.4062C16.4111 18.6536 15.9814 18.7773 15.4346 18.7773H2.44629ZM8.94043 9.68555C8.23079 9.68555 7.5765 9.49349 6.97754 9.10938C6.38509 8.72526 5.90658 8.20768 5.54199 7.55664C5.18392 6.9056 5.00488 6.17643 5.00488 5.36914C5.00488 4.57487 5.18392 3.85872 5.54199 3.2207C5.90658 2.57617 6.38835 2.0651 6.9873 1.6875C7.58626 1.3099 8.2373 1.12109 8.94043 1.12109C9.65007 1.12109 10.3044 1.30664 10.9033 1.67773C11.5023 2.04883 11.9808 2.55664 12.3389 3.20117C12.7035 3.83919 12.8857 4.55534 12.8857 5.34961C12.8857 6.16341 12.7035 6.89909 12.3389 7.55664C11.9808 8.20768 11.5023 8.72526 10.9033 9.10938C10.3044 9.49349 9.65007 9.68555 8.94043 9.68555Z"
      fill="white"
    />
  </svg>
);
UserActiveIcon.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
};
