import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';

import { isEqual } from 'src/utils/isEqualCheck';

import { getOrderState } from 'src/server/selectors/selector';
import { updateOrder, handleOrderLocalData } from 'src/server/slice/cartOrderSlice';

import OspLink from 'src/components/link/osp-link';
import { useSettingsContext } from 'src/components/settings';

import CheckoutSteps from '../checkout-steps';
import CheckoutPayment from '../payment/checkout-payment';
import CheckoutBillingAddress from '../address/checkout-billing-address';

// ----------------------------------------------------------------------

export default function CheckoutView() {
  const router = useRouter();
  const { screenWidth, isMobile } = useScreenWidth();
  const dispatch = useDispatch();

  const { localState } = useSelector(getOrderState);

  const [dataOption, setDataOption] = useState({});
  const getText = useLocalizedText();

  const PRODUCT_CHECKOUT_STEPS = ['Choose Products', 'Shipping', 'Payment'];
  const settings = useSettingsContext();

  const [activeStep, setActiveStep] = useState(1);

  const onBackStep = () => {
    if (activeStep === 1) {
      router?.push(paths.public.cart);
    }
    setActiveStep(activeStep - 1);
  };
  const onNextStep = () => {
    if (activeStep !== 2) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleScreenWidth = () => {
    if (screenWidth < 600) {
      return 2;
    }
    if (screenWidth < 900) {
      return 22;
    }
    if (screenWidth < 1200) {
      return 12;
    }

    return 4;
  };

  // billing_address
  const previousDataOption = useRef();

  useEffect(() => {
    if (!isEqual(previousDataOption.current, dataOption)) {
      previousDataOption.current = dataOption;

      const credentials = {
        state: {
          ...localState,
          ...dataOption,
        },
      };

      dispatch(updateOrder(credentials));
      dispatch(handleOrderLocalData(credentials));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOption, dispatch]);

  return (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{ mb: 10, mt: handleScreenWidth() }}
    >
      {isMobile ? (
        <OspLink href={paths.public.cart}>
          <Stack alignItems="center" spacing={1} flexDirection="row" mb={2}>
            <ArrowBackIcon fontSize="small" />
            <Typography variant="body">{getText('back')}</Typography>
          </Stack>
        </OspLink>
      ) : (
        <Grid container justifyContent="flex-start">
          <Grid xs={12} md={8}>
            <CheckoutSteps activeStep={activeStep} steps={PRODUCT_CHECKOUT_STEPS} />
          </Grid>
        </Grid>
      )}

      <>
        {activeStep === 1 && (
          <CheckoutBillingAddress
            onNextStep={onNextStep}
            onBackStep={onBackStep}
            dataOption={dataOption}
            setDataOption={setDataOption}
          />
        )}
        {activeStep === 2 && <CheckoutPayment onBackStep={onBackStep} dataOption={dataOption} />}
      </>
    </Container>
  );
}
