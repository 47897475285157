import { useScroll } from 'framer-motion';
import { useMemo, useState, useEffect, useCallback } from 'react';

// Custom Hook
export function useOffSetTop(top = 0, options = {}) {
  const { container } = options;
  const { scrollY } = useScroll({ container });
  const [value, setValue] = useState(false);

  const onOffSetTop = useCallback(() => {
    const unsubscribe = scrollY.on('change', (scrollHeight) => {
      if (scrollHeight > top) {
        setValue(true);
      } else {
        setValue(false);
      }
    });

    return unsubscribe;
  }, [scrollY, top]);

  useEffect(() => {
    const unsubscribe = onOffSetTop();

    // Cleanup on unmount
    return () => unsubscribe();
  }, [onOffSetTop]);

  const memoizedValue = useMemo(() => value, [value]);

  return memoizedValue;
}
