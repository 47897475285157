import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/material/styles';
// @mui
import {
  Grid,
  Stack,
  Dialog,
  Rating,
  Divider,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { createReview } from 'src/server/slice/reviewSlice';
import { getProfileState } from 'src/server/selectors/selector';

import Iconify from 'src/components/iconify';
import OspImageUploader from 'src/components/uploadNew';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

ReviewDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
  setOpenReviewId: PropTypes.func,
  product: PropTypes.any,
  orderId: PropTypes.any,
};

export default function ReviewDialog({
  open,
  setOpen,
  orderId,
  setOpenReviewId,
  isUpdate,
  product,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const getText = useLocalizedText();
  const { isMobile } = useScreenWidth();

  const handleClose = () => {
    setOpen(false);
    setOpenReviewId({});
  };
  const dispatch = useDispatch();

  const { loading, user } = useSelector(getProfileState);

  const [imagePreview, setImagePreview] = useState([]);
  const [rating, setRating] = useState(0);

  const ReviewSchema = Yup.object().shape({
    message: Yup.string(),
  });

  const defaultValues = useMemo(() => {
    if (isUpdate?.uid) {
      return {
        message: isUpdate?.message || '',
      };
    }
    return {
      message: '',
    };
  }, [isUpdate?.uid, isUpdate?.message]);
  const methods = useForm({
    resolver: yupResolver(ReviewSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    const CreateCredentials = {
      state: {
        // ...data,
        images: imagePreview,
        message: data?.message,
        rating: parseInt(rating, 10),
        product,
        orderId,
        user: user?.uid,
        // product:'',
        // order:'', orderId
      },
      handleClose,
      handleClear,
      enqueueSnackbar,
    };
    dispatch(createReview(CreateCredentials));
  });
  const handleRating = (value) => {
    setRating(value);
  };
  const renderReviewForm = (
    <Stack spacing={3} sx={{ p: 1, px: 0 }}>
      <Stack direction="row" gap={2}>
        <RHFTextField
          rows={5}
          multiline
          name="message"
          label="Message"
          type="text"
          size="small"
          placeholder="Enter Message"
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <Rating
          name="rating"
          size="medium"
          value={rating}
          onChange={(e) => handleRating(e.target.value)}
        />
      </Stack>

      <Stack
        sx={{
          marginBottom: '1rem',
          backgroundColor: '#ffff',
          position: 'absolute',
          right: '15px',
          bottom: 0,
        }}
        justifyContent="end"
        alignItems="center"
        width="100%"
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="small"
          loading={isSubmitting || loading}
          sx={{ color: 'white', ml: 'auto', zIndex: 1 }}
          color="inherit"
        >
          {getText('create_review')}
        </LoadingButton>
      </Stack>
    </Stack>
  );
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {getText('create_review')}
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              p: 0.5,
              top: 10,
              right: 10,
              transform: 'scale(0.8)',
              position: 'absolute',
              color: 'common.white',
              bgcolor: (theme1) => alpha(theme1.palette.grey[900], 0.48),
              '&:hover': {
                bgcolor: (theme1) => alpha(theme1.palette.grey[900], 0.72),
              },
            }}
          >
            <Iconify icon="mingcute:close-line" width={14} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '250px' : '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderReviewForm}
          </FormProvider>
          <Stack mb={5}>
            <Typography variant="subtitle2" mt={2.5} pl={1} sx={{ fontWeight: '600' }}>
              {getText('images')}{' '}
            </Typography>
            <Divider sx={{ pt: 2, mt: 0, mb: -2 }} />
            <Grid container>
              <Grid item>
                <Stack
                  sx={{ pt: 3, pb: isMobile && 4 }}
                  spacing={0}
                  width="100%"
                  direction="column"
                >
                  <OspImageUploader
                    setImagesPreview={setImagePreview}
                    imagesPreview={imagePreview}
                    folder="review"
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
