import { useLocales } from 'src/locales';

const useTranslatedText = (textObj) => {
  const { currentLang } = useLocales();

  if (!textObj || typeof textObj !== 'object') {
    return '';
  }

  const defaultText = textObj?.english || '';

  if (currentLang?.value === 'ar') {
    return textObj?.arabic ? textObj.arabic : defaultText;
  }

  return defaultText || '';
};

export default useTranslatedText;
