import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Pagination, { paginationClasses } from '@mui/material/Pagination';

import { useScreenWidth } from 'src/hooks/useScreenWidth';

import ProductCardOne from '../cards/product-card-one';
import ProductListLoading from '../common/product-list-loading';

// ----------------------------------------------------------------------

// Animation Variants
const cardVariants = {
  hidden: { opacity: 0, y: 30 }, // Start invisible and below the viewport
  visible: { opacity: 1, y: 0 }, // Fade in and move up
};

export default function ProductList({ isLarge, products, pagination, loading, ...other }) {
  const { screenWidth } = useScreenWidth();
  const { currentPage, setCurrentPage, totalProducts, showLimit } = pagination;

  const totalPages = Math.ceil(totalProducts / showLimit);

  const renderSkeleton = <ProductListLoading />;

  const renderList = (
    <>
      {products?.map((product, key) => (
        <m.div
          key={key}
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.4, delay: key * 0.1 }}
        >
          <ProductCardOne product={product} />
        </m.div>
      ))}
    </>
  );

  const renderListMobile = (
    <Grid container item spacing={1}>
      {products?.map((product, key) => (
        <Grid item xs={6} key={key}>
          <m.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.4, delay: key * 0.1 }}
          >
            <ProductCardOne product={product} />
          </m.div>
        </Grid>
      ))}
    </Grid>
  );

  const renderPagination = (
    <>
      {totalProducts > showLimit && (
        <Pagination
          onChange={(e, newPage) => {
            setCurrentPage(newPage);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }}
          count={totalPages || 8}
          page={currentPage}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );

  return (
    <>
      {screenWidth > 400 ? (
        <>
          {loading ? (
            renderSkeleton
          ) : (
            <Box
              gap={{ xs: 1.5, md: 3 }}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(3, 1fr)',
                md: 'repeat(4, 1fr)',
                lg: isLarge ? 'repeat(6, 1fr)' : 'repeat(5, 1fr)',
                xl: 'repeat(6, 1fr)',
              }}
              width="100%"
              {...other}
            >
              {renderList}
            </Box>
          )}
        </>
      ) : (
        <>{loading ? renderSkeleton : renderListMobile}</>
      )}
      {renderPagination}
    </>
  );
}

ProductList.propTypes = {
  loading: PropTypes.bool,
  products: PropTypes.array,
  pagination: PropTypes.object,
  isLarge: PropTypes.bool,
};
