import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/system';
import Container from '@mui/material/Container';
import { Card, Grid, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { fetchBrands } from 'src/server/slice/brandSlice';
import { getBrandSate } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';
import { useSettingsContext } from 'src/components/settings';

import { BrandItemSkeleton } from './brand-skeleton';

export default function BrandListView() {
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  const getText = useLocalizedText();

  const mdDown = useResponsive('down', 'md');
  const { isMobile, screenWidth } = useScreenWidth();

  const { brands, loading } = useSelector(getBrandSate);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: brands?.total || 20,
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchBrands(credentials));
  }, [brands?.total, dispatch]);

  const brandData = brands?.brands;

  const renderSkeleton = (
    <Grid container spacing={2} sx={{ p: 2 }}>
      {[...Array(16)].map((_, index) => (
        <Grid item key={index} xs={4} sm={3} md={3} lg={1.5}>
          <BrandItemSkeleton />
        </Grid>
      ))}
    </Grid>
  );

  const renderList = (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mb: 15,
        my: isMobile ? 2 : 4,
      }}
    >
      <Grid
        container
        spacing={2}
        // justifyContent="center"
      >
        {brandData?.map((item, key) => {
          const brandName = item?.name?.english.replace(/ /g, '-');

          return (
            <Grid item key={key} xs={4} sm={3} md={3} lg={1.5} xl={1.5}>
              <OspLink
                sx={{ '&:hover': { textDecoration: 'none' } }}
                key={paths.public.brandView(brandName)}
                href={`${paths.public.brandView(brandName)}`}
              >
                <Card
                  sx={{
                    p: isMobile ? 1.5 : 3,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: 2,
                    cursor: 'pointer',
                    aspectRatio: '1 / 1',
                    width: '100%',
                    boxShadow: 'none',
                    border: '1px solid #E5E5E5',
                  }}
                >
                  <BrandImages item={item} />
                </Card>
              </OspLink>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );

  const getMarginTop = () => {
    if (isMobile) return 0;
    if (screenWidth < 900) return 18;
    if (screenWidth < 1200) return 8;
    return 0;
  };

  return (
    <>
      <Box mt={getMarginTop()} py={4} className={`custom-text-brand-div ${mdDown ? 'mt-55' : ''}`}>
        <Box
          className="text-brand-div relative text-center d-flex flex-column justify-content-center align-items-center"
          px={mdDown ? 4 : 5}
          py={mdDown ? 3 : 5}
          my={4}
          gap={mdDown ? 2 : 4}
          borderRadius={1}
          boxShadow={3}
          sx={{ backdropFilter: 'blur(10px)' }}
        >
          <Typography
            className="custom-brand-text "
            variant="h1"
            sx={{
              lineHeight: 'normal',
              color: 'black',
              fontSize: { xs: '20px', md: '35px', lg: '50px' },
            }}
          >
            {getText('brands_to_shop_from')}
          </Typography>
          <Typography
            variant="h2"
            className={`${mdDown ? 'font-size-10' : 'font-size-12'}`}
            sx={{
              color: 'black',
              fontSize: { xs: '10px', md: '12px' },
              fontWeight: 400,
            }}
          >
            {getText('over_10_000_brands_from_all_over_the_world')}
          </Typography>
        </Box>
      </Box>

      {loading ? renderSkeleton : renderList}
    </>
  );
}
const BrandImages = ({ item }) => {
  const BrandImage = useImageTransformation(item?.logo?.url, 'w_500');
  const BrandAltText = useTranslatedText(item?.logo?.alt_text);
  const TranslatedText = useTranslatedText(item?.name);
  const { isMobile } = useScreenWidth();

  return item?.logo?.url ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        // aspectRatio: '1 / 1',
        overflow: 'hidden',
      }}
    >
      <Image
        src={BrandImage}
        alt={BrandAltText}
        style={{
          objectFit: 'contain',
          maxWidth: '100%',
          aspectRatio: 1 / 1,
        }}
      />
    </Box>
  ) : (
    <TextMaxLine
      variant={isMobile ? 'body2' : 'subtitle2'}
      line={2}
      sx={{ fontWeight: 'fontWeightSemiBold' }}
    >
      {TranslatedText}
    </TextMaxLine>
  );
};

BrandImages.propTypes = {
  item: PropTypes.object,
};
