import React from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import Image from 'src/components/image';

export default function Supports() {
  const getText = useLocalizedText();
  const { isMobile } = useScreenWidth();

  const featureData = [
    {
      logo: '/assets/images/home/img8.png',
      title: getText('24/7_free_support'),
      description: getText('online_support_24/7'),
    },
    {
      logo: '/assets/images/home/img9.png',
      title: getText('free_shipping'),
      description: getText('on_order_over_omr_30'),
    },
    {
      logo: '/assets/images/home/img7.png',
      title: getText('money_back_guarantee'),
      description: getText('100%_secure_payment'),
    },
    {
      logo: '/assets/images/home/img10.png',
      title: getText('special_gift_card'),
      description: getText('give_the_perfect_gift'),
    },
  ];

  return (
    <Grid
      item
      container
      my={isMobile ? 1 : 2}
      sx={{ paddingRight: 0 }}
      px={isMobile ? 3 : 2}
      spacing={1}
    >
      {featureData?.map((item, key) => (
        <Grid item sm={6} xs={6} md={3} lg={3} xl={3} key={key}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={isMobile ? 'start' : 'center'}
            mb={3}
          >
            <Image
              alt="support"
              src={item?.logo}
              sx={{
                height: 35,
                width: 35,
              }}
              imageStyle={{
                height: isMobile ? 30 : 35,
                width: isMobile ? 30 : 35,
              }}
            />

            <Stack ml={isMobile ? 1 : 2} sx={isMobile ? { flex: 1 } : {}}>
              <Typography color="black" fontSize={isMobile ? 13 : 18} fontWeight={500}>
                {item?.title}
              </Typography>
              <Typography variant="caption" color="black" fontSize={isMobile ? 10 : 12}>
                {item?.description}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
