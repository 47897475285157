import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

import useTranslatedText from 'src/hooks/useTranslatedText';
import { useProductPathname } from 'src/hooks/useProductPathname';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { useCurrency } from 'src/providers/CurrencyProvider';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';

export const product_label_layouts = ['LAYOUT_1', 'LAYOUT_2', 'LAYOUT_3', 'LAYOUT_4', 'LAYOUT_5'];

// ----------------------------------------------------------------------

export default function ProductGroupCard({ product = null, action = null, noCart = null }) {
  const { currency } = useCurrency();

  const outOfStock = product?.stock <= 0;
  const { productRoute } = useProductPathname(product);

  const { offerPrice } = useProductOfferPriceCalculator(product);

  const translatedProductName = useTranslatedText(product?.name);
  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);
  const translateCurrencyText = useTranslatedText(currency?.title);

  const translatedAltText = useTranslatedText(
    product?.images[0]?.alt_text ? product?.images[0]?.alt_text : {}
  );
  const transformedImage = useImageTransformation(product?.images[0]?.url);
  //
  const translatedTextTwo = useTranslatedText(
    product?.images[1]?.alt_text ? product.images[1]?.alt_text : {}
  );
  const transformedImageTwo = useImageTransformation(product.images[1]?.url);

  const ProductImage = (
    <>
      {/* // first image  */}
      <Image
        alt={translatedAltText}
        src={transformedImage}
        sx={{
          maxWidth: '200px',
          maxHeight: '200px',
          margin: 'auto',
        }}
        imageStyle={{
          ...(outOfStock && {
            opacity: 0.48,
            filter: 'grayscale(1)',
          }),
          borderRadius: '10px',
          aspectRatio: '1/1',
          objectFit: 'contain',
        }}
        className={`product-image ${
          product?.images[1]?.url && !outOfStock && 'hover-image-available'
        }`}
      />

      {/* // second image  */}
      {!outOfStock && product?.images[1]?.url && (
        <Image
          alt={translatedTextTwo}
          src={transformedImageTwo}
          sx={{
            maxWidth: '200px',
            maxHeight: '200px',
            margin: 'auto',
          }}
          imageStyle={{
            ...(outOfStock && {
              opacity: 0.48,
              filter: 'grayscale(1)',
            }),
            borderRadius: '10px',
            aspectRatio: '1/1',
            objectFit: 'contain',
          }}
          className="product-image hover-image"
        />
      )}
    </>
  );

  const renderImg = (
    <Box
      sx={{
        position: 'relative',
        p: 1,
        border: '1px solid #f3f3fc',
        backgroundColor: '#fff',
        borderRadius: '11px',
      }}
    >
      <OspLink
        sx={{ '&:hover': { textDecoration: 'none' }, position: 'unset' }}
        key={productRoute}
        href={productRoute}
      >
        {product?.images?.length > 0 ? (
          ProductImage
        ) : (
          <Image
            alt="alt"
            src="/assets/images/default/product.png"
            sx={{
              maxWidth: '200px',
              maxHeight: '200px',
            }}
            imageStyle={{
              ...(!outOfStock && {
                opacity: 0.48,
                filter: 'grayscale(1)',
              }),
              aspectRatio: 1 / 1,
              borderRadius: '10px',
            }}
          />
        )}
      </OspLink>
    </Box>
  );

  const renderContent = (
    <Stack spacing={0.5} sx={{ p: 1, pt: 1 }}>
      <TextMaxLine color="black" variant="body2" line={2}>
        {translatedProductName}
      </TextMaxLine>

      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Typography variant="caption" color="black" sx={{ fontSize: '14px' }}>
          {translateCurrencyText}
        </Typography>
        <Typography variant="subtitle2" color="black" sx={{ fontSize: '16px', fontWeight: 700 }}>
          {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
        </Typography>

        {offerPrice && offerPrice < product?.price ? (
          <Box
            component="span"
            sx={{
              color: 'text.disabled',
              textDecoration: 'line-through',
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {currencyConversionPrice}
          </Box>
        ) : (
          ''
        )}
      </Stack>
    </Stack>
  );

  return (
    <Card
      sx={{
        boxShadow: 'none',
        border: 'none',
        position: 'relative',
        overflow: 'visible',
        borderRadius: '12px',
        '&:hover .add-cart-btn': {
          opacity: 1,
        },
        maxWidth: '200px',
        backgroundColor: 'transparent',
      }}
      className="product-cart-wrap"
    >
      {action}
      {renderImg}
      <OspLink
        sx={{ '&:hover': { textDecoration: 'none' } }}
        key={productRoute}
        href={productRoute}
      >
        {renderContent}
      </OspLink>
    </Card>
  );
}

ProductGroupCard.propTypes = {
  product: PropTypes.object,
  action: PropTypes.any,
  noCart: PropTypes.string,
};
