import React from 'react';

import { Stack } from '@mui/system';
import { Box, Skeleton, Typography } from '@mui/material';

const LoadingComponent = () => (
  <Stack direction="row">
    <Box sx={{ width: '70%', padding: 2, my: 3 }}>
      <Typography variant="h4">
        <Skeleton width="40%" />
      </Typography>

      <Box sx={{ mt: 3 }}>
        {[...Array(2)].map((_, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              gap: 2,
            }}
          >
            <Skeleton variant="rectangular" width={100} height={100} />

            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1">
                <Skeleton width="80%" />
              </Typography>
              <Typography variant="body2">
                <Skeleton width="50%" />
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Skeleton variant="rectangular" width={60} height={30} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>

      <Box sx={{ mt: 3, border: '1px solid #f2f2f2', borderRadius: '12px', p: 2 }}>
        <Typography variant="h6">
          <Skeleton width="60%" />
        </Typography>
        {[...Array(3)].map((_, index) => (
          <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Skeleton width="30%" />
            <Skeleton width="20%" />
          </Box>
        ))}
        <Box sx={{ mt: 3 }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
      </Box>
    </Box>
    <Box
      sx={{
        width: '30%',
        maxHeight: '300px',
        mt: 3,
        borderRadius: '12px',
        border: '1px solid #F2F2F2',
        p: 2,
      }}
    >
      <Typography variant="h6">
        <Skeleton width="60%" />
      </Typography>
      {[...Array(3)].map((_, index) => (
        <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Skeleton width="30%" />
          <Skeleton width="20%" />
        </Box>
      ))}
      <Box sx={{ mt: 3 }}>
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Box>
    </Box>
  </Stack>
);

export default LoadingComponent;
