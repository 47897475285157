import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

export default function ImageGrid({ groupDetail }) {
  const { screenWidth } = useScreenWidth();
  const isMobile = screenWidth < 600;

  const translatedText1 = useTranslatedText(groupDetail?.images[0]?.alt_text);
  const transformedImage1 = useImageTransformation(groupDetail?.images[0]?.url, 'w_1000', true);

  const translatedText2 = useTranslatedText(groupDetail?.images[1]?.alt_text);
  const transformedImage2 = useImageTransformation(groupDetail?.images[1]?.url, 'w_1000', true);

  const translatedText3 = useTranslatedText(groupDetail?.images[2]?.alt_text);
  const transformedImage3 = useImageTransformation(groupDetail?.images[2]?.url, 'w_1000', true);

  const translatedText4 = useTranslatedText(groupDetail?.images[3]?.alt_text);
  const transformedImage4 = useImageTransformation(groupDetail?.images[3]?.url, 'w_1000', true);

  const translatedText5 = useTranslatedText(groupDetail?.images[4]?.alt_text);
  const transformedImage5 = useImageTransformation(groupDetail?.images[4]?.url, 'w_1000', true);

  const translatedText6 = useTranslatedText(groupDetail?.images[5]?.alt_text);
  const transformedImage6 = useImageTransformation(groupDetail?.images[5]?.url, 'w_1000', true);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        md={3}
        sm={6}
        xs={6}
        component={m.div}
        initial={{ x: '-100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <OspLink
          type={groupDetail?.images[0]?.link_type}
          href={groupDetail?.images[0]?.link || ''}
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Image
            src={transformedImage1}
            alt={translatedText1}
            sx={{}}
            imageStyle={{
              aspectRatio: isMobile ? 1.9 / 4 : 'unset',
              objectFit: 'cover',
              borderRadius: '15px',
              cursor: 'pointer',
              height: '100%',
            }}
          />
        </OspLink>
      </Grid>

      <Grid
        item
        md={3}
        sm={6}
        xs={6}
        display="flex"
        flexDirection="column"
        gap={2}
        component={m.div}
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.8 }}
      >
        <OspLink
          type={groupDetail?.images[1]?.link_type}
          href={groupDetail?.images[1]?.link || ''}
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Image
            src={transformedImage2}
            alt={translatedText2}
            sx={{}}
            imageStyle={{
              aspectRatio: 1 / 1,
              objectFit: 'cover',
              borderRadius: '15px',
              cursor: 'pointer',
              height: '100%',
            }}
          />
        </OspLink>
        <OspLink
          type={groupDetail?.images[2]?.link_type}
          href={groupDetail?.images[2]?.link || ''}
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Image
            src={transformedImage3}
            alt={translatedText3}
            sx={{}}
            imageStyle={{
              aspectRatio: 1 / 1,
              objectFit: 'cover',
              borderRadius: '15px',
              cursor: 'pointer',
              height: '100%',
            }}
          />
        </OspLink>
      </Grid>

      <Grid
        item
        md={3}
        sm={6}
        xs={6}
        display="flex"
        flexDirection="column"
        gap={2}
        component={m.div}
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.8 }}
      >
        <OspLink
          type={groupDetail?.images[3]?.link_type}
          href={groupDetail?.images[3]?.link || ''}
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Image
            src={transformedImage4}
            alt={translatedText4}
            sx={{}}
            imageStyle={{
              aspectRatio: 1 / 1,
              objectFit: 'cover',
              borderRadius: '15px',
              cursor: 'pointer',
              height: '100%',
            }}
          />
        </OspLink>
        <OspLink
          type={groupDetail?.images[4]?.link_type}
          href={groupDetail?.images[4]?.link || ''}
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Image
            src={transformedImage5}
            alt={translatedText5}
            sx={{}}
            imageStyle={{
              aspectRatio: 1 / 1,
              objectFit: 'cover',
              borderRadius: '15px',
              cursor: 'pointer',
              height: '100%',
            }}
          />
        </OspLink>
      </Grid>

      <Grid
        item
        md={3}
        sm={6}
        xs={6}
        component={m.div}
        initial={{ y: '-100%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.8 }}
      >
        <OspLink
          type={groupDetail?.images[5]?.link_type}
          href={groupDetail?.images[5]?.link || ''}
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Image
            src={transformedImage6}
            alt={translatedText6}
            sx={{}}
            imageStyle={{
              aspectRatio: isMobile ? 1.9 / 4 : 'unset',
              objectFit: 'cover',
              borderRadius: '15px',
              cursor: 'pointer',
              height: '100%',
            }}
          />
        </OspLink>
      </Grid>
    </Grid>
  );
}

ImageGrid.propTypes = {
  groupDetail: PropTypes.any,
};
