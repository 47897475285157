import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Box, CircularProgress } from '@mui/material';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const IncrementorButton = forwardRef(
  (
    {
      quantity,
      onIncrease,
      isMobile,
      onDecrease,
      loading,
      disabledIncrease,
      disabledDecrease,
      sx,
      ...other
    },
    ref
  ) => (
    <Stack
      ref={ref}
      flexShrink={0}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        p: 0.5,
        width: 88,
        borderRadius: 1,
        typography: 'subtitle2',
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
        ...sx,
      }}
      {...other}
    >
      <IconButton
        size={isMobile ? 'medium' : 'small'}
        onClick={onDecrease}
        disabled={disabledDecrease}
        sx={{ borderRadius: 0.75, border: isMobile ? '1px solid #e8ecee' : '' }}
      >
        <Iconify icon="eva:minus-fill" width={16} />
      </IconButton>

      <Box sx={{ display: 'flex' }}>{loading ? <CircularProgress size="10px" /> : quantity}</Box>
      <IconButton
        size={isMobile ? 'medium' : 'small'}
        onClick={onIncrease}
        disabled={disabledIncrease}
        sx={{ borderRadius: 0.75, border: isMobile ? '1px solid #e8ecee' : '' }}
      >
        <Iconify icon="mingcute:add-line" width={16} />
      </IconButton>
    </Stack>
  )
);

IncrementorButton.propTypes = {
  disabledDecrease: PropTypes.bool,
  disabledIncrease: PropTypes.bool,
  onDecrease: PropTypes.func,
  onIncrease: PropTypes.func,
  quantity: PropTypes.number,
  sx: PropTypes.object,
  loading: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default IncrementorButton;
