import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';
import { Box, Card, Divider, Pagination, Typography, paginationClasses } from '@mui/material';

import { paths } from 'src/routes/paths';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { colors } from 'src/utils/colors';
import { fDate } from 'src/utils/format-time';

import { getOrderState } from 'src/server/selectors/selector';
import { fetchAllOrder } from 'src/server/slice/cartOrderSlice';

import OspLink from 'src/components/link/osp-link';

import Searchbar from './searchbar';
import OrderLoading from './order-loading';
import OrderItemsCarousel from './order-items-carousel';

export default function MyAccountOrderList() {
  const [search, setSearch] = useState('');
  const { isMobile } = useScreenWidth();
  const dispatch = useDispatch();
  const { orders, loading } = useSelector(getOrderState);

  const orderList = orders?.orders;
  const totalOrder = orders?.total;
  const limit = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalOrder / limit);

  const getText = useLocalizedText();
  useEffect(() => {
    const credentials = {
      page: currentPage,
      search,
      limit,
      state: {
        filter_data: {
          is_deleted: false,
        },
        sort_data: {
          createdAt: -1,
        },
      },
    };
    dispatch(fetchAllOrder(credentials));
  }, [currentPage, dispatch, search]);

  const empty = !orderList?.length;

  return (
    <Stack>
      <Stack
        sx={{
          flexGrow: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Searchbar search={search} setSearch={setSearch} />
      </Stack>

      {loading ? (
        <OrderLoading />
      ) : (
        <>
          {empty ? (
            <Card
              sx={{
                minHeight: '340px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 3,
                boxShadow: 'none',
                border: `1px solid ${colors.themeLightGray}`,
              }}
            >
              <OspLink href={paths.public.products}>
                <Typography variant="body1" textAlign="center">
                  {getText('nothing_here!_why_don’t_we_go_for_some')}
                  <Typography
                    variant="body1"
                    component="span"
                    color={colors.themeSecondary}
                    sx={{ ml: 0.5 }}
                  >
                    {getText('shopping')}
                  </Typography>
                </Typography>
              </OspLink>
            </Card>
          ) : (
            <>
              <Card
                sx={{
                  p: 2,
                  mt: 2,
                  boxShadow: 'none',
                  border: `1px solid ${colors.themeLightGray}`,
                }}
              >
                {orderList?.map((orderItem, index) => (
                  <React.Fragment key={orderItem?.uid || index}>
                    <Stack direction="row" justifyContent="space-between" alignItems="start" mb={2}>
                      <Stack
                        width="100%"
                        direction={isMobile ? 'column' : 'row'}
                        alignItems={isMobile && 'start'}
                      >
                        <Box>
                          <Typography variant="body2" fontWeight="bold" color={colors.themeBlack}>
                            {getText('order_id')}: {orderItem?.order_id}
                          </Typography>
                        </Box>
                        {!isMobile && <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />}

                        {orderItem?.delivered_at ? (
                          <>
                            <Typography variant="body2" sx={{ color: colors.themeGreen }}>
                              {getText('delivered_on')} :&nbsp;
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {fDate(orderItem?.delivered_at)}
                              </Typography>
                            </Typography>{' '}
                          </>
                        ) : (
                          <>
                            {orderItem?.estimated_delivery_date && (
                              <Typography variant="body2" sx={{ color: colors.themeOrange }}>
                                {getText('delivery_by')} :&nbsp;
                                <Typography
                                  variant="body2"
                                  component="span"
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {fDate(orderItem?.estimated_delivery_date)}
                                </Typography>
                              </Typography>
                            )}
                          </>
                        )}
                      </Stack>
                      <Stack width="100%" alignItems="end">
                        <OspLink
                          sx={{ '&:hover': { textDecoration: 'none' }, color: 'black' }}
                          key={paths.private.my_account.orderView(orderItem?.uid)}
                          href={paths.private.my_account.orderView(orderItem?.uid)}
                        >
                          <Stack
                            border="1px solid #e9e9e9"
                            borderRadius="10px"
                            alignItems="center"
                            justifyContent="center"
                            direction="row"
                            spacing={1}
                            px="16px"
                            py="8px"
                            width="fit-content"
                            sx={{
                              borderRadius: '2px',
                            }}
                          >
                            <DescriptionIcon
                              sx={{ fontSize: '17px', color: colors.themePrimary }}
                            />
                            <Typography variant="body2"> {getText('view_details')} </Typography>
                          </Stack>
                        </OspLink>
                      </Stack>
                    </Stack>
                    <OrderItemsCarousel orderData={orderItem?.order_items} />
                    {index + 1 !== orderList?.length && (
                      <Divider
                        sx={{ width: '100%', margin: 'auto', mt: isMobile ? 0 : 2, my: 3 }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Card>
              {totalOrder > 6 && (
                <Pagination
                  onChange={(e, newPage) => setCurrentPage(newPage)}
                  count={totalPages || 8}
                  page={currentPage}
                  sx={{
                    my: 4,

                    [`& .${paginationClasses.ul}`]: {
                      justifyContent: 'center',
                    },
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
}

MyAccountOrderList.propTypes = {};
