import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Stack } from '@mui/system';
import { Grid, Button, Typography, CircularProgress } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';

import { getProfileState } from 'src/server/selectors/selector';
import { removeAddress, fetchAllAddress, makeDefaultAddress } from 'src/server/slice/userSlice';

import { ConfirmDialog } from 'src/components/custom-dialog';

import AddressForm from 'src/sections/checkout/address/address-new-form';
import { MyProfileAddressSkeleton } from 'src/sections/myAccount/address/address-skeleton';

import MyAccountAddress from './my-account-address';

export default function MyAccountAddressList() {
  const getText = useLocalizedText();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const addressForm = useBoolean();

  const { loading, createAddressLoading, address } = useSelector(getProfileState);

  const defaultAddress =
    address?.userShippingAddresses?.find((item) => item.is_default === true) || null;
  const nonDefaultAddresses =
    address?.userShippingAddresses?.filter((item) => !item.is_default) || [];

  const noAddress = address?.userShippingAddresses?.length <= 0;

  useEffect(() => {
    const credentials = {
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
      },
      page: 1,
      search: '',
      limit: 10,
    };
    dispatch(fetchAllAddress(credentials));
  }, [dispatch]);

  const [selectedAddressId, setSelectedAddressId] = useState('');

  const handleEditAddress = (addressId) => {
    addressForm.onTrue();
    setSelectedAddressId(addressId);
  };

  const handleCloseAddressForm = () => {
    addressForm.onFalse();
    setSelectedAddressId('');
  };

  const confirmRemoveAddress = useBoolean();

  const handleRemoveAddressPopup = (addressId) => {
    confirmRemoveAddress?.onTrue();
    setSelectedAddressId(addressId);
  };

  const handleDeleteAddressConfirmClick = () => {
    const credentials = {
      addressId: selectedAddressId,
      onClosePopup: confirmRemoveAddress?.onFalse,
      enqueueSnackbar,
    };
    dispatch(removeAddress(credentials));
  };
  const handleMakeDefaultAddressClick = (addressUid) => {
    const credentials = {
      state: {
        user_shipping_address: addressUid,
        is_default: true,
      },
      enqueueSnackbar,
      dispatch,
    };

    dispatch(makeDefaultAddress(credentials));
  };
  return (
    <>
      {addressForm.value && (
        <AddressForm
          variant="popup"
          open={addressForm.value}
          onClose={handleCloseAddressForm}
          updateDataId={selectedAddressId}
        />
      )}
      <Grid container spacing={2} mb={3}>
        {loading ? (
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <MyProfileAddressSkeleton />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2">Address List</Typography>
                <Button
                  sx={{
                    backgroundColor: colors.themePrimary,
                    '&:hover': { backgroundColor: colors.themePrimary },
                    color: 'white',
                    borderRadius: '4px',
                    px: '12px',
                    py: '8px',
                  }}
                  size="medium"
                  onClick={() => addressForm.onTrue()}
                >
                  {getText('add_new_address')}
                </Button>
              </Stack>
            </Grid>

            {noAddress && (
              <Box
                sx={{
                  border: '1px solid rgba(229, 229, 229, 0.50)',
                  my: 2,
                  mx: 2,
                  borderRadius: '6px',
                  height: '200px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="body2">
                  There is no address provided. Please add one by clicking{' '}
                  <Typography
                    component="span"
                    variant="button"
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => addressForm.onTrue()}
                  >
                    {getText('add_new_address')}
                  </Typography>
                </Typography>
              </Box>
            )}

            {defaultAddress ? (
              <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                <MyAccountAddress
                  address={defaultAddress}
                  onClose={handleCloseAddressForm}
                  updateDataId={selectedAddressId}
                  handleEditAddress={handleEditAddress}
                  handleRemoveAddressPopup={handleRemoveAddressPopup}
                  isAddressDefault="true"
                />
              </Grid>
            ) : (
              ''
            )}

            {nonDefaultAddresses.map((data, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                <MyAccountAddress
                  address={data}
                  onClose={handleCloseAddressForm}
                  updateDataId={selectedAddressId}
                  handleEditAddress={handleEditAddress}
                  handleRemoveAddressPopup={handleRemoveAddressPopup}
                  handleMakeDefaultAddressClick={handleMakeDefaultAddressClick}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
      {confirmRemoveAddress.value && (
        <ConfirmDialog
          open={confirmRemoveAddress.value}
          onClose={confirmRemoveAddress.onFalse}
          title={getText('remove')}
          content={`${getText('are_you_sure_you_want_to_delete_this_address')}?`}
          action={
            <Button variant="contained" color="error" onClick={handleDeleteAddressConfirmClick}>
              {getText('remove')}{' '}
              {createAddressLoading && (
                <CircularProgress color="inherit" size={14} sx={{ color: '#fff', mx: 1 }} />
              )}
            </Button>
          }
        />
      )}
    </>
  );
}

MyAccountAddressList.propTypes = {};
