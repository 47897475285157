import { useState, useEffect } from 'react';

export const useResponsive = () => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  const isMobile = width <= 500;
  const isSmallTab = width < 768 && width > 500;
  const isTab = width <= 1024 && width >= 768;
  const isLargeTab = width < 1200 && width >= 1024;
  const isWeb = width > 1200;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Only add the event listener if window is defined (client-side)
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return {
    isMobile,
    isSmallTab,
    isTab,
    isLargeTab,
    isWeb,
  };
};
