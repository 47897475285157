import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';

import { MailIcon } from 'src/assets/icon/MailIcon';
import { PhoneIcon } from 'src/assets/icon/PhoneIcon';
import { AddressIcon } from 'src/assets/icon/AddressIcon';
import { ContactIcon } from 'src/assets/icon/ContactIcon';
import { getSettingsState } from 'src/server/selectors/selector';

import Logo from 'src/components/logo';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';

// ----------------------------------------------------------------------

export default function Footer() {
  const getText = useLocalizedText();

  const { basicSettings } = useSelector(getSettingsState);

  const footerColumnData = useTranslatedText(basicSettings?.layout);

  const LINKS = [
    {
      headline: footerColumnData?.footer_column_1_heading || '',
      children: footerColumnData?.footer_column_1_titles || '',
    },
    {
      headline: footerColumnData?.footer_column_2_heading || '',
      children: footerColumnData?.footer_column_2_titles || '',
    },
    {
      headline: footerColumnData?.footer_column_3_heading || '',
      children: footerColumnData?.footer_column_3_titles || '',
    },
    {
      headline: footerColumnData?.footer_column_4_heading || '',
      children: footerColumnData?.footer_column_4_titles || '',
    },
    {
      headline: footerColumnData?.footer_column_5_heading || '',
      children: footerColumnData?.footer_column_5_titles || '',
    },
  ];

  // ----------------------------------------------------------------------

  const companyDetails = basicSettings?.company;

  const footerContactData = [
    {
      title: getText('po_box:_1869,_pc_112,_muscat,_sultanate_of_oman'),
      icon: <AddressIcon />,
    },
    {
      title: (
        <>
          {getText('Mail')}: {companyDetails?.contact_details?.email}
        </>
      ),
      icon: <MailIcon />,
    },
    {
      title: <>{getText('customer_care')}: +968-79998866</>,
      icon: <ContactIcon />,
    },
    {
      title: (
        <>
          {getText('phone')} : {companyDetails?.contact_details?.phone}
        </>
      ),
      icon: <PhoneIcon />,
    },
  ];

  const social_links = basicSettings?.social_links;

  const _socials = [
    {
      value: 'facebook',
      name: 'FaceBook',
      icon: 'eva:facebook-fill',
      color: '#ffffff',
      // color: '#1877F2',
      path: social_links?.facebook,
      size: 20,
    },
    {
      value: 'instagram',
      name: 'Instagram',
      icon: 'ant-design:instagram-filled',
      // color: '#E02D69',
      color: '#ffffff',
      path: social_links?.instagram,
      size: 20,
    },

    {
      value: 'twitter',
      name: 'Twitter',
      icon: 'prime:twitter',
      // color: '#00AAEC',
      color: '#ffffff',
      path: social_links?.x,
      size: 15,
    },
  ];

  const siteName = useTranslatedText(companyDetails?.name);

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: '#242b35',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 2,
          textAlign: { xs: 'center', md: 'unset' },
          color: '#fff',
          height: 'auto',
        }}
        maxWidth="xl"
      >
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={12} md={9}>
            <Stack spacing={5} direction={{ xs: 'column', sm: 'row' }}>
              {LINKS?.map((list, key) => (
                <Stack
                  key={key}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" sx={{ color: '#878787' }} variant="body2">
                    {list?.headline}
                  </Typography>

                  {list?.children?.length >= 1 &&
                    list?.children?.map((link, key1) => (
                      <Fragment key={key1}>
                        <OspLink
                          href={link?.link || ''}
                          sx={{ color: 'white' }}
                          color="inherit"
                          variant="body2"
                        >
                          {link?.title}
                        </OspLink>
                      </Fragment>
                    ))}
                </Stack>
              ))}
            </Stack>
          </Grid>

          <Grid
            xs={8}
            md={3}
            sx={{
              borderLeft: '1px solid #39424d',
              paddingLeft: '40px',
            }}
          >
            <Typography component="div" sx={{ color: '#878787' }} variant="body2" mb={1}>
              {getText('contact_us')}
            </Typography>

            <Stack spacing={1.5} mb={2}>
              {footerContactData?.map((item, key) => (
                <Box key={key} sx={{ display: 'flex', alignItems: 'start', gap: '10px' }}>
                  {item?.icon}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      mx: { xs: 'auto', md: 'unset', marginTop: '-4.5px', fontWeight: 400 },
                    }}
                  >
                    {item?.title}
                  </Typography>
                </Box>
              ))}
            </Stack>

            <Logo sx={{ objectFit: 'cover' }} isFooter />

            <Typography component="div" sx={{ color: '#878787' }} variant="body2">
              {getText('follow_us_on')}
            </Typography>
            <Stack direction="row" spacing={1.5} mt={1}>
              {_socials.map((social, key) => (
                <a href={social?.path} key={key}>
                  <IconButton
                    key={social.name}
                    sx={{
                      padding: 0,
                      '&:hover': {
                        bgcolor: alpha(social.color, 0.08),
                      },
                    }}
                  >
                    <Iconify
                      color={social.color}
                      icon={social.icon}
                      width={social?.size}
                      height={social?.size}
                    />
                  </IconButton>
                </a>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Divider sx={{ my: 1 }} />

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            variant="body2"
            display="flex"
            flexDirection="column"
            sx={{ color: '#878787' }}
          >
            ©2024 {siteName}
            <Typography variant="caption">
              Designed & Developed by
              <OspLink href="https://osperb.com/" type="EXTERNAL">
                <Typography variant="caption" color="#2948db" pl={0.5}>
                  osperb
                </Typography>
              </OspLink>
            </Typography>
          </Typography>

          <Image
            src="/assets/images/default/payment-method.png"
            alt=""
            sx={{
              maxHeight: '200px',
              maxWidth: '200px',
            }}
            width={200}
            height={200}
            imgLayout="fixed"
          />
        </Stack>
      </Container>
    </Box>
  );

  return mainFooter;
}
