import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { fetchBanners } from 'src/server/slice/bannerSlice';
import { fetchBrandSingleView } from 'src/server/slice/brandSlice';
import { fetchCollectionSingleView } from 'src/server/slice/collectionSlice';
import { getBannerState, getCollectionState } from 'src/server/selectors/selector';

import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import { useSettingsContext } from 'src/components/settings';

import Banners from 'src/sections/home/banners';
import ProductCardOne from 'src/sections/product/cards/product-card-one';
import { ProductItemSkeleton } from 'src/sections/product/common/product-skeleton';

import SubBannerCarousel from '../sub-banner-carousel';
import { BrandItemSkeleton } from '../collection-skeleton';

export default function CollectionDetailsView({ id }) {
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  const { screenWidth, isMobile } = useScreenWidth();
  // const getText = useLocalizedText();

  const removeArrow = id?.replaceAll('-', ' ');

  const { collectionDetails, loading } = useSelector(getCollectionState);
  const { banners } = useSelector(getBannerState);

  useEffect(() => {
    dispatch(fetchCollectionSingleView({ name: removeArrow }));
  }, [dispatch, removeArrow]);

  const collectionSingleViewData = collectionDetails;

  useEffect(() => {
    const credentials = {
      state: {
        module: 'COLLECTION',
        module_id: collectionSingleViewData?.uid,
        filter_data: {
          is_deleted: false,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    if (collectionSingleViewData && collectionSingleViewData?.uid) {
      dispatch(fetchBanners(credentials));
    }
  }, [collectionSingleViewData, dispatch]);

  const subBanner = banners?.banners?.filter((item) => item.position === 'SUB');
  const mainBanner = banners?.banners?.filter((item) => item.position === 'MAIN');
  const productData = collectionDetails?.items || [];

  useEffect(() => {
    dispatch(fetchBrandSingleView({ name: removeArrow }));
  }, [dispatch, removeArrow]);

  const renderSkeleton = <BrandItemSkeleton />;

  const renderError = (
    <EmptyContent
      filled
      title="Collection not found"
      action={
        <Button
          component={RouterLink}
          href={paths.public.products}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
          sx={{ mt: 3 }}
        >
          Back to List
        </Button>
      }
      sx={{ py: 10 }}
    />
  );

  const renderProductSkeleton = (
    <>
      {[...Array(16)].map((_, index) => (
        <ProductItemSkeleton key={index} />
      ))}
    </>
  );

  const renderCollection = collectionSingleViewData?.uid && (
    <>
      <Box px={{ xs: 0, md: 2 }} mt={{ xs: 0, md: 4 }}>
        <Banners bannerData={mainBanner} />
      </Box>
      <Grid container spacing={2} className="null-margin-calculate">
        <Grid mb={2} xs={12} md={12} lg={12} mt={{ xs: 0.5, md: 0 }} px={{ xs: 1, md: 2 }}>
          <SubBannerCarousel bannerData={subBanner} />
        </Grid>

        {/* <Box
          px={3}
          gap={3}
          display="grid"
          width="100%"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(4, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
        >
          {loading ? (
            renderProductSkeleton
          ) : (
            <>
              {productData?.slice(0, 4)?.map((product, key) => (
                <ProductCardOne key={key} product={product} />
              ))}
            </>
          )}
        </Box> */}
        <Box
          px={3}
          gap={3}
          display="grid"
          width="100%"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(4, 1fr)',
            lg: 'repeat(6, 1fr)',
          }}
        >
          {loading ? (
            renderProductSkeleton
          ) : (
            <>
              {productData?.map((product, key) => (
                <ProductCardOne key={key} product={product} />
              ))}
            </>
          )}
        </Box>
      </Grid>
    </>
  );

  const handleMarginTop = () => {
    let value = 0;

    if (!isMobile) {
      if (screenWidth < 900) {
        value = 18;
      } else if (screenWidth < 1200) {
        value = 8;
      }
    }

    return value;
  };

  return (
    <Container
      className="padding-zero"
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mt: handleMarginTop(),
        mb: 15,
      }}
    >
      {loading && renderSkeleton}

      {!loading && !collectionSingleViewData?.uid && renderError}

      {!loading && collectionSingleViewData?.uid && renderCollection}
    </Container>
  );
}

CollectionDetailsView.displayName = 'CollectionDetailsView';

CollectionDetailsView.propTypes = {
  id: PropTypes.string,
};
