import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Rating from '@mui/material/Rating';
import { Grid, Stack, Button, Typography, Pagination, paginationClasses } from '@mui/material';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useGetToken } from 'src/hooks/useHandleSessions';

import { fShortenNumber } from 'src/utils/format-number';

import { fetchReview } from 'src/server/slice/reviewSlice';
import { checkProductPurchase } from 'src/server/slice/productSlice';
import { getReviewState, getProductState } from 'src/server/selectors/selector';

import ReviewDialog from './reviewModal';
import ProductReviewList from './product-review-list';

const variants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export default function ProductDetailsReview({ totalRatings, totalReviews, productId }) {
  const dispatch = useDispatch();
  const { screenWidth, isMobile } = useScreenWidth();
  const getText = useLocalizedText();
  const { token } = useGetToken();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [showMore, setShowMore] = useState(false);
  const [openReviewPopup, setOpenReviewPopup] = useState(false);
  const [openReviewId, setOpenReviewId] = useState({});

  const { reviews } = useSelector(getReviewState);
  const { checkProductPurchaseStatus } = useSelector(getProductState);

  const reviewList = reviews?.reviews;
  const totalReview = reviews?.total;
  const totalPages = Math.ceil(totalReview / limit);

  useEffect(() => {
    if (!totalReview) {
      const credentials = {
        state: {
          filter_data: {
            product: productId,
            is_deleted: false,
          },
        },
        page: currentPage,
        search: '',
        limit,
      };
      dispatch(fetchReview(credentials));
    }
  }, [currentPage, dispatch, limit, totalReview, productId]);

  const handleReadMore = () => {
    if (showMore) {
      setLimit(4);
      setShowMore(false);
      setCurrentPage(1);
    } else {
      setLimit(10);
      setShowMore(true);
    }
    // window.scrollTo({
    //   top: window.scrollY + 500,
    //   behavior: 'smooth',
    // });
  };
  const handleButtonClick = () => {
    setOpenReviewPopup(true);
  };

  useEffect(() => {
    if (productId && token) {
      const credentials = { productId };
      dispatch(checkProductPurchase(credentials));
    }
    // eslint-disable-next-line no-sparse-arrays
  }, [dispatch, productId, token]);

  const renderSummary = (
    <Stack
      sx={{ backgroundColor: '#F3F3FC', borderRadius: '12px', p: 2, px: 4 }}
      spacing={1}
      alignItems="center"
      justifyContent="center"
      mb={2}
    >
      {/* <Typography variant="subtitle2">Average rating</Typography> */}
      <Typography variant="h2">{totalRatings?.toFixed(1)}</Typography>
      <Rating readOnly value={totalRatings} precision={0.1} />
      {totalReviews > 0 && (
        <Typography variant="caption" component="h2" sx={{ color: 'text.secondary' }}>
          ({fShortenNumber(totalReviews)} reviews)
        </Typography>
      )}
    </Stack>
  );

  const renderReviewButton = (
    <Stack alignItems="center" spacing={1} justifyContent="center">
      <Typography sx={{ px: 2, my: 1, textAlign: 'center' }} variant="body2">
        {getText('share_your_thoughts_with_other_customers')}
      </Typography>
      {checkProductPurchaseStatus?.is_ordered_product === true && (
        <Button
          sx={{ width: '100%', borderRadius: '50px' }}
          size="medium"
          variant="outlined"
          color="inherit"
          onClick={handleButtonClick}
        >
          {getText('write_a_product_review')}
        </Button>
      )}
    </Stack>
  );

  return (
    <>
      {openReviewPopup && (
        <ReviewDialog
          isUpdate={openReviewId}
          setOpenReviewId={setOpenReviewId}
          setOpen={setOpenReviewPopup}
          open={openReviewPopup}
          product={productId}
          orderId={checkProductPurchaseStatus?.order}
        />
      )}
      {reviews?.total >= 1 && (
        <Grid container sx={{ margin: 0, py: 4, position: 'relative' }}>
          <Grid
            sx={{
              position: !isMobile && 'sticky',
              top: '100px',
              height: '250px',
            }}
            item
            xs={12}
            sm={7}
            md={4}
            lg={4}
            xl={4}
          >
            {screenWidth <= 900 && (
              <Typography variant="h6" mb={3} sx={{ mx: 2.1 }}>
                {getText('customer_review')} ({totalReviews || totalReview})
              </Typography>
            )}
            <Stack sx={{ mx: 2.5 }}>
              {renderSummary}
              {renderReviewButton}
            </Stack>
          </Grid>

          <Grid item xs={12} md={8} lg={8} mt={0}>
            <Stack sx={{ px: { md: 10, sm: 0 } }}>
              {screenWidth > 900 && (
                <Typography variant="subtitle1">
                  {getText('customer_review')}({totalReviews || totalReview})
                </Typography>
              )}

              <ProductReviewList
                reviews={reviewList}
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                transition={{ duration: 2 }}
              />

              {totalReview > 10 && showMore && (
                <Pagination
                  onChange={(e, newPage) => setCurrentPage(newPage)}
                  count={totalPages || 8}
                  page={currentPage}
                  sx={{
                    mt: 4,
                    [`& .${paginationClasses.ul}`]: {
                      justifyContent: 'center',
                    },
                  }}
                />
              )}

              {totalReview > 3 && (
                <Button
                  sx={{ my: 3, color: '#1F59B8' }}
                  size="large"
                  color="inherit"
                  variant="outlined"
                  onClick={handleReadMore}
                >
                  {showMore ? `${getText('read_less')}` : `${getText('read_more')}`}
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
}

// {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
// {/* <ProductReviewNewForm open={review.value} onClose={review.onFalse} /> */}

ProductDetailsReview.propTypes = {
  totalRatings: PropTypes.number,
  totalReviews: PropTypes.number,
  productId: PropTypes.any,
};
