import React from 'react';

import { Stack, Container } from '@mui/system';
import { Button, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';

import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';
import { useSettingsContext } from 'src/components/settings';

export const OrderFail = () => {
  const { isMobile } = useScreenWidth();
  const settings = useSettingsContext();
  const getText = useLocalizedText();

  const renderList = (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mb: 15,
        my: isMobile ? 2 : 4,
      }}
    >
      <Stack alignItems="center">
        <Iconify
          icon="mdi:cross-circle-outline"
          sx={{ mb: 2, color: '#FB3337', mr: 2, height: '45px', width: '45px' }}
        />
        <Typography variant="subtitle1" sx={{ fontSize: '22px' }} mb={1.5} color="black">
          {getText('order_failed')}
        </Typography>
        <Typography variant="body1">{getText('we_were_unable_to_place_your_order')}.</Typography>
        <Typography variant="body1" textAlign="center">
          {getText('any_payment_deducted_will_be_refunded_to_your_original_payment_method')}
        </Typography>
        <Stack direction={isMobile ? 'column' : 'row'} mt={4} spacing={3}>
          <OspLink href={paths.public.products}>
            <Button
              size="large"
              sx={{ px: 6, borderRadius: '4px', color: 'black' }}
              variant="outlined"
            >
              {getText('return_to_product_page')}
            </Button>
          </OspLink>
          <OspLink href={paths.public.home}>
            <Button
              size="large"
              width="100%"
              sx={{
                px: 6,
                borderRadius: '4px',
                backgroundColor: colors.themePrimary,
                width: '100%',
                '&:hover': { backgroundColor: '#1968e9' },
              }}
              variant="contained"
            >
              {getText('back_to_shopping')}
            </Button>
          </OspLink>
        </Stack>
        {/* <Divider sx={{ width: '100%', my: 4 }} /> */}
        {/* <AddressBox /> */}
      </Stack>
    </Container>
  );
  return <>{renderList}</>;
};

OrderFail.propTypes = {};
