import * as Yup from 'yup';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// @mui
import {
  Grid,
  Stack,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { colors } from 'src/utils/colors';

import { updateUser } from 'src/server/slice/userSlice';
import { getProfileState } from 'src/server/selectors/selector';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

MyAccountProfileDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function MyAccountProfileDialog({ open, setOpen, isUpdate }) {
  // const theme = useTheme();
  // const router = useRouter();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useScreenWidth();

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const getText = useLocalizedText();

  const { loading, user } = useSelector(getProfileState);

  const ProfileSchema = Yup.object().shape({
    name: Yup.string(),
  });

  const defaultValues = useMemo(() => {
    if (user?.uid) {
      return {
        name: user?.name,
      };
    }
    return {
      name: '',
    };
  }, [user?.uid, user?.name]);
  const methods = useForm({
    resolver: yupResolver(ProfileSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    const UpdateCredentials = {
      state: {
        name: data?.name,
        phone: user?.phone,
        country_code: user?.country_code,
      },
      dispatch,
      handleClose,
      handleClear,
      enqueueSnackbar,
    };

    if (user?.uid) {
      dispatch(updateUser(UpdateCredentials));
    }
  });

  const renderProfileForm = (
    <Stack spacing={3} sx={{ p: 1, px: 0 }}>
      <Grid container maxWidth="md" spacing={2} pl={0}>
        <Grid xs={12} md={12} item>
          <RHFTextField name="name" label="Name" type="string" placeholder="Name" size="medium" />
        </Grid>
        <Grid xs={12} md={12} item>
          <Stack>
            <Box
              py={1.7}
              px={1}
              sx={{ borderRadius: 1, border: '1px solid rgba(145, 158, 171, 0.2)' }}
            >
              <Typography
                component="div"
                color="text.disabled"
                pl={1}
                display="flex"
                alignItems="center"
                gap={2}
              >
                <Typography
                  pr={1}
                  display="flex"
                  alignItems="center"
                  gap={0.5}
                  sx={{ borderRight: '1px solid rgba(145, 158, 171, 0.2)' }}
                >
                  {user?.country_code}
                  <KeyboardArrowDownIcon />
                </Typography>
                {user?.phone}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Stack sx={{ marginBottom: '1rem' }} justifyContent="end" alignItems="center">
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          loading={isSubmitting || loading}
          sx={{
            color: 'white',
            backgroundColor: colors.themePrimary,
            '&:hover': {
              backgroundColor: colors.themePrimary,
            },
            ml: 'auto',
          }}
        >
          {getText('Save')}
        </LoadingButton>
      </Stack>
    </Stack>
  );
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen/}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {getText('edit_profile')}

          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              p: 0.5,
              top: 10,
              right: 10,
              transform: 'scale(0.8)',
              position: 'absolute',
              color: 'common.white',
              bgcolor: (theme1) => alpha(theme1.palette.grey[900], 0.48),
              '&:hover': {
                bgcolor: (theme1) => alpha(theme1.palette.grey[900], 0.72),
              },
            }}
          >
            <Iconify icon="mingcute:close-line" width={14} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '10px' : '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderProfileForm}
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
