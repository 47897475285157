import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
// @mui
import { Stack, Dialog, Typography, DialogContent } from '@mui/material';

// components

import FormProvider, { RHFTextField } from 'src/components/hook-form';

import Image from '../image';

ImageAltTextPopup.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
  handleAddAltTextToImage: PropTypes.func,
};

export default function ImageAltTextPopup({ open, setOpen, isUpdate, handleAddAltTextToImage }) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  const AltTextSchema = Yup.object().shape({
    alt_text: Yup.object().shape({
      english: Yup.string(),
      arabic: Yup.string(),
    }),
  });

  const defaultValues = useMemo(
    () => ({
      alt_text: {
        english: isUpdate?.alt_text?.english || '',
        arabic: isUpdate?.alt_text?.arabic || '',
      },
    }),
    [isUpdate]
  );

  const methods = useForm({
    resolver: yupResolver(AltTextSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
    handleClose();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [isUpdate, reset, defaultValues]);

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    handleAddAltTextToImage(data);
    handleClear();
  });
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent sx={{ minWidth: '550px', p: 2 }}>
          <Typography variant="subtitle2" mb={2}>
            Alt Text
          </Typography>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2} sx={{ px: 0 }}>
              <Image
                alt="review"
                src={isUpdate?.url}
                sx={{
                  height: '60px',
                  width: '60px',
                }}
                imageStyle={{
                  height: isUpdate?.url && 300,
                  width: isUpdate?.url && 400,
                  maxHeight: isUpdate?.url && '400px',
                  objectFit: 'contain',
                }}
              />

              <Stack spacing={1.5}>
                <RHFTextField
                  name="alt_text.english"
                  label="English"
                  type="text"
                  size="small"
                  placeholder="Alt text English here"
                />
              </Stack>
              <Stack spacing={1.5} className="input-arabic">
                <RHFTextField
                  name="alt_text.arabic"
                  label="Arabic"
                  type="text"
                  size="small"
                  placeholder="Alt text Arabic here"
                />
              </Stack>
              <Stack sx={{ alignItems: 'flex-end' }}>
                <Stack sx={{ marginBottom: '1rem', flexDirection: 'row' }} alignItems="center">
                  <LoadingButton
                    variant="contained"
                    size="small"
                    type="button"
                    className="cancelButtonModal"
                    onClick={handleClose}
                    sx={{ mr: 1.5 }}
                  >
                    Cancel{' '}
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="small"
                    loading={isSubmitting}
                    className="submitButtonModal"
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
