import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, InputBase } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';

import { useLocales, useTranslate } from 'src/locales';
import { getCategoriesState } from 'src/server/selectors/selector';
import { fetchCategoriesByHierarchy } from 'src/server/slice/categorySlice';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';

import NavMobile from './nav/mobile';
import NavCurrency from './nav/desktop/nav-currency';

// ----------------------------------------------------------------------

export default function MobileHeader() {
  const { currentLang } = useLocales();
  const { onChangeLang } = useTranslate();
  const { screenWidth } = useScreenWidth();
  const dispatch = useDispatch();
  const getText = useLocalizedText();

  const { categoriesByHierarchy: serverCategoriesByHierarchy } = useSelector(getCategoriesState);

  const categoriesByHierarchy = serverCategoriesByHierarchy;

  const nestedCategory = categoriesByHierarchy?.categories;

  const handleChangeLang = useCallback(
    (newLang) => {
      onChangeLang(newLang);
    },
    [onChangeLang]
  );

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: 10,
      state: {},
    };
    dispatch(fetchCategoriesByHierarchy(credentials));
  }, [dispatch]);

  return (
    <Box sx={{ backgroundColor: '#232B35' }} py={2} px={2} width="100%">
      <Stack
        direction={screenWidth < 366 ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box>
          <OspLink href={paths.public.home} sx={{ display: 'contents' }}>
            <Image
              alt="buildex-logo"
              src="/assets/images/logs/logo.webp"
              disabledEffect
              sx={{ borderRadius: 1, maxWidth: screenWidth <= 400 ? 220 : 235, maxHeight: 40 }}
            />
          </OspLink>
        </Box>

        <Stack direction="row" alignItems="center">
          <NavCurrency />

          {currentLang?.value === 'en' ? (
            <Button
              variant="text"
              sx={{ fontSize: 14, color: '#fff' }}
              onClick={() => handleChangeLang('ar')}
            >
              العربية
            </Button>
          ) : (
            <Button
              variant="text"
              sx={{ fontSize: 14, color: '#fff' }}
              onClick={() => handleChangeLang('en')}
            >
              English
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <OspLink
          href={paths.public.search}
          sx={{ width: '85%', '&:hover': { textDecoration: 'none' } }}
        >
          <Stack direction="row" alignItems="center">
            <InputBase
              fullWidth
              placeholder={getText('search_for_products')}
              inputProps={{
                sx: { typography: 'p' },
              }}
              endAdornment={
                <Iconify
                  icon="uil:search"
                  sx={{ color: colors.themeSecondary, height: '25px', width: '25px' }}
                />
              }
              sx={{
                backgroundColor: '#fff',
                borderRadius: '4px',
                padding: '7px 10px',
                height: '35px',
              }}
            />
          </Stack>
        </OspLink>
        <NavMobile data={nestedCategory} />
      </Stack>
    </Box>
  );
}
