import React from 'react';

import MetaHead from 'src/utils/metaHead';

import Searchbar from 'src/layouts/common/searchbar';

function SearchPage() {
  return (
    <>
      <MetaHead
        title="Search Products - Buildex E-Commerce Platform"
        description="Find the perfect products on Buildex E-Commerce Platform. Search through a wide range of categories to discover exactly what you need, quickly and easily."
        keywords="product search, search products, e-commerce search, Buildex, product categories, find products"
        openGraph={{
          title: 'Search Products - Buildex E-Commerce Platform',
          description:
            'Discover and search for products on Buildex E-Commerce Platform. Easily find what you need with advanced filters and a wide range of categories.',
        }}
        twitter={{
          title: 'Search Products - Buildex E-Commerce Platform',
          description:
            'Explore the Buildex E-Commerce Platform to search for products quickly and easily. Find exactly what you need with a seamless shopping experience.',
        }}
      />
      <Searchbar isFullPage />
    </>
  );
}

export default SearchPage;
