import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/system';
import {
  Card,
  Grid,
  Button,
  Divider,
  Typography,
  Pagination,
  CircularProgress,
  paginationClasses,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';

import { fetchReview, removeReview } from 'src/server/slice/reviewSlice';
import { getReviewState, getProfileState } from 'src/server/selectors/selector';

import { ConfirmDialog } from 'src/components/custom-dialog';

import { MyProfileReviewSkeleton } from 'src/sections/myAccount/review/review-skeleton';

import MyAccountReview from './my-account-review';

// ----------------------------------------------------------------------

// export const metadata = {
//   title: 'My-Account-Review',
// };

export default function MyAccountReviewList() {
  const dispatch = useDispatch();
  const { isMobile } = useScreenWidth();
  const getText = useLocalizedText();
  const { enqueueSnackbar } = useSnackbar();
  const limit = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const { user } = useSelector(getProfileState);
  const { reviews, loading, deleteReviewLoading } = useSelector(getReviewState);

  const totalReview = reviews?.total;
  const totalPages = Math.ceil(totalReview / limit);

  const reviewList = reviews?.reviews;
  const [selectedAddressId, setSelectedAddressId] = useState('');

  useEffect(() => {
    const credentials = {
      state: {
        filter_data: {
          user: user?.uid,
          is_deleted: false,
        },
      },
      page: currentPage,
      search: '',
      limit,
    };
    dispatch(fetchReview(credentials));
  }, [currentPage, dispatch, user?.uid]);

  const empty = reviews?.reviews?.length === 0;
  const confirmRemoveReview = useBoolean();

  const handleRemoveAddressPopup = (addressId) => {
    confirmRemoveReview?.onTrue();
    setSelectedAddressId(addressId);
  };

  const handleDeleteAddressConfirmClick = () => {
    const credentials = {
      reviewId: selectedAddressId,
      onClosePopup: confirmRemoveReview?.onFalse,
      enqueueSnackbar,
    };
    dispatch(removeReview(credentials));
  };

  return (
    <>
      {loading ? (
        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
          <MyProfileReviewSkeleton />
        </Grid>
      ) : (
        <Stack spacing={1} mb={4}>
          {empty && (
            <Card
              sx={{
                minHeight: '290px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                boxShadow: 'none',
                border: `1px solid ${colors.themeLightGray}`,
              }}
            >
              <Typography variant="body1" textAlign="center">
                {getText('no_reviews_added_yet')}
              </Typography>
            </Card>
          )}
          {(reviewList || []).map((item, index) => (
            <React.Fragment key={index}>
              <MyAccountReview review={item} handleRemoveAddressPopup={handleRemoveAddressPopup} />
              {index + 1 !== reviewList?.length && (
                <Divider sx={{ width: '100%', margin: 'auto', mt: isMobile ? 0 : 2, mb: 2 }} />
              )}
            </React.Fragment>
          ))}
          {totalReview > 6 && (
            <Pagination
              onChange={(e, newPage) => setCurrentPage(newPage)}
              count={totalPages || 8}
              page={currentPage}
              sx={{
                my: 4,
                [`& .${paginationClasses.ul}`]: {
                  justifyContent: 'center',
                },
              }}
            />
          )}
        </Stack>
      )}
      {confirmRemoveReview.value && (
        <ConfirmDialog
          open={confirmRemoveReview.value}
          onClose={confirmRemoveReview.onFalse}
          title={getText('remove')}
          content={`${getText('are_you_sure_you_want_to_delete_this_review')}?`}
          action={
            <Button variant="contained" color="error" onClick={handleDeleteAddressConfirmClick}>
              {getText('remove')}{' '}
              {deleteReviewLoading && (
                <CircularProgress color="inherit" size={14} sx={{ color: '#fff', mx: 1 }} />
              )}
            </Button>
          }
        />
      )}
    </>
  );
}

MyAccountReviewList.propTypes = {};
