import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { Divider } from '@mui/material';
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import { fDate } from 'src/utils/format-time';

import Iconify from 'src/components/iconify';
import Lightbox, { useLightBox } from 'src/components/lightbox';

// ----------------------------------------------------------------------

export default function ProductReviewItem({ review }) {
  const { name, user, phone, rating, message, createdAt, images } = review;

  const slides = [
    ...(images?.map((img) => ({
      src: img ?? {},
    })) || []),
  ];

  const lightbox = useLightBox(slides);

  const maskPhoneNumber = (text) => {
    if (!text) return '';
    const visibleDigits = text.slice(0, 5);
    const maskedSection = text.slice(5).replace(/\d/g, '*');

    return `${visibleDigits}${maskedSection}`;
  };

  const renderInfo = (
    <Stack
      spacing={1.5}
      alignItems="center"
      direction="row"
      sx={{
        // width: { md: 240 },
        textAlign: 'start',
      }}
    >
      <Avatar
        src=""
        sx={{
          width: 36,
          height: 36,
        }}
      />

      <ListItemText
        primary={name || user?.name}
        secondary={user?.uid && maskPhoneNumber(phone || `+${user?.country_code} ${user?.phone}`)}
        primaryTypographyProps={{
          noWrap: true,
          typography: 'body2',
          mb: 0,
          color: '#808080',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          typography: 'caption',
          component: 'span',
          color: '#808080',
        }}
      />
    </Stack>
  );

  const renderContent = (
    <Stack spacing={1} flexGrow={1}>
      <Rating size="small" value={rating} precision={0.1} readOnly />
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          color: 'success.main',
          typography: 'caption',
        }}
      >
        <Iconify icon="ic:round-verified" width={16} sx={{ mr: 0.5 }} />
        Verified purchase
      </Stack>

      <Typography variant="body2">{message}</Typography>

      {!!images?.length && (
        <Stack direction="row" flexWrap="wrap" spacing={1} sx={{ pt: 1 }}>
          {images.map((attachment, index) => (
            <Avatar
              src={attachment?.url || ''}
              sx={{ width: 64, height: 64, borderRadius: 0, cursor: 'zoom-in' }}
              alt="alt"
              key={index}
              onClick={() => lightbox.onOpen(attachment)}
            />
          ))}
        </Stack>
      )}
      <Typography variant="caption" sx={{ color: '#808080' }}>
        {fDate(createdAt)}
      </Typography>
    </Stack>
  );

  return (
    <Stack spacing={2} direction="column" sx={{ mt: 5, px: { xs: 2.5, md: 0 } }}>
      {renderInfo}
      {renderContent}
      <Divider />

      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
        onGetCurrentIndex={(index) => lightbox.setSelected(index)}
      />
    </Stack>
  );
}

ProductReviewItem.propTypes = {
  review: PropTypes.object,
};
