import { useParams } from 'src/routes/hooks';

import OrderSinglePage from 'src/sections/myAccount/order/order-single-page';

// ----------------------------------------------------------------------

export default function OrderSinglePageScreen() {
  const params = useParams();
  const { id } = params;

  return <OrderSinglePage id={id} />;
}
