import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';

import { countries } from 'src/assets/data/countries';

import Iconify from 'src/components/iconify';

// import { FlagIcon } from './flag-icon';
import { applyFilter } from '../phone-input/utils';
import SearchNotFound from '../search-not-found/search-not-found';

// ----------------------------------------------------------------------

export function CustomCountryListPopover({ countryCode, onClickCountry }) {
  const [searchCountry, setSearchCountry] = useState('');

  const handleSearchCountry = useCallback((event) => {
    setSearchCountry(event.target.value);
  }, []);

  const dataFiltered = applyFilter({
    inputData: countries,
    query: searchCountry,
  });

  const notFound = !dataFiltered.length && !!setSearchCountry;

  const renderList = (
    <Box
      sx={{
        maxHeight: 170,
        overflow: 'auto', // add scrolling
      }}
    >
      <MenuList>
        {dataFiltered.slice(0, 5).map((country) => {
          if (!country.code) {
            return null;
          }

          return (
            <MenuItem
              key={country.code}
              selected={countryCode === country.code}
              autoFocus={countryCode === country.code}
              onClick={() => {
                // popover.onClose();
                setSearchCountry('');
                onClickCountry(country.phone);
              }}
            >
              <ListItemText
                primary={country.label}
                secondary={`${country.code} (+${country.phone})`}
                primaryTypographyProps={{ noWrap: true, typography: 'body2' }}
                secondaryTypographyProps={{ typography: 'caption' }}
              />
            </MenuItem>
          );
        })}
      </MenuList>
    </Box>
  );

  return (
    <>
      <Box sx={{ px: 1, pt: 2 }}>
        <TextField
          autoFocus
          fullWidth
          value={searchCountry}
          onChange={handleSearchCountry}
          placeholder="Search..."
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ width: 250 }}>
        {notFound ? <SearchNotFound query={searchCountry} sx={{ px: 2, pt: 5 }} /> : renderList}
      </Box>
    </>
  );
}

CustomCountryListPopover.propTypes = {
  countryCode: PropTypes.any,
  onClickCountry: PropTypes.any,
};
