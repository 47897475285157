import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Stack } from '@mui/material';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { getProductState } from 'src/server/selectors/selector';
import { fetchAllProducts } from 'src/server/slice/productSlice';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

import ProductCardOne from 'src/sections/product/cards/product-card-one';

export const OverlapList = ({ groupDetail }) => {
  const { screenWidth, isMobile } = useScreenWidth();
  const dispatch = useDispatch();

  const { products } = useSelector(getProductState);

  const categories = groupDetail?.type === 'SINGLE_CATEGORY' && groupDetail?.categories[0]?.uid;
  const brands = groupDetail?.type === 'SINGLE_BRAND' && groupDetail?.brands[0]?.uid;
  const collections = groupDetail?.type === 'SINGLE_COLLECTION' && groupDetail?.collections[0]?.uid;
  const specialSales =
    groupDetail?.type === 'SINGLE_SPECIAL_SALE' && groupDetail?.special_sales[0]?.uid;

  const handleProductData = () => {
    if (
      groupDetail?.is_live &&
      (groupDetail?.type === 'SINGLE_CATEGORY' ||
        groupDetail?.type === 'SINGLE_COLLECTION' ||
        groupDetail?.type === 'SINGLE_BRAND' ||
        groupDetail?.type === 'SINGLE_SPECIAL_SALE' ||
        groupDetail?.type === 'PRODUCTS')
    ) {
      return products?.products;
    }
    return groupDetail?.products;
  };

  const finalGroupWithActiveProducts = handleProductData()?.filter((item) => item?.is_active);

  useEffect(() => {
    if (groupDetail?.is_live) {
      const credentials = {
        page: 1,
        search: '',
        limit: 10,
        state: {
          filter_data: {
            is_active: true,
            is_deleted: false,
            ...(categories && {
              categories: {
                $in: [categories],
              },
            }),
            ...(brands && {
              brands: {
                $in: [brands],
              },
            }),
            ...(collections && {
              collections: {
                $in: [collections],
              },
            }),
            ...(specialSales && {
              special_sales: {
                $in: [specialSales],
              },
            }),
          },
          sort_data: {
            createdAt: -1,
          },
        },
      };
      dispatch(fetchAllProducts(credentials));
    }
  }, [dispatch, groupDetail, categories, brands, collections, specialSales]);

  const handleHeight = () => {
    if (screenWidth < 700) {
      return {
        show: 2,
      };
    }
    if (screenWidth < 900) {
      return {
        show: 3,
      };
    }

    if (screenWidth < 1200) {
      return {
        show: 4,
      };
    }

    return {
      show: 6,
    };
  };

  const translatedText = useTranslatedText(groupDetail?.images[0]?.alt_text);
  const transformedImage = useImageTransformation(groupDetail?.images[0]?.url, 'w_1600', true);
  return (
    <Stack
      position="relative"
      borderRadius={isMobile ? 0 : 2}
      sx={{
        minHeight: '500px',
      }}
    >
      <OspLink href={groupDetail?.link}>
        <Image
          src={transformedImage}
          alt={translatedText}
          className="cursor-pointer"
          sx={{}}
          imageStyle={{
            borderRadius: isMobile ? 0 : 2,
          }}
        />
      </OspLink>

      <Box width="100%" sx={{ position: 'absolute', bottom: isMobile ? 20 : 10 }} px={1}>
        <Box
          columnGap={1}
          rowGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(4, 1fr)',
            lg: 'repeat(6, 1fr)',
          }}
        >
          {finalGroupWithActiveProducts?.slice(0, handleHeight()?.show)?.map((item, key) => (
            <ProductCardOne isMinimal key={key} product={item} />
          ))}
        </Box>
      </Box>
    </Stack>
  );
};

OverlapList.propTypes = {
  groupDetail: PropTypes.any,
};
