import { useEffect } from 'react';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Stack, Divider, Typography, IconButton } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { colors } from 'src/utils/colors';

import { getProductState } from 'src/server/selectors/selector';
import { fetchAllProducts } from 'src/server/slice/productSlice';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';
import { cardVariants } from 'src/components/animate';

import ProductCardOne from 'src/sections/product/cards/product-card-one';

export default function Highlight({ groupDetail }) {
  const { isMobile, isTab } = useScreenWidth();
  const getText = useLocalizedText();
  const rtl = useLayoutStyle();
  const dispatch = useDispatch();

  const { products } = useSelector(getProductState);

  const handleProductData = () => {
    if (
      groupDetail?.is_live &&
      (groupDetail?.type === 'SINGLE_CATEGORY' ||
        groupDetail?.type === 'SINGLE_COLLECTION' ||
        groupDetail?.type === 'SINGLE_BRAND' ||
        groupDetail?.type === 'SINGLE_SPECIAL_SALE' ||
        groupDetail?.type === 'PRODUCTS')
    ) {
      return products?.products;
    }
    return groupDetail?.products;
  };

  const finalGroupWithActiveProducts = handleProductData()?.filter((item) => item?.is_active);

  // .product?.filter((i) => i.is_active) : '';

  const categories = groupDetail?.type === 'SINGLE_CATEGORY' && groupDetail?.categories[0]?.uid;
  const brands = groupDetail?.type === 'SINGLE_BRAND' && groupDetail?.brands[0]?.uid;
  const collections = groupDetail?.type === 'SINGLE_COLLECTION' && groupDetail?.collections[0]?.uid;
  const specialSales =
    groupDetail?.type === 'SINGLE_SPECIAL_SALE' && groupDetail?.special_sales[0]?.uid;

  useEffect(() => {
    if (groupDetail?.is_live) {
      const credentials = {
        page: 1,
        search: '',
        limit: 10,
        state: {
          filter_data: {
            is_active: true,
            is_deleted: false,
            ...(categories && {
              categories: {
                $in: [categories],
              },
            }),
            ...(brands && {
              brands: {
                $in: [brands],
              },
            }),
            ...(collections && {
              collections: {
                $in: [collections],
              },
            }),
            ...(specialSales && {
              special_sales: {
                $in: [specialSales],
              },
            }),
          },
          sort_data: {
            createdAt: -1,
          },
        },
      };
      dispatch(fetchAllProducts(credentials));
    }
  }, [dispatch, groupDetail, categories, brands, collections, specialSales]);

  const translatedTitle = useTranslatedText(groupDetail?.title);
  const translatedText = useTranslatedText(groupDetail?.images[0]?.alt_text);
  const transformedImage = useImageTransformation(groupDetail?.images[0]?.url, 'w_1000', true);

  return (
    <>
      {isMobile && translatedTitle && (
        <OspLink href={groupDetail?.link} sx={{ '&:hover': { textDecoration: 'none' } }}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            {translatedTitle && (
              <Box position="relative">
                <Typography variant="body1" fontSize={isMobile ? 18 : 20} color="black">
                  {translatedTitle}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: colors.themeSecondary,
                    height: 2,
                    width: 150,
                    position: 'absolute',
                    bottom: -9,
                  }}
                />
              </Box>
            )}

            {groupDetail?.link && (
              <Typography fontSize={14} display="flex" alignItems="center" gap={1} color="gray">
                {getText('view_all')}
                {rtl ? (
                  <IconButton
                    sx={{
                      backgroundColor: colors.themePrimary,
                      p: '5px',
                      '&:hover': {
                        backgroundColor: colors.themePrimary,
                      },
                    }}
                  >
                    <Iconify icon="solar:alt-arrow-left-outline" width={12} color="white" />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      backgroundColor: colors.themePrimary,
                      p: '5px',
                      '&:hover': {
                        backgroundColor: colors.themePrimary,
                      },
                    }}
                  >
                    <Iconify icon="solar:alt-arrow-right-outline" width={12} color="white" />
                  </IconButton>
                )}
              </Typography>
            )}
          </Stack>
          <Divider sx={{ mb: 2, mt: 1 }} />
        </OspLink>
      )}

      <Grid item container spacing={2}>
        <Grid item md={4} sm={12} xs={12} xl={4}>
          <OspLink href={groupDetail?.link || ''}>
            <Image
              src={transformedImage}
              alt={translatedText}
              className="cursor-pointer"
              sx={{
                width: '100%',
                height: '100%',
              }}
              imageStyle={{
                borderRadius: '15px',
              }}
            />
          </OspLink>
        </Grid>
        <Grid item md={8} sm={12} xs={12} xl={8}>
          {!(isMobile || isTab) && translatedTitle && (
            <OspLink
              href={groupDetail?.link || ''}
              sx={{ '&:hover': { textDecoration: 'none' }, position: 'relative' }}
            >
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                  <Typography variant="body1" fontSize={20} color="black">
                    {translatedTitle}
                  </Typography>
                  {groupDetail?.link && (
                    <Typography
                      variant="body2"
                      display="flex"
                      alignItems="center"
                      gap={2}
                      color="text.disabled"
                    >
                      {getText('view_all')}
                      {rtl ? (
                        <IconButton
                          sx={{
                            backgroundColor: colors.themePrimary,
                            borderRadius: '50%',
                            p: 0.5,
                            '&:hover': {
                              backgroundColor: colors.themePrimary,
                            },
                          }}
                        >
                          <Iconify icon="solar:alt-arrow-left-outline" width={20} />
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{
                            backgroundColor: colors.themePrimary,
                            borderRadius: '50%',
                            p: 0.5,
                            '&:hover': {
                              backgroundColor: colors.themePrimary,
                            },
                          }}
                        >
                          <Iconify icon="solar:alt-arrow-right-outline" width={20} color="white" />
                        </IconButton>
                      )}
                    </Typography>
                  )}
                </Stack>
                <Divider sx={{ mb: 2, mt: 1 }} />
              </>

              <Box
                sx={{
                  backgroundColor: colors.themeSecondary,
                  height: 2,
                  width: 150,
                  position: 'absolute',
                  bottom: 0,
                }}
              />
            </OspLink>
          )}
          <Grid item container spacing={1}>
            {finalGroupWithActiveProducts?.slice(0, 8)?.map((item, key) => (
              <Grid
                item
                key={key}
                xl={!transformedImage ? 2 : 3}
                md={3}
                sm={4}
                xs={6}
                className="mb-4"
              >
                <m.div
                  key={key}
                  variants={cardVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.4, delay: key * 0.1 }}
                >
                  <ProductCardOne product={item} />
                </m.div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

Highlight.propTypes = {
  groupDetail: PropTypes.any,
};
