import { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import InputBase from '@mui/material/InputBase';

import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

function Searchbar({ setSearch, search }) {
  const getText = useLocalizedText();
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  return (
    <Box sx={{ flex: 1, position: 'relative' }}>
      <InputBase
        fullWidth
        placeholder={getText('search_orders')}
        value={search || ''}
        onChange={handleSearch}
        inputProps={{
          sx: { typography: 'p' },
        }}
        endAdornment={
          <IconButton
            sx={{
              backgroundColor: colors.themePrimary,
              color: 'white',
              borderRadius: '0px 6px 6px 0px',
              px: '20px',
              height: '40px',
              '&:hover': {
                backgroundColor: colors.themePrimary,
              },
            }}
            size="small"
          >
            <Iconify icon="uil:search" sx={{ color: 'white', height: '20px', width: '20px' }} />
          </IconButton>
        }
        sx={{
          // backgroundColor: '#f2f2f2',
          border: `1px solid ${colors.themeLightGray}`,
          borderRadius: '6px',
          padding: '0px 0px 0px 10px',
          color: 'black',
        }}
      />
    </Box>
  );
}

export default memo(Searchbar);

Searchbar.propTypes = {
  search: PropTypes.any,
  setSearch: PropTypes.any,
};
