import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import { usePathname } from 'src/routes/hooks';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import { useGetToken } from 'src/hooks/useHandleSessions';

import { TOKEN_PREFIX } from 'src/server/http';
import { fetchUser } from 'src/server/slice/userSlice';
import { fetchMyCart, getLocalCart } from 'src/server/slice/cartOrderSlice';
import { fetchCurrency, fetchSettings } from 'src/server/slice/settingsSlice';
import {
  getCartSate,
  getCouponState,
  getProfileState,
  getCurrencyState,
  getSettingsState,
} from 'src/server/selectors/selector';

import { SplashScreen } from 'src/components/loading-screen';

import LoginPopup from 'src/sections/authentication/loginPopup';

import Header from './header';
import Footer from './footer';
import MobileHeader from './mobileHeader';
import MobileFooter from './mobileFooter';
import MobileFooterBar from './mobileFooterBar';

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  const { token } = useGetToken(TOKEN_PREFIX);
  const [isMounted, setIsMounted] = useState(false);
  const pathname = usePathname();
  const { isMobile } = useScreenWidth();
  const dispatch = useDispatch();
  const { isValidCoupon } = useSelector(getCouponState);

  const homePage = pathname === '/';

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { user } = useSelector(getProfileState);
  const { cart } = useSelector(getCartSate);
  const { basicSettings } = useSelector(getSettingsState);
  const { currency } = useSelector(getCurrencyState);

  useEffect(() => {
    if (!user?.uid) {
      dispatch(fetchUser());
    }
  }, [dispatch, user?.uid]);

  useEffect(() => {
    if (!basicSettings?.uid) {
      dispatch(fetchSettings({ type: 'basic' }));
    }
  }, [dispatch, basicSettings?.uid]);

  useEffect(() => {
    if (!currency?.total) {
      const credentials = {
        page: 1,
        search: '',
        limit: 10,
        state: {
          filter_data: {
            is_deleted: false,
          },
        },
      };
      dispatch(fetchCurrency(credentials));
    }
  }, [currency?.total, dispatch]);

  useEffect(() => {
    if (token) {
      if (!cart?.uid) {
        dispatch(fetchMyCart());
      }
    } else {
      dispatch(getLocalCart());
    }
  }, [dispatch, token, isValidCoupon, cart?.uid]);

  if (!isMounted) {
    return <SplashScreen />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1, paddingBottom: '20px' }}>
      <LoginPopup />

      {isMobile ? <MobileHeader /> : <Header />}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!homePage && {
            pt: { xs: 0, md: 10 },
          }),
        }}
      >
        {children}
      </Box>

      {isMobile ? <MobileFooter /> : <Footer />}

      {isMobile && <MobileFooterBar />}
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
