import PropTypes from 'prop-types';

import { Stack } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

const BrandList = ({ brandData }) => {
  const { isMobile } = useScreenWidth();
  const formattedImage = useImageTransformation(brandData?.logo?.url, 'w_500');
  const formattedAltText = useTranslatedText(brandData?.logo?.alt_text);

  return (
    <OspLink
      href={paths.public.brandView(brandData?.name?.english.replace(/ /g, '-'))}
      sx={{ '&:hover': { textDecoration: 'none' } }}
    >
      <Stack>
        <Image
          src={formattedImage}
          alt={formattedAltText}
          sx={{
            cursor: 'pointer',
            maxWidth: isMobile ? 60 : 100,
          }}
          imageStyle={{
            objectFit: 'contain',
            maxWidth: '100%',
            aspectRatio: 1 / 1,
          }}
        />
      </Stack>
    </OspLink>
  );
};

export default BrandList;

BrandList.propTypes = {
  brandData: PropTypes.any,
};
