import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Stack, Avatar } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { fetchBanners } from 'src/server/slice/bannerSlice';
import { fetchAllBrandProducts } from 'src/server/slice/productSlice';
import { fetchBrands, fetchBrandSingleView } from 'src/server/slice/brandSlice';
import { getBrandSate, getBannerState, getProductState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';
import EmptyContent from 'src/components/empty-content';
import { useSettingsContext } from 'src/components/settings';

import Banners from 'src/sections/home/banners';
import ProductCardOne from 'src/sections/product/cards/product-card-one';
import { ProductItemSkeleton } from 'src/sections/product/common/product-skeleton';

import { BrandItemSkeleton } from '../brand-skeleton';
import SubBannerCarousel from '../sub-banner-carousel';

export default function BrandDetailsView({ id }) {
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  const width = useScreenWidth();
  const { isMobile, screenWidth } = useScreenWidth();
  const isBellowTab = width < 768;
  const removeArrow = id?.replaceAll('-', ' ');

  const { brands: subBrands, loading: brandLoading, brandSingleView } = useSelector(getBrandSate);
  const { banners } = useSelector(getBannerState);
  const { brandProduct, brandProductLoading } = useSelector(getProductState);

  useEffect(() => {
    dispatch(fetchBrandSingleView({ name: removeArrow }));
  }, [dispatch, removeArrow]);

  const brandSingleViewData = brandSingleView;
  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: 10,
      state: {
        filter_data: {
          is_deleted: false,
          main_brand: brandSingleViewData?.uid,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    if (brandSingleViewData && brandSingleViewData?.uid) {
      dispatch(fetchBrands(credentials));
    }
  }, [brandSingleViewData, dispatch]);

  useEffect(() => {
    const credentials = {
      state: {
        module: 'BRAND',
        module_id: brandSingleViewData?.uid,
        filter_data: {
          is_deleted: false,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    if (brandSingleViewData && brandSingleViewData?.uid) {
      dispatch(fetchBanners(credentials));
    }
  }, [brandSingleViewData, dispatch]);

  const subBanner = banners?.banners?.filter((item) => item.position === 'SUB');
  const mainBanner = banners?.banners?.filter((item) => item.position === 'MAIN');
  const productData = brandProduct?.products || [];

  console.log(brandSingleViewData?.uid);

  useEffect(() => {
    if (brandSingleViewData?.uid) {
      const credentials = {
        page: '',
        search: '',
        limit: '',
        state: {
          filter_data: {
            is_deleted: false,
            brands: { $in: [brandSingleViewData?.uid] },
            // {/* brands: brandSingleViewData?.uid, */}
          },
          sort_data: {
            createdAt: -1,
          },
        },
      };
      dispatch(fetchAllBrandProducts(credentials));
    }
  }, [brandSingleViewData?.uid, dispatch]);

  useEffect(() => {
    dispatch(fetchBrandSingleView({ name: removeArrow }));
  }, [dispatch, removeArrow]);

  const renderSkeleton = <BrandItemSkeleton />;

  const renderError = (
    <EmptyContent
      filled
      title="Brand not found"
      action={
        <Button
          component={RouterLink}
          href={paths.public.products}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
          sx={{ mt: 3 }}
        >
          Back to List
        </Button>
      }
      sx={{ py: 10 }}
    />
  );

  const renderProductSkeleton = (
    <>
      {[...Array(16)].map((_, index) => (
        <ProductItemSkeleton key={index} />
      ))}
    </>
  );
  const renderBrand = brandSingleViewData?.uid && (
    <>
      <Box px={{ xs: 0, md: 2 }} mt={{ xs: 0, md: 4 }}>
        <Banners bannerData={mainBanner} />
      </Box>
      <Grid container spacing={2} className="null-margin-calculate">
        <Grid xs={12} md={12} lg={12} mt={{ xs: 0.5, md: 0 }} px={{ xs: 1, md: 2 }}>
          <SubBannerCarousel bannerData={subBanner} />
        </Grid>

        <Box
          px={3}
          gap={3}
          display="grid"
          width="100%"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(4, 1fr)',
            lg: 'repeat(6, 1fr)',
          }}
        >
          {brandProductLoading ? (
            renderProductSkeleton
          ) : (
            <>
              {productData?.map((product, key) => (
                <ProductCardOne key={key} product={product} />
              ))}
            </>
          )}
        </Box>
      </Grid>
    </>
  );

  const translatedBrandName = useTranslatedText(brandSingleViewData?.name);
  const brandLogo = useImageTransformation(brandSingleViewData?.logo?.url, 'w_600');

  const getMarginTop = () => {
    if (isMobile) return 0;
    if (screenWidth < 900) return 17;
    if (screenWidth < 1200) return 8;
    return 0;
  };

  return (
    <>
      <Container maxWidth="xl" className="custom-padding-brand">
        <Stack
          className="sub-Brand "
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          spacing={{ xs: 3, md: 15 }}
          mt={getMarginTop()}
          sx={{
            backgroundColor: '#F3F3FC',
            padding: { xs: '0px 15px 16px 15px', lg: '0px 15px 16px 30px' },
          }}
        >
          <Stack flex={3}>
            <Box
              sx={{
                backgroundColor: '#fff',
                height: isBellowTab ? '110px' : '140px',
                width: isBellowTab ? '110px' : '140px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
              }}
            >
              {brandSingleViewData?.logo?.url ? (
                <Image
                  alt={brandSingleViewData?.logo?.public_id}
                  src={brandLogo}
                  sx={{ padding: '12px' }}
                  imageStyle={{
                    aspectRatio: 1 / 1,
                  }}
                />
              ) : (
                <Stack sx={{ p: isMobile ? 1 : 2 }}>
                  <Box component="div">
                    <TextMaxLine variant="subtitle1" line={2}>
                      {translatedBrandName}
                    </TextMaxLine>
                  </Box>
                </Stack>
              )}
            </Box>
          </Stack>

          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'start',
              flexDirection: 'row',
              gap: 2,
              overflowX: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              maxWidth: '100%',
            }}
          >
            {subBrands?.brands?.map((item, key) => (
              <OspLink
                sx={{ '&:hover': { textDecoration: 'none' } }}
                key={paths.public.brandView(item?.name?.english?.replace(/ /g, '-'))}
                href={`${paths.public.brandView(item?.name?.english?.replace(/ /g, '-'))}`}
              >
                <Stack
                  sx={{ padding: '5px' }}
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.5}
                >
                  <SubBrandLogoImage item={item} />
                  {TranslateSubBrandName(item?.name)}
                </Stack>
              </OspLink>
            ))}
          </Stack>
        </Stack>
      </Container>

      <Container
        className="padding-zero"
        maxWidth={settings.themeStretch ? false : 'xl'}
        sx={{
          mt: { xs: 0, md: 1, sm: 3 },
          mb: 15,
        }}
      >
        {brandLoading && renderSkeleton}

        {!brandLoading && !brandSingleViewData?.uid && renderError}

        {!brandLoading && brandSingleViewData?.uid && renderBrand}
      </Container>
    </>
  );
}
BrandDetailsView.displayName = 'BrandDetailsView';

BrandDetailsView.propTypes = {
  id: PropTypes.string,
};

function TranslateSubBrandName(item) {
  const brandName = useTranslatedText(item);
  return (
    <TextMaxLine variant="caption" color="black" line={2} sx={{ textAlign: 'center' }}>
      {brandName}
    </TextMaxLine>
  );
}

const SubBrandLogoImage = ({ item }) => {
  const BrandLogo = useImageTransformation(item?.logo?.url, 'w_400');
  const BrandLogoAltText = useTranslatedText(item?.logo?.alt_text);

  const { isMobile } = useScreenWidth();

  const { brandSingleView } = useSelector(getBrandSate);

  const brandSingleViewData = brandSingleView;
  const TranslatedText = useTranslatedText(brandSingleViewData?.name);

  return (
    <>
      {item?.logo?.url ? (
        <Avatar
          sx={{
            width: isMobile ? 50 : 70,
            height: isMobile ? 50 : 70,
            backgroundColor: 'transparent',
          }}
        >
          <Image
            src={BrandLogo}
            alt={BrandLogoAltText}
            ratio="1/1"
            sx={{
              width: 80,
              height: 80,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            imageStyle={{
              width: 80,
              height: 80,
            }}
          />
        </Avatar>
      ) : (
        <Box
          sx={{
            width: isMobile ? 50 : 70,
            height: isMobile ? 50 : 70,
            backgroundColor: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
          }}
        >
          <TextMaxLine
            variant={isMobile ? 'body2' : 'subtitle2'}
            line={2}
            sx={{ fontWeight: 'fontWeightSemiBold', opacity: 0.4, color: 'black' }}
          >
            {TranslatedText}
          </TextMaxLine>
        </Box>
      )}
    </>
  );
};

SubBrandLogoImage.propTypes = {
  item: PropTypes.any.isRequired,
};
