import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

import useTranslatedText from 'src/hooks/useTranslatedText';

import Iconify from 'src/components/iconify';

function AddressCard({ addressData = {}, addressOnly }) {
  const {
    // uid,
    name,
    first_name,
    middle_name,
    last_name,
    address,
    address_line_1,
    address_line_2,
    street,
    landmark,
    area,
    state,
    city,
    district,
    country,
    tag,
    phone,
    pincode,
    country_code,
    whatsapp_number,
    whatsapp_number_country_code,
    email,
    // is_default,
    // is_last_used,
  } = addressData;

  const translatedState = useTranslatedText(state?.title || {});
  const translatedDistrict = useTranslatedText(district?.title || {});
  const translatedCountry = useTranslatedText(country?.title || {});

  return (
    <>
      {addressOnly ? (
        <Typography variant="body2" sx={{ color: '' }}>
          {middle_name && `${middle_name} `}
          {last_name && `${last_name} `}
        </Typography>
      ) : (
        <>
          <Typography
            variant="subtitle2"
            sx={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}
          >
            {name || first_name}
            <Box component="span" sx={{ ml: 0.5, typography: 'overline', color: 'text.secondary' }}>
              ({tag})
            </Box>
          </Typography>
          <Typography variant="body2" sx={{ color: '' }}>
            {middle_name && `${middle_name} `}
            {last_name && `${last_name} `}
          </Typography>
        </>
      )}

      <Typography variant="body2" sx={{ overflowWrap: 'anywhere', whiteSpace: 'normal' }}>
        {address && (
          <Typography variant="body2" component="span">
            {address}
          </Typography>
        )}
        {street && (
          <Typography variant="body2" component="span" pl={0.5}>
            {street}
          </Typography>
        )}
        {landmark && (
          <Typography variant="body2" component="span" pl={0.5}>
            {landmark}
          </Typography>
        )}
        {area && (
          <Typography variant="body2" component="span" pl={0.5}>
            {area}
          </Typography>
        )}
        {city && (
          <Typography variant="body2" component="span" pl={0.5}>
            {city}
          </Typography>
        )}
        {translatedDistrict && (
          <Typography variant="body2" component="span" pl={0.5}>
            {translatedDistrict},
          </Typography>
        )}
        {translatedState && (
          <Typography variant="body2" component="span" pl={0.5}>
            {translatedState},
          </Typography>
        )}
        {translatedCountry && (
          <Typography variant="body2" component="span" pl={0.5}>
            {translatedCountry},
          </Typography>
        )}
        {address_line_1 && (
          <Typography variant="body2" component="span" pl={0.5}>
            {address_line_1}
          </Typography>
        )}
        {address_line_2 && (
          <Typography variant="body2" component="span" pl={0.5}>
            {address_line_2}
          </Typography>
        )}
        {pincode && (
          <Typography variant="body2" component="span" pl={0.5}>
            {pincode}
          </Typography>
        )}
      </Typography>

      {email && (
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', gap: 0.5 }}
        >
          <EmailRoundedIcon fontSize="small" sx={{ height: '14px' }} /> {address?.email}
          {email}
        </Typography>
      )}

      <Typography variant="caption" color="#999" mt={2} mb={0.5}>
        Contact No :
      </Typography>
      {phone && country_code && (
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', gap: 0.5 }}
        >
          {/* <LocalPhoneIcon fontSize="small" sx={{ height: '14px' }} /> */}+{country_code} {phone}
        </Typography>
      )}

      {whatsapp_number && (
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', gap: 0.5 }}
        >
          <Iconify icon="basil:whatsapp-outline" sx={{ height: '14px' }} />+
          {whatsapp_number_country_code} {whatsapp_number}
        </Typography>
      )}
    </>
  );
}

export default AddressCard;

AddressCard.propTypes = {
  addressData: PropTypes.any,
  addressOnly: PropTypes.bool,
};
