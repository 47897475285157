import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

import { Box, IconButton } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

import ProductCardOne from './product-card-one';

// SwiperCore.use([Navigation, Autoplay]);

function RelatedProductCarousel({ products }) {
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(false);
  const swiperRef = useRef(null);

  const updateArrowVisibility = (swiper) => {
    setShowPrevArrow(swiper.activeIndex > 0);
    setShowNextArrow(!swiper.isEnd);
  };

  return (
    // <Box className="sub-banner-slider" sx={{ position: 'relative' }}>
    <Box sx={{ position: 'relative' }}>
      <Swiper
        autoplay={false}
        modules={[Navigation, Autoplay]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          updateArrowVisibility(swiper);
        }}
        spaceBetween={15}
        navigation={{
          prevEl: '.custom_prev_sub_banner',
          nextEl: '.custom_next_sub_banner',
        }}
        className="custom-class mt-2"
        onSlideChange={(swiper) => updateArrowVisibility(swiper)}
        breakpoints={{
          300: {
            slidesPerView: 2,
          },
          400: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 4,
          },
          640: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 6,
          },
          1024: {
            slidesPerView: 6,
          },
          1200: {
            slidesPerView: 6,
          },
        }}
      >
        {products?.map((item, key) => (
          <SwiperSlide key={key}>
            <ProductCardOne product={item} />
          </SwiperSlide>
        ))}
      </Swiper>

      {showPrevArrow && (
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            right: '10px',
            zIndex: 9,
            transform: 'translateY(-50%)',
          }}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <ChevronRightRoundedIcon />
        </IconButton>
      )}
      {showNextArrow && (
        <IconButton
          onClick={() => swiperRef.current?.slideNext()}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '10px',
            zIndex: 9,
            transform: 'translateY(-50%)',
          }}
        >
          <KeyboardArrowLeftRoundedIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default RelatedProductCarousel;

RelatedProductCarousel.propTypes = {
  products: PropTypes.any,
};
