import MetaHead from 'src/utils/metaHead';

import { CheckoutView } from 'src/sections/checkout/view';

export default function CheckoutPage() {
  return (
    <>
      <MetaHead
        title="Secure Checkout"
        description="Complete your purchase securely with Buildex. Enjoy a seamless checkout experience with various payment options."
        keywords="checkout, secure payment, e-commerce, Buildex, online shopping, order confirmation"
        openGraph={{
          title: 'Secure Checkout - Buildex E-Commerce Platform',
          description:
            'Complete your purchase securely with Buildex. Enjoy a seamless checkout experience with various payment options.',
        }}
        twitter={{
          title: 'Secure Checkout - Buildex E-Commerce Platform',
          description:
            'Complete your purchase securely with Buildex. Enjoy a seamless checkout experience with various payment options.',
        }}
      />

      <CheckoutView />
    </>
  );
}
