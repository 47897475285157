import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, Stack } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';
import { Divider, IconButton, Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';

import { colors } from 'src/utils/colors';

import { getOrderSingleViewState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';

import ReviewDialog from 'src/sections/product/review/reviewModal';

// ----------------------------------------------------------------------

export default function MyAccountOrderProducts({ row, orderId }) {
  const getText = useLocalizedText();
  const translatedText = useTranslatedText();
  const [openReviewPopup, setOpenReviewPopup] = useState(false);
  const [openReviewId, setOpenReviewId] = useState({});
  const {
    quantity,
    product,
    product_item_price,
    image_url,
    product_price_discount,
    shipping_price,
  } = row;

  const { orderSingleView } = useSelector(getOrderSingleViewState);

  const translatedProductName = useTranslatedText(product?.name);
  const currencyConversionPrice = useCurrencyConversion(product?.price);
  const totalPrice = quantity * currencyConversionPrice;
  const totalOfferDiscount = product_price_discount * quantity;

  const priceDetails = [
    { label: `${getText('qty')}`, value: quantity },
    {
      label: `${getText('Price')}`,
      value: totalPrice,
      // ? `${currencyConversionOfferPrice}`
      // : `${currencyConversionPrice}`,
    },
    { label: `${getText('Discount')}`, value: totalOfferDiscount },
    { label: `${getText('sub_total')}`, value: product_item_price },
    { label: `${getText('delivery_charge')}`, value: shipping_price },
    // { label: `${getText('tax')}`, value: product?.shipping_price },
  ];

  const handleButtonClick = () => {
    setOpenReviewPopup(true);
  };
  return (
    <>
      {openReviewPopup && (
        <ReviewDialog
          isUpdate={openReviewId}
          setOpenReviewId={setOpenReviewId}
          setOpen={setOpenReviewPopup}
          open={openReviewPopup}
          orderId
          product={product?.product?.uid}
        />
      )}
      <Stack width="100%">
        <Stack direction="row" gap={1}>
          <Stack
            sx={{
              my: 'auto',
              height: '90px',
              width: '90px',
              minWidth: '90px',
              border: '1px solid #e9e9e9',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',

              overflow: 'hidden',
            }}
          >
            {image_url ? (
              <Image
                alt={translatedText}
                src={image_url}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  p: 0.5,
                }}
                imageStyle={{
                  objectFit: 'cover',
                  aspectRatio: 1 / 1,
                  borderRadius: '10px',
                }}
                className={`product-image ${image_url && 'hover-image-available'}`}
              />
            ) : (
              <Image
                alt="alt"
                src="/assets/images/default/product.png"
                sx={{}}
                imageStyle={{
                  objectFit: 'cover',
                  aspectRatio: 1 / 1,
                  borderRadius: '10px',
                }}
              />
            )}
          </Stack>
          <Stack spacing={1} justifyContent="space-between" py={1}>
            <TextMaxLine variant="caption" line={2} sx={{ fontWeight: 'fontWeightSemiBold' }}>
              {translatedProductName}
            </TextMaxLine>
            {orderSingleView?.order_status === 'DELIVERED' && (
              <IconButton
                sx={{
                  borderRadius: '4px',
                  width: 'fit-content',
                  backgroundColor: colors.themePrimary,
                  p: 0.3,
                  ':hover': { backgroundColor: '#77a3e9' },
                }}
                size="small"
                onClick={handleButtonClick}
              >
                <Stack
                  sx={{
                    cursor: 'pointer',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  spacing={0.2}
                >
                  <StarIcon sx={{ color: 'yellow', fontSize: '12px' }} />

                  <Typography variant="caption" color="white">
                    {' '}
                    {getText('rate_product')}
                  </Typography>
                </Stack>
              </IconButton>
            )}
            {/* <Stack sx={{ cursor: 'pointer', flexDirection: 'row', alignItems: 'center' }}>
              <StarIcon sx={{ color: 'yellow', fontSize: '12px', ml: 1 }} />

              <Typography onClick={handleButtonClick} variant="caption">
                {' '}
                {getText('rate_product')}
              </Typography>
            </Stack> */}
            <Stack>
              <Typography variant="caption" sx={{ fontWeight: 300 }}>
                {getText('color')} : White
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 300 }}>
                {getText('size')} :
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: 2,
            width: '100%',

            justifyContent: 'center',
          }}
        >
          {priceDetails.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                mb: 1,
              }}
            >
              <Typography variant="body2">{item.label}</Typography>
              {item?.label === 'Discount' ? (
                <Typography variant="body2" color="green">
                  - {item.value}
                </Typography>
              ) : (
                <Typography variant="body2">{item.value}</Typography>
              )}
            </Box>
          ))}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              mb: 1,
            }}
          >
            <Typography variant="subtitle2"> {getText('total')}</Typography>
            <Typography variant="subtitle2">{product_item_price}</Typography>
          </Box>

          <Divider sx={{ width: '90%', margin: 'auto', my: 2 }} />
        </Stack>
      </Stack>
    </>
  );
}

MyAccountOrderProducts.propTypes = {
  row: PropTypes.any,
  orderId: PropTypes.any,
};
