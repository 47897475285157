import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import {
  Card,
  Radio,
  Divider,
  Tooltip,
  Checkbox,
  TextField,
  RadioGroup,
  Typography,
  FormControl,
  Autocomplete,
  LinearProgress,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';

import { colors } from 'src/utils/colors';
import Storage from 'src/utils/localStorage';

import { removeAddress, fetchAllAddress } from 'src/server/slice/userSlice';
import { fetchSettings, fetchPickupLocation } from 'src/server/slice/settingsSlice';
import { updateOrder, handleOrderLocalData } from 'src/server/slice/cartOrderSlice';
import {
  getCartSate,
  getOrderState,
  getProfileState,
  getSettingsState,
} from 'src/server/selectors/selector';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import AddressForm from './address-new-form';
import { AddressItem, AddressNewForm } from '.';
import CheckoutSummary from '../checkout-summary';
import AddressAlertDialog from './address-confirmation';

export default function CheckoutBillingAddress({
  onNextStep,
  onBackStep,
  setDataOption,
  dataOption,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const rtl = useLayoutStyle();
  const { isMobile } = useScreenWidth();

  const { address, loading, createAddressLoading } = useSelector(getProfileState);
  const { localState } = useSelector(getOrderState);
  const { cart } = useSelector(getCartSate);
  const { pickupLocations, shippingSettings } = useSelector(getSettingsState);

  const getText = useLocalizedText();
  const addressForm = useBoolean();
  const addressConfirmation = useBoolean();

  const deliveryType = localState?.delivery_type;
  const selectedPickupPoint = localState?.pickup_location;
  const [pickupSearchText, setPickupSearchText] = useState('');

  useEffect(() => {
    if (deliveryType === 'STORE_PICKUP') {
      const credentials = {
        state: {
          filter_data: {
            is_deleted: false,
            is_active: true,
          },
        },
        page: 1,
        search: pickupSearchText,
        limit: pickupLocations?.total || 10,
      };
      dispatch(fetchPickupLocation(credentials));
    }
  }, [deliveryType, dispatch, pickupLocations?.total, pickupSearchText]);

  const pickupOptions = pickupLocations?.pickupLocations?.map((item) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const label = useTranslatedText(item?.title);
    return {
      ...item,
      label,
      id: item?.uid,
    };
  });

  useEffect(() => {
    const credentials = {
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
      },
      page: 1,
      search: '',
      limit: 10,
    };
    dispatch(fetchAllAddress(credentials));
  }, [dispatch]);

  const allAddress = address?.userShippingAddresses;

  const [sameAddress, setSameAddress] = useState(true);
  const [currentSelectedAddress, setCurrentSelectedAddress] = useState();

  useEffect(() => {
    const defaultAddress = allAddress?.map((i) => i.is_default);
    const defaultAddressId = defaultAddress ? defaultAddress[0]?.uid : '';
    const currentAddressId = allAddress?.length > 0 ? allAddress[0]?.uid : '';

    if (defaultAddressId) {
      setCurrentSelectedAddress(defaultAddressId);
    } else {
      setCurrentSelectedAddress(currentAddressId);
    }
  }, [allAddress]);

  useEffect(() => {
    setDataOption({
      is_billing_same_as_shipping: sameAddress,
      shipping_address: currentSelectedAddress,
    });
  }, [sameAddress, currentSelectedAddress, setDataOption]);

  const [selectedAddressId, setSelectedAddressId] = useState('');

  const handleEditAddress = (addressId) => {
    addressForm.onTrue();
    setSelectedAddressId(addressId);
  };

  const handleCloseAddressForm = () => {
    addressForm.onFalse();
    setSelectedAddressId('');
  };

  const confirmRemoveAddress = useBoolean();

  const handleRemoveAddressPopup = (addressId) => {
    confirmRemoveAddress?.onTrue();
    setSelectedAddressId(addressId);
  };

  const handleDeleteAddressConfirmClick = () => {
    const credentials = {
      addressId: selectedAddressId,
      onClosePopup: confirmRemoveAddress?.onFalse,
      enqueueSnackbar,
    };
    dispatch(removeAddress(credentials));
  };

  const isCartEmpty = !cart?.cart_items || cart.cart_items.length === 0;

  const handleNextStep = () => {
    // Handle billing address case
    if (dataOption?.is_billing_same_as_shipping === false) {
      if (dataOption?.billing_address) {
        return onNextStep();
      }
      return enqueueSnackbar('Please provide billing address.', { variant: 'warning' });
    }

    // Handle delivery types
    switch (deliveryType) {
      case 'HOME_DELIVERY':
        if (!currentSelectedAddress && !isCartEmpty) {
          return addressConfirmation.onTrue();
        }
        return onNextStep();

      case 'STORE_PICKUP':
        if (!isCartEmpty && selectedPickupPoint) {
          return onNextStep();
        }
        return enqueueSnackbar(
          <Typography variant="caption" component="div">
            Please select a pickup location.
          </Typography>,
          { variant: 'info' }
        );

      default:
        console.error('Invalid delivery type');
        return enqueueSnackbar('Invalid delivery type selected.', { variant: 'error' });
    }
  };

  const mobileAddressStyle = isMobile
    ? {
        borderRadius: '6px',
        boxShadow: 'none',
        padding: '0',
      }
    : {};

  const handlePickupLocationChange = (event, newValue) => {
    const credentials = {
      state: {
        ...localState,
        pickup_location: newValue,
      },
    };
    dispatch(updateOrder(credentials));
    dispatch(handleOrderLocalData(credentials));
  };

  const handleChangeShippingMethod = (newValue) => {
    if (newValue === 'STORE_PICKUP') {
      setSameAddress(false);
    } else {
      setSameAddress(true);
    }

    const credentials = {
      state: {
        ...localState,
        delivery_type: newValue,
      },
    };

    dispatch(updateOrder(credentials));
    dispatch(handleOrderLocalData(credentials));
  };

  const isGuest = Storage.get('is_guest');

  const deliveryOption = isGuest
    ? shippingSettings?.shipping?.guest_delivery_option
    : shippingSettings?.shipping?.user_delivery_option;

  const renderDeliveryOptions = () => {
    switch (deliveryOption) {
      case 'BOTH':
        return (
          <>
            <FormControlLabel
              value="HOME_DELIVERY"
              control={<Radio />}
              label={getText('home_delivery')}
            />
            <FormControlLabel
              value="STORE_PICKUP"
              control={<Radio />}
              label={getText('self_pickup')}
            />
          </>
        );
      case 'HOME_DELIVERY':
        return (
          <FormControlLabel
            value="HOME_DELIVERY"
            control={<Radio />}
            label={getText('home_delivery')}
          />
        );
      case 'STORE_PICKUP':
        return (
          <FormControlLabel
            value="STORE_PICKUP"
            control={<Radio />}
            label={getText('self_pickup')}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!shippingSettings?.uid) {
      dispatch(fetchSettings({ type: 'shipping' }));
    }
  }, [dispatch, shippingSettings?.uid]);

  return (
    <>
      {/* // create new address popup */}
      {addressForm.value && (
        <AddressForm
          variant="popup"
          open={addressForm.value}
          onClose={handleCloseAddressForm}
          updateDataId={selectedAddressId}
        />
      )}

      {/* //  address confirm popup */}
      {addressConfirmation.value && (
        <AddressAlertDialog
          open={addressConfirmation.value}
          onClose={() => addressConfirmation.onFalse()}
        />
      )}

      {/* // remove address confirmation */}
      {confirmRemoveAddress.value && (
        <ConfirmDialog
          open={confirmRemoveAddress.value}
          onClose={confirmRemoveAddress.onFalse}
          title={getText('remove')}
          content={`${getText('are_you_sure_you_want_to_delete_this_address')}?`}
          action={
            <Button variant="contained" color="error" onClick={handleDeleteAddressConfirmClick}>
              {getText('remove')}{' '}
              {createAddressLoading && (
                <CircularProgress color="inherit" size={14} sx={{ color: '#fff', mx: 1 }} />
              )}
            </Button>
          }
        />
      )}

      <Grid container spacing={3}>
        {isMobile && (
          <Grid xs={12} md={8}>
            <CheckoutSummary />
          </Grid>
        )}

        <Grid xs={12} md={8}>
          <Card
            sx={{
              p: 3,
              mb: 2,
              ...mobileAddressStyle,
              overflow: 'unset',
              zIndex: 1,
            }}
          >
            <>
              {loading ? (
                <Box sx={{ width: '100%', px: 2, mb: 2 }}>
                  <LinearProgress color="info" />
                </Box>
              ) : (
                ''
              )}

              <Stack
                mb={3}
                direction="column"
                justifyContent="space-between"
                alignItems="start"
                spacing={1}
              >
                <Typography variant="subtitle1"> {getText('shipping_method')}</Typography>

                <FormControl sx={{ ml: 1, mx: 1.5 }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="shipping-method-options"
                    sx={{ flexDirection: 'row' }}
                    value={deliveryType}
                    onChange={(e) => handleChangeShippingMethod(e.target.value)}
                  >
                    {renderDeliveryOptions()}
                  </RadioGroup>
                </FormControl>
              </Stack>

              {deliveryType === 'HOME_DELIVERY' ? (
                <>
                  <Box>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle1" sx={{ mb: 2, width: 'fit-content' }}>
                        {getText('delivery_address')}
                      </Typography>
                      {allAddress?.length > 0 ? (
                        <Button
                          size="small"
                          color="inherit"
                          sx={{
                            border: '1px solid #F3F3FC',
                            color: '#30AE4A',
                            borderRadius: '4px',
                            px: 1,
                            py: 1.5,
                          }}
                          onClick={() => {
                            setSelectedAddressId('');
                            addressForm.onTrue();
                          }}
                        >
                          <AddRoundedIcon fontSize="small" sx={{ mr: 0.5 }} />
                          <Typography variant="body2"> {getText('add_address')}</Typography>
                        </Button>
                      ) : (
                        ''
                      )}
                    </Stack>

                    {allAddress?.length <= 0 && <AddressNewForm variant="normal" />}

                    <FormControl sx={{ width: '100%' }}>
                      <RadioGroup
                        value={currentSelectedAddress || ''}
                        onChange={(e) => setCurrentSelectedAddress(e.target.value)}
                        aria-labelledby="delivery-address-options"
                        name="delivery-address-options"
                      >
                        {allAddress?.map((item, key) => (
                          <Box key={key}>
                            <AddressItem
                              key={key}
                              address={item}
                              selectedAddress={currentSelectedAddress}
                              onAddressChange={setCurrentSelectedAddress}
                              action={
                                <Stack
                                  flexDirection="row"
                                  spacing={1.5}
                                  flexWrap="wrap"
                                  justifyContent="end"
                                  width={isMobile ? '100%' : 'auto'}
                                  flexShrink={0}
                                  sx={{
                                    right: 0,
                                    bottom: 0,
                                  }}
                                >
                                  <Button
                                    size="small"
                                    color="inherit"
                                    sx={{
                                      backgroundColor: '#F3F3FC',
                                      borderRadius: '4px',
                                      px: 1,
                                      py: 1.5,
                                    }}
                                    onClick={() => handleEditAddress(item?.uid)}
                                  >
                                    <BorderColorRoundedIcon
                                      fontSize="small"
                                      sx={{ height: '12px', mr: 0.5 }}
                                    />
                                    <Typography variant="caption">
                                      {getText('edit_address')}
                                    </Typography>
                                  </Button>

                                  <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      borderRadius: '4px',
                                      px: 1,
                                      py: 1.5,
                                    }}
                                    onClick={() => handleRemoveAddressPopup(item?.uid)}
                                  >
                                    <DeleteRoundedIcon
                                      fontSize="small"
                                      sx={{ height: '12px', mr: 0.5 }}
                                    />
                                    <Typography variant="caption">{getText('remove')}</Typography>
                                  </Button>
                                </Stack>
                              }
                              sx={{
                                mb: 1,
                                borderRadius: 2,
                              }}
                            />
                            {key + 1 !== allAddress?.length && <Divider sx={{ my: 2 }} />}
                          </Box>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
                </>
              ) : (
                <>
                  {pickupOptions && (
                    <Autocomplete
                      fullWidth
                      size="small"
                      options={pickupOptions}
                      value={selectedPickupPoint}
                      onChange={handlePickupLocationChange}
                      sx={{
                        width: 'auto',
                        maxHeight: '50px',
                        maxWidth: '400px',
                        mb: 3,
                      }}
                      required
                      isOptionEqualToValue={(option, isEqualValue) =>
                        option?.id === isEqualValue?.id
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label={getText('search_pickup_point')}
                          placeholder={getText('search_pickup_point')}
                          required
                          onChange={(e) => setPickupSearchText(e.target.value)}
                        />
                      )}
                    />
                  )}
                </>
              )}

              <Stack direction="row" alignItems="start" justifyContent="space-between">
                <Typography variant="subtitle1" sx={{ mb: 2, width: 'fit-content' }}>
                  {getText('billing_address')}

                  {deliveryType === 'HOME_DELIVERY' && (
                    <Tooltip
                      arrow
                      placement="top"
                      title={!currentSelectedAddress ? 'First select an delivery address' : ''}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="success"
                            checked={currentSelectedAddress ? sameAddress : true}
                            onClick={(e) =>
                              currentSelectedAddress && setSameAddress(e.target.checked)
                            }
                          />
                        }
                        label={getText('use_the_same_address_for_delivery_and_billing')}
                        sx={{
                          width: '100%',
                          margin: 0,
                        }}
                      />
                    </Tooltip>
                  )}
                </Typography>
              </Stack>

              {!sameAddress && (
                <AddressNewForm variant="normal" isBillingAddress setDataOption={setDataOption} />
              )}
            </>
          </Card>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {!isMobile && (
              <Button
                size="small"
                color="inherit"
                onClick={onBackStep}
                startIcon={
                  <Iconify
                    icon={
                      rtl
                        ? 'material-symbols-light:keyboard-arrow-right'
                        : 'eva:arrow-ios-back-fill'
                    }
                  />
                }
              >
                {getText('back')}
              </Button>
            )}

            <Button
              onClick={handleNextStep}
              size="medium"
              type="submit"
              variant="contained"
              className={isMobile ? 'checkout-mobile-button' : ''}
              sx={{
                px: 8,
                borderRadius: '4px',
                backgroundColor: colors.themePrimary,
                '&:hover': { backgroundColor: '#1968e9' },
              }}
            >
              {getText('continue_to_payment')}
            </Button>
          </Stack>
        </Grid>

        {!isMobile && (
          <Grid xs={12} md={4}>
            <CheckoutSummary />
          </Grid>
        )}
      </Grid>
    </>
  );
}

CheckoutBillingAddress.propTypes = {
  onBackStep: PropTypes.any,
  onNextStep: PropTypes.any,
  setDataOption: PropTypes.any,
  dataOption: PropTypes.any,
};

// enqueueSnackbar(
//   <Typography variant="caption" component="div">
//     Please add some product to cart
//   </Typography>,
//   { variant: 'info' }
// );

// const [isRouteActive, setIsRouteActive] = useState({
//   status: false,
//   message: '',
// });

// useEffect(() => {
//   if (cartEmpty && isRouteActive.status !== false) {
//     setIsRouteActive({ status: false, message: 'Please add some product to cart' });
//   }

//   const localDeliveryType = Storage.get('delivery_type');
//   const localDeliveryLocation = Storage.get('delivery_location');
//   const localPickupLocation = Storage.get('pickup_location');

//   switch (localDeliveryType) {
//     case 'HOME_DELIVERY':
//       if (
//         !localDeliveryLocation?.uid &&
//         isRouteActive.message !== 'Please select delivery location'
//       ) {
//         setIsRouteActive({ status: false, message: 'Please select delivery location' });
//       }
//       break;

//     case 'STORE_PICKUP':
//       if (
//         !localPickupLocation?.uid &&
//         isRouteActive.message !== 'Please select pickup location'
//       ) {
//         setIsRouteActive({ status: false, message: getText('Please_select_pickup_location') });
//       }
//       break;

//     default:
//       if (isRouteActive.message) {
//         setIsRouteActive({ status: false, message: '' });
//       }
//   }

// }, [cartEmpty, getText, isRouteActive]);
