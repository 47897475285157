import React from 'react';
import PropTypes from 'prop-types';

import { Box, Stack, Container } from '@mui/system';
import { Card, Grid, Divider, Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { fDate } from 'src/utils/format-time';

import AddressCard from 'src/sections/myAccount/address/address-card';

import OrderItemRow from './orderItemsRow';

function OrderItems({ orderSingleViewDetails }) {
  const getText = useLocalizedText();
  const { isMobile } = useScreenWidth();

  const deliveryType = (() => {
    if (orderSingleViewDetails?.delivery_type === 'HOME_DELIVERY') {
      return getText('home_delivery');
    }
    if (orderSingleViewDetails?.delivery_type === 'STORE_PICKUP') {
      return getText('store_pickup');
    }
    return '--';
  })();

  const mobileStyle = isMobile && {
    boxShadow: 'none',
    border: 'none',
    marginTop: '20px',
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} p={isMobile ? 0 : 2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h6" mb={1.5}>
            {getText('order_details')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Stack direction="row">
              <Typography variant="subtitle2">{getText('order_id')} :</Typography>
              <Typography ml={1} variant="body2" display="inline">
                {orderSingleViewDetails?.order_id}{' '}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="subtitle2"> {getText('order_status')} :</Typography>
              <Typography ml={1} variant="body2" display="inline">
                {orderSingleViewDetails?.order_status}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="subtitle2"> {getText('order_date')} :</Typography>
              <Typography ml={1} variant="body2" display="inline">
                {fDate(orderSingleViewDetails?.order_initiated_at)}
              </Typography>
            </Stack>

            <Stack direction="row">
              <Typography variant="subtitle2"> {getText('payment_type')}:</Typography>
              <Typography ml={1} variant="body2" display="inline">
                {orderSingleViewDetails?.payment_type}{' '}
              </Typography>
            </Stack>
            {orderSingleViewDetails?.payment_transaction_id && (
              <Stack direction="row">
                <Typography variant="subtitle2"> {getText('transaction_id')}:</Typography>
                <Typography ml={1} variant="body2" display="inline">
                  {orderSingleViewDetails?.payment_transaction_id || '--'}
                </Typography>
              </Stack>
            )}
          </Box>
          <Stack mt={3}>
            <Typography variant="h6" mb={isMobile ? 2 : 1}>
              {getText('shipping_details')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Stack direction="row" mb={isMobile && 2}>
                <Typography variant="subtitle2" sx={{ minWidth: '130px' }}>
                  {' '}
                  {getText('shipping_method')} :{' '}
                </Typography>
                <Typography ml={1} variant="body2" display="inline">
                  {deliveryType}{' '}
                </Typography>
              </Stack>
              <Stack direction="row" mb={isMobile && 2}>
                <Typography variant="subtitle2" sx={{ minWidth: '130px' }}>
                  {' '}
                  {getText('shipping_address')} :{' '}
                </Typography>
                <Stack ml={1}>
                  <AddressCard addressData={orderSingleViewDetails?.shipping_address} />
                </Stack>
              </Stack>

              <Stack direction="row">
                <Typography variant="subtitle2" sx={{ minWidth: '130px' }}>
                  {' '}
                  {getText('billing_address')} :{' '}
                </Typography>
                {orderSingleViewDetails?.is_billing_same_as_shipping === true ? (
                  <Stack ml={1}>
                    <AddressCard addressData={orderSingleViewDetails?.shipping_address} />
                  </Stack>
                ) : (
                  <Stack ml={1}>
                    <AddressCard addressData={orderSingleViewDetails?.billing_address} />
                  </Stack>
                )}
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card sx={{ p: isMobile ? 0 : 2, ...mobileStyle }}>
            {orderSingleViewDetails?.order_items?.map((order, index) => (
              <React.Fragment key={index}>
                <OrderItemRow order={order} />
                {index + 1 !== orderSingleViewDetails?.order_items?.length && (
                  <Divider sx={{ width: '100%', margin: 'auto', mt: isMobile ? 0 : 2, mb: 2 }} />
                )}
              </React.Fragment>
            ))}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default OrderItems;

OrderItems.propTypes = {
  orderSingleViewDetails: PropTypes.object,
};
