import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';

import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';

import { getSettingsState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';

// ----------------------------------------------------------------------
export const _socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#ffffff',
    // color: '#1877F2',
    path: 'https://www.facebook.com/caitlyn.kerluke',
    size: 20,
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    // color: '#E02D69',
    color: '#ffffff',
    path: 'https://www.instagram.com/caitlyn.kerluke',
    size: 20,
  },

  {
    value: 'twitter',
    name: 'Twitter',
    icon: 'prime:twitter',
    // color: '#00AAEC',
    color: '#ffffff',
    path: 'https://www.twitter.com/caitlyn.kerluke',
    size: 15,
  },
];
export default function MobileFooter() {
  const getText = useLocalizedText();
  const pathname = usePathname();

  const { basicSettings } = useSelector(getSettingsState);

  const footerColumnData = useTranslatedText(basicSettings?.layout);

  const LINKS = [
    {
      headline: footerColumnData?.footer_column_1_heading || '',
      children: footerColumnData?.footer_column_1_titles || '',
    },
    {
      headline: footerColumnData?.footer_column_2_heading || '',
      children: footerColumnData?.footer_column_2_titles || '',
    },
    {
      headline: footerColumnData?.footer_column_3_heading || '',
      children: footerColumnData?.footer_column_3_titles || '',
    },
    {
      headline: footerColumnData?.footer_column_4_heading || '',
      children: footerColumnData?.footer_column_4_titles || '',
    },
    {
      headline: footerColumnData?.footer_column_5_heading || '',
      children: footerColumnData?.footer_column_5_titles || '',
    },
  ];

  const handleFooterPaddingBottom = () => {
    switch (pathname) {
      case `${paths.public.cart}/`:
        return 17;
      case `${paths.public.checkout}/`:
        return 17;

      default:
        return 11;
    }
  };

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: '#242b35',
        pb: handleFooterPaddingBottom(),
      }}
    >
      <Box
        sx={{
          pt: 2,
        }}
      >
        {LINKS?.map((item, key) => (
          <>
            {item?.headline && (
              <Accordion
                key={key}
                sx={{
                  m: 0,
                  backgroundColor: '#242b35',
                  borderRadius: 0,
                  color: 'white',
                  boxShadow: 'none',
                }}
                className="footer-accordian"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: '#878787' }} />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <Typography sx={{ color: '#878787' }} variant="body2">
                    {item?.headline}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {item?.children?.length >= 1 &&
                    item?.children?.map((link, key1) => (
                      <Fragment key={key1}>
                        <OspLink href={link?.link || '#'} sx={{ color: 'white' }} color="inherit">
                          <Typography component="p" variant="body2" pb={1}>
                            {link?.title}
                          </Typography>
                        </OspLink>
                      </Fragment>
                    ))}
                </AccordionDetails>
              </Accordion>
            )}
          </>
        ))}
        <Divider sx={{ mt: '20px', mb: '10px' }} />
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          px={2}
          pt={1}
        >
          <Box>
            <Typography component="div" sx={{ color: '#878787' }} variant="body2">
              {getText('follow_us_on')}
            </Typography>
            <Stack direction="row" spacing={1.5} mt={1}>
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    padding: 0,
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                >
                  <Iconify
                    color={social.color}
                    icon={social.icon}
                    width={social?.size}
                    height={social?.size}
                  />
                </IconButton>
              ))}
            </Stack>
          </Box>
          <Box sx={{ backgroundColor: 'text.disabled', width: 1.5, height: 55 }} />
          <Box>
            <OspLink href={paths.public.home}>
              <Image
                alt="footer-logo"
                src="/assets/images/logs/footer-logo.webp"
                sx={{}}
                imageStyle={{
                  width: 170,
                }}
              />
            </OspLink>
          </Box>
        </Stack>
      </Box>
    </Box>
  );

  return mainFooter;
}
