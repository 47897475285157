import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import VimeoPlayer from 'react-player/vimeo';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRef, useState, useEffect } from 'react';
import { Autoplay, Navigation } from 'swiper/modules';

import { Box, IconButton } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { colors } from 'src/utils/colors';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

import { BannerSkeleton } from '../product/common/banner-skeleton';

const Banners = ({ bannerData }) => {
  const { screenWidth } = useScreenWidth();
  const rtl = useLayoutStyle();

  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(false);
  const swiperRef = useRef(null);

  const updateArrowVisibility = (swiper) => {
    setShowPrevArrow(swiper.activeIndex > 0);
    setShowNextArrow(!swiper.isEnd);
  };
  useEffect(() => {
    if (swiperRef.current) {
      updateArrowVisibility(swiperRef.current);
    }
  }, [bannerData]);

  const isMobile = screenWidth < 600;
  const isTab = screenWidth < 900;

  const [isPlayerReady, setIsPlayerReady] = useState(false);

  const handlePlayerReady = () => {
    setIsPlayerReady(true);
  };

  const [isPlayerReadyMobile, setIsPlayerReadyMobile] = useState(false);

  const handlePlayerReadyMobile = () => {
    setIsPlayerReadyMobile(true);
  };

  return (
    <Box sx={{ position: 'relative', p: 0 }}>
      <Swiper
        slidesPerView={1}
        modules={[Navigation, Autoplay]}
        spaceBetween={15}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          updateArrowVisibility(swiper);
        }}
        onSlideChange={(swiper) => updateArrowVisibility(swiper)}
        navigation={{
          prevEl: '.custom_prev_i1',
          nextEl: '.custom_next_i1',
        }}
        onP
        autoplay
        className="banner-container"
      >
        {bannerData?.map((item, key) => {
          const isImage = item?.banner_type === 'IMAGE';
          const isVideo = item?.banner_type === 'VIDEO';

          const shouldRender =
            (screenWidth < 786 && item?.platform === 'APP') ||
            (screenWidth > 768 && item?.platform === 'WEB') ||
            item?.platform === 'COMMON';

          if (!shouldRender || (!isImage && !isVideo)) return null;

          const getHightTop = () => {
            if (isMobile) return '';
            if (isTab) return '300px';
            return '550px';
          };
          return (
            <SwiperSlide key={key}>
              <Box
                sx={{
                  height: getHightTop(),
                  mb: 2,
                }}
              >
                {isImage && (
                  <OspLink
                    type={item?.link_type}
                    href={item?.link || ''}
                    sx={{ '&:hover': { textDecoration: 'none' } }}
                  >
                    <ImageComp item={item} isMobile={isMobile} />
                  </OspLink>
                )}
                {isVideo &&
                  (isMobile ? (
                    <OspLink
                      type={item?.link_type}
                      href={item?.link || ''}
                      sx={{ '&:hover': { textDecoration: 'none' } }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                          aspectRatio: '3/ 4',
                          height: '100%',
                          position: 'relative',
                          cursor: 'pointer',
                        }}
                      >
                        {!isPlayerReadyMobile && <BannerSkeleton />}

                        <VimeoPlayer
                          className="Main-banner-Vimeo"
                          video={item?.video_id || ''}
                          responsive
                          muted
                          playsInline
                          autoplay
                          onReady={handlePlayerReadyMobile}
                          controls={false}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            pointerEvents: 'none',
                          }}
                        />
                      </Box>
                    </OspLink>
                  ) : (
                    <OspLink
                      type={item?.link_type}
                      href={item?.link || ''}
                      sx={{ '&:hover': { textDecoration: 'none' } }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          borderRadius: '15px',
                          overflow: 'hidden',
                          aspectRatio: screenWidth < 786 ? '16/10' : '16 / 5',
                          height: '100%',
                          position: 'relative',
                          cursor: 'pointer',
                        }}
                      >
                        {!isPlayerReady && <BannerSkeleton />}

                        <VimeoPlayer
                          video={item?.video_id || ''}
                          responsive
                          muted
                          playsInline
                          controls={false}
                          autoplay
                          onReady={handlePlayerReady}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            pointerEvents: 'none',
                          }}
                        />
                      </Box>
                    </OspLink>
                  ))}
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {showPrevArrow && (
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            // right: '10px',
            left: '10px',
            zIndex: 9,
            transform: 'translateY(-50%)',
            color: colors.themeSecondary,
            backgroundColor: 'white',
            ':hover': {
              color: colors.themeSecondary,
              backgroundColor: 'white',
            },
          }}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          {rtl ? <ChevronRightRoundedIcon /> : <KeyboardArrowLeftRoundedIcon />}
        </IconButton>
      )}
      {showNextArrow && (
        <IconButton
          onClick={() => swiperRef.current?.slideNext()}
          sx={{
            position: 'absolute',
            top: '50%',
            // left: '10px',
            right: '10px',
            color: colors.themeSecondary,
            backgroundColor: 'white',
            zIndex: 9,
            transform: 'translateY(-50%)',
            ':hover': {
              color: colors.themeSecondary,
              backgroundColor: 'white',
            },
          }}
        >
          {rtl ? <KeyboardArrowLeftRoundedIcon /> : <ChevronRightRoundedIcon />}
        </IconButton>
      )}
    </Box>
  );
};

export default Banners;

Banners.displayName = 'Banners';

Banners.propTypes = {
  bannerData: PropTypes.any,
};
const ImageComp = ({ item, isMobile }) => {
  const transformedImage = useImageTransformation(item?.image?.url, 'w_1600', true);

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={transformedImage} />
      </Helmet>
      <Image
        alt="banner"
        src={transformedImage}
        sx={{}}
        loading="eager"
        imageStyle={{
          height: isMobile ? 'unset' : '550px',
          objectFit: 'cover',
          borderRadius: !isMobile && '15px',
          aspectRatio: isMobile ? 'unset' : '16/9',
        }}
        // priority
      />
    </>
  );
};

ImageComp.propTypes = {
  item: PropTypes.any,
  isMobile: PropTypes.bool,
};
