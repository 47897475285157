import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, Stack } from '@mui/system';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import StarIcon from '@mui/icons-material/Star';
import { TableBody, IconButton, Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { colors } from 'src/utils/colors';

import { useCurrency } from 'src/providers/CurrencyProvider';
import { getOrderSingleViewState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';

import ReviewDialog from 'src/sections/product/review/reviewModal';

// ----------------------------------------------------------------------

export default function MyAccountOrderProduct({ row, onView, orderId }) {
  const {
    quantity,
    product,
    product_item_price,
    name,
    product_price_discount,
    image_url,
    // product_price,
  } = row;

  const { orderSingleView } = useSelector(getOrderSingleViewState);

  const getText = useLocalizedText();
  const { currency } = useCurrency();
  const translatedText = useTranslatedText();
  const [openReviewPopup, setOpenReviewPopup] = useState(false);
  const [openReviewId, setOpenReviewId] = useState({});
  const translatedProductName = useTranslatedText(name);

  const { offerPrice } = useProductOfferPriceCalculator(product);

  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);

  const totalPrice = quantity * currencyConversionPrice;
  const handleButtonClick = () => {
    setOpenReviewPopup(true);
  };
  const totalOfferDiscount = product_price_discount * quantity;
  const translateCurrencyText = useTranslatedText(currency?.title);

  const currencyConvertedTotalPrice = useCurrencyConversion(totalPrice);
  const currencyConvertedTotalOfferDiscount = useCurrencyConversion(totalOfferDiscount);
  const currencyConvertedTotalProductItemPrice = useCurrencyConversion(product_item_price);
  const currencyConvertedProductItemPrice = useCurrencyConversion(product_item_price);

  return (
    <>
      {openReviewPopup && (
        <ReviewDialog
          isUpdate={openReviewId}
          setOpenReviewId={setOpenReviewId}
          setOpen={setOpenReviewPopup}
          open={openReviewPopup}
          orderId
          product={product?.uid}
        />
      )}
      <TableBody>
        <TableRow>
          <TableCell sx={{ color: '#1C252E' }}>
            <Stack direction="row" gap={1}>
              <Stack
                sx={{
                  my: 'auto',
                  height: '90px',
                  width: '90px',
                  minWidth: '90px',
                  border: '1px solid #e9e9e9',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',

                  overflow: 'hidden',
                }}
              >
                {image_url ? (
                  <Image
                    alt={translatedText}
                    src={image_url}
                    sx={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      p: 0.5,
                    }}
                    imageStyle={{
                      objectFit: 'cover',
                      aspectRatio: 1 / 1,
                      borderRadius: '10px',
                    }}
                    className={`product-image ${image_url && 'hover-image-available'}`}
                  />
                ) : (
                  <Image
                    alt="alt"
                    src="/assets/images/default/product.png"
                    sx={{}}
                    imageStyle={{
                      objectFit: 'cover',
                      aspectRatio: 1 / 1,
                      borderRadius: '10px',
                    }}
                  />
                )}
              </Stack>
              <Stack spacing={1} justifyContent="start">
                <Stack>
                  <TextMaxLine variant="caption" line={2} sx={{ fontWeight: 'fontWeightSemiBold' }}>
                    {translatedProductName}
                  </TextMaxLine>
                </Stack>
                {orderSingleView?.order_status === 'DELIVERED' && (
                  <IconButton
                    sx={{
                      borderRadius: '4px',
                      width: 'fit-content',
                      backgroundColor: colors.themePrimary,
                      ':hover': { backgroundColor: '#77a3e9' },
                    }}
                    size="small"
                    onClick={handleButtonClick}
                  >
                    <Stack
                      sx={{
                        cursor: 'pointer',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      spacing={0.5}
                    >
                      <StarIcon sx={{ color: 'yellow', fontSize: '12px' }} />

                      <Typography variant="caption" color="white">
                        {' '}
                        {getText('rate_product')}
                      </Typography>
                    </Stack>
                  </IconButton>
                )}
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: '400',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                  }}
                >
                  {translateCurrencyText}
                  {offerPrice && (
                    <Box
                      component="span"
                      sx={{
                        color: 'text.disabled',
                        textDecoration: 'line-through',
                        lineHeight: 'normal',
                      }}
                    >
                      {currencyConversionPrice}
                    </Box>
                  )}
                  <Typography component="span" variant="caption" sx={{ fontWeight: 'bold' }}>
                    {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
          </TableCell>
          <TableCell sx={{ color: '#1C252E' }}>{quantity}</TableCell>
          <TableCell sx={{ color: '#1C252E' }}>
            <Typography
              sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                gap: 0.8,
              }}
            >
              {currencyConvertedTotalPrice}
            </Typography>
          </TableCell>
          <TableCell sx={{ color: 'green' }}>
            {currencyConvertedTotalOfferDiscount > 0 ? (
              <>- {currencyConvertedTotalOfferDiscount}</>
            ) : (
              '---'
            )}{' '}
          </TableCell>
          <TableCell sx={{ color: '#1C252E', width: 100 }}>
            {currencyConvertedTotalProductItemPrice}
          </TableCell>
          {/* <TableCell sx={{ color: '#1C252E' }}>{currencyConvertedShippingPrice}</TableCell> */}
          <TableCell sx={{ color: '#1C252E' }}>{currencyConvertedProductItemPrice}</TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}

MyAccountOrderProduct.propTypes = {
  row: PropTypes.object,
  onView: PropTypes.func,
  orderId: PropTypes.any,
};
