import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, Grid, Chip, Stack, Container, Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { colors } from 'src/utils/colors';

import { fetchBanners } from 'src/server/slice/bannerSlice';
import { fetchCategoryDetail, fetchCategoriesByHierarchy } from 'src/server/slice/categorySlice';
import { fetchAllCategoryProducts, productFilterObjectState } from 'src/server/slice/productSlice';
import { getBannerState, getProductState, getCategoriesState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import EmptyContent from 'src/components/empty-content';

import Banners from 'src/sections/home/banners';
import ProductList from 'src/sections/product/list-filter/product-list';
import ProductListLoading from 'src/sections/product/common/product-list-loading';

import { CategoryItemSkeleton } from '../category-skeleton';

const CategorySingleView = ({ params }) => {
  const categoryName = params;

  const { screenWidth, isMobile } = useScreenWidth();
  const dispatch = useDispatch();

  const {
    loading: categoryLoading,
    categoryDetails,
    categoriesByHierarchy: serverCategoriesByHierarchy,
  } = useSelector(getCategoriesState);

  const categoryId = categoryDetails?.uid;

  const categoriesByHierarchy = serverCategoriesByHierarchy;

  const {
    categoryProducts: productsByCategory,
    categoryProdLoading,
    productFilterObject,
  } = useSelector(getProductState);

  const { banners } = useSelector(getBannerState);

  const getText = useLocalizedText();

  useEffect(() => {
    if (categoryName) {
      const credentials = {
        categoryId: categoryName?.replaceAll('-', ' '),
        state: {},
      };
      dispatch(fetchCategoryDetail(credentials));
    }
  }, [categoryName, dispatch]);

  useEffect(() => {
    if (categoryId) {
      const credentials = {
        page: 1,
        search: '',
        limit: 20,
        state: {
          filter_data: {
            is_deleted: false,
            uid: categoryId,
          },
          sort_data: {
            createdAt: -1,
          },
        },
      };
      dispatch(fetchCategoriesByHierarchy(credentials));
    }
  }, [categoryId, dispatch]);

  useEffect(() => {
    const credentials = {
      state: {
        module: 'CATEGORY',
        module_id: categoryId,
        filter_data: {
          is_deleted: false,
        },
        sort_data: {
          createdAt: -1,
        },
      },
    };
    if (categoryDetails && categoryId) {
      dispatch(fetchBanners(credentials));
    }
  }, [categoryDetails, categoryId, dispatch]);

  const handleMarginTop = () => {
    let value = 0;
    if (!isMobile) {
      if (screenWidth < 900) {
        value = 18;
      } else if (screenWidth < 1200) {
        value = 8;
      }
    }
    return value;
  };

  const subCategories = categoriesByHierarchy?.categories?.length
    ? categoriesByHierarchy?.categories[0]?.sub_categories
    : [];

  // products
  const products = productsByCategory?.products || [];
  const totalProducts = products?.length;

  const showLimit = '30';
  const [currentPage, setCurrentPage] = useState(1);

  // Memoize product filter object to avoid recalculating on each render
  const modifiedFilterData = useMemo(() => {
    const modifiedObject = { ...productFilterObject?.filter_data, is_active: true };

    // Remove empty filter conditions
    if (!modifiedObject?.categories?.$in?.length) delete modifiedObject.categories;
    if (!modifiedObject?.brands?.$in?.length) delete modifiedObject.brands;
    if (modifiedObject?.average_rating?.$gte === 0) delete modifiedObject.average_rating;
    if (!modifiedObject?.is_free_delivery_product) delete modifiedObject.is_free_delivery_product;
    if (!modifiedObject?.price) delete modifiedObject.price;

    return modifiedObject;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilterObject?.filter_data?.categories]);

  useEffect(() => {
    if (productFilterObject?.filter_data?.categories) {
      const fetchProducts = async () => {
        const credentials = {
          page: currentPage,
          search: '',
          limit: 30,
          state: {
            filter_data: modifiedFilterData,
            // sort_data: productFilterObject?.sort_data,
          },
        };
        dispatch(fetchAllCategoryProducts(credentials));
      };

      fetchProducts();
    }
  }, [
    currentPage,
    dispatch,
    modifiedFilterData,
    productFilterObject.categories,
    productFilterObject?.filter_data?.categories,
  ]);

  // category filter
  // Helper function for updating filters
  const updateProductFilterObject = (key, value, type = false) => {
    dispatch(
      productFilterObjectState({
        filter_data: {
          ...productFilterObject?.filter_data,
          [key]: value,
        },
        isSubcategoryItems: type,
      })
    );
  };

  const handleSelectedCategories = (value, isSubItemAll) => {
    const categoryLabel = [value] || [];
    dispatch(productFilterObjectState({ categoryLabel }));
    const mainCategory = categoryLabel?.map((item) => item?.uid);
    const susCategories = value?.sub_categories?.map((item) => item?.uid);
    const categoryIds = [...mainCategory, ...susCategories];

    updateProductFilterObject(
      'categories',
      {
        $in: categoryIds,
      },
      isSubItemAll
    );
  };

  const handleListAllProduct = useCallback(() => {
    const categories =
      categoriesByHierarchy?.categories?.length && categoriesByHierarchy?.categories[0];
    const firstGenCatIds = categories?.uid;
    const secondGenCatIds = categories?.sub_categories?.length
      ? categories?.sub_categories?.map((i) => i.uid)
      : [];
    const thirdGenCatIds = [];

    categories?.sub_categories.forEach((category) => {
      category.sub_categories.forEach((subCategory) => {
        thirdGenCatIds.push(subCategory.uid);

        if (subCategory.sub_categories && subCategory.sub_categories.length > 0) {
          subCategory.sub_categories.forEach((nestedSub) => {
            thirdGenCatIds.push(nestedSub.uid);
          });
        }
      });
    });

    const allGenCatIds = [firstGenCatIds, ...secondGenCatIds, ...thirdGenCatIds];

    dispatch(productFilterObjectState({ categoryLabel: [] }));
    updateProductFilterObject('categories', {
      $in: allGenCatIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesByHierarchy?.categories, dispatch]);

  useEffect(() => {
    if (categoryName) {
      handleListAllProduct();
    }
  }, [categoryName, handleListAllProduct]);

  const renderNotFound = <EmptyContent filled title="Product not found" sx={{ py: 10 }} />;
  const currentSelectedCategory = productFilterObject?.categoryLabel?.length
    ? productFilterObject?.categoryLabel[0]
    : [];

  const handleClickSubItem = (item) => {
    updateProductFilterObject(
      'categories',
      {
        $in: [item?.uid],
      },
      true
    );
  };

  const currentSubCategory = productFilterObject?.filter_data?.categories?.$in;

  const handleCheckLabel = (id) =>
    productFilterObject?.isSubcategoryItems && currentSubCategory?.includes(id);
  const handleCheckAllLabel = (id) => currentSubCategory?.includes(id);

  // animation
  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const subCategoryVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  const [showNotFound, setShowNotFound] = useState(false);

  // useEffect(() => {
  //   if (products?.length <= 0) {
  //     const timer = setTimeout(() => {
  //       setShowNotFound(true);
  //     }, 3000);

  //     // Cleanup function
  //     return () => clearTimeout(timer);
  //   }

  //   // Explicitly return undefined for clarity and to satisfy linting
  //   return undefined;

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [productsByCategory?.products]);

  useEffect(() => {
    let timer;

    if (products?.length <= 0) {
      timer = setTimeout(() => {
        setShowNotFound(true);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsByCategory?.products]);

  const renderNotFountContent = () => {
    if (showNotFound) {
      return renderNotFound;
    }

    return <ProductListLoading />;
  };

  return (
    <Stack mb={screenWidth < 600 && 15} mt={handleMarginTop()}>
      {!categoryName && categoryLoading ? (
        <CategoryItemSkeleton />
      ) : (
        <Box sx={{ mb: 4 }}>
          <Container maxWidth="xl" sx={{ mt: 2 }}>
            <Banners bannerData={banners?.banners} />
            <Grid px={0} mb={3}>
              <Card
                component={m.div}
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                sx={{
                  borderRadius: 2,
                  backgroundColor: '#F3F3FC',
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'start',
                  gap: 2,
                  py: '10px',
                  px: '20px',
                  overflowX: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <Box
                  textAlign="center"
                  sx={{
                    cursor: 'pointer',
                    minWidth: '100px',
                    width: 'fit-content',
                  }}
                  onClick={handleListAllProduct}
                >
                  <Image
                    src="/assets/images/default/default.png"
                    alt="default Image"
                    sx={{
                      maxWidth: '72px',
                      maxHeight: '72px',
                      margin: 'auto',
                    }}
                    imageStyle={{
                      aspectRatio: 1 / 1,
                      objectFit: 'contain',
                      width: '72px',
                      height: '72px',
                    }}
                  />
                  <Box textAlign="center" pt={0}>
                    <Typography variant="caption" className="m-0 text-dark mb-5">
                      {getText('all_products')}
                    </Typography>

                    {productFilterObject?.categoryLabel?.length <= 0 && (
                      <Stack
                        component={m.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        py={0}
                      >
                        <ExpandMoreIcon fontSize="medium" color="#232B35" />
                      </Stack>
                    )}
                  </Box>
                </Box>
                {/* Sub-categories */}
                {subCategories?.map((item, key) => (
                  <m.div
                    key={key}
                    initial="hidden"
                    animate="visible"
                    variants={subCategoryVariants}
                    transition={{ delay: key * 0.1 }}
                  >
                    <CategorySubItem
                      key={key}
                      setSelectItem={handleSelectedCategories}
                      item={item}
                    />
                  </m.div>
                ))}
              </Card>

              {/* Sub-sub-categories */}
              {productFilterObject?.categoryLabel?.length > 0 && (
                <Stack
                  component={m.div}
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ duration: 0.5, ease: 'easeInOut' }}
                  flexDirection="row"
                  spacing="12px"
                  sx={{
                    my: 2,
                    alignItems: 'start',
                    justifyContent: 'start',
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                >
                  <Chip
                    pill="true"
                    label="All"
                    color="info"
                    sx={{
                      backgroundColor: handleCheckAllLabel(currentSelectedCategory?.uid)
                        ? colors.themePrimary
                        : 'rgba(40, 116, 240, 0.1)',
                      color: handleCheckAllLabel(currentSelectedCategory?.uid) ? 'white' : 'black',
                      '&:hover': {
                        backgroundColor: colors.themePrimary,
                        color: 'white',
                      },
                      borderRadius: '56px',
                      cursor: 'pointer',
                    }}
                    size="medium"
                    onClick={() => handleSelectedCategories(currentSelectedCategory, true)}
                  />

                  {currentSelectedCategory?.sub_categories?.map((item, key) => (
                    <m.div
                      key={key}
                      initial="hidden"
                      animate="visible"
                      variants={subCategoryVariants}
                      transition={{ delay: key * 0.1 }}
                    >
                      <Chip
                        pill="true"
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        label={useTranslatedText(item?.name)}
                        onClick={() => handleClickSubItem(item)}
                        sx={{
                          backgroundColor: handleCheckLabel(item?.uid)
                            ? colors.themePrimary
                            : 'rgba(40, 116, 240, 0.1)',
                          color: handleCheckLabel(item?.uid) ? 'white' : 'black',
                          borderRadius: '56px',
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: colors.themePrimary,
                            color: 'white',
                          },
                        }}
                        size="medium"
                      />
                    </m.div>
                  ))}
                </Stack>
              )}
            </Grid>

            {/* {!categoryLoading && categoryName && products?.length <= 0 && renderNotFound} */}
            {products?.length ? (
              <Stack sx={{ minHeight: '500px' }}>
                <ProductList
                  isLarge
                  products={products}
                  pagination={{ currentPage, setCurrentPage, totalProducts, showLimit }}
                  loading={categoryProdLoading}
                />
              </Stack>
            ) : (
              renderNotFountContent()
            )}
          </Container>
        </Box>
      )}
    </Stack>
  );
};

export default CategorySingleView;

CategorySingleView.propTypes = {
  params: PropTypes.string,
};

const CategorySubItem = ({ setSelectItem, item }) => {
  const image = useImageTransformation(item?.logo?.url, 'w_500');
  const onHandleClickItem = () => {
    setSelectItem(item);
  };

  const { productFilterObject } = useSelector(getProductState);

  return (
    <Box
      textAlign="center"
      sx={{
        cursor: 'pointer',
        minWidth: '100px',
        width: 'fit-content',
      }}
      onClick={onHandleClickItem}
    >
      <Image
        src={image || '/assets/images/default/default.png'}
        alt="default Image"
        sx={{
          maxWidth: '72px',
          maxHeight: '72px',
          margin: 'auto',
        }}
        imageStyle={{
          aspectRatio: 1 / 1,
          objectFit: 'contain',
          width: '72px',
          height: '72px',
        }}
      />
      <Box textAlign="center" pt={0}>
        <Typography variant="caption" className="m-0 text-dark mb-5">
          {useTranslatedText(item?.name)}
        </Typography>

        {productFilterObject?.categoryLabel[0]?.uid === item?.uid && (
          <Stack flexDirection="row" alignItems="center" justifyContent="center" py={0}>
            <ExpandMoreIcon fontSize="medium" color="#232B35" />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

CategorySubItem.propTypes = {
  item: PropTypes.any,
  setSelectItem: PropTypes.any,
};
