import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Tooltip, Typography } from '@mui/material';
import SouthRoundedIcon from '@mui/icons-material/SouthRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';

import useFormatNumber from 'src/hooks/useFormatNumber';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import useTranslatedText from 'src/hooks/useTranslatedText';
import { useProductPathname } from 'src/hooks/useProductPathname';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { colors } from 'src/utils/colors';

import { CartIcon } from 'src/assets/icon/CartIcon';
import { CartAdded } from 'src/assets/icon/CartAdded';
import { useCurrency } from 'src/providers/CurrencyProvider';

import Image from 'src/components/image';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';

import useHandleCart from 'src/sections/cart/hooks/useHandleCart';

export const product_label_layouts = ['LAYOUT_1', 'LAYOUT_2', 'LAYOUT_3', 'LAYOUT_4', 'LAYOUT_5'];

// ----------------------------------------------------------------------

export default function ProductCardOne({
  product = null,
  action = null,
  noCart = null,
  isMinimal,
}) {
  const getText = useLocalizedText();
  const { currency } = useCurrency();
  const { screenWidth } = useScreenWidth();
  const rtl = useLayoutStyle();
  const { handleAddCart, isSuccess } = useHandleCart({ product });

  const handleAddToCart = () => {
    handleAddCart();
  };

  const outOfStock = product?.stock <= 0;
  const { productRoute } = useProductPathname(product);

  const { discountPercentage, offerPrice } = useProductOfferPriceCalculator(product);

  const translatedProductName = useTranslatedText(product?.name);
  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);
  const translateCurrencyText = useTranslatedText(currency?.title);

  const translatedAltText = useTranslatedText(
    product?.images[0]?.alt_text ? product?.images[0]?.alt_text : {}
  );
  const transformedImage = useImageTransformation(product?.images[0]?.url);
  //
  const translatedTextTwo = useTranslatedText(
    product?.images[1]?.alt_text ? product.images[1]?.alt_text : {}
  );
  const transformedImageTwo = useImageTransformation(product.images[1]?.url);

  const productLabels = product_label_layouts.map((layout) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTranslatedText(product?.product_labels?.find((i) => i.layout === layout)?.title)
  );

  const [productLabelOne, productLabelTwo, productLabelThree, productLabelFour, productLabelFive] =
    productLabels;

  const ProductImage = (
    <Tooltip title={outOfStock ? 'Out of stock' : ''} placement="bottom-end">
      <>
        {/* // first image  */}
        <Image
          alt={translatedAltText}
          src={transformedImage}
          sx={{
            maxWidth: '200px',
            maxHeight: '200px',
            margin: 'auto',
          }}
          imageStyle={{
            ...(outOfStock && {
              opacity: 0.48,
              filter: 'grayscale(1)',
            }),
            borderRadius: '10px',
            aspectRatio: '1/1',
            objectFit: 'contain',
          }}
          className={`product-image ${
            product?.images[1]?.url && !outOfStock && 'hover-image-available'
          }`}
        />

        {/* // second image  */}
        {!outOfStock && product?.images[1]?.url && (
          <Image
            alt={translatedTextTwo}
            src={transformedImageTwo}
            sx={{
              maxWidth: '200px',
              maxHeight: '200px',
              margin: 'auto',
            }}
            imageStyle={{
              ...(outOfStock && {
                opacity: 0.48,
                filter: 'grayscale(1)',
              }),
              borderRadius: '10px',
              aspectRatio: '1/1',
              objectFit: 'contain',
            }}
            className="product-image hover-image"
          />
        )}
      </>
    </Tooltip>
  );

  const formattedRatingCount = useFormatNumber(product?.average_rating_count);

  const renderImg = (
    <Box
      sx={{
        textAlign: 'center',
        position: 'relative',
        p: 0,
        border: '1px solid #f3f3fc',
        borderRadius: '11px',
      }}
    >
      {!outOfStock && (
        <>
          {!noCart && (
            <Stack
              className={`bg-cart-icon ${isSuccess && 'added'} ${rtl && 'ar-bg-cart-icon'}`}
              sx={{
                cursor: outOfStock && 'default',
              }}
              onClick={handleAddToCart}
              direction="row"
            >
              <Stack direction="row">
                <CartAdded width={18} height={18} className="cart-added" />
                <Typography mx={1} variant="caption" className="cart-added">
                  {getText('added_to_cart')}
                </Typography>
              </Stack>
              <CartIcon className="cart-icon-svg" width={20} height={20} />
            </Stack>
          )}
        </>
      )}

      <OspLink
        sx={{ '&:hover': { textDecoration: 'none' }, position: 'unset' }}
        key={productRoute}
        href={productRoute}
      >
        {product?.images?.length > 0 ? (
          ProductImage
        ) : (
          <Image
            alt="alt"
            src="/assets/images/default/product.png"
            sx={{
              maxWidth: '200px',
              maxHeight: '200px',
            }}
            imageStyle={{
              ...(outOfStock && {
                opacity: 0.48,
                filter: 'grayscale(1)',
              }),
              borderRadius: '10px',
              aspectRatio: '1/1',
              objectFit: 'contain',
            }}
          />
        )}
      </OspLink>

      {outOfStock && (
        <Stack className="out-of-stock-container p-1">
          <Typography sx={{ color: '#fff', fontWeight: 400 }} variant="body2">
            {getText('out_of_stock')}
          </Typography>
        </Stack>
      )}

      {/* // label group 1 */}
      {productLabelOne && (
        <Label
          variant="filled"
          color="error"
          sx={{
            position: 'absolute',
            left: '0px',
            top: '0px',
            borderRadius: isMinimal ? '12px 0px' : '16px 0px',
            padding: '0 14px',
          }}
        >
          {productLabelOne}
        </Label>
      )}

      {/* // label group 2 */}
      <Stack
        sx={{
          position: 'absolute',
          left: '8px',
          bottom: '8px',
        }}
        spacing={1}
      >
        {productLabelTwo && (
          <Label
            variant="filled"
            color="default"
            sx={{
              padding: '0 14px 0px 10px',
              with: 'fit-content',
              backgroundColor: '#30AE4A',
            }}
            className="width-fit-content"
          >
            <TrendingUpRoundedIcon fontSize="small" sx={{ mr: 0.6 }} />
            {productLabelTwo}
          </Label>
        )}
        {product?.average_rating ? (
          <Stack>
            <Label
              variant="soft"
              color="default"
              sx={{
                padding: '4px 14px',
                borderRadius: '20px',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'flex-start',
                with: 'fit-content',
              }}
              className="width-fit-content"
            >
              <Typography
                variant="caption"
                sx={{ color: 'black', display: 'flex', alignItems: 'center' }}
              >
                {product?.average_rating?.toFixed(1)}
                <StarRateRoundedIcon color="inherit" sx={{ color: '#fced50' }} fontSize="small" />
              </Typography>

              {formattedRatingCount > 0 && (
                <Typography variant="caption">{formattedRatingCount}</Typography>
              )}
            </Label>
          </Stack>
        ) : (
          ''
        )}
      </Stack>
    </Box>
  );

  const renderContent = (
    <Stack spacing={0.5} sx={{ p: 1, pt: 1 }}>
      <TextMaxLine color="black" variant="subtitle2" line={2}>
        {translatedProductName}
      </TextMaxLine>

      {/* // label group 3 */}
      {productLabelThree && (
        <Label
          variant="soft"
          color="info"
          sx={{
            padding: '0 14px 0px 10px',
            width: screenWidth > 400 ? 'fit-content' : '170px',
          }}
          className={screenWidth > 400 ? 'width-fit-content' : ''}
        >
          <AutoAwesomeRoundedIcon
            fontSize="small"
            sx={{ mr: 0.6, height: '15px', width: '15px' }}
          />
          {productLabelThree}
        </Label>
      )}

      <Stack
        mt={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={0.5}
      >
        {discountPercentage && (
          <Typography
            variant="subtitle2"
            color="#30ae4a"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ArrowDownwardRoundedIcon
              fontSize="small"
              sx={{ mr: 0.6, height: '15px', width: '15px' }}
            />
            {discountPercentage}% {getText('off')}
          </Typography>
        )}

        {/* // label group 4 */}
        {productLabelFour && (
          <Label
            variant="soft"
            color="error"
            sx={{
              padding: '0 14px 0px 10px',
              with: 'fit-content',
              color: '#ff0000',
            }}
            className="width-fit-content"
          >
            <LocalFireDepartmentRoundedIcon
              fontSize="small"
              sx={{ mr: 0.6, height: '15px', width: '15px' }}
            />
            {productLabelFour}
          </Label>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Typography variant="caption" color="black" sx={{ fontSize: '14px' }}>
          {translateCurrencyText}
        </Typography>
        <Typography variant="subtitle2" color="black" sx={{ fontSize: '16px', fontWeight: 700 }}>
          {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
        </Typography>

        {offerPrice && offerPrice < product?.price ? (
          <Box
            component="span"
            sx={{
              color: 'text.disabled',
              textDecoration: 'line-through',
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {currencyConversionPrice}
          </Box>
        ) : (
          ''
        )}
      </Stack>

      {productLabelFive && (
        <Label
          variant="soft"
          color="success"
          sx={{
            padding: '0 14px 0px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
          className="width-fit-content"
        >
          <SouthRoundedIcon fontSize="small" sx={{ mr: 0.6, height: '15px', width: '15px' }} />
          {productLabelFive}
        </Label>
      )}
      {product?.stock < 10 && product?.stock !== 0 && !outOfStock ? (
        <Typography mt={0.2} variant="caption" color="#ff0000">
          {getText('only')} {product?.stock} {getText('left_in_stock')}
        </Typography>
      ) : (
        ''
      )}
      {product?.is_free_delivery_product ? (
        <Typography
          mt={0.2}
          variant="subtitle2"
          color="#30ae4a"
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 2px',
          }}
        >
          <LocalShippingRoundedIcon
            fontSize="small"
            sx={{ mr: 0.6, height: '15px', width: '15px' }}
          />
          {getText('free_delivery')}
        </Typography>
      ) : (
        ''
      )}
    </Stack>
  );

  return (
    <Card
      sx={{
        boxShadow: 'none',
        border: 'none',
        position: 'relative',
        overflow: 'visible',
        borderRadius: '12px',
        '&:hover .add-cart-btn': {
          opacity: 1,
        },
        maxWidth: isMinimal ? '100%' : '200px',
      }}
      className="product-cart-wrap"
    >
      {action}
      {renderImg}
      <OspLink
        sx={{ '&:hover': { textDecoration: 'none' } }}
        key={productRoute}
        href={productRoute}
      >
        {renderContent}
      </OspLink>

      {noCart && (
        <Button
          fullWidth
          disabled={outOfStock}
          size="small"
          color="inherit"
          variant="outlined"
          startIcon={<Iconify icon="solar:cart-plus-bold" width={18} />}
          onClick={handleAddToCart}
          sx={{
            whiteSpace: 'nowrap',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: '#e4e4e4' },
            borderRadius: '4px',
            border: !isMinimal && `1px solid ${colors.themePrimary}`,
            color: colors.themePrimary,
            mt: 1,
          }}
        >
          {getText('add_to_cart')}
        </Button>
      )}
    </Card>
  );
}

ProductCardOne.propTypes = {
  product: PropTypes.object,
  action: PropTypes.any,
  noCart: PropTypes.string,
  isMinimal: PropTypes.bool,
};
