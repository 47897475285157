import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
// ----------------------------------------------------------------------

const RedAsteriskTextField = styled(TextField)({
  '& .MuiInputLabel-asterisk': {
    color: 'red', // Set the asterisk color to red
  },
});

export default function RHFTextField({
  name,
  required,
  size = 'small',
  helperText,
  shrink,
  type,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <RedAsteriskTextField
          {...field}
          fullWidth
          size={size}
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          InputLabelProps={{
            shrink,
            required,
          }}
          {...other}
        />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  shrink: PropTypes.bool,
};
