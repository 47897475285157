import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const commonMetaData = (title) => ({
  frontendUrl: 'https://buildexstores.com',
  title: `${title || 'Home'} | Buildex E-Commerce Platform`,
  favIcon: '/assets/images/logs/favIcon.png',
  twitterSite: '@Buildex',
  description:
    'Welcome to Buildex! Shop a wide range of quality products from top brands. Find the best deals on electronics, fashion, home appliances, and more. Fast shipping and great prices await you!',
  images: {
    url: '/assets/images/logs/favIcon.png',
    width: 1200,
    height: 630,
    alt: 'Buildex E-Commerce Platform',
  },
  type: 'website',
  url: 'https://buildexstores.com',
  card: 'summary_large_image',
});

const MetaHead = ({ title, description, keywords, openGraph, twitter }) => {
  const defaultMeta = commonMetaData(title);
  const openGraphImage = openGraph?.images ? openGraph?.images : defaultMeta.images;

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{`${title || 'Home'} | Buildex E-Commerce Platform` || defaultMeta.title}</title>
      <meta name="description" content={description || defaultMeta.description} />
      <meta name="keywords" content={keywords || ''} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={defaultMeta.frontendUrl} />

      {/* Open Graph Meta Tags */}
      {openGraph && (
        <>
          <meta property="og:title" content={openGraph.title || defaultMeta.title} />
          <meta
            property="og:description"
            content={openGraph.description || defaultMeta.description}
          />
          <meta property="og:url" content={defaultMeta.url} />
          <meta property="og:type" content={defaultMeta.type || 'website'} />

          <meta property="og:image" content={openGraphImage?.url} />
        </>
      )}

      {/* Twitter Meta Tags */}
      {twitter && (
        <>
          <meta name="twitter:card" content={defaultMeta.card} />
          <meta name="twitter:site" content={defaultMeta.twitterSite} />
          <meta name="twitter:title" content={twitter.title || defaultMeta.title} />
          <meta
            name="twitter:description"
            content={twitter.description || defaultMeta.description}
          />
          <meta name="twitter:image" content={twitter.image || defaultMeta.favIcon} />
        </>
      )}

      {/* Favicon */}
      <link rel="icon" href={defaultMeta.favIcon} type="image/png" />
    </Helmet>
  );
};

MetaHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  openGraph: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        alt: PropTypes.string,
      })
    ),
  }),
  twitter: PropTypes.shape({
    card: PropTypes.string,
    site: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
};

export default MetaHead;
