import PropTypes from 'prop-types';

export const HomeActiveIcon = ({ width = 20, height = 20, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.982 16.8226H8.66039V11.2853C8.66039 10.8918 8.92214 10.6478 9.30498 10.6478H12.3464C12.738 10.6478 12.982 10.8918 12.982 11.2853V16.8226ZM3.44242 16.3255C3.44242 17.4867 4.1441 18.1706 5.32308 18.1706H16.3443C17.5234 18.1706 18.2251 17.4867 18.2251 16.3255V9.68622L11.3338 3.91228C11.0181 3.63405 10.635 3.64476 10.33 3.91228L3.44242 9.68262V16.3255ZM0.826172 8.8453C0.826172 9.19025 1.09827 9.51569 1.55347 9.51569C1.78643 9.51569 1.97841 9.38765 2.14736 9.24715L10.5348 2.20178C10.7275 2.0412 10.9468 2.04284 11.1326 2.20178L19.5128 9.24715C19.6906 9.38765 19.8826 9.51569 20.1156 9.51569C20.5103 9.51569 20.8252 9.27024 20.8252 8.87194C20.8252 8.6176 20.7487 8.44865 20.5654 8.29565L11.8477 0.966152C11.2228 0.439017 10.4572 0.439017 9.82857 0.966152L1.09482 8.29565C0.91156 8.44865 0.826172 8.65316 0.826172 8.8453Z"
      fill="white"
    />
  </svg>
);

HomeActiveIcon.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
};
