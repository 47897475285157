import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Stack, Divider, Typography, IconButton } from '@mui/material';

import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { colors } from 'src/utils/colors';

import { getProductState } from 'src/server/selectors/selector';
import { fetchAllProducts } from 'src/server/slice/productSlice';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';

import ProductCardTwo from 'src/sections/product/cards/product-card-2';

import useLocalizedText from '../../../hooks/useLocalizedText';
import useTranslatedText from '../../../hooks/useTranslatedText';
import useImageTransformation from '../../../hooks/useImageTransformation';

export const ListHorizontal = ({ groupDetail }) => {
  const getText = useLocalizedText();
  const { isMobile } = useScreenWidth();
  const { screenWidth } = useScreenWidth();
  const rtl = useLayoutStyle();
  const dispatch = useDispatch();

  const { products } = useSelector(getProductState);

  const categories = groupDetail?.type === 'SINGLE_CATEGORY' && groupDetail?.categories[0]?.uid;
  const brands = groupDetail?.type === 'SINGLE_BRAND' && groupDetail?.brands[0]?.uid;
  const collections = groupDetail?.type === 'SINGLE_COLLECTION' && groupDetail?.collections[0]?.uid;
  const specialSales =
    groupDetail?.type === 'SINGLE_SPECIAL_SALE' && groupDetail?.special_sales[0]?.uid;

  const handleProductData = () => {
    if (
      groupDetail?.is_live &&
      (groupDetail?.type === 'SINGLE_CATEGORY' ||
        groupDetail?.type === 'SINGLE_COLLECTION' ||
        groupDetail?.type === 'SINGLE_BRAND' ||
        groupDetail?.type === 'SINGLE_SPECIAL_SALE' ||
        groupDetail?.type === 'PRODUCTS')
    ) {
      return products?.products;
    }
    return groupDetail?.products;
  };

  const finalGroupWithActiveProducts = handleProductData()?.filter((item) => item?.is_active);

  useEffect(() => {
    if (groupDetail?.is_live) {
      const credentials = {
        page: 1,
        search: '',
        limit: 10,
        state: {
          filter_data: {
            is_active: true,
            is_deleted: false,
            ...(categories && {
              categories: {
                $in: [categories],
              },
            }),
            ...(brands && {
              brands: {
                $in: [brands],
              },
            }),
            ...(collections && {
              collections: {
                $in: [collections],
              },
            }),
            ...(specialSales && {
              special_sales: {
                $in: [specialSales],
              },
            }),
          },
          sort_data: {
            createdAt: -1,
          },
        },
      };
      dispatch(fetchAllProducts(credentials));
    }
  }, [dispatch, groupDetail, categories, brands, collections, specialSales]);

  const translatedTitle = useTranslatedText(groupDetail?.title);
  const translatedText = useTranslatedText(groupDetail?.images[0]?.alt_text);
  const transformedImage = useImageTransformation(groupDetail?.images[0]?.url, '', true);

  return (
    <Box position="relative">
      {translatedTitle && (
        <OspLink href={groupDetail?.link} sx={{ '&:hover': { textDecoration: 'none' } }}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <Box position="relative">
              <Typography variant="body1" fontSize={isMobile ? 18 : 20} color="black">
                {translatedTitle}
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.themeSecondary,
                  height: 2,
                  width: 150,
                  position: 'absolute',
                  bottom: -9,
                }}
              />
            </Box>
            {groupDetail?.link && (
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                gap={2}
                color="text.disabled"
              >
                {getText('view_all')}
                {rtl ? (
                  <IconButton
                    sx={{
                      backgroundColor: colors.themePrimary,
                      borderRadius: '50%',
                      p: isMobile ? '5px' : 0.5,
                      '&:hover': {
                        backgroundColor: colors.themePrimary,
                      },
                    }}
                  >
                    <Iconify
                      icon="solar:alt-arrow-left-outline"
                      width={isMobile ? 12 : 20}
                      color="white"
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      backgroundColor: colors.themePrimary,
                      borderRadius: '50%',
                      p: isMobile ? '5px' : 0.5,
                      '&:hover': {
                        backgroundColor: colors.themePrimary,
                      },
                    }}
                  >
                    <Iconify
                      icon="solar:alt-arrow-right-outline"
                      width={isMobile ? 12 : 20}
                      color="white"
                    />
                  </IconButton>
                )}
              </Typography>
            )}
          </Stack>
          <Divider sx={{ mb: 2, mt: 1 }} />
        </OspLink>
      )}

      <Grid item container spacing={1}>
        <Grid item md={3} sm={12} xs={12} xl={2} pb={screenWidth < 900 ? 2 : 0}>
          <OspLink
            href={groupDetail?.link || ''}
            sx={{
              '&:hover': { textDecoration: 'none' },
            }}
          >
            <Image
              src={transformedImage}
              alt={translatedText}
              className="cursor-pointer"
              sx={{}}
              imageStyle={{
                objectFit: 'cover',
                borderRadius: '10px',
                height: '100%',
                width: '100%',
                aspectRatio: screenWidth < 900 && 4 / 1.5,
              }}
            />
          </OspLink>
        </Grid>
        <Grid item md={9} sm={12} xs={12} xl={10}>
          <Box
            columnGap={1}
            rowGap={1.5}
            display="grid"
            gridTemplateColumns={{
              xs: `repeat(1, 1fr)`,
              sm: `repeat(2, 1fr)`,
              md: 'repeat(2, 1fr)',
              lg: `repeat(${screenWidth < 1380 ? '2' : '3'}, 1fr)`,
            }}
          >
            {finalGroupWithActiveProducts?.slice(0, 6)?.map((item, key) => (
              <ProductCardTwo key={key} product={item} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ListHorizontal.propTypes = {
  groupDetail: PropTypes.any,
};
