import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import { paths } from 'src/routes/paths';
import { useRouter, usePathname } from 'src/routes/hooks';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useGetToken } from 'src/hooks/useHandleSessions';

import { TOKEN_PREFIX } from 'src/server/http';
import { logOutUser } from 'src/server/slice/authentication';
import { getProfileState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

// ----------------------------------------------------------------------

export default function NavMobile({ data }) {
  const pathname = usePathname();
  const getText = useLocalizedText();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const route = useRouter();

  const { isSuccess, token } = useGetToken(TOKEN_PREFIX);

  const [openMenu, setOpenMenu] = useState(false);

  const { user, logoutLoading } = useSelector(getProfileState);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const bottomData = [
    {
      name: `${getText('help&support')}`,
      icon: <HelpOutlineIcon fontSize="small" />,
      link: '',
    },
    {
      name: `${getText('privacy_policy')}`,
      icon: <VerifiedUserOutlinedIcon fontSize="small" />,
      link: paths.legal.privacyPolicy,
    },
    {
      name: `${getText('terms_of_use')}`,
      icon: <TextSnippetIcon fontSize="small" />,
      link: paths.legal.termsAndConditions,
    },
  ];

  const handleLogOut = () => {
    const credentials = {
      enqueueSnackbar,
      dispatch,
      route,
    };

    dispatch(logOutUser(credentials));
  };

  const navValues = [
    {
      name: `${getText('products')}`,
      link: paths.public.products,
    },
    // {
    //   name: `${getText('categories')}`,
    //   link: paths.public.c,
    // },
    {
      name: `${getText('collections')}`,
      link: paths.public.collections,
    },
    {
      name: `${getText('brands')}`,
      link: paths.public.brands,
    },
  ];

  if (isSuccess && token) {
    navValues.push(
      { name: `${getText('my_account')}`, link: paths.private.my_account.root },
      { name: `${getText('wishlist')}`, link: paths.private.my_account.root }
    );
  }

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <MenuIcon fontSize="large" sx={{ color: 'white' }} />
      </IconButton>

      <Drawer
        open={openMenu}
        onClose={handleCloseMenu}
        anchor="right"
        className="mobile-nav"
        PaperProps={{
          sx: {
            width: 300,
          },
        }}
      >
        <Stack sx={{ backgroundColor: '#232B35' }}>
          <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
            <IconButton onClick={handleCloseMenu}>
              <CloseIcon fontSize="large" sx={{ color: 'white' }} />
            </IconButton>

            <Image
              alt="nav-logo"
              src="/assets/images/logs/image.png"
              sx={{}}
              imageStyle={{
                width: 120,
              }}
            />
          </Box>
          <Stack direction="row" justifyContent="space-between" px={2} pb={1}>
            {isSuccess && token ? (
              <Typography variant="subtitle2" color="white">
                {!user?.is_guest && user?.uid ? user?.name?.slice(0, 20) : getText('guest')}
              </Typography>
            ) : (
              <OspLink href={paths?.public.login} sx={{ '&:hover': { textDecoration: 'none' } }}>
                <Typography variant="subtitle2" color="white">
                  {getText('login')}
                </Typography>
              </OspLink>
            )}
            <PersonRoundedIcon sx={{ color: 'white' }} />
          </Stack>
        </Stack>
        <Stack m={2} height="100%" direction="column" justifyContent="space-between">
          <Stack>
            {navValues?.map((item, key) => (
              <OspLink
                key={key}
                href={item?.link || ''}
                sx={{ '&:hover': { textDecoration: 'none' } }}
              >
                <Box
                  px={0.5}
                  py={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ borderBottom: '1px solid #ececec' }}
                >
                  <Typography color="#232B35" variant="subtitle2">
                    {item?.name}
                  </Typography>
                  <ChevronRightOutlinedIcon sx={{ color: '#232B35' }} />
                </Box>
              </OspLink>
            ))}
          </Stack>
          <Box>
            {bottomData?.map((item, key) => (
              <OspLink key={key} href={item.link} sx={{ '&:hover': { textDecoration: 'none' } }}>
                <Typography
                  variant="body2"
                  display="flex"
                  alignItems="center"
                  gap={1}
                  pb={2}
                  color="black"
                >
                  {item?.icon} {item?.name}
                </Typography>
              </OspLink>
            ))}
            {isSuccess && token && (
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                gap={1}
                pb={2}
                color="black"
                onClick={handleLogOut}
              >
                {logoutLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <LogoutIcon fontSize="small" />
                )}
                {getText('logout')}
              </Typography>
            )}
          </Box>
        </Stack>
      </Drawer>
    </>
  );
}

NavMobile.propTypes = {
  data: PropTypes.array,
};
