import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

// ----------------------------------------------------------------------

export function CategoryItemSkeleton({ sx, ...other }) {
  return (
    <Box sx={{ px: 2, mt: 2 }}>
      <Stack sx={{ p: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Skeleton sx={{ borderRadius: '6px' }} variant="rectangular" width="100%" height="500px" />
      </Stack>

      <Stack spacing={2} direction="row" flexWrap="wrap" justifyContent="start" my={3}>
        {[...Array(6)].map((_, index) => (
          <Skeleton
            key={index}
            variant="circular"
            sx={{
              width: 100,
              minHeight: 20,
              borderRadius: 1,
            }}
          />
        ))}
      </Stack>
      <Stack spacing={2} direction="row" flexWrap="wrap" justifyContent="space-between" my={3}>
        {[...Array(12)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            sx={{
              width: 180,
              minHeight: 250,
              borderRadius: 1,
            }}
          />
        ))}
      </Stack>
    </Box>
  );
}

CategoryItemSkeleton.propTypes = {
  sx: PropTypes.object,
};
