import PropTypes from 'prop-types';

export const ReturnIcon = ({ width = 16, height = 16, className }) => (
  <svg
    width="15"
    className={className}
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7578 10.4531C14.7578 11.4531 14.5443 12.3255 14.1172 13.0703C13.6953 13.8203 13.0781 14.401 12.2656 14.8125C11.4531 15.2292 10.4635 15.4375 9.29688 15.4375H7.49219C7.27865 15.4375 7.10677 15.3698 6.97656 15.2344C6.84635 15.099 6.78125 14.9349 6.78125 14.7422C6.78125 14.5495 6.84635 14.3854 6.97656 14.25C7.10677 14.1146 7.27865 14.0469 7.49219 14.0469H9.28906C10.1797 14.0469 10.9271 13.8854 11.5312 13.5625C12.1354 13.2448 12.5911 12.8099 12.8984 12.2578C13.2057 11.7057 13.3594 11.0781 13.3594 10.375C13.3594 9.67708 13.2057 9.0599 12.8984 8.52344C12.5911 7.98177 12.1354 7.5599 11.5312 7.25781C10.9271 6.95573 10.1797 6.80469 9.28906 6.80469H3.92188L1.5 6.69531L1.64062 6.33594L3.57031 7.99219L5.82812 10.1875C5.88542 10.25 5.92969 10.3203 5.96094 10.3984C5.9974 10.4766 6.01562 10.5651 6.01562 10.6641C6.01562 10.8672 5.95052 11.0339 5.82031 11.1641C5.69531 11.2891 5.52865 11.3516 5.32031 11.3516C5.1276 11.3516 4.96094 11.2786 4.82031 11.1328L0.226562 6.625C0.148438 6.55208 0.0885417 6.47135 0.046875 6.38281C0.0104167 6.29427 -0.0078125 6.20312 -0.0078125 6.10938C-0.0078125 6.01042 0.0104167 5.91667 0.046875 5.82812C0.0885417 5.73958 0.148438 5.65885 0.226562 5.58594L4.82031 1.07812C4.96094 0.932292 5.1276 0.859375 5.32031 0.859375C5.52865 0.859375 5.69531 0.924479 5.82031 1.05469C5.95052 1.17969 6.01562 1.34375 6.01562 1.54688C6.01562 1.64583 5.9974 1.73438 5.96094 1.8125C5.92969 1.89062 5.88542 1.96094 5.82812 2.02344L3.57031 4.22656L1.64062 5.875L1.5 5.51562L3.92188 5.40625H9.17188C10.3594 5.40625 11.3698 5.61979 12.2031 6.04688C13.0365 6.47396 13.6693 7.06771 14.1016 7.82812C14.5391 8.58333 14.7578 9.45833 14.7578 10.4531Z"
      fill="#F24043"
    />
  </svg>
);

ReturnIcon.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
};
