import MetaHead from 'src/utils/metaHead';

import CartView from 'src/sections/cart/view/cart';

export default function CartPage() {
  return (
    <>
      <MetaHead
        title="Your Shopping Cart"
        description="Review the items in your shopping cart on Buildex. Make sure you’ve got everything you need before checking out. Shop top brands and quality products today!"
        keywords="shopping cart, Buildex, checkout, online shopping, review cart, top brands, quality products"
        openGraph={{
          title: 'Your Shopping Cart - Buildex E-Commerce Platform',
          description:
            'Check out the items in your cart on Buildex. Complete your purchase of top-quality products from leading brands with fast and secure checkout options.',
        }}
        twitter={{
          title: 'Your Shopping Cart - Buildex E-Commerce Platform',
          description:
            'Check out the items in your cart on Buildex. Complete your purchase of top-quality products from leading brands with fast and secure checkout options.',
        }}
      />
      <CartView />
    </>
  );
}
