import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import { Box, Stack } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { useCurrency } from 'src/providers/CurrencyProvider';
import { fetchWishListUpdate } from 'src/server/slice/userSlice';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';

import useHandleCart from 'src/sections/cart/hooks/useHandleCart';

import IncrementorButton from '../common/incrementer-button';

// ----------------------------------------------------------------------

export default function MyAccountWishlist({ wishListData }) {
  const getText = useLocalizedText();
  const { currency } = useCurrency();
  const { isMobile } = useScreenWidth();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const product = wishListData?.product;

  const [quantity, setQuantity] = useState(1);

  const translatedText = useTranslatedText(
    product?.images[0]?.alt_text ? product?.images[0]?.alt_text : {}
  );
  const transformedImage = useImageTransformation(product?.images[0]?.url);

  const isOutOfStock = product?.stock <= 0;
  const isLowStock = product?.stock <= 5 && product?.stock !== 0;

  const { discountPercentage, offerPrice } = useProductOfferPriceCalculator(product);

  const translatedProductName = useTranslatedText(product?.name);
  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);
  const translateCurrencyText = useTranslatedText(currency?.title);

  const { handleAddCart } = useHandleCart({ product });
  const handleAddToCart = () => {
    handleAddCart(quantity);
  };

  const handleWishList = () => {
    const credentials = {
      state: {
        product: product?.uid,
        operation: 'REMOVE',
      },
      enqueueSnackbar,
    };
    dispatch(fetchWishListUpdate(credentials));
  };

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'center' : 'start'}
      justifyContent="space-between"
      spacing={isMobile ? 2 : 0}
    >
      <Stack direction="row" alignItems="start" justifyContent="start" spacing={2}>
        <Stack direction={isMobile ? 'column' : 'row'} sx={{ pt: 0 }}>
          {product?.images?.length > 0 ? (
            <Image
              alt={translatedText}
              src={transformedImage}
              sx={{
                border: '1px solid #e9e9e9',
                borderRadius: '10px',
                minHeight: isMobile ? '100px' : '150px',
                minWidth: isMobile ? '100px' : '150px',
                maxHeight: '150px',
                maxWidth: '150px',
                padding: '10px',
              }}
              imageStyle={{
                objectFit: 'contain',
                aspectRatio: 1 / 1,
                borderRadius: '10px',
              }}
              className={`product-image ${product?.images[1]?.url && 'hover-image-available'}`}
            />
          ) : (
            <Image
              alt="alt"
              src="/assets/images/default/product.png"
              sx={{
                borderRadius: '10px',
                maxHeight: isMobile ? '150px' : '200px',
                maxWidth: isMobile ? '150px' : '200px',
              }}
              imageStyle={{
                aspectRatio: 1 / 1,
                borderRadius: '10px',
              }}
            />
          )}
        </Stack>
        <Stack spacing={isMobile ? 5 : 6}>
          <Stack>
            <TextMaxLine variant="body2" line={2} sx={{ fontWeight: 'fontWeightSemiBold' }}>
              {translatedProductName}
            </TextMaxLine>

            <Stack>
              <Box
                component="span"
                sx={{
                  typography: 'caption',
                  textTransform: 'capitalize',
                  color:
                    (isOutOfStock && 'error.main') ||
                    (isLowStock && 'warning.main') ||
                    'success.main',
                }}
              >
                {(isOutOfStock && getText('out_of_stock')) ||
                  (isLowStock && (
                    <>
                      {getText('only')} {product?.stock} {getText('left_in_stock')}
                    </>
                  )) ||
                  getText('in_stock')}
              </Box>

              {/* <Typography variant="body2" sx={{ fontWeight: 300 }}>
              {getText('color')} : White
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 300 }}>
              {getText('size')} : {`5"W x 7"H x 5"D`}
            </Typography> */}
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" width="fit-content" spacing={3}>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {getText('quantity')}
            </Typography>

            <Stack
              spacing={1}
              sx={{
                backgroundColor: '#EEF0F4',
                borderRadius: '8px',
              }}
            >
              <IncrementorButton
                name="quantity"
                quantity={quantity}
                disabledDecrease={quantity <= 1}
                disabledIncrease={quantity >= product?.stock}
                onIncrease={() => setQuantity(quantity + 1)}
                onDecrease={() => setQuantity(quantity - 1)}
                sx={{
                  padding: isMobile ? '2px' : '4px',
                }}
                // loading
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        // sx={{
        //   padding: 2,
        //   pt: 0,
        //   mt: isMobile ? 2 : 0,
        //   textAlign: 'end',
        //   height: '100%',
        //   alignSelf: isMobile ? 'end' : '',
        // }}
        direction={isMobile ? 'row' : 'column'}
        spacing={isMobile ? 2 : 8}
        textAlign="end"
      >
        <Stack>
          {discountPercentage && (
            <Typography mx={1} variant="subtitle1" color="#26883A" sx={{ fontWeight: 700 }}>
              {discountPercentage}% {getText('off')}
            </Typography>
          )}
          <Typography
            variant="h5"
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              justifyContent: isMobile ? 'flex-start' : 'flex-end',
              gap: 0.8,
            }}
          >
            <Typography
              component="span"
              variant="subtitle2"
              sx={{ fontWeight: 400, marginBottom: '-4px' }}
            >
              {translateCurrencyText}
            </Typography>
            {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}

            {offerPrice && offerPrice < product?.price && (
              <Box
                component="span"
                sx={{
                  color: 'text.disabled',
                  textDecoration: 'line-through',
                  lineHeight: 'normal',
                  marginBottom: '-4px',
                }}
              >
                <Typography component="span" variant="subtitle1">
                  {currencyConversionPrice}
                </Typography>
              </Box>
            )}
          </Typography>

          {!product?.is_free_delivery_product ? (
            <Typography
              mt={0.4}
              component="div"
              variant="subtitle2"
              color="#26883A"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0 2px',
                fontWeight: 600,
              }}
            >
              <Iconify icon="la:shipping-fast" sx={{ mr: 1.2, height: '18px', width: '18px' }} />

              {getText('free_delivery')}
            </Typography>
          ) : (
            ''
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Button
            fullWidth
            // disabled={}
            size="small"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="solar:cart-plus-bold" width={24} />}
            onClick={handleAddToCart}
            sx={{
              whiteSpace: 'nowrap',
              backgroundColor: 'transparent',
              '&:hover': { backgroundColor: '#e4e4e4' },
              borderRadius: '8px',
              // border: '1px solid colors.themePrimary,
              // color: colors.themePrimary,
            }}
          >
            {getText('add_to_cart')}
          </Button>
          <IconButton
            size="small"
            sx={{ ml: 1, borderRadius: 1, border: '1px solid #f2f2f2' }}
            onClick={handleWishList}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

MyAccountWishlist.propTypes = {
  wishListData: PropTypes.object,
};
