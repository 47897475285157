import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, Typography, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/paths';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { fDate } from 'src/utils/format-time';

import { CloseIcon } from 'src/assets/icon/CloseIcon';
import { ReturnIcon } from 'src/assets/icon/ReturnIcon';
import { getOrderState, getOrderSingleViewState } from 'src/server/selectors/selector';
import { orderStatusUpdate, fetchOrderSingleView } from 'src/server/slice/cartOrderSlice';

import OspLink from 'src/components/link/osp-link';

import OrderTracking from './order-tracking';
import MyAccountOrderItems from './order-Items';
import AddressCard from '../address/address-card';
import OrderDetailsSkeleton from './order-details-skeleton';

// ----------------------------------------------------------------------

export default function OrderSinglePage() {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useScreenWidth();

  const getText = useLocalizedText();
  const rtl = useLayoutStyle();
  const params = useParams();
  const dispatch = useDispatch();
  const orderId = params?.id;

  const { orderSingleView: serverOrderSingleView } = useSelector(getOrderSingleViewState);
  const { orderStatusLoading, loading } = useSelector(getOrderState);

  const orderSingleView = serverOrderSingleView;

  useEffect(() => {
    dispatch(fetchOrderSingleView({ orderId }));
  }, [dispatch, orderId]);

  const deliveryType = (() => {
    if (orderSingleView?.delivery_type === 'HOME_DELIVERY') {
      return 'Home Delivery';
    }
    if (orderSingleView?.delivery_type === 'STORE_PICKUP') {
      return 'Store Pickup';
    }
    return '--';
  })();

  const shippingAddress = orderSingleView?.shipping_address || {};
  const billingAddress = orderSingleView?.shipping_address || {};
  const currentStatus = orderSingleView?.order_status;

  const handleUpdateOrderStatus = (order_status) => {
    const credentials = {
      state: {
        order: orderSingleView?.uid,
        order_status,
      },
      customNotification: enqueueSnackbar,
    };
    dispatch(orderStatusUpdate(credentials));
  };

  const handleOrderStatus = () => {
    switch (orderSingleView?.order_status) {
      case 'CANCEL_INITIATED':
        return 'Cancel Initiated';

      case 'RETURN_INITIATED':
        return 'Return Initiated';

      default:
        return orderSingleView?.order_status;
    }
  };

  return (
    <>
      {loading ? (
        <OrderDetailsSkeleton />
      ) : (
        <Stack alignItems="start" justifyContent="space-between">
          <OspLink
            sx={{ '&:hover': { textDecoration: 'none' }, color: 'black' }}
            key={paths.private.my_account.root}
            href={paths.private.my_account.root}
          >
            <Stack direction={rtl ? 'column' : 'row'} alignItems="center" spacing={1}>
              {rtl ? <ArrowForwardIcon /> : <ArrowBackIcon />}
              <Typography variant="body2">{getText('back')}</Typography>
            </Stack>
          </OspLink>

          <Typography variant="subtitle1" my={2}>
            {getText('order_details')}
          </Typography>

          <Stack direction="row" gap={3}>
            <Stack gap={1}>
              <Typography component="span" variant="body2">
                {getText('order_id')}&nbsp;:
                <Typography ml={1} component="span" variant="body2" display="inline">
                  {orderSingleView?.order_id}
                </Typography>
              </Typography>

              <Typography component="span" variant="body2">
                {isMobile ? getText('status') : getText('order_status')}&nbsp;:
                <Typography
                  ml={1}
                  component="span"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  variant="body2"
                  display="inline"
                >
                  {handleOrderStatus() || '--'}{' '}
                </Typography>
              </Typography>
              <Typography component="span" variant="body2">
                {getText('order_date')} :
                <Typography ml={1} component="span" variant="body2" display="inline">
                  {fDate(orderSingleView?.order_initiated_at)}
                </Typography>
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography component="span" variant="body2">
                {getText('invoice_date')}&nbsp;:
                <Typography ml={1} component="span" variant="body2" display="inline">
                  {fDate(orderSingleView?.createdAt)}
                </Typography>
              </Typography>
              <Typography component="span" variant="body2">
                {getText('payment_type')}&nbsp;:
                <Typography ml={1} component="span" variant="body2" display="inline">
                  {orderSingleView?.payment_type || '--'}{' '}
                </Typography>
              </Typography>
              {orderSingleView?.payment_transaction_id && (
                <Typography component="span" variant="body2">
                  {getText('transaction_id')}&nbsp;:
                  <Typography ml={1} component="span" variant="body2" display="inline">
                    {orderSingleView?.payment_transaction_id || '--'}{' '}
                  </Typography>
                </Typography>
              )}
            </Stack>
          </Stack>

          <Grid container spacing={2} width="100%" my={2} justifyContent="start">
            {/* // Todo */}
            {/* <Grid item xs={6} md={3}>
          <Stack
            border="1px solid #e9e9e9"
            borderRadius="4px"
            alignItems="center"
            spacing={1.5}
            display="flex"
            direction="row"
            height="100%"
            sx={{
              padding: isMobile ? '12px' : '12px 20px',
              cursor: 'pointer',
            }}
            width="100%"
          >
            <DocIcon />
            <Typography variant="body2">{getText('download_invoice')}</Typography>
          </Stack>
        </Grid> */}
            {currentStatus === 'DELIVERED' && (
              <Grid item xs={6} md={3}>
                <Stack
                  border="1px solid #e9e9e9"
                  borderRadius="4px"
                  alignItems="center"
                  spacing={1.5}
                  display="flex"
                  direction="row"
                  height="100%"
                  sx={{
                    padding: isMobile ? '12px' : '12px 20px',
                    cursor: 'pointer',
                  }}
                  width="100%"
                  onClick={() => handleUpdateOrderStatus('RETURN_INITIATED')}
                >
                  {orderStatusLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <ReturnIcon />
                  )}

                  <Typography variant="body2">{getText('return_product')}</Typography>
                </Stack>
              </Grid>
            )}
            {currentStatus === 'PROCESSING' && (
              <Grid item xs={6} md={3}>
                <Stack
                  border="1px solid #e9e9e9"
                  borderRadius="4px"
                  alignItems="center"
                  spacing={1.5}
                  display="flex"
                  direction="row"
                  height="100%"
                  sx={{
                    padding: isMobile ? '12px' : '12px 20px',
                    cursor: 'pointer',
                  }}
                  width="100%"
                  onClick={() => handleUpdateOrderStatus('CANCEL_INITIATED')}
                >
                  {orderStatusLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <CloseIcon />
                  )}
                  <Typography variant="body2">{getText('cancel_product')}</Typography>
                </Stack>
              </Grid>
            )}
          </Grid>

          <Stack p={0} spacing={1}>
            <Typography variant="subtitle1" mt={2}>
              {getText('shipping_details')}
            </Typography>

            <Typography component="span" variant="body2">
              {getText('shipping_method')}&nbsp;:
              <Typography ml={1} component="span" variant="body2" display="inline">
                {deliveryType}{' '}
              </Typography>
            </Typography>
            {orderSingleView?.cancel_initiated_at && (
              <Typography component="span" variant="body2">
                Cancel initiated at&nbsp;:
                <Typography ml={1} component="span" variant="body2" display="inline">
                  {fDate(orderSingleView?.cancel_initiated_at)}{' '}
                </Typography>
              </Typography>
            )}
            {orderSingleView?.estimated_delivery_date && currentStatus === 'PROCESSING' && (
              <Typography component="span" variant="body2" color="green">
                Estimated Delivery &nbsp;:
                <Typography ml={1} component="span" variant="body2" display="inline">
                  {fDate(orderSingleView?.estimated_delivery_date)}{' '}
                </Typography>
              </Typography>
            )}
          </Stack>

          <OrderTracking orderSingleView={orderSingleView} />

          <Stack
            direction={isMobile ? 'column' : 'row'}
            gap={3}
            p={0}
            mb={isMobile ? 3 : 0}
            width="100%"
          >
            <Stack flex={5} direction="row" alignItems="flex-start" gap={1}>
              <Stack sx={{ width: isMobile ? '70px' : 'auto' }}>
                <Typography component="span" variant="body2" sx={{ fontWeight: 500 }}>
                  {getText('shipped_address')}&nbsp;:
                </Typography>
              </Stack>

              {shippingAddress && (
                <Stack flex={1} ml={1}>
                  <AddressCard addressData={shippingAddress} />
                </Stack>
              )}
            </Stack>

            <Stack gap={1} flex={5} direction="row" alignItems="flex-start">
              <Stack sx={{ width: isMobile ? '70px' : 'auto' }}>
                <Typography component="span" variant="body2" sx={{ fontWeight: 500 }}>
                  {getText('billing_address')}&nbsp;:
                </Typography>
              </Stack>
              <Stack flex={8}>
                {orderSingleView?.is_billing_same_as_shipping === true ? (
                  <Stack flex={8} ml={1}>
                    <AddressCard addressData={shippingAddress} />
                  </Stack>
                ) : (
                  <Stack flex={8} ml={1}>
                    <AddressCard addressData={billingAddress} />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>

          <MyAccountOrderItems
            orderData={orderSingleView}
            orderItems={orderSingleView?.order_items}
            orderId={orderSingleView?.uid}
          />
        </Stack>
      )}
    </>
  );
}

OrderSinglePage.propTypes = {
  // orderSingleViewDetails: PropTypes.object,
};
