import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, Fragment, useEffect } from 'react';

import { Stack } from '@mui/system';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import { Card, Grid, Button, Checkbox, Typography, FormControlLabel } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useGetToken } from 'src/hooks/useHandleSessions';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';

import { colors } from 'src/utils/colors';

import { TOKEN_PREFIX } from 'src/server/http';
import { useCurrency } from 'src/providers/CurrencyProvider';
import { getProductState } from 'src/server/selectors/selector';
import { uploadBulkCart } from 'src/server/slice/cartOrderSlice';
import { triggerLoginPopup } from 'src/server/slice/authentication';

import ProductGroupCard from './product-group-card';

function ProductGroup({ product }) {
  const { isMobile, screenWidth } = useScreenWidth();
  const { token } = useGetToken(TOKEN_PREFIX);

  const { currency } = useCurrency();
  const getText = useLocalizedText();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const route = useRouter();

  const { productGroups } = useSelector(getProductState);

  const [productGroupSelectedProducts, setProductGroupSelectedProducts] = useState([]);

  const handleSelectProductGroup = (check, value) => {
    if (check) {
      setProductGroupSelectedProducts([...productGroupSelectedProducts, value]);
    } else {
      setProductGroupSelectedProducts(
        productGroupSelectedProducts?.filter((item) => item?.product !== value?.product)
      );
    }
  };

  const totalPrice = productGroupSelectedProducts.reduce((total, item) => {
    const currentDate = new Date();
    const offerStartDate = item.offer_start_date ? new Date(item.offer_start_date) : null;
    const offerEndDate = item.offer_end_date ? new Date(item.offer_end_date) : null;
    const isOfferActive =
      offerStartDate && offerEndDate
        ? currentDate >= offerStartDate && currentDate <= offerEndDate
        : false;

    const priceToAdd = isOfferActive && item.offer_price ? item.offer_price : item.price;

    return total + (priceToAdd || 0);
  }, 0);

  const currencyConversionTotalPrice = useCurrencyConversion(totalPrice);

  const productGroupItemFiltered = productGroups?.items?.filter(
    (item) => item.is_active && !item.is_deleted && item?.stock >= 1
  );

  const finalProductGroupData = [product, ...productGroupItemFiltered];

  useEffect(() => {
    const initialDataSelectedGroup = finalProductGroupData?.map((item) => ({
      product: item?.uid,
      quantity: 1,
      offer_price: item?.offer_price,
      price: item?.price,
      offer_start_date: item?.offer_start_date,
      offer_end_date: item?.offer_end_date,
    }));
    setProductGroupSelectedProducts(initialDataSelectedGroup);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddGroupProductToCart = () => {
    console.log(productGroupSelectedProducts);
    if (token) {
      const credentials = {
        state: {
          cart_items: productGroupSelectedProducts?.map((i) => ({
            quantity: i.quantity,
            product: i.product,
          })),
        },
        path: paths.public.cart,
        route,
        enqueueSnackbar,
      };
      dispatch(uploadBulkCart(credentials));
    } else {
      dispatch(triggerLoginPopup({ state: true }));
    }
    // TODO: bulk upload in local
  };

  return (
    <Grid item xs={12} md={12} lg={12} padding={3} sx={{ p: isMobile && 0, mt: 10, mx: 'auto' }}>
      <Card
        sx={{
          backgroundColor: '#f3f3fc',
          py: isMobile ? 2 : 0,
          px: isMobile ? 0 : 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: isMobile ? 0 : '16px',
        }}
      >
        {isMobile && (
          <Typography variant="h5" sx={{ alignSelf: 'baseline', px: 1.8, pb: 1.5 }}>
            {getText('better_together')}
          </Typography>
        )}

        <Stack
          mt={screenWidth < 600 ? 0 : 4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
          spacing={5}
        >
          <Card
            sx={{
              padding: isMobile ? 1.5 : 3,
              py: isMobile ? 2 : 2,
              borderRadius: isMobile && 0,
              backgroundColor: 'transparent',
              border: 'none',
              boxShadow: 'none',
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                alignItems: 'start',
                flexDirection: 'row',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
              spacing={2}
            >
              {finalProductGroupData?.map((item, i) => (
                <Fragment key={i}>
                  <Stack sx={{ width: isMobile ? '165px' : '200px' }}>
                    <ProductGroupCard
                      product={item}
                      action={
                        <FormControlLabel
                          className="check-box-style"
                          control={
                            <Checkbox
                              color="info"
                              checked={productGroupSelectedProducts?.some(
                                (selectedProduct) => selectedProduct.product === item?.uid
                              )}
                              onClick={(e) =>
                                handleSelectProductGroup(e.target.checked, {
                                  product: item?.uid,
                                  quantity: 1,
                                  offer_price: item?.offer_price,
                                  price: item?.price,
                                })
                              }
                            />
                          }
                          label=""
                          sx={{
                            width: '100%',
                            margin: 0,
                            position: 'absolute',
                            top: -15,
                            left: -15,
                            zIndex: 9,
                          }}
                        />
                      }
                    />
                  </Stack>
                  {screenWidth > 600 && finalProductGroupData?.length !== i + 1 && (
                    <Stack sx={{ margin: 'auto', display: 'block' }}>
                      <AddRoundedIcon />
                    </Stack>
                  )}
                </Fragment>
              ))}
            </Stack>
          </Card>

          <Stack
            alignItems={isMobile ? 'center' : 'start'}
            mb={4}
            spacing={2}
            maxWidth="400px"
            flexDirection="column"
          >
            {!isMobile && <Typography variant="h5">{getText('better_together')}</Typography>}
            <Typography
              sx={{
                maxWidth: '350px',
                width: isMobile ? '90%' : '100%',
                textAlign: isMobile && 'center',
                fontSize: isMobile && '14px',
              }}
              variant="body"
            >
              {getText('together_description') || ''}
            </Typography>

            <Button
              variant="contained"
              color="inherit"
              size="medium"
              sx={{
                backgroundColor: colors.themePrimary,
                '&:hover': { backgroundColor: '#1c5cc5' },
                padding: '16px 32px',
                minHeight: isMobile ? '52px' : '57px',
                borderRadius: '4px',
              }}
              onClick={handleAddGroupProductToCart}
            >
              <AddShoppingCartRoundedIcon fontSize={isMobile ? 'small' : 'medium'} />
              &nbsp;&nbsp;
              <Typography
                variant="p"
                sx={{
                  fontSize: isMobile ? '14px' : '16px',
                }}
              >
                {getText('buy')} {productGroupSelectedProducts?.length} {getText('together_for')}{' '}
                {currency?.symbol} {currencyConversionTotalPrice}
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
}

export default ProductGroup;

ProductGroup.propTypes = {
  product: PropTypes.any,
};
