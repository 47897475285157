import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Box, Stack, Container } from '@mui/system';

import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { fetchGroups } from 'src/server/slice/groupSlice';
import { fetchBanners } from 'src/server/slice/bannerSlice';
import { getGroupState, getBannerState } from 'src/server/selectors/selector';

import TopSlider from './top-slider';
import Supports from './support-data';
import HeroSlider from './hero-slider';
import Highlight from './group-layouts/highlight';
import ImageGrid from './group-layouts/imageGrid';
import { BannerSkeleton } from './banner-skeleton';
import IconCarousal from './group-layouts/IconCarousal';
import { OverlapList } from './group-layouts/overlapList';
import { BannerCarousal } from './group-layouts/bannerCarousal';
import { ListHorizontal } from './group-layouts/listHorrizontal';
import { ProductCarousal } from './group-layouts/productCarousal';
import { SpecialSellCarousal } from './group-layouts/specialSellCarousal';

export default function HomeView() {
  // const { scrollYProgress } = useScroll();
  const dispatch = useDispatch();
  const { screenWidth } = useScreenWidth();

  const { groups } = useSelector(getGroupState);
  const { banners, loading } = useSelector(getBannerState);

  const DefaultModule = 'HOME';
  const mainBannerData = banners?.banners?.filter((item) => item.position === 'MAIN');

  const limit = 5;
  const total = groups?.total;
  const allPages = Math.ceil(total / limit);

  const [allGroups, setAllGroups] = useState([]);
  const [pages, setPages] = useState(1);

  const HandleFetchNextPage = () => {
    if (pages < allPages) {
      setPages((prevPages) => prevPages + 1);
    }
  };

  const paginatedGp = groups?.groups;

  const handleGroupData = useCallback(() => {
    const commonData = paginatedGp?.filter((item) => item?.platform === 'COMMON') || [];
    const webData = paginatedGp?.filter((item) => item?.platform === 'WEB') || [];
    return [...commonData, ...webData];
  }, [paginatedGp]);

  useEffect(() => {
    setAllGroups((prevGroups) => [...prevGroups, ...handleGroupData()]);
  }, [pages, handleGroupData]);

  const ids = allGroups?.map((o) => o.uid);

  const filteredFinalGp = allGroups.filter(({ uid }, index) => !ids.includes(uid, index + 1));

  // const orderedList = filteredFinalGp?.sort((a, b) => a.priority - b.priority);

  useEffect(() => {
    const credentials = {
      page: pages,
      limit,
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchGroups(credentials));
  }, [dispatch, pages]);

  useEffect(() => {
    const credentials = {
      state: {
        module: DefaultModule,
        filter_data: {
          is_deleted: false,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchBanners(credentials));
  }, [dispatch]);

  const handleLayoutWithProduct = (group) => {
    switch (group.layout) {
      case 'IMAGE_GRID':
        return <ImageGrid groupDetail={group} />;
      case 'HIGHLIGHT':
        return <Highlight groupDetail={group} />;
      case 'ICON_CAROUSEL':
        return <IconCarousal groupDetail={group} />;
      case 'CAROUSEL':
        return <ProductCarousal groupDetail={group} />;
      case 'BANNER':
        return <BannerCarousal groupDetail={group} />;
      case 'LIST_HORIZONTAL':
        return <ListHorizontal groupDetail={group} />;
      case 'OVERLAP_LIST':
        return <OverlapList groupDetail={group} />;
      case 'SPECIAL_SELL_CAROUSEL':
        return <SpecialSellCarousal groupDetail={group} />;
      default:
        return '';
    }
  };

  const groupTypeHandler = (group) => {
    switch (group.type) {
      case 'BANNERS':
      case 'PRODUCTS':
      case 'CATEGORIES':
      case 'BRANDS':
        return handleLayoutWithProduct(group);
      default:
        return handleLayoutWithProduct(group);
    }
  };

  const getMarginTop = () => {
    if (screenWidth < 1200) return 20;
    return 11;
  };

  // {/* <ScrollProgress scrollYProgress={scrollYProgress} /> */}
  return (
    <Box mt={getMarginTop()} mb={2}>
      <Container maxWidth="xl">
        <TopSlider />
        {loading ? <BannerSkeleton /> : <HeroSlider bannerDetails={mainBannerData} />}
        <Supports />
      </Container>

      <div style={{ marginTop: '30px', marginBottom: '50px' }}>
        <InfiniteScroll
          scrollThreshold={0}
          dataLength={filteredFinalGp?.length || 0}
          next={HandleFetchNextPage}
          hasMore={pages < allPages}
          // loader={
          //   <Stack alignItems="center" spacing={1} justifyContent="center" sx={{ my: 10 }}>
          //     <Typography variant="body2">Hang on, loading inventory</Typography>
          //     <CircularProgress color="success" size={20} />
          //   </Stack>
          // }
        >
          <Stack spacing={5}>
            {filteredFinalGp?.map((group, idx) => (
              <Container key={idx} maxWidth="xl" sx={{ px: 2 }}>
                <Box>{groupTypeHandler(group)}</Box>
              </Container>
            ))}
          </Stack>
        </InfiniteScroll>
      </div>
    </Box>
  );
}
