import MetaHead from 'src/utils/metaHead';

import { ProductShopView } from 'src/sections/product/view';

export default function ProductPage() {
  return (
    <>
      <MetaHead
        title="Product - online shop"
        description="Browse through our extensive collection of products. Find the best deals and enjoy a seamless shopping experience at Buildex."
        keywords="products, online shop, e-commerce, Buildex, buy products, best deals, featured items"
        openGraph={{
          title: 'Product - online shop',
          description:
            'Browse through our extensive collection of products. Find the best deals and enjoy a seamless shopping experience at Buildex.',
        }}
        twitter={{
          title: 'Product - online shop',
          description:
            'Browse through our extensive collection of products. Find the best deals and enjoy a seamless shopping experience at Buildex.',
        }}
      />
      <ProductShopView />
    </>
  );
}
