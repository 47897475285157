import PropTypes from 'prop-types';

import { Stack } from '@mui/system';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Card, IconButton, Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';

import { EditIcon } from 'src/assets/icon/EditIcon';

import Iconify from 'src/components/iconify';

import AddressCard from './address-card';

// ----------------------------------------------------------------------

export default function MyAccountAddress({
  address,
  handleRemoveAddressPopup,
  handleEditAddress,
  handleMakeDefaultAddressClick,
  isAddressDefault,
}) {
  const getText = useLocalizedText();
  const uid = address?.uid;

  return (
    <Card
      sx={{
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        gap: 3,
        boxShadow: 'none',
        borderRadius: '12px',
        border: '1px solid rgba(229, 229, 229, 0.50)',
        backgroundColor: isAddressDefault ? '#F7FAFF' : '#ffff',
      }}
    >
      <Stack sx={{ p: 2, pt: 1, width: '90%' }}>
        <Stack spacing={1.5} my={4} direction="column">
          {isAddressDefault ? (
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'start',
                justifyContent: 'start',
                gap: 1,
              }}
            >
              <FmdGoodIcon fontSize="small" sx={{ color: colors.themePrimary }} />
              <Typography variant="subtitle2" color={colors.themePrimary}>
                Default Address
              </Typography>
            </Stack>
          ) : (
            <Typography
              variant="body2"
              color={colors.themeBlack}
              sx={{
                cursor: 'pointer',
                textAlign: 'start',
                textDecoration: 'underline',
              }}
              onClick={() => handleMakeDefaultAddressClick(uid)}
            >
              {getText('make_default_address')}
            </Typography>
          )}
        </Stack>

        <Typography variant="caption" color="#999" mb={0.5}>
          {getText('address')} :
        </Typography>
        <AddressCard addressData={address} />
      </Stack>

      <Stack direction="row" spacing={0} justifyContent="space-between">
        <IconButton
          onClick={() => handleEditAddress(uid)}
          sx={{
            cursor: 'pointer',
            textAlign: 'center',
            borderRadius: 0,
            borderTop: '1px solid rgba(229, 229, 229, 0.50)',
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <EditIcon />
          <Typography variant="body2" color={colors.themeBlack}>
            {getText('Edit')}
          </Typography>
        </IconButton>

        <IconButton
          onClick={() => handleRemoveAddressPopup(uid)}
          sx={{
            cursor: 'pointer',
            textAlign: 'center',
            borderRadius: 0,
            borderTop: '1px solid rgba(229, 229, 229, 0.50)',
            borderLeft: '1px solid rgba(229, 229, 229, 0.50)',
            width: '50%',
            gap: 1,
          }}
        >
          <Iconify icon="fluent:delete-32-light" />
          <Typography variant="body2" color={colors.themeBlack}>
            {getText('remove')}
          </Typography>
        </IconButton>
      </Stack>
    </Card>
  );
}

MyAccountAddress.propTypes = {
  address: PropTypes.object,
  handleEditAddress: PropTypes.any,
  handleRemoveAddressPopup: PropTypes.any,
  handleMakeDefaultAddressClick: PropTypes.any,
  isAddressDefault: PropTypes.bool,
};
