import React from 'react';
import PropTypes from 'prop-types';

import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import Image from 'src/components/image';

export const MainBannerCarousel = ({ bannerDetail }) => {
  const formattedImage = useImageTransformation(bannerDetail?.url, 'w_2000', true);
  const formattedAltText = useTranslatedText(bannerDetail?.alt_text);
  return (
    <Image
      src={formattedImage}
      alt={formattedAltText}
      sx={{ borderRadius: '15px', cursor: 'pointer' }}
    />
  );
};

MainBannerCarousel.propTypes = {
  bannerDetail: PropTypes.any,
};
