const useFormatNumber = (number) => {
  if (!number) return 0;

  const formatNumber = (num, divisor, suffix) =>
    `${(Math.round((num / divisor) * 100) / 100).toFixed(2)}${suffix}`;

  if (number >= 1000000) {
    return formatNumber(number, 1000000, 'M'); // e.g., 1.25M
  }
  if (number >= 1000) {
    return formatNumber(number, 1000, 'k'); // e.g., 20.50k
  }

  return number.toFixed(0); // e.g., 123.45 for numbers less than 1000
};

export default useFormatNumber;
