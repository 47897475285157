import PropTypes from 'prop-types';

export const ProductActiveIcon = ({ width = 20, height = 20, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.61377 9.20703C1.91064 9.20703 1.3833 9.03125 1.03174 8.67969C0.686686 8.32812 0.51416 7.78776 0.51416 7.05859V2.98633C0.51416 2.25716 0.686686 1.72005 1.03174 1.375C1.3833 1.02344 1.91064 0.847656 2.61377 0.847656H6.76416C7.46729 0.847656 7.99463 1.02344 8.34619 1.375C8.69775 1.72005 8.87354 2.25716 8.87354 2.98633V7.05859C8.87354 7.78776 8.69775 8.32812 8.34619 8.67969C7.99463 9.03125 7.46729 9.20703 6.76416 9.20703H2.61377ZM12.4966 9.20703C11.7935 9.20703 11.2661 9.03125 10.9146 8.67969C10.563 8.32812 10.3872 7.78776 10.3872 7.05859V2.98633C10.3872 2.25716 10.563 1.72005 10.9146 1.375C11.2661 1.02344 11.7935 0.847656 12.4966 0.847656H16.647C17.3501 0.847656 17.8742 1.02344 18.2192 1.375C18.5708 1.72005 18.7466 2.25716 18.7466 2.98633V7.05859C18.7466 7.78776 18.5708 8.32812 18.2192 8.67969C17.8742 9.03125 17.3501 9.20703 16.647 9.20703H12.4966ZM2.61377 19.0801C1.91064 19.0801 1.3833 18.9043 1.03174 18.5527C0.686686 18.2077 0.51416 17.6706 0.51416 16.9414V12.8594C0.51416 12.1367 0.686686 11.5996 1.03174 11.248C1.3833 10.8965 1.91064 10.7207 2.61377 10.7207H6.76416C7.46729 10.7207 7.99463 10.8965 8.34619 11.248C8.69775 11.5996 8.87354 12.1367 8.87354 12.8594V16.9414C8.87354 17.6706 8.69775 18.2077 8.34619 18.5527C7.99463 18.9043 7.46729 19.0801 6.76416 19.0801H2.61377ZM12.4966 19.0801C11.7935 19.0801 11.2661 18.9043 10.9146 18.5527C10.563 18.2077 10.3872 17.6706 10.3872 16.9414V12.8594C10.3872 12.1367 10.563 11.5996 10.9146 11.248C11.2661 10.8965 11.7935 10.7207 12.4966 10.7207H16.647C17.3501 10.7207 17.8742 10.8965 18.2192 11.248C18.5708 11.5996 18.7466 12.1367 18.7466 12.8594V16.9414C18.7466 17.6706 18.5708 18.2077 18.2192 18.5527C17.8742 18.9043 17.3501 19.0801 16.647 19.0801H12.4966Z"
      fill="white"
    />
  </svg>
);
ProductActiveIcon.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
};
