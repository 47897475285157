import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Stack } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { TextField, Typography, Autocomplete, InputAdornment } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';

import { colors } from 'src/utils/colors';
import Storage from 'src/utils/localStorage';

import { timeZone } from 'src/server/http';
import { useCurrency } from 'src/providers/CurrencyProvider';
import { fetchStates } from 'src/server/slice/settingsSlice';
import { handleOrderLocalData } from 'src/server/slice/cartOrderSlice';
import { getOrderState, getSettingsState } from 'src/server/selectors/selector';

// ----------------------------------------------------------------------

export default function ShippingLocation({ weighCategory }) {
  const dispatch = useDispatch();
  const getText = useLocalizedText();
  const { currency } = useCurrency();
  const translateCurrencyText = useTranslatedText(currency?.title);
  const { localState } = useSelector(getOrderState);
  const { states, basicSettings } = useSelector(getSettingsState);

  const [stateSearchText, setStateSearchText] = useState('');
  const [selectedState, setSelectedState] = useState('');

  useEffect(() => {
    const credentials = {
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
      },
      page: 1,
      search: stateSearchText,
      limit: states?.total || 10,
    };

    dispatch(fetchStates(credentials));
  }, [dispatch, stateSearchText, states?.total]);

  const stateOptions = states?.states?.map((item) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const label = useTranslatedText(item?.title);
    return {
      ...item,
      label,
      value: item?.uid,
    };
  });

  const default_country = stateOptions?.filter(
    (item) => item?.uid === basicSettings?.setup?.default_state?.uid
  );

  useEffect(() => {
    const localSavedDeliveryLocation =
      localState?.delivery_location || Storage.get('delivery_location');

    if (localSavedDeliveryLocation) {
      setSelectedState(localSavedDeliveryLocation);
    } else if (default_country?.length > 0) {
      setSelectedState(default_country[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states, localState?.delivery_location]);

  const handleStateChange = (event, newValue) => {
    if (newValue && newValue !== selectedState) {
      setSelectedState(newValue);
      Storage.set('delivery_location', newValue);
      const credentials = {
        state: {
          delivery_location: newValue,
        },
      };
      dispatch(handleOrderLocalData(credentials));
    }
  };

  const shippingCharge = () => {
    if (selectedState) {
      switch (weighCategory) {
        case 'LIGHT':
          return selectedState?.shipping_charge?.light_product;
        case 'MEDIUM':
          return selectedState?.shipping_charge?.medium_product;
        case 'HEAVY':
          return selectedState?.shipping_charge?.heavy_product;
        default:
          return selectedState?.shipping_charge?.light_product;
      }
    }

    return '';

    // getText('shipping_charge_calculated_at_checkout');
  };

  const renderStateDropdown = stateOptions && (
    <Autocomplete
      // fullWidth
      sx={{ mt: 0.6 }}
      size="small"
      options={stateOptions}
      value={selectedState}
      onChange={handleStateChange}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          variant="standard"
          onChange={(e) => setStateSearchText(e.target.value)}
          placeholder={getText('select_your_state')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <LocationOnIcon
                  sx={{ color: colors.themePrimary, fontSize: '12px' }}
                  fontSize="small"
                />
              </InputAdornment>
            ),
          }}
          className="location-input"
        />
      )}
    />
  );

  const [deliveryMessage, setDeliveryMessage] = useState('');
  const productDeliveryDay = selectedState?.delivery_duration || 1;

  useEffect(() => {
    const getDeliveryMessage = () => {
      const currentDateNormalDate = new Date();
      const currentDate = new Date(currentDateNormalDate.toLocaleString('en-US', { timeZone }));

      const currentHour = currentDate.getHours();

      const deliveryStartHour = 12;
      const oneDayInMs = 1000 * 60 * 60 * 24;

      const todayDeliveryTime = new Date(currentDate);
      todayDeliveryTime.setHours(deliveryStartHour, 0, 0, 0);

      const updateMessage = (timeDiff, remainingDays) => {
        const hoursLeft = Math.floor(timeDiff / (1000 * 60 * 60)) % 24;
        const minutesLeft = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const secondsLeft = Math.floor((timeDiff % (1000 * 60)) / 1000);

        if (remainingDays > 1) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 0.3 }}>
              Delivery in
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {remainingDays} days
              </Typography>
              order within
              <Typography variant="body2" color={colors.themePrimary} sx={{ fontWeight: 700 }}>
                {hoursLeft}h {minutesLeft}m {secondsLeft}s
              </Typography>
            </Box>
          );
        }
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 0.3 }}>
            Delivery by
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              tomorrow
            </Typography>
            order within
            <Typography variant="body2" color={colors.themePrimary} sx={{ fontWeight: 700 }}>
              {hoursLeft}h {minutesLeft}m {secondsLeft}s
            </Typography>
          </Box>
        );
      };

      if (currentHour < deliveryStartHour) {
        const timeDiff = todayDeliveryTime - currentDate;

        if (productDeliveryDay === 1) {
          setDeliveryMessage(
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 0.3 }}>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                Same day
              </Typography>
              delivery, order within
              {updateMessage(timeDiff, productDeliveryDay)}
            </Box>
          );
        } else {
          const futureDeliveryTime = new Date(todayDeliveryTime);
          futureDeliveryTime.setDate(futureDeliveryTime.getDate() + productDeliveryDay - 1);
          futureDeliveryTime.setHours(deliveryStartHour, 0, 0, 0);

          const timeDiffTomorrow = futureDeliveryTime - currentDate;
          const remainingDays = Math.ceil(timeDiffTomorrow / oneDayInMs);

          setDeliveryMessage(updateMessage(timeDiffTomorrow, remainingDays));
        }
      } else {
        // After 12 PM, calculate delivery countdown for future days
        const futureDeliveryTime = new Date(currentDate);
        futureDeliveryTime.setDate(futureDeliveryTime.getDate() + productDeliveryDay);
        futureDeliveryTime.setHours(deliveryStartHour, 0, 0, 0);

        const timeDiff = futureDeliveryTime - currentDate;
        const remainingDays = Math.ceil(timeDiff / oneDayInMs);

        setDeliveryMessage(updateMessage(timeDiff, remainingDays));
      }
    };

    getDeliveryMessage();

    const timer = setInterval(getDeliveryMessage, 1000);

    return () => clearInterval(timer);
  }, [productDeliveryDay]);

  const currencyConvertedShippingCharge = useCurrencyConversion(shippingCharge());

  return (
    <Box
      sx={{
        backgroundColor: '#F3F3FC',
        padding: '10px 15px',
        borderRadius: '12px',
        mt: 2,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1} mb={1}>
        <Typography variant="body2" sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
          {getText('deliver_to')}
        </Typography>
        {renderStateDropdown}
      </Stack>

      <Typography
        variant="body2"
        component="div"
        mb={1}
        sx={{ gap: 0.8, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
      >
        <LocalShippingOutlinedIcon fontSize="small" sx={{ fontSize: '18px' }} />
        {getText('shipping_charge')}
        <Typography variant="body2" sx={{ fontWeight: '700' }}>
          {translateCurrencyText} {currencyConvertedShippingCharge}
        </Typography>
        <Box>{deliveryMessage}</Box>
      </Typography>

      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        spacing={2}
      >
        <Box sx={{ width: '100%', backgroundColor: '#CECECE', height: '1px' }} />
        <Typography>Or</Typography>
        <Box sx={{ width: '100%', backgroundColor: '#CECECE', height: '1px' }} />
      </Stack>

      <Typography variant="body2" sx={{ mt: 1, color: '#30AE4A' }}>
        Enjoy Zero Fees When You Collect In-Store!
      </Typography>
    </Box>
  );
}

ShippingLocation.propTypes = {
  weighCategory: PropTypes.any,
};
