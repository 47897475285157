import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { m, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import WidgetsIcon from '@mui/icons-material/Widgets';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Card, Stack, Typography, CircularProgress, ClickAwayListener } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter, usePathname } from 'src/routes/hooks';

import useLocalizedText from 'src/hooks/useLocalizedText';

import { logOutUser } from 'src/server/slice/authentication';
import { getProfileState } from 'src/server/selectors/selector';

import OspLink from 'src/components/link/osp-link';

const AccountData = ({ closeAccountData }) => {
  const getText = useLocalizedText();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const route = useRouter();
  const pathName = usePathname();

  const { logoutLoading } = useSelector(getProfileState);

  const data = [
    {
      title: `${getText('my_account')}`,
      link: paths.private.my_account.root,
      icon: <PersonIcon fontSize="small" />,
    },
    {
      title: `${getText('Orders')}`,
      link: paths.private.my_account.root,
      icon: <WidgetsIcon fontSize="small" />,
    },
    {
      title: `${getText('Wishlist')}`,
      link: paths.private.my_account.root,
      icon: <FavoriteIcon fontSize="small" />,
    },
  ];

  const handleLogOut = () => {
    dispatch(logOutUser({ enqueueSnackbar, closeAccountData, dispatch, route }));
  };

  const subCategoryVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };

  return (
    <ClickAwayListener onClickAway={closeAccountData}>
      <Card
        sx={{
          position: 'absolute',
          top: 50,
          left: -20,
          width: 200,
          borderRadius: 1,
          zIndex: 1,
        }}
      >
        <AnimatePresence>
          {data?.map((item, key) => (
            <m.div
              key={key}
              variants={subCategoryVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <Stack
                px={2}
                py={1}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f1f3f5' } }}
              >
                <OspLink href={item?.link || ''}>
                  <Typography
                    variant="body2"
                    display="flex"
                    alignItems="center"
                    gap={1}
                    fontSize={15}
                    color="#212b36"
                  >
                    {item?.icon}
                    {item?.title}
                  </Typography>
                </OspLink>
              </Stack>
            </m.div>
          ))}
        </AnimatePresence>

        <AnimatePresence>
          {pathName !== `${paths.private.checkout}/` && (
            <m.div variants={subCategoryVariants} initial="hidden" animate="visible" exit="hidden">
              <Stack
                px={2.4}
                py={1}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f1f3f5' } }}
              >
                <Typography
                  variant="body2"
                  display="flex"
                  alignItems="center"
                  gap={1}
                  fontSize={15}
                  color="#212b36"
                  onClick={handleLogOut}
                >
                  {logoutLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <LogoutIcon fontSize="small" />
                  )}
                  {getText('Logout')}
                </Typography>
              </Stack>
            </m.div>
          )}
        </AnimatePresence>
      </Card>
    </ClickAwayListener>
  );
};

export default AccountData;

AccountData.propTypes = {
  closeAccountData: PropTypes.func,
};
