import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { Chip, Grid, LinearProgress } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';

import { getProductState } from 'src/server/selectors/selector';
import { fetchAllProducts } from 'src/server/slice/productSlice';

import EmptyContent from 'src/components/empty-content';
import { useSettingsContext } from 'src/components/settings';

import ProductSort from '../list-filter/product-sort';
import ProductList from '../list-filter/product-list';
import ProductLimit from '../list-filter/product-limit';
import ProductFilters from '../list-filter/product-filters';
import ProductListLoading from '../common/product-list-loading';

export const PRODUCT_LIMIT_OPTIONS = [
  { value: '2', label: '2' },
  { value: '20', label: '20' },
  { value: '30', label: '30' },
  { value: '40', label: '40' },
  { value: '100', label: '100' },
];

export default function ProductShopView() {
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  const isMdDown = useResponsive('down', 'md');
  const { isMobile, screenWidth } = useScreenWidth();
  const getText = useLocalizedText();
  const { productFilterObject, loading, products: productData } = useSelector(getProductState);
  const openFilters = useBoolean();

  const [showLimit, setShowLimit] = useState('20');
  const [currentPage, setCurrentPage] = useState(1);

  const handleShowLimit = useCallback((newValue) => setShowLimit(newValue), []);

  const modifiedFilterData = useMemo(() => {
    const modifiedObject = { ...productFilterObject?.filter_data, is_active: true };

    if (!modifiedObject?.categories?.$in?.length) delete modifiedObject.categories;
    if (!modifiedObject?.brands?.$in?.length) delete modifiedObject.brands;
    if (modifiedObject?.average_rating?.$gte === 0) delete modifiedObject.average_rating;
    if (!modifiedObject?.is_free_delivery_product) delete modifiedObject.is_free_delivery_product;
    if (!modifiedObject?.price) delete modifiedObject.price;

    return modifiedObject;
  }, [productFilterObject]);

  useEffect(() => {
    const fetchProducts = async () => {
      const credentials = {
        page: currentPage,
        search: productFilterObject?.search,
        limit: showLimit,
        state: {
          filter_data: modifiedFilterData,
          sort_data: productFilterObject?.sort_data,
        },
      };
      dispatch(fetchAllProducts(credentials));
    };

    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    dispatch,
    modifiedFilterData?.brands,
    modifiedFilterData?.categories,
    modifiedFilterData?.price,
    modifiedFilterData?.average_rating,
    productFilterObject?.search,
    productFilterObject?.sort_data,
    showLimit,
  ]);

  console.log(modifiedFilterData);

  const products = productData?.products || [];
  const totalProducts = productData?.total;
  // const productsEmpty = totalProducts <= 0;
  // const notFound = products.length === 0;

  const renderFilters = (
    <Stack
      spacing={3}
      justifyContent="space-between"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      direction={{ xs: 'column', sm: 'row' }}
    >
      {!isMobile && (
        <Stack>
          <Chip
            label={`${getText('show')} ${totalProducts} ${getText('results')}`}
            variant="outlined"
            sx={{ borderRadius: '20px' }}
          />
        </Stack>
      )}
      <Stack direction="row" flexWrap="wrap" spacing={isMobile ? 0 : 1} flexShrink={0}>
        {isMdDown && (
          <ProductFilters
            isStatic={false}
            open={openFilters.value}
            onOpen={openFilters.onTrue}
            onClose={openFilters.onFalse}
          />
        )}

        <ProductLimit
          sort={showLimit}
          onSort={handleShowLimit}
          sortOptions={PRODUCT_LIMIT_OPTIONS}
        />
        <ProductSort />
      </Stack>
    </Stack>
  );

  const renderNotFound = (
    <Box>
      <EmptyContent filled title="No Data" sx={{ py: 10 }} />
    </Box>
  );

  const renderFilterWeb = !isMdDown && (
    <Grid
      item
      xs={8}
      md={2}
      sx={{
        border: '1px solid #EAE9E9',
        borderRadius: '6px',
      }}
    >
      <ProductFilters
        isStatic
        open={openFilters.value}
        onOpen={openFilters.onTrue}
        onClose={openFilters.onFalse}
      />
    </Grid>
  );

  const handleScreenWidth = () => {
    if (screenWidth < 600) {
      return 0;
    }
    if (screenWidth < 900) {
      return 20;
    }
    if (screenWidth < 1200) {
      return 13;
    }

    return 2;
  };


  console.log(products);
  console.log(productFilterObject);

  const [showNotFound, setShowNotFound] = useState(false);

  useEffect(() => {
    if (products?.length <= 0) {
      const timer = setTimeout(() => {
        setShowNotFound(true);
      }, 4000);

      return () => clearTimeout(timer);
    }

    return setShowNotFound(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData?.products]);

  const renderNotFountContent = () => {
    if (showNotFound) {
      return renderNotFound;
    }

    return <ProductListLoading />;
  };


  return (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mb: 15,
        mt: handleScreenWidth(),
        padding: 0,
      }}
    >
      <Grid
        container
        justifyContent={{
          xs: 'center',
          md: 'space-between',
        }}
        margin={0}
      >
        {renderFilterWeb}

        <Grid item xs={12} md={10} sx={{ padding: { sm: 0, md: '0 20px' } }} mt={{ xs: 2 }}>
          <Stack
            spacing={2.5}
            sx={{
              mb: { xs: 3, md: 2 },
            }}
          >
            {renderFilters}
          </Stack>

          <Box sx={{ padding: '10px' }}>
            {loading ? (
              <Box sx={{ width: '100%', px: 2, mb: 2 }}>
                <LinearProgress color="info" />
              </Box>
            ) : (
              ''
            )}

            {products?.length ? (
              <Stack sx={{ minHeight: '500px' }}>
                <ProductList
                  products={products}
                  pagination={{ currentPage, setCurrentPage, totalProducts, showLimit }}
                  loading={loading}
                />
              </Stack>
            ) : (
              renderNotFountContent()
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
