import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { IconButton, Typography } from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useGetToken } from 'src/hooks/useHandleSessions';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import { useLoginPathname } from 'src/hooks/useLoginPathname';

import { TOKEN_PREFIX } from 'src/server/http';
import { useLocales, useTranslate } from 'src/locales';
import { getCartSate, getProfileState } from 'src/server/selectors/selector';

import Logo from 'src/components/logo';
import OspLink from 'src/components/link/osp-link';

import AccountData from './accountData';
import { HEADER } from '../config-layout';
import Searchbar from '../common/searchbar';
import HeaderShadow from '../common/header-shadow';
import NavCurrency from './nav/desktop/nav-currency';

// ----------------------------------------------------------------------

export default function Header() {
  const { currentLang } = useLocales();
  const { onChangeLang } = useTranslate();
  const getText = useLocalizedText();
  const theme = useTheme();
  const isAccountData = useBoolean();
  const { screenWidth } = useScreenWidth();
  const router = useRouter();
  const loginPathname = useLoginPathname();

  const { user } = useSelector(getProfileState);
  const { cart } = useSelector(getCartSate);

  const { isSuccess, token } = useGetToken(TOKEN_PREFIX);

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const handleChangeLang = useCallback(
    (newLang) => {
      const url = new URL(window.location.href);
      url.searchParams.set('lang', newLang);
      router.push(url?.pathname);
      onChangeLang(newLang);
    },
    [onChangeLang, router]
  );

  const handleClose = () => {
    isAccountData.onFalse();
  };

  return (
    <AppBar sx={{ backgroundColor: '#232B35' }}>
      <Toolbar
        className="header-scroll"
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            sm: 150,
            md: screenWidth < 1200 ? 150 : HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            height: {
              sm: 140,
              md: screenWidth < 1200 ? 140 : HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Logo />
            {screenWidth >= 1200 && (
              <Stack
                sx={{
                  flexGrow: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mx: 4,
                }}
              >
                <Searchbar />
              </Stack>
            )}
            {screenWidth >= 1200 && (
              <Stack sx={{ display: 'flex', alignItems: 'center' }} flexDirection="row" gap={1.5}>
                <NavCurrency />

                {currentLang?.value === 'en' ? (
                  <Button
                    variant="text"
                    sx={{ color: '#fff' }}
                    onClick={() => handleChangeLang('ar')}
                  >
                    العربية
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    sx={{ color: '#fff' }}
                    onClick={() => handleChangeLang('en')}
                  >
                    English
                  </Button>
                )}
              </Stack>
            )}
            <Stack
              mx={2}
              alignItems="center"
              sx={{ color: '#fff' }}
              direction={{ xs: 'row', md: 'row' }}
              spacing={1}
              position="relative"
            >
              {screenWidth < 1200 && (
                <Stack sx={{ display: 'flex', alignItems: 'center' }} flexDirection="row" gap={1.5}>
                  <NavCurrency />

                  {currentLang?.value === 'en' ? (
                    <Button
                      variant="text"
                      sx={{ color: '#fff' }}
                      onClick={() => handleChangeLang('ar')}
                    >
                      العربية
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      sx={{ color: '#fff' }}
                      onClick={() => handleChangeLang('en')}
                    >
                      English
                    </Button>
                  )}
                </Stack>
              )}
              {isSuccess && token ? (
                <IconButton
                  sx={{ borderRadius: '4px', color: '#fff', cursor: 'pointer' }}
                  onClick={() => isAccountData.onToggle()}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'start',
                      justifyContent: 'center',
                      maxWidth: 250,
                    }}
                  >
                    <PersonRoundedIcon fontSize="small" />{' '}
                    {!user?.is_guest && user?.uid ? user?.name?.slice(0, 20) : getText('guest')}{' '}
                    {isAccountData.value ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </Typography>
                </IconButton>
              ) : (
                <OspLink href={loginPathname || ''}>
                  <IconButton sx={{ borderRadius: '4px', color: '#fff', cursor: 'pointer' }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        display: 'flex',
                        gap: 0.5,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <PersonRoundedIcon fontSize="small" /> {getText('login')}
                    </Typography>
                  </IconButton>
                </OspLink>
              )}

              <OspLink href={paths.public.cart}>
                <IconButton>
                  {cart?.cart_items?.length > 0 ? (
                    <Badge badgeContent={cart?.cart_items?.length} color="primary">
                      <ShoppingCartOutlinedIcon sx={{ color: '#fff' }} fontSize="small" />
                    </Badge>
                  ) : (
                    <ShoppingCartOutlinedIcon sx={{ color: '#fff' }} fontSize="small" />
                  )}
                </IconButton>
              </OspLink>

              {isAccountData.value && <AccountData closeAccountData={handleClose} />}
            </Stack>
          </Stack>
          {screenWidth < 1200 && (
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                mx: 6,
                mt: 2,
              }}
            >
              <Searchbar />
            </Stack>
          )}
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
