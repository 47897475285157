import React from 'react';

import { Stack } from '@mui/system';
import { Box, Skeleton, Typography } from '@mui/material';

const OrderLoading = () => (
  <Stack direction="row">
    <Box sx={{ width: '100%', padding: 2, px: 0, my: 1 }}>
      {[...Array(3)].map((__, index) => (
        <Box key={index} sx={{ mb: 2, border: '1px solid #F2F2F2', borderRadius: '12px', p: 2 }}>
          <Typography variant="h6">
            <Skeleton width="60%" />
          </Typography>
          <>
            {[...Array(3)].map((_, index1) => (
              <Box key={index1} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Skeleton width="30%" />
                <Skeleton width="20%" />
              </Box>
            ))}
          </>

          <Box sx={{ mt: 3 }}>
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Box>
        </Box>
      ))}
    </Box>
  </Stack>
);

export default OrderLoading;
