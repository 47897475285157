export const colors = {
  themePrimary: '#2874F0', // blue
  themeSecondary: '#AF291F', // red
  themeGreen: '#30AE4A', // green
  themeGreenLight: '#A6CFA866', // light green
  themeBlack: '#232B35', //  black
  themeLightGray: '#F2F2F2', // light grey
  themeGray: '#999', // light grey
  themeOrange: '#EBA337', // orange
  themeLightBlue: '#DDEAFF', // light blue
};
