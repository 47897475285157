import { useMemo } from 'react';

import { useCurrency } from '../providers/CurrencyProvider';

const useCurrencyConversion = (price) => {
  const { currency } = useCurrency();

  const conversionRate = currency?.conversion_rate_from_omr || 1;

  return useMemo(() => {
    if (price == null) {
      return null;
    }

    const convertedPrice = price * conversionRate;
    return convertedPrice.toFixed(3);
  }, [price, conversionRate]);
};

export default useCurrencyConversion;
