import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTheme } from '@emotion/react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, Fragment, useState, useEffect } from 'react';
// import parsePhoneNumberFromString, { getCountryCallingCode } from 'libphonenumber-js';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogContent from '@mui/material/DialogContent';
import { TextField, Autocomplete } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';

import Storage from 'src/utils/localStorage';

import { handleOrderLocalData } from 'src/server/slice/cartOrderSlice';
import { getProfileState, getSettingsState } from 'src/server/selectors/selector';
import {
  createNewAddress,
  updateUserAddress,
  fetchAddressDetails,
} from 'src/server/slice/userSlice';
import {
  fetchStates,
  fetchCountry,
  fetchDistrict,
  fetchSettings,
} from 'src/server/slice/settingsSlice';

import RHFPhoneField from 'src/components/hook-form/rhf-phone-input-new';
// import { RHFPhoneInput } from 'src/components/hook-form/rhf-phone-input';
import FormProvider, { RHFTextField, RHFRadioGroup } from 'src/components/hook-form';

import AddressFormLoading from './address-form-loading';

const NewAddressSchema = Yup.object().shape({
  name: Yup.string(),
  first_name: Yup.string(),
  middle_name: Yup.string(),
  last_name: Yup.string(),
  email: Yup.string().email(),
  phone: Yup.string().required('Phone number is required'),
  country_code: Yup.string(),
  address: Yup.string(),
  address_line_1: Yup.string(),
  address_line_2: Yup.string(),
  street: Yup.string(),
  landmark: Yup.string(),
  area: Yup.string(),
  city: Yup.string(),
  pincode: Yup.string(),
  whatsapp_number: Yup.string(),
  tag: Yup.string(),
  tax_id: Yup.string(),
  // district: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string(),
  // }),
  // state: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string(),
  // }),
  country: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
});

const defaultFormData = {
  name: '',
  phone: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  country_code: '',
  address: '',
  address_line_1: '',
  address_line_2: '',
  street: '',
  landmark: '',
  area: '',
  city: '',
  district: {
    label: '',
    value: '',
  },
  state: {
    label: '',
    value: '',
  },
  country: {
    label: '',
    value: '',
  },
  pincode: '',
  whatsapp_number: '',
  tag: 'HOME',
  tax_id: '',
};

export default function AddressForm({
  onClose,
  open,
  variant = 'normal',
  updateDataId,
  isBillingAddress,
  setDataOption,
}) {
  const getText = useLocalizedText();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useScreenWidth();
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { createAddressLoading, addressDetail } = useSelector(getProfileState);
  const { shippingSettings, states, country, district, basicSettings, loading } =
    useSelector(getSettingsState);

  // get address details
  useEffect(() => {
    const credentials = {
      addressId: updateDataId,
    };
    if (updateDataId) {
      dispatch(fetchAddressDetails(credentials));
    }
  }, [dispatch, updateDataId]);

  useEffect(() => {
    if (!shippingSettings?.uid) {
      dispatch(fetchSettings({ type: 'shipping' }));
    }
  }, [dispatch, shippingSettings?.uid]);

  useEffect(() => {
    dispatch(fetchSettings({ type: 'basic' }));
  }, [dispatch]);

  const shippingInputs = shippingSettings?.shipping?.shipping_inputs;

  // state
  const default_state = basicSettings?.setup?.default_state;

  const defaultSettingsState = useMemo(
    () =>
      ({
        label: default_state?.title?.english || '',
        value: default_state?.uid || '',
      }) || {
        label: '',
        value: '',
      },
    [default_state?.title?.english, default_state?.uid]
  );

  const defaultState = useMemo(
    () =>
      ({
        label: addressDetail?.state?.title?.english || '',
        value: addressDetail?.state?.uid || '',
      }) || {
        label: '',
        value: '',
      },
    [addressDetail?.state?.title?.english, addressDetail?.state?.uid]
  );

  // country
  const default_country = basicSettings?.setup?.default_country;

  const defaultSettingsCountry = useMemo(
    () =>
      ({
        label: default_country?.title?.english,
        value: default_country?.uid,
      }) || [],
    [default_country?.title?.english, default_country?.uid]
  );

  const defaultCountry = useMemo(
    () =>
      addressDetail?.country
        ? {
            label: addressDetail?.country?.title?.english || '',
            value: addressDetail?.country?.uid || '',
          }
        : null,
    [addressDetail?.country]
  );

  // district
  const defaultDistrict = useMemo(
    () =>
      ({
        label: addressDetail?.district?.title?.english || '',
        value: addressDetail?.district?.uid || '',
      }) || [],
    [addressDetail?.district?.title, addressDetail?.district?.uid]
  );

  const defaultValues = useMemo(() => {
    if (updateDataId) {
      return {
        name: addressDetail?.name || '',
        // phone: `+${addressDetail?.country_code}${addressDetail?.phone} ` || '',
        phone: addressDetail?.phone || '',
        first_name: addressDetail?.first_name,
        middle_name: addressDetail?.middle_name,
        last_name: addressDetail?.last_name,
        email: addressDetail?.email,
        country_code: addressDetail?.country_code,
        address: addressDetail?.address,
        address_line_1: addressDetail?.address_line_1,
        address_line_2: addressDetail?.address_line_2,
        street: addressDetail?.street,
        landmark: addressDetail?.landmark,
        area: addressDetail?.area,
        pincode: addressDetail?.pincode,
        // whatsapp_number:
        //   `+${addressDetail?.whatsapp_number_country_code}${addressDetail?.whatsapp_number}` || '',
        whatsapp_number: addressDetail?.whatsapp_number || '',
        tag: isBillingAddress ? '' : addressDetail?.tag || 'HOME',
        state: defaultState || {},
        country: defaultCountry,
        district: defaultDistrict || {},
        tax_id: addressDetail?.tax_id,
      };
    }
    return {
      ...defaultFormData,
      state: defaultSettingsState || {},
      country: defaultSettingsCountry || {},
    };
  }, [
    updateDataId,
    defaultSettingsState,
    defaultSettingsCountry,
    addressDetail?.name,
    addressDetail?.phone,
    addressDetail?.first_name,
    addressDetail?.middle_name,
    addressDetail?.last_name,
    addressDetail?.email,
    addressDetail?.country_code,
    addressDetail?.address,
    addressDetail?.address_line_1,
    addressDetail?.address_line_2,
    addressDetail?.street,
    addressDetail?.landmark,
    addressDetail?.area,
    addressDetail?.pincode,
    addressDetail?.whatsapp_number,
    addressDetail?.tag,
    addressDetail?.tax_id,
    isBillingAddress,
    defaultState,
    defaultCountry,
    defaultDistrict,
  ]);

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
    control,
    watch,
  } = methods;

  const handleClose = () => {
    onClose();
    reset(defaultFormData);
  };

  useEffect(() => {
    setCountryCode(addressDetail?.country_code || '968');
    setWhatsappCountryCode(addressDetail?.whatsapp_number_country_code || '968');
  }, [addressDetail?.country_code, addressDetail?.whatsapp_number_country_code]);

  // const [selectedPhoneCode, setSelectedPhoneCode] = useState('986');
  // const [selectedWhatsappCode, setSelectedWhatsappCode] = useState('986');
  const [countryCode, setCountryCode] = useState('968');
  const [whatsappCountryCode, setWhatsappCountryCode] = useState('968');

  // const handlePhoneInputChange = (value) => {
  //   const country_code = getCountryCallingCode(value);
  //   setSelectedPhoneCode(country_code);
  // };

  // const handleWhatsappInputChange = (value) => {
  //   const country_code = getCountryCallingCode(value);
  //   setSelectedWhatsappCode(country_code);
  // };

  const onHandleSubmit = async (data) => {
    if (isBillingAddress === true) {
      setDataOption((prev) => ({
        ...prev,
        billing_address: data,
      }));
      enqueueSnackbar('Billing address saved', { variant: 'success' });
    } else {
      const credentials = {
        state: {
          ...data,
          state: data?.state?.value || null,
          country: data?.country?.value,
          district: data?.district?.value || null,
          // phone: parsePhoneNumberFromString(data?.phone)?.nationalNumber,
          phone: data?.phone,
          country_code: countryCode,
          // whatsapp_number: parsePhoneNumberFromString(data?.whatsapp_number)?.nationalNumber,
          whatsapp_number: data?.whatsapp_number,
          whatsapp_number_country_code: whatsappCountryCode,
        },
        onClose: variant === 'popup' ? onClose : '',
        enqueueSnackbar,
        dispatch,
      };

      if (updateDataId) {
        const updateCredentials = {
          ...credentials,
          addressId: updateDataId,
        };
        dispatch(updateUserAddress(updateCredentials));
      } else {
        dispatch(createNewAddress(credentials));
      }
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleInputProperties = (inputName) => {
    const propertyMap = {
      NAME: { label: 'Name', type: 'text' },
      FIRST_NAME: { label: 'First Name', type: 'text' },
      LAST_NAME: { label: 'Last Name', type: 'text' },
      MIDDLE_NAME: { label: 'Middle Name', type: 'text' },
      EMAIL: { label: 'Email Address', type: 'email' },
      PHONE: { label: 'Phone', type: 'text' },
      ADDRESS: { label: 'Address', type: 'text' },
      ADDRESS_LINE_1: { label: 'Address Line 1', type: 'text' },
      ADDRESS_LINE_2: { label: 'Address Line 2', type: 'text' },
      STREET: { label: 'Street', type: 'text' },
      LANDMARK: { label: 'Landmark', type: 'text' },
      AREA: { label: 'Area', type: 'text' },
      CITY: { label: 'City', type: 'text' },
      DISTRICT: { label: 'District', type: 'text' },
      STATE: { label: 'State', type: 'text' },
      COUNTRY: { label: 'Country', type: 'text' },
      PINCODE: { label: 'Pincode', type: 'text' },
      WHATSAPP_NUMBER: { label: 'WhatsApp Number', type: 'text' },
      // COUNTRY_CODE: { label: 'Country Code', type: 'text' },
      // WHATSAPP_NUMBER_COUNTRY_CODE: { label: 'WhatsApp Number Country Code', type: 'text' },
    };

    return propertyMap[inputName] || { label: inputName, type: 'text' };
  };

  const handleInputName = (name) => name.toLowerCase();

  // country
  const selectedCountry = watch('country');

  const [countrySearchText, setCountrySearchText] = useState('');
  const countryClicked = useBoolean(false);

  useEffect(() => {
    if (countryClicked?.value) {
      const credentials = {
        state: {
          filter_data: {
            is_deleted: false,
            is_active: true,
          },
        },
        page: 1,
        search: countrySearchText,
        limit: 10,
      };
      dispatch(fetchCountry(credentials));
    }
  }, [dispatch, countrySearchText, countryClicked?.value]);

  const countryOptions = useMemo(
    () =>
      country?.countries
        ? country.countries.map((item) => ({
            // eslint-disable-next-line react-hooks/rules-of-hooks
            label: useTranslatedText(item?.title),
            value: item?.uid,
          }))
        : [],
    [country?.countries]
  );

  // state
  const selectedState = watch('state');
  const [stateSearchText, setStateSearchText] = useState('');
  const stateClicked = useBoolean(false);

  useEffect(() => {
    if (stateClicked.value) {
      const credentials = {
        state: {
          filter_data: {
            is_deleted: false,
            is_active: true,
            country: selectedCountry?.value,
          },
        },
        page: 1,
        search: stateSearchText,
        limit: 10,
      };
      dispatch(fetchStates(credentials));
    }
  }, [dispatch, selectedCountry, stateClicked.value, stateSearchText]);

  const stateOptions = states?.states
    ? states?.states?.map((item) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const label = useTranslatedText(item?.title);
        return {
          ...item,
          label,
          value: item?.uid,
        };
      })
    : [{ label: 'Loading...', value: 'loading' }];

  // district
  const [districtSearchText, setDistrictSearchText] = useState('');
  const districtClicked = useBoolean(false);

  useEffect(() => {
    if (districtClicked?.value) {
      const credentials = {
        state: {
          filter_data: {
            is_deleted: false,
            is_active: true,
            state: selectedState?.value,
          },
        },
        page: 1,
        search: districtSearchText,
        limit: 10,
      };
      dispatch(fetchDistrict(credentials));
    }
  }, [dispatch, districtClicked?.value, districtSearchText, selectedState?.value]);

  const districtOptions = district?.districts
    ? district?.districts?.map((item) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const label = useTranslatedText(item?.title);
        return {
          ...item,
          label,
          value: item?.uid,
        };
      })
    : [{ label: 'Loading...', value: 'loading' }];

  const handleRenderInputs = (input) => {
    const span = isMobile ? 'span 2' : 'span 1';
    switch (input?.field) {
      case 'DISTRICT':
        return (
          <Box gridColumn={span}>
            <Controller
              name="district"
              control={control}
              defaultValue={defaultDistrict}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                  size="small"
                  options={districtOptions}
                  onOpen={() => districtClicked.onTrue()}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  isOptionEqualToValue={(option, isEqualValue) =>
                    option?.value === isEqualValue?.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={handleInputProperties(input?.field)?.label}
                      onChange={(e) => setDistrictSearchText(e.target.value)}
                      placeholder="Select district"
                      required={input?.is_required}
                      sx={{ maxHeight: '50px' }}
                      error={!!error}
                      helperText={error ? error.message : null}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  getOptionDisabled={(option) => option.value === 'loading'}
                />
              )}
            />
          </Box>
        );

      case 'COUNTRY':
        return (
          <Box gridColumn={span}>
            <Controller
              name="country"
              control={control}
              defaultValue={defaultCountry}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                  size="small"
                  options={countryOptions}
                  onOpen={() => countryClicked.onTrue()}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  isOptionEqualToValue={(option, isEqualValue) =>
                    option?.value === isEqualValue?.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={handleInputProperties(input?.field)?.label}
                      onChange={(e) => setCountrySearchText(e.target.value)}
                      placeholder="Select country"
                      required={input?.is_required}
                      sx={{ maxHeight: '50px' }}
                      error={!!error}
                      helperText={error ? error.message : null}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  getOptionDisabled={(option) => option.value === 'loading'}
                />
              )}
            />
          </Box>
        );
      case 'STATE':
        return (
          <Box gridColumn={span}>
            <Controller
              name="state"
              control={control}
              defaultValue={defaultState}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                  size="small"
                  options={stateOptions}
                  onOpen={() => stateClicked.onTrue()}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                    Storage.set('delivery_location', newValue);
                    const credentials = {
                      state: {
                        delivery_location: newValue,
                      },
                    };
                    dispatch(handleOrderLocalData(credentials));
                  }}
                  isOptionEqualToValue={(option, isEqualValue) =>
                    option?.value === isEqualValue?.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={handleInputProperties(input?.field)?.label}
                      onChange={(e) => setStateSearchText(e.target.value)}
                      placeholder={getText('select_your_state')}
                      required={input?.is_required}
                      sx={{ maxHeight: '50px' }}
                      error={!!error}
                      helperText={error ? error.message : null}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              )}
            />
          </Box>
        );
      case 'ADDRESS':
        return (
          <Box gridColumn="span 2">
            <RHFTextField
              type="textarea"
              multiline
              rows={3}
              name="address"
              label="Address"
              fullWidth
              shrink
            />
          </Box>
        );
      case 'WHATSAPP_NUMBER':
        return (
          <Box gridColumn={span}>
            {/* <RHFPhoneInput
              size="small"
              name={handleInputName(input?.field)}
              label={handleInputProperties(input?.field)?.label}
              placeholder={getText('enter_whats_app_number')}
              required={input?.is_required}
              setSelectedCode={(value) => handleWhatsappInputChange(value)}
            /> */}
            <RHFPhoneField
              name={handleInputName(input?.field)}
              label={handleInputProperties(input?.field)?.label}
              required={input?.is_required}
              size="small"
              countryCode={countryCode}
              setCountryCode={setCountryCode}
            />
          </Box>
        );
      case 'PHONE':
        return (
          <Box gridColumn={span}>
            {/* <RHFPhoneInput
              size="small"
              name={handleInputName(input?.field)}
              label={handleInputProperties(input?.field)?.label}
              placeholder={getText('enter_phone_number')}
              required={input?.is_required}
              setSelectedCode={(value) => handlePhoneInputChange(value)}
            /> */}
            <RHFPhoneField
              name={handleInputName(input?.field)}
              label={handleInputProperties(input?.field)?.label}
              required={input?.is_required}
              size="small"
              countryCode={whatsappCountryCode}
              setCountryCode={setWhatsappCountryCode}
            />
          </Box>
        );

      default:
        return (
          <Box gridColumn={input?.field === 'name' ? 'span 2' : span}>
            <RHFTextField
              name={handleInputName(input?.field)}
              label={handleInputProperties(input?.field)?.label}
              type={handleInputProperties(input?.field)?.type}
              required={input?.is_required}
              shrink
            />
          </Box>
        );
    }
  };

  const FormContent = (
    <>
      {loading ? (
        <AddressFormLoading />
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onHandleSubmit)}>
          <Stack spacing={1}>
            {!isBillingAddress && (
              <RHFRadioGroup
                row
                name="tag"
                options={[
                  { label: 'Home', value: 'HOME' },
                  { label: 'Work', value: 'WORK' },
                ]}
                sx={{ mb: 1 }}
              />
            )}

            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              {shippingInputs?.map((item, key) => (
                <Fragment key={key}>{handleRenderInputs(item)}</Fragment>
              ))}

              {isBillingAddress && (
                <Box gridColumn="span 1">
                  <RHFTextField
                    name="tax_id"
                    label="Tax Id"
                    type="string"
                    placeholder="Enter tax id"
                    required={false}
                    shrink
                  />
                </Box>
              )}
            </Box>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
            <LoadingButton
              type="submit"
              color="primary"
              variant="outlined"
              loading={isSubmitting || createAddressLoading}
            >
              {(() => {
                if (variant === 'popup') {
                  if (updateDataId) {
                    return getText('save_address');
                  }
                  return getText('deliver_to_this_address');
                }
                if (isBillingAddress) {
                  return 'Save billing address';
                }
                return getText('save_address');
              })()}
            </LoadingButton>
          </Stack>
        </FormProvider>
      )}
    </>
  );

  if (variant === 'popup') {
    return (
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {updateDataId ? getText('edit_address') : getText('new_address')}
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>{FormContent}</DialogContent>
      </Dialog>
    );
  }

  return (
    <Box px={1} sx={{ width: isMobile ? '100%' : '60%' }}>
      {FormContent}
    </Box>
  );
}

AddressForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['normal', 'popup']),
  updateDataId: PropTypes.string,
  isBillingAddress: PropTypes.bool,
  setDataOption: PropTypes.func,
};
