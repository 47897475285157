import * as React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export default function AddressAlertDialog({ onClose, open }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Please select delivery address!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Once you've selected your delivery address, you can proceed to payment`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus variant="outlined">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddressAlertDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
