import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/system';
import {
  Box,
  Card,
  Paper,
  Table,
  Divider,
  TableBody,
  Typography,
  TableContainer,
} from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';

import { useCurrency } from 'src/providers/CurrencyProvider';

import Scrollbar from 'src/components/scrollbar';
import { TableNoData, TableHeadCustom } from 'src/components/table';

import MyAccountOrderProducts from './order-products';
import MyAccountOrderProduct from './my-account-order';

// ----------------------------------------------------------------------

export default function MyAccountOrderItems({ orderItems, orderId, orderData }) {
  const getText = useLocalizedText();
  const { isMobile } = useScreenWidth();
  const TABLE_HEAD = [
    { id: 'product', label: `${getText('Product')}` },
    { id: 'qty', label: `${getText('qty')}` },
    { id: 'price', label: `${getText('Price')}` },
    { id: 'discount', label: `${getText('Discount')}` },
    { id: 'sub_total', label: `${getText('sub_total')}` },
    // { id: 'delivery_fee', label: `${getText('delivery_fee')}` },
    // { id: 'tax', label: `${getText('tax')}` },

    { id: 'total', label: `${getText('Total')}` },
  ];
  const empty = !orderItems?.length;

  const currencyConvertedTotalShippingPrice = useCurrencyConversion(
    orderData?.total_shipping_price
  );
  const currencyConvertedCodCharge = useCurrencyConversion(orderData?.cod_charge);
  const currencyConvertedTotalPrice = useCurrencyConversion(orderData?.total_price);
  const { currency } = useCurrency();

  const translateCurrencyText = useTranslatedText(currency?.title);

  const renderTotals = (
    <Stack spacing={1.5} mr={1.5} direction="row" justifyContent="end">
      <Box sx={{ width: '100%', pl: 2, pr: 1 }}>
        <Stack direction="row" spacing={3} mb={2}>
          <Typography variant="body2" width={200}>
            {getText('delivery_fee')}
          </Typography>
          <Typography variant="body2" width="100%" textAlign="end">
            {translateCurrencyText} {currencyConvertedTotalShippingPrice}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={3} mb={2}>
          <Typography variant="body2" width={200}>
            {getText('cod_charge')}
          </Typography>
          <Typography variant="body2" width="100%" textAlign="end">
            {translateCurrencyText} {currencyConvertedCodCharge}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={3} mb={2}>
          <Typography variant="subtitle1" width={200}>
            {getText('Total')}
          </Typography>
          <Typography variant="subtitle1" width="100%" textAlign="end">
            {translateCurrencyText} {currencyConvertedTotalPrice}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );

  return (
    <Stack width="100%">
      {isMobile ? (
        <Card sx={{ my: 2, width: '100%', p: 1 }}>
          {orderItems?.map((row, key) => (
            <React.Fragment key={key}>
              <MyAccountOrderProducts row={row} />
              {key + 1 !== orderItems?.length && (
                <Divider sx={{ width: '90%', margin: 'auto', my: 2 }} />
              )}
              {renderTotals}
            </React.Fragment>
          ))}
        </Card>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            overflow: 'unset',
            border: '1px solid #f4f3f3',
            minHeight: '200px',
            borderRadius: '12px',
            mt: 2,
          }}
        >
          <Scrollbar>
            <Table sx={{ minWidth: 720 }}>
              <TableHeadCustom custom="custom" headLabel={TABLE_HEAD} />

              {orderItems?.map((row, key) => (
                <MyAccountOrderProduct key={key} row={row} orderId={orderId} />
              ))}

              <TableBody>
                <TableNoData notFound={empty} />
              </TableBody>
            </Table>

            {renderTotals}
          </Scrollbar>
        </TableContainer>
      )}
    </Stack>
  );
}

MyAccountOrderItems.propTypes = {
  orderItems: PropTypes.any,
  orderId: PropTypes.any,
  orderData: PropTypes.any,
};
