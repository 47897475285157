import MetaHead from 'src/utils/metaHead';

import BrandListView from 'src/sections/brand/brandList/brand-list';

export default function BrandPage() {
  return (
    <>
      <MetaHead
        title="Shop Top Brands"
        description="Browse and shop a wide variety of top brands available on Buildex. Get the best deals on quality products!"
        keywords=""
        openGraph={{
          title: 'Shop Top Brands',
          description: 'Browse top brands and find the best deals on quality products at Buildex.',
        }}
        twitter={{
          title: 'Shop Top Brands',
          description: 'Browse top brands and find the best deals on quality products at Buildex.',
          image: '/assets/images/logs/favIcon.png',
        }}
      />
      <BrandListView />
    </>
  );
}
