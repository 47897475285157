import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

const CategoryList = ({ categoryData }) => {
  const formattedImage = useImageTransformation(categoryData?.logo?.url, 'w_500');
  const formattedAltText = useTranslatedText(categoryData?.logo?.alt_text);
  const formattedCatName = useTranslatedText(categoryData?.name);

  return (
    <OspLink
      href={paths.public.categoryView(categoryData?.name?.english.replace(/ /g, '-'))}
      sx={{ '&:hover': { textDecoration: 'none' } }}
    >
      <Stack direction="row" flexDirection="column" alignItems="center" spacing={1.2}>
        <Image
          src={formattedImage}
          alt={formattedAltText}
          sx={{ cursor: 'pointer', maxWidth: '100%' }}
          imageStyle={{
            objectFit: 'contain',
            aspectRatio: 1 / 1,
          }}
        />
        <Typography textAlign="center" color="black" fontSize={12}>
          {formattedCatName}
        </Typography>
      </Stack>
    </OspLink>
  );
};

export default CategoryList;

CategoryList.propTypes = {
  categoryData: PropTypes.any,
};
