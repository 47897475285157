import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { Radio } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useScreenWidth } from 'src/hooks/useScreenWidth';

import Label from 'src/components/label';

import AddressCard from 'src/sections/myAccount/address/address-card';

// ----------------------------------------------------------------------

export default function AddressItem({
  address,
  onAddressChange,
  selectedAddress,
  sameAddress,
  action,
  sx,
  ...other
}) {
  const { uid, name, first_name, tag, is_default, is_last_used } = address;

  const { isMobile } = useScreenWidth();

  return (
    <Stack
      spacing={1}
      alignItems={isMobile ? 'flex-start' : 'flex-end'}
      justifyContent="space-between"
      direction={isMobile ? 'column' : 'row'}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        ...sx,
      }}
      {...other}
    >
      <Box component="div" sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
        <Radio
          value={uid}
          checked={selectedAddress === uid}
          sx={{
            marginTop: '-5px',
          }}
        />

        <Stack flexGrow={1} spacing={1} onClick={() => onAddressChange(uid)} width="100%">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">
              {name || first_name}
              <Box
                component="span"
                sx={{ ml: 0.5, typography: 'overline', color: 'text.secondary' }}
              >
                ({tag})
              </Box>
            </Typography>

            {is_default && (
              <Label color="info" sx={{ ml: 1 }}>
                Default
              </Label>
            )}
            {is_last_used && <Label color="primary"> {is_last_used ? 'Recently used' : ''}</Label>}
          </Stack>

          <Stack ml={1}>
            <AddressCard addressOnly addressData={address} />
          </Stack>
        </Stack>
      </Box>

      {action && action}
    </Stack>
  );
}

AddressItem.propTypes = {
  action: PropTypes.node,
  address: PropTypes.object,
  sx: PropTypes.object,
  onAddressChange: PropTypes.func,
  selectedAddress: PropTypes.any,
  sameAddress: PropTypes.bool,
};
