import PropTypes from 'prop-types';

export const EditIcon = ({ width = 16, height = 16, className }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 15.5391C2.1875 15.5391 1.57552 15.3359 1.16406 14.9297C0.752604 14.5286 0.546875 13.9245 0.546875 13.1172V4.11719C0.546875 3.3099 0.752604 2.70573 1.16406 2.30469C1.57552 1.89844 2.1875 1.69531 3 1.69531H11.8516L10.5938 2.95312H3.02344C2.63281 2.95312 2.33073 3.05729 2.11719 3.26562C1.90885 3.47396 1.80469 3.78125 1.80469 4.1875V13.0547C1.80469 13.4609 1.90885 13.7682 2.11719 13.9766C2.33073 14.1797 2.63281 14.2812 3.02344 14.2812H12.1562C12.4271 14.2812 12.6562 14.1797 12.8438 13.9766C13.0365 13.7682 13.1328 13.4609 13.1328 13.0547V5.55469L14.3906 4.29688V13.1172C14.3906 13.9245 14.1953 14.5286 13.8047 14.9297C13.4193 15.3359 12.875 15.5391 12.1719 15.5391H3ZM5.84375 10.4766C5.77083 10.5078 5.70312 10.4922 5.64062 10.4297C5.58333 10.362 5.57031 10.2943 5.60156 10.2266L6.30469 8.75781L13.5938 1.46875L14.6641 2.52344L7.36719 9.8125L5.84375 10.4766ZM15.2422 1.95312L14.1719 0.875L14.7422 0.3125C14.8724 0.1875 15.0208 0.119792 15.1875 0.109375C15.3594 0.0989583 15.5052 0.153646 15.625 0.273438L15.8047 0.460938C15.9401 0.591146 16.0052 0.742188 16 0.914062C16 1.08073 15.9349 1.23438 15.8047 1.375L15.2422 1.95312Z"
      fill="black"
    />
  </svg>
);

EditIcon.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
};
