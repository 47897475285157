import PropTypes from 'prop-types';

import { Stack } from '@mui/system';
import { Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { useCurrency } from 'src/providers/CurrencyProvider';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';

// ----------------------------------------------------------------------

export default function OrderItemRow({ order }) {
  const getText = useLocalizedText();
  const { isMobile } = useScreenWidth();
  const { currency } = useCurrency();

  const translateCurrencyText = useTranslatedText(currency?.title);

  const translatedText = useTranslatedText(
    order?.product?.images[0]?.alt_text ? order?.product?.images[0]?.alt_text : {}
  );
  const transformedImage = useImageTransformation(order?.product?.images[0]?.url, 'w_500');

  const translatedProductName = useTranslatedText(order?.product?.name);

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'center' : 'start'}
      justifyContent="space-between"
    >
      <Stack
        mb={isMobile ? 2 : ''}
        direction="row"
        alignItems="start"
        justifyContent="start"
        gap={1.5}
      >
        <Stack
          sx={{
            my: 'auto',
            p: 0,
            height: isMobile ? '100px' : '150px',
            width: isMobile ? '100px' : '150px',
            minWidth: isMobile ? '100px' : '150px',
            border: '1px solid #e9e9e9',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          {order?.product?.images?.length > 0 ? (
            <Image
              alt={translatedText}
              src={transformedImage}
              sx={{
                maxWidth: '100%',
                maxHeight: '100%',
                padding: 0.5,
              }}
              imageStyle={{
                objectFit: 'cover',
                aspectRatio: 1 / 1,
                borderRadius: '10px',
              }}
              className={`product-image ${
                order?.product?.images[0]?.url && 'hover-image-available'
              }`}
            />
          ) : (
            <Image
              alt="alt"
              src="/assets/images/default/product.png"
              sx={{}}
              imageStyle={{
                objectFit: 'cover',
                aspectRatio: 1 / 1,
              }}
            />
          )}
        </Stack>
        <Stack py={1} spacing={isMobile ? 2 : 3} justifyContent="space-between">
          <Stack spacing={5}>
            <TextMaxLine
              variant="body2"
              line={2}
              sx={{ maxWidth: '200px', fontWeight: 'fontWeightSemiBold' }}
            >
              {translatedProductName}
            </TextMaxLine>

            <Stack>
              {/* <Typography variant="body2" sx={{ fontWeight: 300 }}>
                {getText('color')} : White
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 300 }}>
                {getText('size')} : {`5"W x 7"H x 5"D`}
              </Typography> */}
              <Typography
                display="flex"
                alignItems="center"
                variant="body2"
                sx={{ fontWeight: 300 }}
              >
                {getText('qty')} : {order?.quantity}
              </Typography>
              <Stack alignItems="center" direction="row">
                <Stack direction="row" alignItems="center" width="fit-content" spacing={3}>
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: '400',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {translateCurrencyText}&nbsp;
                    <Typography component="span" variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {order?.product_price}
                    </Typography>
                  </Typography>
                </Stack>

                {order?.product_price_discount > 0 && (
                  <Typography
                    variant="caption"
                    color="primary"
                    sx={{ fontWeight: '400', width: '100px' }}
                  >
                    {`${order?.product_price_discount?.toFixed(3)} ${getText('discount')}`}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {!isMobile && (
        <Stack
          sx={{
            pl: 2,
            marginTop: '10px',
            textAlign: 'end',
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
          spacing={5}
        >
          <Stack alignItems="end" direction="column">
            <Stack direction="row" alignItems="center" width="fit-content" spacing={3}>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: '400',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {translateCurrencyText}&nbsp;
                <Typography component="span" variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  {order?.product_price}
                </Typography>
              </Typography>
            </Stack>

            {order?.product_price_discount > 0 && (
              <Typography
                variant="caption"
                color="primary"
                sx={{ fontWeight: '400', width: '100px' }}
              >
                {`${order?.product_price_discount?.toFixed(3)} ${getText('discount')}`}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

OrderItemRow.propTypes = {
  order: PropTypes.object,
};
