import PropTypes from 'prop-types';

import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { useCurrency } from 'src/providers/CurrencyProvider';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';

export default function CheckoutProductList({ cartItem }) {
  const getText = useLocalizedText();
  const { currency } = useCurrency();

  const product = cartItem?.product;

  const translatedText = useTranslatedText(
    product?.images[0]?.alt_text ? product?.images[0]?.alt_text : {}
  );
  const transformedImage = useImageTransformation(product?.images[0]?.url, 'w_500');

  const isOutOfStock = product?.stock <= 0;

  const { offerPrice } = useProductOfferPriceCalculator(product);

  const translatedProductName = useTranslatedText(product?.name);
  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);
  const translateCurrencyText = useTranslatedText(currency?.title);

  return (
    <Stack direction="row" px={2} py={1} spacing={0.5} alignItems="start" justifyContent="start">
      <Stack>
        {product?.images?.length > 0 ? (
          <Image
            alt={translatedText}
            src={transformedImage}
            sx={{}}
            imageStyle={{
              aspectRatio: 1 / 1,
              borderRadius: '10px',
              width: '100px',
              height: '100px',
            }}
            className={`product-image ${product?.images[1]?.url && 'hover-image-available'}`}
          />
        ) : (
          <Image
            alt="alt"
            src="/assets/images/default/product.png"
            sx={{
              maxHeight: '100px',
              maxWidth: '100px',
              minWidth: '100px',
            }}
            imageStyle={{
              aspectRatio: 1 / 1,
              borderRadius: '10px',
              width: '100px',
              height: '100px',
            }}
          />
        )}
      </Stack>

      <Stack spacing={1} direction="column" sx={{ maxWidth: '160px' }}>
        <Box
          component="span"
          sx={{
            typography: 'caption',
            textTransform: 'capitalize',
            color: isOutOfStock && 'error.main',
          }}
        >
          {isOutOfStock && getText('out_of_stock')}
        </Box>
        <TextMaxLine variant="body2" line={2}>
          {translatedProductName}
        </TextMaxLine>

        <Box>
          <Stack direction="row" justifyContent="flex-start" spacing={0.6}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translateCurrencyText}
            </Typography>
            <Typography variant="subtitle2" display="flex" gap={0.5}>
              {offerPrice && offerPrice < product?.price && (
                <Typography
                  variant="subtitle2"
                  component="span"
                  sx={{
                    color: 'text.disabled',
                    textDecoration: 'line-through',
                    fontWeight: 400,
                  }}
                >
                  {currencyConversionPrice}
                </Typography>
              )}
              {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="flex-start" spacing={0.6}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              QTY :
            </Typography>
            <Typography variant="subtitle2">{cartItem?.quantity}</Typography>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}

CheckoutProductList.propTypes = {
  cartItem: PropTypes.object,
};
