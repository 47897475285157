import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

import { Box } from '@mui/material';

import useImageTransformation from 'src/hooks/useImageTransformation';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

import { useResponsive } from '../../../hooks/useResponsive';

export const BannerCarousal = ({ groupDetail }) => {
  const { isMobile } = useResponsive();

  return (
    <Box position="relative">
      <Swiper
        autoplay={false}
        modules={[Navigation, Autoplay]}
        navigation={{
          prevEl: '.custom_prev_bnr',
          nextEl: '.custom_next_bnr',
        }}
        spaceBetween={10}
        className={`custom-class ${isMobile ? 'mt-10' : 'mt-20'}`}
        breakpoints={{
          400: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
          1200: {
            slidesPerView: 1,
          },
        }}
      >
        {groupDetail?.images?.map((item, key) => (
          <SwiperSlide key={key}>
            <OspLink href={groupDetail?.link || ''}>
              <ImageComponent item={item} />
            </OspLink>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

BannerCarousal.displayName = 'BannerCarousal';

BannerCarousal.propTypes = {
  groupDetail: PropTypes.any,
};

const ImageComponent = ({ item }) => {
  const transformedImage = useImageTransformation(item?.url, 'w_2000', true);
  return <Image src={transformedImage} alt="" sx={{ maxWidth: '100%' }} />;
};

ImageComponent.propTypes = {
  item: PropTypes.any,
};
