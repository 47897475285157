import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProductState } from 'src/server/selectors/selector';
import { fetchProductGroups } from 'src/server/slice/productSlice';

import ProductGroup from '../productGroup/productGroup';

function ProductGroupWrapper({ product }) {
  const dispatch = useDispatch();

  const { productGroups } = useSelector(getProductState);

  useEffect(() => {
    if (product?.uid) {
      const credentials = { productId: product?.uid };
      dispatch(fetchProductGroups(credentials));
    }
  }, [dispatch, product?.uid]);

  return <>{productGroups?.items?.length >= 1 ? <ProductGroup product={product} /> : ''}</>;
}

export default ProductGroupWrapper;

ProductGroupWrapper.propTypes = {
  product: PropTypes.any,
};
