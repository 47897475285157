import React from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Stack, Divider, Typography, ClickAwayListener } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { RHFTextField } from '.';
import { CustomCountryListPopover } from './customCountryPopover';

// ----------------------------------------------------------------------

export default function RHFPhoneField({
  countryCode,
  setCountryCode,
  name,
  label,
  type,
  required,
  ...other
}) {
  const isOpenCountryCode = useBoolean(false) // better method

  const onClickCountry = (value) => {
    isOpenCountryCode.onFalse()
    setCountryCode(value);
  };




  return (
    <Stack
      direction="row"
      width="100%"
      sx={{ border: '1px solid #efefef', borderRadius: 1, position: "relative" }}
      {...other}
    >
      <ClickAwayListener onClickAway={() => isOpenCountryCode.onFalse()}>
        {/* // changed : when user click the search box the dropdown close */}
        <Box
          sx={{
            width: 100,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            margin: 'auto',
            borderRight: '1px solid #dfd2d2',
          }}
        >
          {' '}
          <Typography
            display="flex"
            alignItems="center"
            textAlign="center"
            color="text.disabled"
            fontSize={15}
            gap={0.5}
            onClick={() => isOpenCountryCode.onToggle()}
          >
            {countryCode}
            {isOpenCountryCode.value ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </Typography>
          {isOpenCountryCode.value && (
            <Stack
              position="absolute"
              top={45}
              left={0}
              zIndex={3}
              sx={{
                backgroundColor: 'white',
                border: '1px solid #eeeeee',
                borderRadius: 1,
              }}
            >
              <CustomCountryListPopover isOpenCountryCode={isOpenCountryCode} onClickCountry={onClickCountry} countryCode={countryCode} />
            </Stack>
          )}
        </Box>
      </ClickAwayListener>
      <Divider orientation="vertical" />
      <RHFTextField
        name={name || 'phone'}
        label={label || 'Phone'}
        type={type || 'text'}
        size={other?.size || 'medium'}
        required={required}
        className="custom-phone-input"
        {...other}
      />
    </Stack>
  );
}

RHFPhoneField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool, // Add prop types for multiline and rows
  rows: PropTypes.number,
  countryCode: PropTypes.any,
  setCountryCode: PropTypes.any,
  required: PropTypes.any,
};
