import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

import CategoryList from '../../categoryList';

function CategorySlider({ handleData }) {
  return (
    <Swiper
      autoplay={false}
      modules={[Navigation, Autoplay]}
      navigation={{
        prevEl: '.custom_prev_cat',
        nextEl: '.custom_next_cat',
      }}
      className="custom-class"
      breakpoints={{
        300: {
          slidesPerView: 2.5,
        },
        640: {
          slidesPerView: 5.5,
        },
        768: {
          slidesPerView: 5.5,
        },
        1024: {
          slidesPerView: 8,
        },
        1200: {
          slidesPerView: 8,
        },
      }}
    >
      {handleData()?.map((item, key) => (
        <SwiperSlide key={key}>
          <CategoryList categoryData={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default CategorySlider;

CategorySlider.propTypes = {
  handleData: PropTypes.any,
};
