import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

import Card from '@mui/material/Card';
import { Box, Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Divider, Container, IconButton, LinearProgress } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { colors } from 'src/utils/colors';

import emptyImage from 'src/assets/icons/ic_cart.svg';
import { getCartSate, getOrderState, getProductState } from 'src/server/selectors/selector';

import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';
import EmptyContent from 'src/components/empty-content';

import ProductCardOne from 'src/sections/product/cards/product-card-one';

import CartSummary from '../cart-summary';
import CartProductList from '../cart-item';
import LoadingComponent from './cartLoading';

// ----------------------------------------------------------------------

const checkoutButtonWebStyles = {
  backgroundColor: colors.themePrimary,
  '&:hover': { backgroundColor: '#1968e9' },
  borderRadius: '4px',
};
const checkoutButtonMobileStyles = {
  backgroundColor: colors.themePrimary,
  '&:hover': { backgroundColor: '#1968e9' },
  position: 'fixed',
  bottom: '57px',
  left: 0,
  borderRadius: 0,
  height: '60px',
  zIndex: 9,
};

export default function CartView() {
  const { products } = useSelector(getProductState);
  const { cart } = useSelector(getCartSate);
  const { cartUpdateLoading, loading } = useSelector(getOrderState);
  const checkoutButtonLoading = useBoolean();

  const getText = useLocalizedText();
  const rtl = useLayoutStyle();
  const { isMobile, screenWidth } = useScreenWidth();
  const isSmallDevice = screenWidth < 670;

  const cartItems = cart?.cart_items || [];
  const cartEmpty = !cartItems?.length;

  const handleScreenWidth = () => {
    if (screenWidth < 600) {
      return 0;
    }
    if (screenWidth < 900) {
      return 20;
    }
    if (screenWidth < 1200) {
      return 10;
    }

    return 0;
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        mt: handleScreenWidth(),
        position: 'relative',
      }}
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        <Grid container spacing={3} sx={{ my: 5, mt: 1 }}>
          <Grid xs={12} md={8} sx={{ padding: isSmallDevice ? 0 : '' }}>
            <Card
              sx={{
                mb: 3,
                pb: 3,
                border: isSmallDevice ? 'none' : '',
                boxShadow: isSmallDevice ? 'none' : '',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6">
                    {getText('your_cart')}

                    <Typography component="span" sx={{ color: 'text.secondary' }}>
                      &nbsp;({cartItems?.length} {getText('item')})
                    </Typography>
                  </Typography>
                }
                sx={{ mb: isSmallDevice ? 1.5 : 3, px: isSmallDevice ? 2 : '' }}
              />

              {isSmallDevice && <CartSummary priceDetails={cart} />}

              {cartEmpty ? (
                <EmptyContent
                  title={getText('cart_is_empty!')}
                  description={getText('look_like_you_have_no_items_in_your_shopping_cart.')}
                  imgUrl={emptyImage}
                  sx={{ pt: 5, pb: 10 }}
                />
              ) : (
                <>
                  {cartUpdateLoading && (
                    <Box sx={{ width: '100%', px: 2, mb: 2 }}>
                      <LinearProgress color="info" />
                    </Box>
                  )}
                  {cartItems?.map((cartItem, key) => (
                    <Fragment key={key}>
                      <CartProductList cartItem={cartItem} />
                      {key + 1 !== cartItems?.length && (
                        <Divider sx={{ width: '90%', margin: 'auto', mb: 3 }} />
                      )}
                    </Fragment>
                  ))}
                </>
              )}
            </Card>

            {!isSmallDevice && (
              <Button
                component={RouterLink}
                href={paths.public.products}
                color="inherit"
                startIcon={
                  <Iconify icon={rtl ? 'eva:arrow-ios-forward-fill' : 'eva:arrow-ios-back-fill'} />
                }
              >
                {getText('continue_shopping')}
              </Button>
            )}
          </Grid>

          <Grid xs={12} md={4}>
            {!isSmallDevice && <CartSummary priceDetails={cart} />}

            <OspLink href={!cartEmpty ? paths.private.checkout : ''}>
              <LoadingButton
                fullWidth
                variant="contained"
                size="large"
                type="submit"
                disabled={cartEmpty}
                sx={isSmallDevice ? checkoutButtonMobileStyles : checkoutButtonWebStyles}
                loading={checkoutButtonLoading?.value}
                onClick={() => checkoutButtonLoading?.onTrue()}
              >
                {getText('Proceed_to_Buy')}
              </LoadingButton>
            </OspLink>
          </Grid>

          {products?.products?.length >= 0 && (
            <Grid xs={12} md={12} mt={10}>
              <OspLink
                href={paths.public.products}
                sx={{ '&:hover': { textDecoration: 'none' }, position: 'relative' }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                  <Typography variant="h6" color="black">
                    {getText('products')}
                  </Typography>
                  <Typography
                    variant="body2"
                    display="flex"
                    alignItems="center"
                    gap={2}
                    color="text.disabled"
                  >
                    {getText('view_all')}
                    {rtl ? (
                      <IconButton
                        sx={{
                          backgroundColor: colors.themePrimary,
                          borderRadius: '50%',
                          p: isMobile ? '5px' : 0.5,
                          '&:hover': {
                            backgroundColor: colors.themePrimary,
                          },
                        }}
                      >
                        <Iconify
                          icon="solar:alt-arrow-left-outline"
                          width={isMobile ? 12 : 20}
                          color="white"
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        sx={{
                          backgroundColor: colors.themePrimary,
                          borderRadius: '50%',
                          p: isMobile ? '5px' : 0.5,
                          '&:hover': {
                            backgroundColor: colors.themePrimary,
                          },
                        }}
                      >
                        <Iconify
                          icon="solar:alt-arrow-right-outline"
                          width={isMobile ? 12 : 20}
                          color="white"
                        />
                      </IconButton>
                    )}
                  </Typography>
                </Stack>
                <Divider sx={{ mb: 2, mt: 1 }} />
                <Box
                  sx={{
                    backgroundColor: colors.themeSecondary,
                    height: 2,
                    width: 150,
                    position: 'absolute',
                    bottom: '1px',
                  }}
                />
              </OspLink>
              <Box mt={2}>
                <Swiper
                  autoplay={false}
                  navigation={{
                    prevEl: '.custom_prev_i1',
                    nextEl: '.custom_next_i1',
                  }}
                  spaceBetween={10}
                  className="custom-class position-relative"
                  breakpoints={{
                    300: {
                      slidesPerView: 2,
                    },
                    480: {
                      slidesPerView: 2,
                    },
                    600: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 6.5,
                    },
                  }}
                >
                  {products?.products?.map((item, key) => (
                    <SwiperSlide key={key}>
                      <ProductCardOne key={key} product={item} noCart="no-cart" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
}
