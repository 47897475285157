import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import React, { useEffect } from 'react';

import SouthRoundedIcon from '@mui/icons-material/SouthRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { Box, Card, Stack, Button, Rating, Typography } from '@mui/material';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';

import { useBoolean } from 'src/hooks/use-boolean';
import useFormatNumber from 'src/hooks/useFormatNumber';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import { useProductPathname } from 'src/hooks/useProductPathname';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { colors } from 'src/utils/colors';

import { useCurrency } from 'src/providers/CurrencyProvider';

import Image from 'src/components/image';
import Label from 'src/components/label';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';

import useHandleCart from 'src/sections/cart/hooks/useHandleCart';

export const product_label_layouts = ['LAYOUT_1', 'LAYOUT_2', 'LAYOUT_3', 'LAYOUT_4', 'LAYOUT_5'];

const ProductCardThree = ({ product }) => {
  const getText = useLocalizedText();
  const { currency } = useCurrency();
  const { screenWidth } = useScreenWidth();
  const showOfferText = useBoolean(false);
  const { productRoute } = useProductPathname(product);
  const { handleAddCart } = useHandleCart({ product });

  const handleAddToCart = () => {
    handleAddCart();
  };

  const formattedNumber = useFormatNumber(product?.average_rating_count);
  const translatedText = useTranslatedText(product?.images[0]?.alt_text);
  const transformedImage = useImageTransformation(product?.images[0]?.url, 'w_400');

  const translatedDescription = useTranslatedText(product?.description);
  const translatedProdName = useTranslatedText(product?.name);

  const productLabels = product_label_layouts.map((layout) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTranslatedText(product?.product_labels?.find((i) => i.layout === layout)?.title)
  );

  const [productLabelOne, productLabelTwo, productLabelThree, productLabelFour, productLabelFive] =
    productLabels;

  const { discountPercentage, offerPrice } = useProductOfferPriceCalculator(product);

  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);

  const offerEndDate = product?.offer_end_date;

  useEffect(() => {
    const currentDate = new Date();
    const endDate = new Date(offerEndDate);
    const timeDifference = endDate.getTime() - currentDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    if (daysDifference <= 2 && daysDifference >= 0 && !showOfferText?.value) {
      showOfferText.onTrue();
    } else if (daysDifference > 2 && showOfferText?.value) {
      showOfferText.onFalse();
    }
  }, [offerEndDate, showOfferText]);

  return (
    <Card
      sx={{
        borderRadius: '15px',
        border: '1px solid #e4e4e4',
        // minHeight: 320,
        cursor: 'pointer',
        position: 'relative',
        p: 1,
        width: '100%',
      }}
    >
      {productLabelOne && (
        <Label
          variant="filled"
          color="error"
          sx={{
            position: 'absolute',
            left: '0px',
            top: '0px',
            borderRadius: '10px 0px 10px 0px',
            px: 4,
            fontSize: 15,
            height: 30,
            zIndex: 1,
          }}
        >
          {productLabelOne}
        </Label>
      )}
      <Box
        columnGap={1}
        rowGap={1.5}
        display="grid"
        gridTemplateColumns={{
          xs: `repeat(1, 1fr)`,
          sm: `repeat(2, 1fr)`,
          md: 'repeat(2, 1fr)',
          lg: `repeat(2, 1fr)`,
        }}
      >
        <OspLink href={productRoute} sx={{ '&:hover': { textDecoration: 'none' } }}>
          <Box position="relative">
            <Image
              src={transformedImage}
              alt={translatedText}
              imageStyle={{
                objectFit: 'contain',
                width: '100%',
                aspectRatio: 1 / 1,
              }}
              sx={{ width: '100%' }}
            />
            {productLabelTwo && (
              <Label
                variant="filled"
                sx={{
                  padding: '0 20px 0px 20px',
                  with: 'fit-content',
                  backgroundColor: colors.themeGreen,
                  position: 'absolute',
                  bottom: 20,
                  left: 10,
                  height: 32,
                }}
              >
                <TrendingUpRoundedIcon fontSize="small" sx={{ mr: 0.6 }} />
                {productLabelTwo}
              </Label>
            )}
          </Box>
        </OspLink>
        <Stack direction="row" flexDirection="column" justifyContent="space-between">
          <OspLink href={productRoute} sx={{ '&:hover': { textDecoration: 'none' } }}>
            <Box>
              <TextMaxLine color="black" line={2} sx={{ fontSize: 24 }}>
                {translatedProdName}
              </TextMaxLine>

              {(product?.average_rating || productLabelThree) && (
                <Stack direction="row" alignItems="center" spacing={2}>
                  {product?.average_rating ? (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography color="black" fontSize={15}>
                        {product?.average_rating?.toFixed(1)}
                      </Typography>
                      <Rating
                        size="small"
                        value={product?.average_rating}
                        precision={0.1}
                        readOnly
                        sx={{ mr: 1 }}
                      />
                      {formattedNumber > 0 && (
                        <Typography style={{ fontSize: 15 }}>({formattedNumber})</Typography>
                      )}
                    </Box>
                  ) : (
                    ''
                  )}
                  {productLabelThree && (
                    <Label
                      variant="soft"
                      color="info"
                      sx={{
                        padding: '0 14px 0px 10px',
                        with: 'fit-content',
                      }}
                      className="width-fit-content"
                    >
                      <AutoAwesomeRoundedIcon
                        fontSize="small"
                        sx={{ mr: 0.6, height: '15px', width: '15px' }}
                      />
                      {productLabelThree}
                    </Label>
                  )}
                </Stack>
              )}

              <Box
                component="div"
                sx={{
                  color: '#919EAB',
                  fontSize: '14px',
                }}
              >
                {parse(translatedDescription?.slice(0, 100))}
              </Box>
            </Box>
          </OspLink>
          <OspLink href={productRoute} sx={{ '&:hover': { textDecoration: 'none' } }}>
            <Box>
              {productLabelFive && (
                <Label
                  variant="soft"
                  color="success"
                  sx={{
                    padding: '0px 14px 0px 10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    mb: 0.5,
                  }}
                  className="width-fit-content"
                >
                  <SouthRoundedIcon
                    fontSize="small"
                    sx={{ mr: 0.6, height: '15px', width: '15px' }}
                  />
                  {productLabelFive}
                </Label>
              )}

              <Stack direction="row" alignItems="center" spacing={2}>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography
                    color="black"
                    variant="h5"
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                  >
                    <Typography color="black" fontSize={17}>
                      {currency?.symbol}
                    </Typography>
                    {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
                  </Typography>
                  {offerPrice && offerPrice < product?.price ? (
                    <Box
                      component="span"
                      sx={{
                        color: 'black',
                        textDecoration: 'line-through',
                        fontSize: '17px',
                        fontWeight: 400,
                      }}
                    >
                      {currencyConversionPrice}
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
                <Stack direction="row" alignItems="center" spacing={1} flexWrap="wrap">
                  {discountPercentage && (
                    <Typography
                      variant="subtitle2"
                      color="#30ae4a"
                      fontWeight={700}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ArrowDownwardRoundedIcon
                        fontSize="small"
                        sx={{ height: '15px', fontWeight: 700 }}
                      />
                      {discountPercentage}% {getText('off')}
                    </Typography>
                  )}
                  {productLabelFour && (
                    <Label
                      variant="soft"
                      color="error"
                      sx={{
                        padding: '0 14px 0px 10px',
                        with: 'fit-content',
                        color: '#ff0000',
                      }}
                      className="width-fit-content"
                    >
                      <LocalFireDepartmentRoundedIcon
                        fontSize="small"
                        sx={{ mr: 0.6, height: '15px', width: '15px' }}
                      />
                      {productLabelFour}
                    </Label>
                  )}
                </Stack>
              </Stack>
              <Box>
                {product?.is_free_delivery_product ? (
                  <Typography
                    variant="subtitle2"
                    color="#30ae4a"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 2px',
                    }}
                  >
                    <LocalShippingRoundedIcon
                      fontSize="small"
                      sx={{ mr: 0.6, height: '15px', width: '15px' }}
                    />
                    {getText('free_delivery')}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </OspLink>
          <Stack direction="row" alignItems="center" spacing={2} mt={3}>
            <Box>
              <Button
                size="medium"
                onClick={handleAddToCart}
                sx={{
                  backgroundColor: '#3258DD',
                  px: screenWidth > 1300 ? 4 : 2,
                  py: 1,
                  color: 'white',
                  borderRadius: '4px',
                  '&:hover': { backgroundColor: '#3258DD', color: 'white' },
                }}
              >
                {getText('add_to_cart')}
              </Button>
            </Box>
            {showOfferText?.value && (
              <Box>
                <Typography fontSize={14} fontWeight={700}>
                  {getText('hurry_up')}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  gap={0.5}
                  variant="body2"
                  color="text.disabled"
                >
                  <Typography color="#FC404A" variant="body2">
                    {getText('offer_ends_in')}&nbsp; {getText('soon')}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};

export default ProductCardThree;

ProductCardThree.propTypes = {
  product: PropTypes.any,
};
