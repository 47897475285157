import React from 'react';

import { Box } from '@mui/system';
import { Grid, Skeleton } from '@mui/material';

function ProductListLoading() {
  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        {Array(12)
          .fill('')
          .map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Box sx={{ border: '1px solid #f2f2f2', padding: '12px', borderRadius: '12px' }}>
                {/* Image Placeholder */}
                <Skeleton variant="rectangular" width="100%" height={150} />

                {/* Product Title Placeholder */}
                <Skeleton variant="text" width="80%" height={20} style={{ marginTop: 8 }} />

                {/* Price Placeholder */}
                <Skeleton variant="text" width="50%" height={20} />

                {/* Add to Cart Placeholder */}
                <Skeleton variant="rectangular" width="40%" height={30} style={{ marginTop: 8 }} />
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default ProductListLoading;
