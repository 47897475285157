// ----------------------------------------------------------------------

export * from './variants';

export { default as MotionViewport } from './motion-viewport';
export { default as MotionContainer } from './motion-container';

export const cardVariants = {
  hidden: { opacity: 0, y: 30 }, // Start invisible and below the viewport
  visible: { opacity: 1, y: 0 }, // Fade in and move up
};
