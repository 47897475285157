import React from 'react';

import { Box } from '@mui/system';
import { Skeleton } from '@mui/material';

function AddressFormLoading() {
  return (
    <Box padding={2} width="100%">
      {/* Page Header */}
      <Skeleton variant="text" width="30%" height={30} />

      {/* Radio Buttons */}
      <Box display="flex" alignItems="center" gap={2} marginTop={2}>
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="text" width={60} />
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="text" width={60} />
      </Box>

      {/* Form Fields */}
      <Box marginTop={3}>
        {Array(3)
          .fill('')
          .map((_, index) => (
            <Box key={index} marginBottom={2}>
              <Skeleton variant="text" width="30%" height={20} />
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
          ))}
      </Box>

      {/* Save Button */}
      <Box marginTop={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Skeleton variant="rectangular" width={120} height={40} />
      </Box>
    </Box>
  );
}

export default AddressFormLoading;
