const useImageTransformation = (imgUrl = '', styles = 'w_400', isOriginal = false) => {
  if (imgUrl?.includes('image/')) {
    const imgUrlArray = imgUrl.split('image/');

    if (isOriginal) {
      const modifiedUrl = `${imgUrlArray[0]}image/${imgUrlArray[1]}`;
      return modifiedUrl || '';
    }
    const modifiedUrl = `${imgUrlArray[0]}image/${styles}/${imgUrlArray[1]}`;
    return modifiedUrl || '';
  }

  return imgUrl;
};

export default useImageTransformation;
