import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/system';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { Star } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase, { inputBaseClasses } from '@mui/material/InputBase';
import { List, Paper, Checkbox, ListItem, FormControlLabel } from '@mui/material';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';

import { useBoolean } from 'src/hooks/use-boolean';
import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';

import { colors } from 'src/utils/colors';

import { fetchBrandsByHierarchy } from 'src/server/slice/brandSlice';
import { fetchCategoriesByHierarchy } from 'src/server/slice/categorySlice';
import { getBrandSate, getProductState, getCategoriesState } from 'src/server/selectors/selector';
import {
  productFilterObjectState,
  removeProductFilterObjectState,
} from 'src/server/slice/productSlice';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import TextMaxLine from 'src/components/text-max-line';

// ----------------------------------------------------------------------

const listItemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  exit: { opacity: 0, height: 0, transition: { duration: 0.3 } },
};
export default function ProductFilters({ open, onOpen, onClose, isStatic }) {
  const dispatch = useDispatch();
  const getText = useLocalizedText();

  const { productFilterObject } = useSelector(getProductState);
  const { categoriesByHierarchy } = useSelector(getCategoriesState);

  const { brandsByHierarchy } = useSelector(getBrandSate);

  // Remove filter
  const handleRemoveFilter = (type, item) => {
    dispatch(removeProductFilterObjectState({ type, item }));
  };

  const handleConvertSortToObj = (value) => {
    let str = '';
    if (value?.price === 1) str = getText('lowToHigh');
    if (value?.price === -1) str = getText('highToLow');
    if (value?.createdAt === -1) str = getText('new');
    if (value?.is_featured === -1) str = getText('featured');

    const spacedStr = str.replace(/([A-Z])/g, ' $1').toLowerCase();
    return spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1);
  };

  const renderHead = (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2, pr: 1, pl: 2.5 }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {getText('filters')}
        </Typography>

        <Tooltip title="Reset">
          <IconButton onClick={() => handleRemoveFilter('clear_all', '')}>
            <Badge color="error" variant="dot">
              <Iconify icon="solar:restart-bold" />
            </Badge>
          </IconButton>
        </Tooltip>

        {!isStatic && (
          <IconButton onClick={onClose}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        )}
      </Stack>
      <Stack
        flexGrow={1}
        px={1.5}
        pb={2}
        spacing={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
      >
        {productFilterObject?.search && (
          <FilterChip
            name="search"
            label={productFilterObject?.search}
            onDelete={() => handleRemoveFilter('search', '')}
          />
        )}

        {handleConvertSortToObj(productFilterObject?.sort_data) && (
          <FilterChip
            name="sort"
            label={handleConvertSortToObj(productFilterObject?.sort_data)}
            onDelete={() => handleRemoveFilter('sort', '')}
          />
        )}

        {productFilterObject?.filter_data?.is_free_delivery_product && (
          <FilterChip
            name="free_delivery"
            label={getText('free_delivery_products')}
            onDelete={() => handleRemoveFilter('free_delivery', '')}
          />
        )}

        {productFilterObject?.filter_data?.average_rating?.$gte ? (
          <FilterChip
            label={
              <Stack spacing={0.5} direction="row" alignItems="center">
                {productFilterObject?.filter_data?.average_rating?.$gte}
                <Star
                  color="inherit"
                  sx={{ color: '#FCED50', height: '14px', width: '14px' }}
                  fontSize="small"
                />
                {getText('rating')}
              </Stack>
            }
            onDelete={() => handleRemoveFilter('ratings', 0)}
            name=""
          />
        ) : (
          ''
        )}

        {productFilterObject?.categoryLabel?.map((item, key) =>
          CategoryLabel(key, item, handleRemoveFilter)
        )}

        {productFilterObject?.brandLabel?.map((item, key) =>
          BrandLabel(key, item, handleRemoveFilter)
        )}

        {productFilterObject?.filter_data?.price && (
          <FilterChip
            name="price"
            label={
              <>
                {getText('price')} : {productFilterObject?.filter_data?.price?.$gte} {getText('to')}{' '}
                {productFilterObject?.filter_data?.price?.$lte}
              </>
            }
            onDelete={() => handleRemoveFilter('price')}
          />
        )}
      </Stack>
    </Stack>
  );

  // Helper function for updating filters
  const updateProductFilterObject = (key, value) => {
    dispatch(
      productFilterObjectState({
        filter_data: {
          ...productFilterObject?.filter_data,
          [key]: value,
        },
      })
    );
  };

  // category
  const toggleAllCategory = useBoolean();

  const handleSelectedCategories = (value) => {
    const categoryLabel = productFilterObject?.categoryLabel || [];
    const updatedCategories = categoryLabel.includes(value)
      ? categoryLabel.filter((item) => item !== value)
      : [...categoryLabel, value];

    dispatch(productFilterObjectState({ categoryLabel: updatedCategories }));
  };

  useEffect(() => {
    const selectedCategoryIds = productFilterObject?.categoryLabel?.map((item) => item?.uid) || [];
    updateProductFilterObject('categories', { $in: selectedCategoryIds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilterObject?.categoryLabel]);

  // api main category
  const nestedCategory = categoriesByHierarchy?.categories;

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: 20,
      state: {},
    };
    dispatch(fetchCategoriesByHierarchy(credentials));
  }, [dispatch]);

  const renderCategories = (
    <Stack>
      <Typography
        variant="subtitle2"
        onClick={() => toggleAllCategory?.onToggle()}
        sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}
      >
        <Iconify icon="carbon:grid" />
        {getText('all_categories')}
        <KeyboardArrowDownTwoToneIcon sx={{ cursor: 'pointer' }} fontSize="small" />
      </Typography>

      {toggleAllCategory?.value && (
        <List sx={{ pt: 0, m: 0 }}>
          <AnimatePresence>
            <m.div
              variants={listItemVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ duration: 0.3 }}
            >
              {nestedCategory?.map((item, key) => (
                <CategoryMenuItem
                  setSelectItem={handleSelectedCategories}
                  selected={productFilterObject?.categoryLabel}
                  key={key}
                  item={item}
                  activeIndices={productFilterObject?.filter_data?.categories?.$in || []}
                  isFirstLevel
                />
              ))}
            </m.div>
          </AnimatePresence>
        </List>
      )}
    </Stack>
  );

  // brand
  const toggleAllBrands = useBoolean();

  const handleSelectedBrands = (value) => {
    const brandLabel = productFilterObject?.brandLabel || [];
    const updatedBrands = brandLabel.includes(value)
      ? brandLabel.filter((item) => item !== value)
      : [...brandLabel, value];

    dispatch(productFilterObjectState({ brandLabel: updatedBrands }));
  };

  useEffect(() => {
    const selectedBrandIds = productFilterObject?.brandLabel?.map((item) => item?.uid) || [];
    updateProductFilterObject('brands', { $in: selectedBrandIds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilterObject?.brandLabel]);

  const nestedBrand = brandsByHierarchy?.brands;

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: brandsByHierarchy?.total || 5,
      state: {
        filter_data: {
          is_deleted: false,
        },
        sort_data: {
          createdAt: -1,
        },
      },
    };
    dispatch(fetchBrandsByHierarchy(credentials));
  }, [brandsByHierarchy?.total, dispatch]);

  // scroll
  const listRef = useRef(null);

  const handleScroll = (direction) => {
    const list = listRef.current;

    if (list) {
      const scrollDistance = 50; // Total pixels to scroll
      const scrollStep = 5; // Pixels per step
      const scrollDirection = direction; // Direction: 1 for down, -1 for up
      let scrolled = 0;

      const animateScroll = () => {
        if (scrolled >= scrollDistance) return;

        list.scrollTop += scrollStep * scrollDirection;
        scrolled += scrollStep;

        requestAnimationFrame(animateScroll);
      };

      requestAnimationFrame(animateScroll);
    }
  };

  const renderBrands = (
    <Stack>
      <Typography
        variant="subtitle2"
        onClick={() => toggleAllBrands?.onToggle()}
        sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}
      >
        {getText('brand')}
        <KeyboardArrowDownTwoToneIcon sx={{ cursor: 'pointer' }} fontSize="small" />
      </Typography>

      {toggleAllBrands?.value && (
        <List
          ref={listRef}
          sx={{
            pt: 0,
            m: 0,
            maxHeight: '200px',
            overflowY: 'scroll',
            position: 'relative',
            transition: 'opacity 0.3s',
          }}
          className="custom-list"
          onMouseEnter={() => handleScroll(1)}
          onMouseLeave={() => handleScroll(-1)}
        >
          <AnimatePresence>
            {nestedBrand?.map((item, key) => (
              <BrandMenuItem
                setSelectItem={handleSelectedBrands}
                selected={productFilterObject?.brandLabel}
                key={key}
                item={item}
                activeIndices={productFilterObject?.filter_data?.brands?.$in || []}
                isFirstLevel
              />
            ))}
          </AnimatePresence>
        </List>
      )}
    </Stack>
  );

  // price range
  const handleChangePriceRange = (newPriceRage) => {
    const newValue = newPriceRage?.target?.value;

    updateProductFilterObject('price', {
      $gte: newValue[0],
      $lte: newValue[1],
    });
  };

  const handleChangePriceRangeInput = (value, type) => {
    const priceRange = productFilterObject?.filter_data?.price || {};

    if (value) {
      updateProductFilterObject('price', {
        $gte: type === 'min' ? Number(value) : priceRange.$gte,
        $lte: type === 'max' ? Number(value) : priceRange.$lte,
      });
    }
  };

  const renderPrice = (
    <Stack>
      <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
        {getText('price')}
      </Typography>

      <Stack direction="row" spacing={2} alignItems="center" sx={{ my: 2 }}>
        <InputRange
          type="min"
          value={productFilterObject?.filter_data?.price?.$gte}
          onFilters={handleChangePriceRangeInput}
        />
        <Typography variant="caption">To</Typography>
        <InputRange
          type="max"
          value={productFilterObject?.filter_data?.price?.$lte || 5000}
          onFilters={handleChangePriceRangeInput}
        />
      </Stack>

      <Slider
        defaultValue={[0, 5000]}
        value={[
          productFilterObject?.filter_data?.price?.$gte,
          productFilterObject?.filter_data?.price?.$lte || 5000,
        ]}
        min={0}
        max={5000}
        onChange={handleChangePriceRange}
        step={50}
        // marks={[{ label: 'ameen', value: 12 }]}
        getAriaValueText={(value) => `${value}`}
        valueLabelFormat={(value) => `${value}`}
        sx={{
          alignSelf: 'center',
          width: `calc(100% - 24px)`,
          color: colors.themePrimary,
        }}
      />
    </Stack>
  );

  // ratings
  const onHandleClickRatingItem = (e, value) => {
    if (e.target.checked) {
      dispatch(
        productFilterObjectState({
          filter_data: {
            average_rating: {
              $gte: value,
            },
          },
        })
      );
    } else {
      dispatch(
        productFilterObjectState({
          filter_data: {
            average_rating: {
              $gte: 0,
            },
            // average_rating: {
            //   $in: currentSelectedRatings?.filter((i) => i !== value),
            // },
          },
        })
      );
    }
  };

  const currentSelectedRatings = productFilterObject?.filter_data?.average_rating?.$gte;

  const ratingOption = [4, 3, 2, 1];

  const renderRating = (
    <DynamicSingleSelectInputGroup
      label={(item) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography fontSize={16} width={8}>
            {item}
          </Typography>{' '}
          <Star color="inherit" sx={{ color: '#fcec50' }} fontSize="small" /> & {getText('above')}
        </Stack>
      )}
      options={ratingOption}
      onHandleClickItem={onHandleClickRatingItem}
      title={getText('customer_ratings')}
      currentSelected={currentSelectedRatings}
    />
  );

  // discounts todo
  const [discount, setDiscount] = useState([]);
  const currentSelectedDiscount = discount;
  const discountOption = [20, 50];

  const onHandleClickDiscountItem = (e, value) => {
    if (e.target.checked) {
      setDiscount([...discount, value]);
    } else {
      setDiscount(currentSelectedDiscount?.filter((i) => i !== value));
    }
  };

  const renderDiscount = (
    <DynamicMultiSelectInputGroup
      label={(item) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          {item}% {getText('off')}
        </Stack>
      )}
      options={discountOption}
      onHandleClickItem={onHandleClickDiscountItem}
      title={getText('discount')}
      currentSelected={currentSelectedDiscount}
    />
  );

  // availability todo
  // const currentSelectedAvailability = productFilterObject?.filter_data?.is_free_delivery_product
  //   ? ['free_delivery']
  //   : [];
  // const availabilityOption = ['self_pickup', 'free_delivery'];

  // const onHandleClickAvailabilityItem = (e, value) => {
  //   if (value === 'free_delivery') {
  //     updateProductFilterObject('is_free_delivery_product', e.target.checked);
  //   }
  // };

  // const renderAvailability = (
  //   <DynamicMultiSelectInputGroup
  //     label={(item) => (
  //       <Stack direction="row" alignItems="center" spacing={1} sx={{ textTransform: 'capitalize' }}>
  //         {getText(item) || ''}
  //       </Stack>
  //     )}
  //     options={availabilityOption}
  //     onHandleClickItem={onHandleClickAvailabilityItem}
  //     title={getText('availability')}
  //     currentSelected={currentSelectedAvailability}
  //   />
  // );

  // offers todo
  // const [offers, setOffers] = useState([]);
  // const currentSelectedOffers = offers;
  // const offersOption = ['buy_more', 'special_price'];

  // const onHandleClickOfferItem = (e, value) => {
  //   if (e.target.checked) {
  //     setOffers([...discount, value]);
  //   } else {
  //     setOffers(currentSelectedOffers?.filter((i) => i !== value));
  //   }
  // };

  // const renderOffers = (
  //   <DynamicMultiSelectInputGroup
  //     label={(item) => (
  //       <Stack direction="row" alignItems="center" spacing={1} sx={{ textTransform: 'capitalize' }}>
  //         {getText(item) || ''}
  //       </Stack>
  //     )}
  //     options={offersOption}
  //     onHandleClickItem={onHandleClickOfferItem}
  //     title={getText('offers')}
  //     currentSelected={currentSelectedOffers}
  //   />
  // );

  return (
    <>
      {isStatic ? (
        <>
          {renderHead}
          <Divider />
          <Scrollbar sx={{ px: 2.5, py: 3 }}>
            <Stack spacing={3}>
              {renderCategories}
              <Divider />
              {renderPrice}
              <Divider />
              {renderBrands}
              <Divider />
              {renderRating}
              <Divider />

              {/* {renderDiscount} */}
              {/* <Divider /> */}
              {/* {renderAvailability}
              <Divider />
              {renderOffers} */}
            </Stack>
          </Scrollbar>
        </>
      ) : (
        <>
          <Button
            disableRipple
            color="inherit"
            endIcon={
              <Badge color="error" variant="dot">
                <Iconify icon="ic:round-filter-list" />
              </Badge>
            }
            onClick={onOpen}
          >
            Filters
          </Button>
          <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            slotProps={{
              backdrop: { invisible: true },
            }}
            PaperProps={{
              sx: { width: 280 },
            }}
          >
            {renderHead}
            <Divider />
            <Scrollbar sx={{ px: 2.5, py: 3 }}>
              <Stack spacing={3}>
                {renderCategories}
                <Divider />
                {renderPrice}
                <Divider />
                {renderBrands}
                <Divider />
                {renderRating}
                <Divider />
                {renderDiscount}
                <Divider />
                {/* {renderAvailability}
                <Divider />
                {renderOffers} */}
              </Stack>
            </Scrollbar>
          </Drawer>
        </>
      )}
    </>
  );
}

ProductFilters.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isStatic: PropTypes.bool,
};

// ----------------------------------------------------------------------

const CategoryMenuItem = ({ item, activeIndices, isFirstLevel, setSelectItem, selected }) => {
  const onHandleClickItem = () => {
    setSelectItem(item);
  };

  const translatedCategoryName = useTranslatedText(item?.name);

  return (
    <ListItem sx={{ flexDirection: 'column', padding: '0' }}>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              // color: colors.themePrimary,
              '&.Mui-checked': {
                color: colors.themePrimary,
              },
            }}
            checked={activeIndices.includes(item?.uid)}
            onClick={onHandleClickItem}
          />
        }
        label={
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextMaxLine line={1}>{translatedCategoryName}</TextMaxLine>
            {isFirstLevel && item?.sub_categories?.length > 0 && (
              <KeyboardArrowDownTwoToneIcon fontSize="small" />
            )}
          </Stack>
        }
        sx={{ width: '100%', margin: 0 }}
      />

      <AnimatePresence>
        {activeIndices.includes(item?.uid) && item?.sub_categories?.length > 0 && (
          <m.ul
            variants={listItemVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ duration: 0.3 }}
            className="dropdown"
            style={{ width: '100%', paddingLeft: '20px' }}
          >
            {item?.sub_categories.map((subItem, subKey) => (
              <CategoryMenuItem
                key={subKey}
                item={subItem}
                activeIndices={activeIndices}
                setSelectItem={setSelectItem}
                selected={selected}
              />
            ))}
          </m.ul>
        )}
      </AnimatePresence>
    </ListItem>
  );
};

CategoryMenuItem.propTypes = {
  item: PropTypes.any,
  activeIndices: PropTypes.any,
  isFirstLevel: PropTypes.any,
  setSelectItem: PropTypes.func,
  selected: PropTypes.any,
};

function BrandLabel(key, item, handleRemoveFilter) {
  return (
    <FilterChip
      name={key}
      label={useTranslatedText(item?.name)}
      onDelete={() => handleRemoveFilter('badge', item?.uid)}
    />
  );
}

function CategoryLabel(key, item, handleRemoveFilter) {
  return (
    <FilterChip
      name={key}
      label={useTranslatedText(item?.name)}
      onDelete={() => handleRemoveFilter('category', item?.uid)}
    />
  );
}

// ----------------------------------------------------------------------
// price range
function InputRange({ type, value, onFilters }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: 1 }}
    >
      {/* <Typography
        variant="caption"
        sx={{
          flexShrink: 0,
          color: 'text.disabled',
          textTransform: 'capitalize',
          fontWeight: 'fontWeightSemiBold',
        }}
      >
        {`${type}`}
      </Typography> */}

      <InputBase
        fullWidth
        value={value}
        onChange={(e) =>
          type === 'min' ? onFilters(e.target.value, 'min') : onFilters(e.target.value, 'max')
        }
        defaultValue={type === 'min' ? 0 : 1000}
        // onBlur={}

        inputProps={{
          step: 50,
          min: 0,
          max: 1000,
          type: 'number',
          'aria-labelledby': 'input-slider',
        }}
        sx={{
          minWidth: 50,
          width: '100%',
          maxWidth: '80px',
          borderRadius: 0.75,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
          [`& .${inputBaseClasses.input}`]: {
            // pr: 1,
            py: 0.75,
            px: 1,
            textAlign: 'left',
            typography: 'body2',
          },
        }}
      />
    </Stack>
  );
}

InputRange.propTypes = {
  onFilters: PropTypes.func,
  type: PropTypes.oneOf(['min', 'max']),
  value: PropTypes.any,
};

// ----------------------------------------------------------------------

const BrandMenuItem = ({ item, activeIndices, isFirstLevel, setSelectItem }) => {
  const onHandleClickItem = () => {
    setSelectItem(item);
  };

  const translatedBrandName = useTranslatedText(item?.name);

  return (
    <m.div
      variants={listItemVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      style={{
        maxWidth: 600,
        flexShrink: 0,
        overflow: 'hidden',
      }}
    >
      <ListItem sx={{ flexDirection: 'column', padding: '0' }}>
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                // color: colors.themePrimary,
                '&.Mui-checked': {
                  color: colors.themePrimary,
                },
              }}
              checked={activeIndices.includes(item?.uid)}
              onClick={onHandleClickItem}
            />
          }
          label={
            <Stack direction="row" alignItems="center" spacing={1}>
              <TextMaxLine line={1}>{translatedBrandName}</TextMaxLine>
              {isFirstLevel && item?.sub_categories?.length > 0 && (
                <KeyboardArrowDownTwoToneIcon fontSize="small" />
              )}
            </Stack>
          }
          sx={{ width: '100%', margin: 0 }}
        />

        <AnimatePresence>
          {activeIndices.includes(item?.uid) && item?.sub_categories?.length > 0 && (
            <m.ul
              variants={listItemVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ duration: 0.3 }}
              className="dropdown"
              style={{ width: '100%', paddingLeft: '20px' }}
            >
              {item?.sub_categories.map((subItem, subKey) => (
                <BrandMenuItem
                  key={subKey}
                  item={subItem}
                  activeIndices={activeIndices}
                  setSelectItem={setSelectItem}
                />
              ))}
            </m.ul>
          )}
        </AnimatePresence>
      </ListItem>
    </m.div>
  );
};

BrandMenuItem.propTypes = {
  item: PropTypes.any,
  activeIndices: PropTypes.any,
  isFirstLevel: PropTypes.any,
  setSelectItem: PropTypes.func,
};

// ----------------------------------------------------------------------

const DynamicSingleSelectInputGroup = ({
  onHandleClickItem,
  currentSelected,
  options,
  title,
  label,
}) => {
  const toggle = useBoolean(true);

  return (
    <Stack spacing={0} alignItems="flex-start">
      <Typography
        variant="subtitle2"
        onClick={() => toggle?.onToggle()}
        sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}
      >
        {title}
        <KeyboardArrowDownTwoToneIcon sx={{ cursor: 'pointer' }} fontSize="small" />
      </Typography>

      {toggle?.value && (
        <List>
          {options?.map((item, key) => (
            <ListItem key={key} sx={{ flexDirection: 'column', padding: '0' }}>
              <FormControlLabel
                name="rating"
                control={
                  <Checkbox
                    checked={currentSelected === item}
                    onClick={(e) => onHandleClickItem(e, item)}
                    sx={{
                      // color: colors.themePrimary,
                      '&.Mui-checked': {
                        color: colors.themePrimary,
                      },
                    }}
                  />
                }
                label={label(item)}
                sx={{ width: '100%', margin: 0 }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
};

DynamicSingleSelectInputGroup.propTypes = {
  options: PropTypes.any,
  title: PropTypes.any,
  label: PropTypes.func,
  onHandleClickItem: PropTypes.func,
  currentSelected: PropTypes.any,
};

const DynamicMultiSelectInputGroup = ({
  onHandleClickItem,
  currentSelected,
  options,
  title,
  label,
}) => {
  const toggle = useBoolean();

  return (
    <Stack spacing={0} alignItems="flex-start">
      <Typography
        variant="subtitle2"
        onClick={() => toggle?.onToggle()}
        sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}
      >
        {title}
        <KeyboardArrowDownTwoToneIcon sx={{ cursor: 'pointer' }} fontSize="small" />
      </Typography>

      {toggle?.value && (
        <List>
          {options?.map((item, key) => (
            <ListItem key={key} sx={{ flexDirection: 'column', padding: '0' }}>
              <FormControlLabel
                name="rating"
                control={
                  <Checkbox
                    checked={currentSelected.includes(item)}
                    onClick={(e) => onHandleClickItem(e, item)}
                    sx={{
                      // color: colors.themePrimary,
                      '&.Mui-checked': {
                        color: colors.themePrimary,
                      },
                    }}
                  />
                }
                label={label(item)}
                sx={{ width: '100%', margin: 0 }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
};

DynamicMultiSelectInputGroup.propTypes = {
  options: PropTypes.any,
  title: PropTypes.any,
  label: PropTypes.func,
  onHandleClickItem: PropTypes.func,
  currentSelected: PropTypes.any,
};

// ----------------------------------------------------------------------

const FilterChip = ({ label, onDelete, name }) => (
  <Chip
    key={name}
    size="medium"
    label={label}
    onDelete={onDelete}
    variant="outlined"
    sx={{
      backgroundColor: '#2874f01a',
      border: 'none',
      borderRadius: '4px',
      padding: '8px',
    }}
  />
);

FilterChip.propTypes = {
  label: PropTypes.node.isRequired,
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.any,
};

// ----------------------------------------------------------------------

function Block({ label, children, sx, ...other }) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  sx: PropTypes.object,
};
