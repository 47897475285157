import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

import BrandList from '../../brandList';

function BrandSlider({ handleData }) {
  return (
    <Swiper
      autoplay={false}
      modules={[Navigation, Autoplay]}
      navigation={{
        prevEl: '.custom_prev_cat',
        nextEl: '.custom_next_cat',
      }}
      className="custom-class"
      breakpoints={{
        300: {
          slidesPerView: 5.3,
        },

        640: {
          slidesPerView: 5.5,
        },
        768: {
          slidesPerView: 5.5,
        },
        1024: {
          slidesPerView: 8,
        },
        1200: {
          slidesPerView: 11,
        },
      }}
    >
      {handleData()?.map((item, key) => (
        <SwiperSlide key={key}>
          <BrandList brandData={item} key={key} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default BrandSlider;

BrandSlider.propTypes = {
  handleData: PropTypes.any,
};
