import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { m, AnimatePresence } from 'framer-motion';
import React, { useState, useCallback } from 'react';

import { Card, Stack, Button, Typography } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';

import { useCurrency } from 'src/providers/CurrencyProvider';
import { getCurrencyState } from 'src/server/selectors/selector';

function NavCurrency() {
  const { isMobile } = useScreenWidth();

  const { currency } = useSelector(getCurrencyState);

  const { currency: siteCurrency } = useCurrency();

  const currencies = currency?.currencies;

  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const translatedSiteCurrencyTitle = useTranslatedText(siteCurrency?.title);

  const subCategoryVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };

  return (
    <Stack sx={{ position: 'relative' }}>
      {isMobile ? (
        <Button
          variant="text"
          display="flex"
          sx={{
            color: '#fff',
            '&:hover': { backgroundColor: 'transparent' },
            cursor: 'pointer',
            backgroundColor: 'transparent',
            alignItems: 'center',
            fontSize: 12,
          }}
          onClick={() => setOpenMenu(!openMenu)}
        >
          {translatedSiteCurrencyTitle}
          {openMenu ? (
            <ExpandLessRoundedIcon sx={{ fontSize: 12 }} />
          ) : (
            <ExpandMoreRoundedIcon sx={{ fontSize: 12 }} />
          )}{' '}
          {openMenu && (
            <Card
              sx={{
                position: 'absolute',
                top: '40px',
                padding: '10px 15px',
                borderRadius: '6px',
                gap: '7px',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all ease-in-out',
                alignItems: 'flex-start',
                zIndex: 2,
              }}
            >
              <AnimatePresence>
                {currencies?.map((item, key) => (
                  <m.div
                    key={key}
                    variants={subCategoryVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                  >
                    <CurrencyTextBox currencies={item} handleCloseMenu={handleCloseMenu} />
                  </m.div>
                ))}
              </AnimatePresence>
            </Card>
          )}
        </Button>
      ) : (
        <Button
          variant="text"
          display="flex"
          sx={{
            '&:hover': { backgroundColor: 'transparent' },
            cursor: 'pointer',
            color: '#fff',
            backgroundColor: 'transparent',
            alignItems: 'center',
          }}
          onMouseEnter={handleOpenMenu}
          onMouseLeave={handleCloseMenu}
        >
          {translatedSiteCurrencyTitle} <ExpandMoreRoundedIcon />{' '}
          {openMenu && (
            <Card
              sx={{
                position: 'absolute',
                top: '33px',
                padding: '10px 15px',
                borderRadius: '6px',
                gap: '7px',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all ease-in-out',
                alignItems: 'flex-start',
                zIndex: 2,
              }}
            >
              <AnimatePresence>
                {currencies?.map((item, key) => (
                  <m.div
                    key={key}
                    variants={subCategoryVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                  >
                    <CurrencyTextBox
                      currencies={item}
                      key={key}
                      handleCloseMenu={handleCloseMenu}
                    />
                  </m.div>
                ))}
              </AnimatePresence>
            </Card>
          )}
        </Button>
      )}
    </Stack>
  );
}

function CurrencyTextBox({ currencies, handleCloseMenu }) {
  const translatedTitles = useTranslatedText(currencies?.title);
  const { changeCurrency } = useCurrency();

  const handleChangeCurrency = (cur) => {
    const modifiedObject = {
      conversion_rate_from_omr: cur?.conversion_rate_from_omr,
      title: cur?.title,
      symbol: cur?.symbol,
      uid: cur?.uid,
    };
    changeCurrency(modifiedObject);
    handleCloseMenu();
  };

  return (
    <Typography
      variant="body2"
      sx={{ cursor: 'pointer' }}
      onClick={() => handleChangeCurrency(currencies)}
    >
      {translatedTitles}
    </Typography>
  );
}

export default NavCurrency;
CurrencyTextBox.propTypes = {
  currencies: PropTypes.any,
  handleCloseMenu: PropTypes.any,
};
