

// ----------------------------------------------------------------------

import MetaHead from "src/utils/metaHead";

import Home from "src/sections/home";


export default function HomePage() {
  return (
    <>
      <MetaHead
        title="Home"
        description="Welcome to Buildex! Shop a wide range of quality products from top brands."
        keywords="Buildex, e-commerce, online shopping, electronics, fashion"
        openGraph={{
          title: 'Home | Buildex E-Commerce Platform',
          description:
            'Discover top products from leading brands at Buildex. Shop electronics, fashion, home appliances, and more with unbeatable deals and fast shipping.',
        }}
        twitter={{
          title: 'Home | Buildex E-Commerce Platform',
          description:
            'Discover and shop quality products from top brands at Buildex.',
          image: '/assets/images/logs/favIcon.png',
        }}
      />
      <Home />
    </>
  );
}
