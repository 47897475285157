import PropTypes from 'prop-types';

import { Box, Stack } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Rating, Tooltip, Typography } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';

import { colors } from 'src/utils/colors';
import { fDate } from 'src/utils/format-time';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';
import Lightbox, { useLightBox } from 'src/components/lightbox';

// ----------------------------------------------------------------------

export default function MyAccountReview({ review, handleRemoveAddressPopup }) {
  const getText = useLocalizedText();
  const { isMobile } = useScreenWidth();

  const slides = [
    ...(review?.images?.map((img) => ({
      src: img ?? {},
    })) || []),
  ];

  const lightbox = useLightBox(slides);

  const translatedProductName = useTranslatedText(review?.product?.name);
  const translatedText = useTranslatedText(
    review?.product?.images[0]?.alt_text ? review?.product?.images[0]?.alt_text : {}
  );
  return (
    <>
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        gap={isMobile ? 2 : 10}
      >
        <Stack spacing={2} flexGrow={1} direction="row">
          <Stack
            sx={{
              // my: 'auto',
              p: 0.5,
              height: isMobile ? '95px' : '110px',
              width: isMobile ? '95px' : '110px',
              minWidth: isMobile ? '95px' : '110px',
              border: '1px solid #e9e9e9',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',

              overflow: 'hidden',
            }}
          >
            {review?.product?.images?.length > 0 ? (
              <Image
                alt={translatedText}
                src={review?.product?.images[0]?.url}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  padding: 1,
                }}
                imageStyle={{
                  aspectRatio: 1 / 1,
                  objectFit: 'contain',
                  borderRadius: '10px',
                }}
                className={`product-image ${
                  review?.product?.images[0]?.url && 'hover-image-available'
                }`}
              />
            ) : (
              <Image
                alt="alt"
                src="/assets/images/default/product.png"
                sx={{}}
                imageStyle={{
                  aspectRatio: 1 / 1,
                  borderRadius: '10px',
                }}
              />
            )}
          </Stack>
          <Stack my="auto" gap={0.5}>
            <TextMaxLine variant="caption" line={2} sx={{ fontWeight: 'fontWeightSemiBold' }}>
              {translatedProductName}
            </TextMaxLine>
            <Rating size="small" value={review?.rating} precision={0.1} readOnly />

            <TextMaxLine
              variant={isMobile ? 'body2' : 'subtitle2'}
              line={2}
              sx={{ fontWeight: '400' }}
            >
              {review?.message}
            </TextMaxLine>
            {Array.isArray(review?.images) && review.images.length > 0 && (
              <Stack direction="row" flexWrap="wrap" spacing={1} sx={{ pt: 1 }}>
                {review.images.map((attachment) => (
                  <Avatar
                    key={attachment?.public_id}
                    src={attachment?.url || ''}
                    sx={{ width: 64, height: 64, borderRadius: 0, cursor: 'zoom-in' }}
                    alt={attachment?.public_id}
                    onClick={() => lightbox.onOpen(attachment)}
                  />
                ))}
              </Stack>
            )}
            <Typography variant="caption" sx={{ color: '#808080' }}>
              {fDate(review?.createdAt)}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Tooltip title={getText('delete_review') || ''} arrow>
            <>
              <Box
                onClick={() => handleRemoveAddressPopup(review?.uid)}
                p={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'grey.300',
                  },
                }}
              >
                <DeleteIcon sx={{ color: colors.themeSecondary }} />
              </Box>{' '}
            </>
          </Tooltip>
        </Stack>
      </Stack>
      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
        onGetCurrentIndex={(index) => lightbox.setSelected(index)}
      />
    </>
  );
}

MyAccountReview.propTypes = {
  review: PropTypes.object,
  handleRemoveAddressPopup: PropTypes.any,
};
