import { useEffect, useCallback } from 'react';
import { m, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import GridViewIcon from '@mui/icons-material/GridView';
import { Stack, Button, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useBoolean } from 'src/hooks/use-boolean';
import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';

import { colors } from 'src/utils/colors';

import { fetchCategoriesByHierarchyHome } from 'src/server/slice/categorySlice';
import { getSettingsState, getCategoriesState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

import AllCategoryDropdownList from './allCategoryList';

export default function TopSlider() {
  const dispatch = useDispatch();
  const openMenu = useBoolean(false);

  const { categoriesByHierarchyHome: serverCategoriesByHierarchy } =
    useSelector(getCategoriesState);

  const categoriesByHierarchy = serverCategoriesByHierarchy;

  const { basicSettings } = useSelector(getSettingsState);

  const nestedCategory = categoriesByHierarchy?.categories;
  const headerData = useTranslatedText(basicSettings?.layout)?.header_titles;

  const getText = useLocalizedText();

  const handleOpenMenu = useCallback(() => {
    openMenu.onTrue();
  }, [openMenu]);

  const handleCloseMenu = useCallback(() => {
    openMenu.onFalse();
  }, [openMenu]);

  useEffect(() => {
    // if (openMenu?.value) {
    const credentials = {
      page: 1,
      search: '',
      limit: 10,
      state: {},
    };
    dispatch(fetchCategoriesByHierarchyHome(credentials));
    // }
  }, [dispatch]);

  return (
    <Box display="flex" gap={1}>
      <Stack
        position="relative"
        direction="row"
        alignItems="center"
        spacing={0.5}
        flexShrink={0}
        sx={{ cursor: 'pointer' }}
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
      >
        <GridViewIcon fontSize="small" />
        <Typography variant="subtitle1" fontWeight={700}>
          {getText('All_categories')}
        </Typography>
        {openMenu?.value ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}

        <AnimatePresence>
          {openMenu?.value && (
            <m.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              style={{
                position: 'absolute',
                top: 33,
                left: 0,
                zIndex: 100,
                width: '100%',
              }}
            >
              <Stack>
                <AllCategoryDropdownList data={nestedCategory} />
              </Stack>
            </m.div>
          )}
        </AnimatePresence>
      </Stack>
      <Typography fontSize={20}>{' | '}</Typography>
      <Stack direction="row" className="top-slider-tab">
        {headerData?.map((item, key) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              margin: '0px 20px',
            }}
          >
            <OspLink
              type={item?.link_type}
              href={item?.link}
              sx={{ '&:hover': { textDecoration: 'none' } }}
            >
              {item?.is_button ? (
                <Button
                  fullWidth
                  size="small"
                  color="inherit"
                  variant="outlined"
                  startIcon={
                    <Image
                      alt="top slider"
                      src={item?.image?.url}
                      sx={{
                        height: '15px',
                        width: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      imageStyle={{
                        height: '15px',
                        width: '15px',
                        objectFit: 'contain',
                      }}
                    />
                  }
                  sx={{
                    whiteSpace: 'nowrap',
                    backgroundColor: item?.bg_color || colors.themePrimary,
                    '&:hover': {
                      backgroundColor: item?.bg_color || colors.themePrimary,
                      border: 'none',
                      boxShadow: 'none',
                    },
                    borderRadius: '4px',
                    color: item?.fg_color || 'white',
                    border: 'none',
                    px: '13px',
                  }}
                >
                  {item?.title}
                </Button>
              ) : (
                <Typography whiteSpace="pre" color="black">
                  {item?.title}
                </Typography>
              )}
            </OspLink>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
