import { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/system';
import { Card, Grid, Divider, Typography, Pagination, paginationClasses } from '@mui/material';

import { paths } from 'src/routes/paths';

import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';

import { fetchWishList } from 'src/server/slice/userSlice';
import { getProfileState } from 'src/server/selectors/selector';

import OspLink from 'src/components/link/osp-link';

import { MyProfileReviewSkeleton } from 'src/sections/myAccount/review/review-skeleton';

import MyAccountWishlist from './my-account-wishlist';

// ----------------------------------------------------------------------
// export const metadata = {
//   title: 'My-Account-wishlist',
// };
export default function MyAccountWishLists() {
  const getText = useLocalizedText();
  const dispatch = useDispatch();

  const { wishListItems, loading } = useSelector(getProfileState);

  const empty = !wishListItems?.wishlist_items?.length;
  const totalWishList = wishListItems?.total;
  const limit = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalWishList / limit);

  useEffect(() => {
    const credentials = {
      page: currentPage,
      search: '',
      limit,
    };
    dispatch(fetchWishList(credentials));
  }, [currentPage, dispatch]);

  return (
    <>
      {loading ? (
        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
          <MyProfileReviewSkeleton />
        </Grid>
      ) : (
        <Stack>
          {empty ? (
            <Card
              sx={{
                minHeight: '285px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                boxShadow: 'none',
                border: `1px solid ${colors.themeLightGray}`,
              }}
            >
              <OspLink href={paths.public.products} sx={{ '&:hover': { textDecoration: 'none' } }}>
                <Typography variant="body1" textAlign="center">
                  {getText('nothing_here!_why_don’t_we_go_for_some')}
                  <Typography
                    variant="body1"
                    component="span"
                    color={colors.themeSecondary}
                    sx={{ ml: 0.5 }}
                  >
                    {getText('shopping')}
                  </Typography>
                </Typography>
              </OspLink>
            </Card>
          ) : (
            <>
              <Card
                sx={{
                  mb: 3,
                  p: 2,
                  boxShadow: 'none',
                  border: `1px solid ${colors.themeLightGray}`,
                }}
              >
                {wishListItems?.wishlist_items?.map((cartItem, key) => (
                  <Fragment key={key}>
                    <MyAccountWishlist wishListData={cartItem} />
                    {key + 1 !== wishListItems?.wishlist_items?.length && (
                      <Divider sx={{ width: '90%', margin: 'auto', my: 3 }} />
                    )}
                  </Fragment>
                ))}
              </Card>

              {totalWishList > 6 && (
                <Pagination
                  onChange={(e, newPage) => setCurrentPage(newPage)}
                  count={totalPages || 8}
                  page={currentPage}
                  sx={{
                    my: 4,

                    [`& .${paginationClasses.ul}`]: {
                      justifyContent: 'center',
                    },
                  }}
                />
              )}
            </>
          )}
        </Stack>
      )}
    </>
  );
}

MyAccountWishLists.propTypes = {};
