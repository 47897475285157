import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CancelIcon from '@mui/icons-material/Cancel';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Chip,
  Tooltip,
  Accordion,
  CircularProgress,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useGetToken } from 'src/hooks/useHandleSessions';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';

import { colors } from 'src/utils/colors';

import { TOKEN_PREFIX } from 'src/server/http';
import { useCurrency } from 'src/providers/CurrencyProvider';
import { getOrderState } from 'src/server/selectors/selector';
import { resetCoupon, validateCoupon } from 'src/server/slice/cartOrderSlice';

// ----------------------------------------------------------------------

export default function CartSummary({ priceDetails }) {
  const { currency } = useCurrency();
  const { token } = useGetToken(TOKEN_PREFIX);
  const { enqueueSnackbar } = useSnackbar();
  const { screenWidth } = useScreenWidth();

  const translateCurrencyText = useTranslatedText(currency?.title);
  const getText = useLocalizedText();
  const dispatch = useDispatch();

  const { loading, isValidCoupon, resetCouponData } = useSelector(getOrderState);

  const {
    total_price,
    items_price,
    total_coupon_discount,
    coupon_code,
    cod_charge,
    total_shipping_price,
    // shipping_price,
    // shipping_discount,
    // tax_summary,
    // tax_value,
    total_discount,
    // total_offer_discount,
  } = priceDetails;

  const [discountCode, setDiscountCode] = useState('');

  const onApplyDiscount = () => {
    const credentials = {
      state: {
        code: discountCode,
      },
      customNotification: enqueueSnackbar,
      dispatch,
      setDiscountCode,
    };
    dispatch(validateCoupon(credentials));
  };

  const cartItems = priceDetails?.cart_items;

  const modifiedCartItem = cartItems?.map((i) => ({
    price: i.product?.price,
    quantity: i.quantity,
    product_price_discount: i.product_price_discount,
  }));

  const totalPriceWithoutOffer = modifiedCartItem?.reduce(
    (accumulator, item) => accumulator + item.price * item.quantity,
    0
  );

  const totalOfferDiscount = modifiedCartItem?.reduce(
    (accumulator, item) => accumulator + item.product_price_discount * item.quantity,
    0
  );

  const offerPrice = cartItems?.reduce(
    // eslint-disable-next-line no-unsafe-optional-chaining
    (total, item) => total + (item?.product?.price - item?.product?.offer_price || 0),
    0
  );

  const totalOfferDiscountLocal = modifiedCartItem?.reduce(
    (accumulator, item) => accumulator + offerPrice * item.quantity,
    0
  );

  // const totalPriceWithoutOffer = priceWithoutOffer?.reduce(
  //   (accumulator, currentValue) => accumulator + currentValue,
  //   0
  // );

  const currencyConvertedTotalPriceWithoutOffer = useCurrencyConversion(totalPriceWithoutOffer);
  const currencyConvertedTotalOfferDiscount = useCurrencyConversion(totalOfferDiscount);
  const currencyConvertedTotalOfferDiscountLocal = useCurrencyConversion(totalOfferDiscountLocal);
  const currencyConvertedItemsPrice = useCurrencyConversion(items_price);
  const currencyConvertedTotalCouponDiscount = useCurrencyConversion(total_coupon_discount);
  const currencyConvertedTotalShippingPrice = useCurrencyConversion(total_shipping_price);
  const currencyConvertedTotalPrice = useCurrencyConversion(total_price);

  const handleDelete = () => {
    const credentials = {
      customNotification: enqueueSnackbar,
      setDiscountCode,
      dispatch,
    };
    dispatch(resetCoupon(credentials));
  };

  const renderSummeryInfo = (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {getText('price')} ({cartItems?.length} {getText('item')})
        </Typography>
        <Typography variant="subtitle2">
          {translateCurrencyText} {currencyConvertedTotalPriceWithoutOffer}
        </Typography>
      </Stack>

      {currencyConvertedTotalOfferDiscount > 0 && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {getText('offer_discount')}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'green' }}>
            - {translateCurrencyText}{' '}
            {token ? currencyConvertedTotalOfferDiscount : currencyConvertedTotalOfferDiscountLocal}
          </Typography>
        </Stack>
      )}

      {total_discount && !token ? (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {getText('offer_discount')}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'green' }}>
            {total_discount ? (
              <>
                - {translateCurrencyText} {total_discount?.toFixed(3)}
              </>
            ) : (
              '-'
            )}
          </Typography>
        </Stack>
      ) : (
        ''
      )}

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {getText('sub_total')}
        </Typography>
        <Typography variant="subtitle2">
          {translateCurrencyText} {currencyConvertedItemsPrice}
        </Typography>
      </Stack>

      {cod_charge && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {getText('cod_charge')}{' '}
            <Tooltip title="This can be adjusted in the payment section">
              <span style={{ cursor: 'pointer' }}>*</span>
            </Tooltip>
          </Typography>
          <Typography variant="subtitle2">
            {translateCurrencyText} {cod_charge}
          </Typography>
        </Stack>
      )}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {total_coupon_discount ? (
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {getText('coupon_discount')}&nbsp;
            {/* <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
              (#{coupon_code})
            </Typography> */}
            {/* <Label variant="soft" color="success">
              {coupon_code}
            </Label> */}
            <Chip
              label={coupon_code}
              variant="filled"
              color="success"
              size="small"
              onDelete={handleDelete}
              deleteIcon={
                <Tooltip title="Remove coupon">
                  <CancelIcon />
                </Tooltip>
              }
            />
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'green' }}>
            {total_coupon_discount ? (
              <>
                - {translateCurrencyText} {currencyConvertedTotalCouponDiscount}
              </>
            ) : (
              '-'
            )}
          </Typography>
        </Stack>
      ) : (
        ''
      )}
    </>
  );

  const renderCouponForm = (
    <>
      {token && (
        <>
          <TextField
            sx={{ mt: 0.5 }}
            fullWidth
            placeholder={getText('discount_codes_/_gifts')}
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    color="primary"
                    onClick={onApplyDiscount}
                    sx={{ mr: -0.5 }}
                    disabled={!discountCode}
                  >
                    {loading ? <CircularProgress size={24} /> : getText('apply')}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {!resetCouponData?.uid && isValidCoupon?.uid && (
            <Typography
              variant="caption"
              color="primary"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <CheckCircleOutlineIcon sx={{ fontSize: 16, mr: 0.5 }} />
              {getText('coupon_applied')}
            </Typography>
          )}
          {!resetCouponData?.uid && isValidCoupon === 'Invalid Coupon!' ? (
            <Typography
              variant="caption"
              color="error"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <ErrorOutlineIcon sx={{ fontSize: 16, mr: 0.5 }} />
              {getText('invalid_coupon')}
            </Typography>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );

  const renderSummeryWeb = (
    <Card sx={{ mb: 3 }}>
      <CardHeader title={`${getText('price_details')}`} />

      <CardContent>
        <Stack spacing={2}>
          {cartItems?.length ? (
            renderSummeryInfo
          ) : (
            <Typography variant="caption" color={colors.themeSecondary}>
              {getText('please_add_some_products_to_your_cart')}
            </Typography>
          )}

          {renderCouponForm}

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {getText('shipping_fee')}{' '}
              <Tooltip title={`Exact ${getText('shipping_charge_calculated_at_checkout')}`}>
                <span style={{ cursor: 'pointer' }}>*</span>
              </Tooltip>
            </Typography>

            {total_shipping_price ? (
              <Typography variant="subtitle2">
                {translateCurrencyText}&nbsp;
                {currencyConvertedTotalShippingPrice}
              </Typography>
            ) : (
              <Typography variant="caption">
                {getText('shipping_charge_calculated_at_checkout')}
              </Typography>
            )}
          </Stack>
          {/* <ShippingLocation /> */}
          {/* getText('shipping_charge_calculated_at_checkout') */}

          {/* <Stack>
            <Typography variant="caption" sx={{ color: 'green' }}>
              {getText('you_saved')}
              <Typography variant="caption" component="span" sx={{ fontWeight: '600' }}>
                {translateCurrencyText} {total_discount}
              </Typography>{' '}
              
              {getText('on_this_order')}
            </Typography>
          </Stack> */}

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">{getText('Total')} </Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="h6" sx={{ color: 'default.main' }}>
                {translateCurrencyText} {currencyConvertedTotalPrice}
              </Typography>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                ({getText('Inclusive_of_VAT')})
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );

  const renderSummeryMobile = (
    <>
      <Accordion
        defaultExpanded
        sx={{
          m: 0,
          boxShadow: 'none',
          border: '1px solid #f2f2f2',
          borderRadius: '6px',
          mb: 1,
        }}
        className="cart-summery-box"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            alignItems: 'center',
          }}
        >
          <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6">{getText('Total')} </Typography>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                ({getText('Inclusive_of_VAT')})
              </Typography>
            </Box>
            <Typography variant="h6" sx={{ color: 'default.main' }}>
              {translateCurrencyText} {currencyConvertedTotalPrice}
            </Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <Stack spacing={2}>
            {cartItems?.length ? (
              renderSummeryInfo
            ) : (
              <Typography variant="caption" color={colors.themeSecondary}>
                {getText('please_add_some_products_to_your_cart')}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" mt={2} justifyContent="space-between" alignItems="center">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {getText('shipping_fee')}
            </Typography>
            <Typography variant="caption">
              {getText('shipping_charge_calculated_at_checkout')}
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mb: 4, mx: '10px' }}>{renderCouponForm}</Box>
      {/* <ShippingLocation /> */}
    </>
  );

  return screenWidth < 670 ? <>{renderSummeryMobile}</> : <>{renderSummeryWeb}</>;
}

CartSummary.propTypes = {
  priceDetails: PropTypes.any,
};
