import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { paths } from 'src/routes/paths';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';

import { useCurrency } from 'src/providers/CurrencyProvider';
import { getCartSate, getOrderState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import Scrollbar from 'src/components/scrollbar';
import OspLink from 'src/components/link/osp-link';

import CheckoutProductList from './checkout-item-card';

// ----------------------------------------------------------------------

export default function CheckoutSummary({ notShowShipping }) {
  const { currency } = useCurrency();
  const getText = useLocalizedText();

  const { isMobile } = useScreenWidth();

  const translateCurrencyText = useTranslatedText(currency?.title);

  const { cart } = useSelector(getCartSate);
  const { bulkLoading, localState } = useSelector(getOrderState);

  const {
    total_price,
    // items_price,
    total_coupon_discount,
    coupon_code,
    // shipping_price,
    // shipping_discount,
    // tax_summary,
    // tax_value,
    // total_discount,
    cod_charge,
    // total_offer_discount,
    total_shipping_price,
    total_shipping_discount,
  } = cart;

  const checkoutItemList = cart?.cart_items;

  const modifiedCartItem = checkoutItemList?.map((i) => ({
    price: i.product?.price,
    quantity: i.quantity,
    product_price_discount: i.product_price_discount,
  }));

  const totalPriceWithoutOffer = modifiedCartItem?.reduce(
    (accumulator, item) => accumulator + item.price * item.quantity,
    0
  );

  const totalOfferDiscount = modifiedCartItem?.reduce(
    (accumulator, item) => accumulator + item.product_price_discount * item.quantity,
    0
  );

  // const priceWithoutOffer = checkoutItemList?.map((i) => i.product?.price);
  // const totalPriceWithoutOffer = priceWithoutOffer?.reduce(
  //   (accumulator, currentValue) => accumulator + currentValue,
  //   0
  // );

  const currencyConvertedTotalPriceWithoutOffer = useCurrencyConversion(totalPriceWithoutOffer);
  const currencyConvertedTotalOfferDiscount = useCurrencyConversion(totalOfferDiscount);
  const currencyConvertedTotalCouponDiscount = useCurrencyConversion(total_coupon_discount);
  const currencyConvertedCodCharge = useCurrencyConversion(cod_charge);
  const currencyConvertedTotalPrice = useCurrencyConversion(total_price);
  const currencyConvertedTotalShippingPrice = useCurrencyConversion(total_shipping_price);
  const currencyConvertedTotalShippingDiscount = useCurrencyConversion(total_shipping_discount);

  const renderSummeryInfo = (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {getText('price')} ({checkoutItemList?.length} item)
        </Typography>
        <Typography variant="subtitle2">
          {' '}
          {translateCurrencyText} {currencyConvertedTotalPriceWithoutOffer}
        </Typography>
      </Stack>

      {currencyConvertedTotalOfferDiscount > 0 && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {getText('offer_discount')}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'green' }}>
            - {translateCurrencyText} {currencyConvertedTotalOfferDiscount}
          </Typography>
        </Stack>
      )}

      {coupon_code ? (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {getText('coupon_discount')} &nbsp;
            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
              ({coupon_code})
            </Typography>
          </Typography>
          <Typography variant="subtitle2" color="green">
            - {translateCurrencyText} {currencyConvertedTotalCouponDiscount}
          </Typography>
        </Stack>
      ) : (
        ''
      )}

      {/* <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {getText('total_discount')}
        </Typography>
        <Typography variant="subtitle2" color="green">
          {' '}
          {total_discount ? (
            <>
              - {translateCurrencyText} {total_discount}
            </>
          ) : (
            '-'
          )}
        </Typography>
      </Stack> */}

      {localState?.payment_type === 'COD' && localState?.delivery_type !== 'STORE_PICKUP' && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {getText('cod_charge')}
          </Typography>
          <Typography variant="subtitle2">
            {translateCurrencyText} {currencyConvertedCodCharge}
          </Typography>
        </Stack>
      )}

      {currencyConvertedTotalShippingPrice > 0 && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Shipping Fee
          </Typography>
          <Typography variant="subtitle2">
            {translateCurrencyText} {currencyConvertedTotalShippingPrice}
          </Typography>
        </Stack>
      )}
      {currencyConvertedTotalShippingDiscount > 0 && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Shipping Discount
          </Typography>
          <Typography variant="subtitle2" color="green">
            - {translateCurrencyText} {currencyConvertedTotalShippingDiscount}
          </Typography>
        </Stack>
      )}
    </>
  );

  const renderWebCheckoutItem = (
    <Card sx={{ mb: 3 }}>
      <CardHeader
        sx={{
          color: 'text.secondary',
        }}
        title={`${getText('order_summery')} (${checkoutItemList?.length || 0} ${getText('items')})`}
      />

      <CardContent sx={{ pt: 2 }}>
        <Card
          sx={{
            mb: 3,
            p: 0,
            pt: 1,
            boxShadow: 'none',
            border: '1px solid #E5E5E580',
            borderRadius: '14px',
          }}
        >
          {bulkLoading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              mb={3}
            >
              <Image
                alt="buildex-loading"
                src="https://res.cloudinary.com/osperb/image/upload/v1727350655/Buildex/shopping-cart_15576198_yrhjj6.gif"
                sx={{
                  maxWidth: '100px',
                  aspectRatio: 1 / 1,
                }}
              />
              <Typography variant="caption">{getText('local_cart_syncing_to_server')}</Typography>
            </Box>
          )}

          {checkoutItemList?.length <= 0 && (
            <Stack
              sx={{ padding: 2, pb: 3 }}
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Typography variant="subtitle2">
                {' '}
                {getText('cart_is_Empty_add_some_product')}
              </Typography>
              <OspLink href={paths.public.products}>
                <Button size="small" variant="contained" color="success">
                  {getText('shop_now')}
                </Button>
              </OspLink>
            </Stack>
          )}

          <Scrollbar sx={{ maxHeight: 200, height: '100%', overflowX: 'hidden' }}>
            {checkoutItemList?.map((item, key) => (
              <Fragment key={key}>
                <CheckoutProductList key={key} cartItem={item} />
                {key + 1 !== checkoutItemList?.length && <Divider sx={{ my: 1 }} />}
              </Fragment>
            ))}
          </Scrollbar>
        </Card>
        <Stack spacing={1}>{renderSummeryInfo}</Stack>

        <Stack spacing={2} mt={2}>
          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">{getText('Total')} </Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle1" sx={{ color: 'default.main' }}>
                {translateCurrencyText} {currencyConvertedTotalPrice}
              </Typography>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                ({getText('Inclusive_of_VAT')})
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );

  const renderMobileCheckout = (
    <Accordion
      defaultExpanded
      sx={{
        m: 0,
        boxShadow: 'none',
        border: '1px solid #f2f2f2',
        borderRadius: '6px',
        mb: 1,
      }}
      className="mobile-checkout-accordion"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3-content"
        id="panel3-header"
        sx={{
          alignItems: 'center',
        }}
      >
        <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="h6">{getText('Total')} </Typography>
            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
              ({getText('Inclusive_of_VAT')})
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ color: 'default.main' }}>
            {translateCurrencyText} {currencyConvertedTotalPrice}
          </Typography>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>{renderSummeryInfo}</Stack>
      </AccordionDetails>
    </Accordion>
  );

  return <>{isMobile ? renderMobileCheckout : renderWebCheckoutItem}</>;
}

CheckoutSummary.propTypes = {
  notShowShipping: PropTypes.bool,
};
