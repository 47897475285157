import PropTypes from 'prop-types';

export const HomeIcon = ({ width = 20, height = 20, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_15_162)">
      <path
        d="M5.13487 18.5818H16.4704C17.6621 18.5818 18.3564 17.9021 18.3564 16.7331V7.47761L17.0102 6.56776V16.3902C17.0102 16.9394 16.7142 17.2351 16.1823 17.2351H5.41406C4.8839 17.2351 4.59681 16.9394 4.59681 16.3902V6.57137L3.23991 7.47761V16.7331C3.23991 17.9039 3.93434 18.5818 5.13487 18.5818ZM0.826172 9.26568C0.826172 9.61029 1.09827 9.93541 1.55347 9.93541C1.78643 9.93541 1.97841 9.80758 2.14736 9.66722L10.5348 2.6287C10.7275 2.46826 10.9468 2.46992 11.1326 2.6287L19.5128 9.66722C19.6906 9.80758 19.8826 9.93541 20.1156 9.93541C20.5103 9.93541 20.8252 9.6902 20.8252 9.2923C20.8252 9.03821 20.7487 8.86942 20.5654 8.71658L11.8477 1.39428C11.2209 0.869477 10.4572 0.867668 9.82857 1.39428L1.09482 8.71658C0.91156 8.86942 0.826172 9.07373 0.826172 9.26568Z"
        fill="white"
      />
      <mask id="path-2-inside-1_15_162" fill="white">
        <path d="M8.4126 11.4922C8.4126 11.216 8.63646 10.9922 8.9126 10.9922H12.7388C13.0149 10.9922 13.2388 11.216 13.2388 11.4922V18.1504H8.4126V11.4922Z" />
      </mask>
      <path
        d="M8.4126 11.4922C8.4126 11.216 8.63646 10.9922 8.9126 10.9922H12.7388C13.0149 10.9922 13.2388 11.216 13.2388 11.4922V18.1504H8.4126V11.4922Z"
        stroke="white"
        strokeWidth="2.2"
        mask="url(#path-2-inside-1_15_162)"
      />
    </g>
    <defs>
      <clipPath id="clip0_15_162">
        <rect width="20" height="19" fill="white" transform="translate(0.826172)" />
      </clipPath>
    </defs>
  </svg>
);

HomeIcon.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
};
