import PropTypes from 'prop-types';

import { Stack } from '@mui/system';
import { Box, Typography } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import OpenInNewTwoToneIcon from '@mui/icons-material/OpenInNewTwoTone';

import { paths } from 'src/routes/paths';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';

import OspLink from 'src/components/link/osp-link';

import Banners from './banners';

const HeroSlider = ({ bannerDetails }) => {
  const { isMobile } = useScreenWidth();
  const getText = useLocalizedText();

  return (
    <Stack mt={isMobile ? 0 : 2} className="home-slide-cover" px={0}>
      <Banners bannerData={bannerDetails} />
      {!isMobile ? (
        <OspLink sx={{ '&:hover': { textDecoration: 'none' } }} href={paths.public.products}>
          <Stack
            className="pop-up-banner-button-container display-sm-none"
            sx={{ backgroundColor: 'white' }}
          >
            <Stack
              className="popup-banner-button"
              sx={{ fontSize: '20px', fontWeight: 600, backgroundColor: colors.themePrimary }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography
                  variant="subtitle1"
                  sx={{ color: 'white', display: 'flex', alignItems: 'center', gap: 0.5 }}
                >
                  <GridViewIcon fontSize="small" />

                  {getText('explore_product_categories')}
                </Typography>
                <OpenInNewTwoToneIcon fontSize="small" />
              </Box>
            </Stack>
            <Stack>
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 19 19"
                className="white-curve-layer-one"
              >
                <defs>
                  <style>
                    {`
.cls-1 {
fill: #fff;
strokeWidth: 0px;
}
`}
                  </style>
                </defs>
                <path className="cls-1" d="M19,0v19H0c10.5,0,19-8.51,19-19Z" />
              </svg>
              <Stack className="white-box-el" />
            </Stack>
            <Stack>
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 19 19"
                className="white-curve-layer-two"
              >
                <defs>
                  <style>
                    {`
.cls-1 {
fill: #fff;
strokeWidth: 0px;
}
`}
                  </style>
                </defs>
                <path className="cls-1" d="M19,0v19H0c10.5,0,19-8.51,19-19Z" />
              </svg>
              <Stack className="white-box-el-two" />
            </Stack>
          </Stack>
        </OspLink>
      ) : (
        ''
      )}
    </Stack>
  );
};

export default HeroSlider;

HeroSlider.propTypes = {
  bannerDetails: PropTypes.any,
};
