import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import React, { useRef, useState, Fragment, useEffect } from 'react';

import { Box, IconButton } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

function SubBannerCarousel({ bannerData }) {
  const { screenWidth } = useScreenWidth();
  const rtl = useLayoutStyle();

  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(false);
  const swiperRef = useRef(null);

  const updateArrowVisibility = (swiper) => {
    setShowPrevArrow(swiper.activeIndex > 0);
    setShowNextArrow(!swiper.isEnd);
  };

  useEffect(() => {
    if (swiperRef.current) {
      updateArrowVisibility(swiperRef.current);
    }
  }, [bannerData]);

  return (
    <Box className="sub-banner-slider" sx={{ position: 'relative' }}>
      <Swiper
        autoplay={false}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          updateArrowVisibility(swiper);
        }}
        modules={[Navigation, Autoplay]}
        spaceBetween={15}
        navigation={{
          prevEl: '.custom_prev_sub_banner',
          nextEl: '.custom_next_sub_banner',
        }}
        className="custom-class mt-2"
        onSlideChange={(swiper) => updateArrowVisibility(swiper)}
        breakpoints={{
          400: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 2,
          },
        }}
      >
        {bannerData?.map((item, key) => {
          const isImage = item?.banner_type === 'IMAGE';
          const isVideo = item?.banner_type === 'VIDEO';

          const shouldRender =
            (screenWidth < 786 && item?.platform === 'APP') ||
            (screenWidth > 768 && item?.platform === 'WEB') ||
            item?.platform === 'COMMON';

          if (!shouldRender || (!isImage && !isVideo)) return null;

          if (isVideo) {
            const videoId = item?.video_id;
            if (!videoId) {
              return null;
            }

            return (
              <SwiperSlide className="dddd" key={key}>
                <OspLink
                  type={item?.link_type}
                  href={item?.link || ''}
                  sx={{ '&:hover': { textDecoration: 'none' } }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '15px',
                      overflow: 'hidden',
                      aspectRatio: '8 / 3',
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                  >
                    <Vimeo
                      video={item?.video_id}
                      responsive
                      muted
                      playsInline
                      autoplay
                      controls={false}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        pointerEvents: 'none',
                      }}
                    />
                  </Box>
                </OspLink>
              </SwiperSlide>
            );
          }

          if (isImage) {
            return (
              <SwiperSlide key={key}>
                <Box sx={{ mx: 1 }}>
                  <OspLink
                    type={item?.link_type}
                    href={item?.link || ''}
                    sx={{ '&:hover': { textDecoration: 'none' } }}
                  >
                    <SubBannerImage item={item} />
                  </OspLink>
                </Box>
              </SwiperSlide>
            );
          }

          return <Fragment key={key} />;
        })}
      </Swiper>

      {showPrevArrow && (
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            left: '10px',

            zIndex: 9,
            transform: 'translateY(-50%)',
          }}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          {rtl ? <ChevronRightRoundedIcon /> : <KeyboardArrowLeftRoundedIcon />}
        </IconButton>
      )}
      {showNextArrow && (
        <IconButton
          onClick={() => swiperRef.current?.slideNext()}
          sx={{
            position: 'absolute',
            top: '50%',
            zIndex: 9,
            right: '10px',
            transform: 'translateY(-50%)',
          }}
        >
          {rtl ? <KeyboardArrowLeftRoundedIcon /> : <ChevronRightRoundedIcon />}
        </IconButton>
      )}
    </Box>
  );
}

export default SubBannerCarousel;

SubBannerCarousel.displayName = 'SubBannerCarousel';

SubBannerCarousel.propTypes = {
  bannerData: PropTypes.any,
};

const SubBannerImage = ({ item }) => {
  const BannerImage = useImageTransformation(item?.image?.url, 'w_500', true);
  const BannerLogoAltText = useTranslatedText(item?.image?.alt_text);
  return (
    <Image
      alt={item?.image?.url ? BannerLogoAltText : 'null'}
      src={item?.image?.url ? BannerImage : ''}
      imageStyle={{
        objectFit: 'cover',
        borderRadius: '15px',
        aspectRatio: '8 / 3',
      }}
      sx={{}}
    />
  );
};
SubBannerImage.propTypes = {
  item: PropTypes.any,
};
