import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Stack, Container } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';

import { paths } from 'src/routes/paths';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';

import { colors } from 'src/utils/colors';

import { getOrderSingleViewState } from 'src/server/selectors/selector';
import { fetchMyCart, fetchOrderSingleView } from 'src/server/slice/cartOrderSlice';

import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';
import { useSettingsContext } from 'src/components/settings';

import OrderItems from './orderItems';

export const OrderSuccess = () => {
  const { isMobile, screenWidth } = useScreenWidth();
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  const getText = useLocalizedText();

  const params = useParams();

  const { orderSingleView } = useSelector(getOrderSingleViewState);

  const orderId = params?.id;

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderSingleView({ orderId }));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    dispatch(fetchMyCart());
  }, [dispatch]);

  const handleScreenWidth = () => {
    if (screenWidth < 600) {
      return 5;
    }
    if (screenWidth < 900) {
      return 24;
    }
    if (screenWidth < 1200) {
      return 10;
    }

    return 5;
  };

  const renderList = (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mb: 15,
        mt: handleScreenWidth(),
      }}
    >
      <Stack alignItems="center">
        <TaskAltRoundedIcon
          fontSize="large"
          color="success"
          sx={{ mb: 2, height: '40px', width: '40px' }}
        />
        <Typography variant="subtitle1" sx={{ fontSize: '22px' }} mb={1.5} color="black">
          {getText('order_successfully')}
        </Typography>
        <Typography variant="body1">
          {getText('your_order_has_been_placed_successfully')}
        </Typography>
        <Stack direction={isMobile ? 'column' : 'row'} mt={4} spacing={3}>
          <OspLink href={paths.private.my_account.root}>
            <Button size="large" sx={{ px: 6, borderRadius: '4px' }} variant="outlined">
              <Iconify icon="fluent:box-20-filled" sx={{ mr: 2 }} />
              {getText('go_to_my_orders')}
            </Button>
          </OspLink>

          <OspLink href={paths.public.home}>
            <Button
              size="large"
              sx={{
                px: 6,
                borderRadius: '4px',
                width: '100%',
                backgroundColor: colors.themePrimary,
                '&:hover': { backgroundColor: '#1968e9' },
              }}
              variant="contained"
            >
              {getText('back_to_shopping')}{' '}
            </Button>
          </OspLink>
        </Stack>
        <Divider sx={{ width: '100%', my: 4 }} />
        <OrderItems orderSingleViewDetails={orderSingleView} />
      </Stack>
    </Container>
  );
  return <>{renderList}</>;
};

OrderSuccess.propTypes = {};
