import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { Box, Card, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import { useGetToken } from 'src/hooks/useHandleSessions';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { colors } from 'src/utils/colors';

import { TOKEN_PREFIX } from 'src/server/http';
import { getAuthState } from 'src/server/selectors/selector';
import { CART_KEY, uploadBulkCart } from 'src/server/slice/cartOrderSlice';
import { loginUser, verifyOtp, guestUserLogin } from 'src/server/slice/authentication';

import OspLink from 'src/components/link/osp-link';
import FormProvider, { RHFCode } from 'src/components/hook-form';
import RHFPhoneField from 'src/components/hook-form/rhf-phone-input-new';

const LoginView = ({ type = 'normal' }) => {
  const dispatch = useDispatch();
  const route = useRouter();
  const getText = useLocalizedText();
  const rtl = useLayoutStyle();
  const { enqueueSnackbar } = useSnackbar();
  const searchParams = useSearchParams();
  const { token } = useGetToken(TOKEN_PREFIX);
  const { screenWidth, isMobile } = useScreenWidth();
  const { loginData, loading, guestUserLoading, otpData } = useSelector(getAuthState);

  const LoginSchema = Yup.object().shape({
    phone: Yup.string(),
  });

  const OtpSchema = Yup.object().shape({
    otp: Yup.string(),
  });

  const [otp, setOtp] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);
  // const [selectedCode, setSelectedCode] = useState('968');
  const [mobileNumber, setMobileNumber] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [countryCode, setCountryCode] = useState('968');

  useEffect(() => {
    if (loginData?.user && otp) {
      setSeconds(60);
    }
  }, [loginData?.user, otp]);

  useEffect(() => {
    if (loginData?.user && otp && seconds > 0) {
      const timer = setInterval(() => setSeconds((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }

    setIsDisabled(false);
    return () => {};
  }, [loginData?.user, otp, seconds]);

  const defaultValues = {
    phone: '',
  };

  const methods = useForm({
    resolver: yupResolver(otp ? OtpSchema : LoginSchema),
    defaultValues: otp ? { otp: '' } : defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const handlePhoneInputChange = (value, inputName) => {
  //   const country_code = getCountryCallingCode(value);
  //   setSelectedCode(country_code);
  // };

  const onSubmit = handleSubmit(async (data) => {
    setSelectedCountryCode(countryCode);
    setMobileNumber(data?.phone);

    const credentials = {
      state: {
        phone: data?.phone,
        country_code: countryCode,
      },
      setOtp,
      enqueueSnackbar,
    };

    const otpCredentials = {
      state: {
        ...data,
        user: loginData?.user,
      },
      path: searchParams.get('returnTo') || paths.public.home,
      route,
      enqueueSnackbar,
    };
    if (loginData?.user && otp) {
      dispatch(verifyOtp(otpCredentials));
    } else {
      dispatch(loginUser(credentials));
    }
  });

  const handleResendOtp = () => {
    const credentials = {
      state: {
        phone: mobileNumber,
        country_code: selectedCountryCode,
      },
      setSeconds,
      enqueueSnackbar,
    };
    dispatch(loginUser(credentials));
  };

  const handleGuestLogin = () => {
    const credentials = {
      enqueueSnackbar,
      path: searchParams.get('returnTo') || paths.public.home,
      route,
    };
    dispatch(guestUserLogin(credentials));
  };

  useEffect(() => {
    const localCheckoutItemListWithoutParse = localStorage.getItem(CART_KEY);
    const isLocalCart = localStorage.getItem('isLocalCart');
    const localCheckoutItemList = localCheckoutItemListWithoutParse
      ? JSON.parse(localCheckoutItemListWithoutParse)
      : {};

    const credentials = {
      state: {
        cart_items: localCheckoutItemList?.cart_items?.map((i) => ({
          quantity: i.quantity,
          product: i.product?.uid,
        })),
      },
      path: searchParams.get('returnTo') || paths.public.home,
      route,
      enqueueSnackbar,
    };
    if (isLocalCart && token) {
      dispatch(uploadBulkCart(credentials));
    }
  }, [dispatch, enqueueSnackbar, token, route, searchParams]);

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 4 }}>
      {type === 'normal' ? (
        <Typography variant="h4">{getText('login_to_buildex')}</Typography>
      ) : (
        <Typography variant="subtitle1">{getText('login_to_buildex')}</Typography>
      )}
      {otp && (
        <Typography component="div" display="flex" alignItems="center" gap={0.5} variant="body2">
          {`${getText('otp_Sent_to')} ${mobileNumber}`}
          <Typography
            variant="body2"
            sx={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
            onClick={() => setOtp(false)}
          >
            {getText('change_phone_number')}
          </Typography>
        </Typography>
      )}
    </Stack>
  );

  const renderOtpForm = (
    <Stack spacing={2.5}>
      <Stack>
        <Box py={2} px={1} sx={{ borderRadius: 1, border: '1px solid rgba(145, 158, 171, 0.2)' }}>
          <Typography color="text.disabled" pl={1} display="flex" alignItems="center" gap={2}>
            <Typography
              pr={1}
              display="flex"
              alignItems="center"
              gap={0.5}
              sx={{ borderRight: '1px solid rgba(145, 158, 171, 0.2)' }}
            >
              {selectedCountryCode}
              <KeyboardArrowDownIcon />
            </Typography>
            {mobileNumber}
          </Typography>
        </Box>
      </Stack>
      <Box>
        <RHFCode name="otp" />
        <Typography variant="body2" display="flex" pt={1} gap={0.5}>
          {`${getText('didnt_get_otp')}`}
          {isDisabled ? (
            <Typography color="text.disabled" variant="body2">
              {`${getText('resend_otp_in')} ${seconds} ${getText('sec')}`}
            </Typography>
          ) : (
            <Typography
              color="blue"
              variant="body2"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={handleResendOtp}
            >
              {getText('resend')}
            </Typography>
          )}
        </Typography>
      </Box>
    </Stack>
  );

  const renderMobileForm = (
    <Stack spacing={2.5}>
      <Box>
        <RHFPhoneField
          name="phone"
          label="Phone"
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
        {/* <RHFPhoneInput
          name="phone"
          label={getText('phone')}
          placeholder={getText('enter_phone_number')}
          required
          setSelectedCode={(value) => handlePhoneInputChange(value, 'phone')}
        /> */}
        <Typography variant="caption" color="text.disabled" pl={1}>
          {getText('otp_has_sent_to_this_phone_number')}
        </Typography>
      </Box>
    </Stack>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        {otp ? renderOtpForm : renderMobileForm}
        {otpData?.message && (
          <Typography color="red" variant="caption">
            {otpData?.message}
          </Typography>
        )}
        <Box>
          <LoadingButton
            fullWidth
            size={type === 'normal' ? 'large' : 'medium'}
            type="submit"
            variant="contained"
            loading={loading || isSubmitting}
            sx={{
              backgroundColor: colors.themePrimary,
              color: 'white',
              ':hover': { backgroundColor: colors.themePrimary, color: 'white' },
            }}
          >
            {otp ? `${getText('verify')}` : `${getText('get_otp')}`}
          </LoadingButton>
          {type === 'normal' ? (
            <Typography variant="subtitle1" textAlign="center" py={0.5}>
              {getText('or')}
            </Typography>
          ) : (
            <p
              style={{
                height: '0px',
                margin: '5px',
              }}
            >
              &nbsp;
            </p>
          )}
          <LoadingButton
            fullWidth
            variant="outlined"
            size={type === 'normal' ? 'large' : 'medium'}
            type="button"
            loading={guestUserLoading}
            onClick={handleGuestLogin}
            endIcon={rtl ? <WestIcon fontSize="small" /> : <EastIcon fontSize="small" />}
          >
            {getText('continue_as_guest')}
          </LoadingButton>
        </Box>
      </Stack>
    </FormProvider>
  );

  const getMarginTop = () => {
    if (isMobile) return 0;
    if (screenWidth < 900) return 15;
    return 0;
  };

  const mainContent = (
    <>
      {renderHead}
      <Stack spacing={2.5}>
        {renderForm}
        <Typography variant="caption" fontSize={13} textAlign="center" color="text.disabled">
          {getText('by_signing_up_i_agree_to')}{' '}
          <OspLink href={paths.legal.termsAndConditions}>
            <Typography
              color="#1C252E"
              variant="caption"
              fontSize={13}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {getText('terms_of_use_login')}
            </Typography>{' '}
          </OspLink>
          {getText('and')}{' '}
          <OspLink href={paths.legal.privacyPolicy}>
            <Typography
              color="#1C252E"
              variant="caption"
              fontSize={13}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {getText('privacy_policy')}
            </Typography>
          </OspLink>
        </Typography>
      </Stack>
    </>
  );

  if (type === 'popup') {
    return mainContent;
  }

  return (
    <Stack direction="row" justifyContent="center" mt={getMarginTop()}>
      <Card sx={{ my: 15, p: '16px', width: 420, minHeight: 400 }}>{mainContent}</Card>
    </Stack>
  );
};

export default LoginView;

LoginView.propTypes = {
  type: PropTypes.any,
};
