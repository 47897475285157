import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';

// ----------------------------------------------------------------------

export function ProductItemSkeleton({ sx, ...other }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 2,
        ...sx,
      }}
      {...other}
    >
      <Stack sx={{ p: 1 }}>
        <Skeleton sx={{ paddingTop: '100%' }} />
      </Stack>

      <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
        <Skeleton sx={{ width: 0.5, height: 16 }} />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row">
            <Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
            <Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
            <Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
          </Stack>
          <Skeleton sx={{ width: 40, height: 16 }} />
        </Stack>
      </Stack>
    </Paper>
  );
}

ProductItemSkeleton.propTypes = {
  sx: PropTypes.object,
};

// ----------------------------------------------------------------------

export function ProductDetailsSkeleton() {
  return (
    <Grid container spacing={4} mt={3}>
      <Grid xs={12} md={6} lg={7}>
        <Box sx={{ position: 'relative', height: '300px', display: 'flex', gap: 2 }}>
          <Stack direction="column" spacing={2} sx={{ mt: 2, mb: '20px' }}>
            {[...Array(4)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                sx={{
                  width: 100,
                  minHeight: 100,
                  borderRadius: 1,
                }}
              />
            ))}
          </Stack>
          <Skeleton variant="rectangular" height="500px" width="100%" />
        </Box>
      </Grid>

      {/* Right Section: Product Info */}
      <Grid xs={12} md={6} lg={5}>
        <Stack spacing={2}>
          {/* Product Name */}
          <Skeleton sx={{ height: 32, width: '60%' }} />
          <Skeleton sx={{ height: 24, width: '40%' }} />
          {/* Price */}
          <Skeleton sx={{ height: 32, width: '30%' }} />
          {/* Delivery Info */}
          <Skeleton sx={{ height: 16, width: '80%' }} />
          <Skeleton sx={{ height: 16, width: '80%' }} />
          <Skeleton sx={{ height: 16, width: '80%' }} />
          <Skeleton sx={{ height: 16, width: '80%' }} />
          <Skeleton sx={{ height: 16, width: '80%' }} />
          <Skeleton sx={{ height: 16, width: '50%' }} />
          <Skeleton sx={{ height: 16, width: '50%' }} />
          <Skeleton sx={{ height: 16, width: '50%' }} />
          {/* Quantity Selector */}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Skeleton variant="rectangular" sx={{ width: 40, height: 40, borderRadius: 1 }} />
            <Skeleton variant="rectangular" sx={{ width: 100, height: 40, borderRadius: 1 }} />
            <Skeleton variant="rectangular" sx={{ width: 40, height: 40, borderRadius: 1 }} />
          </Stack>

          {/* Buttons */}
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Skeleton variant="rectangular" sx={{ width: 120, height: 40, borderRadius: 1 }} />
            <Skeleton variant="rectangular" sx={{ width: 120, height: 40, borderRadius: 1 }} />
          </Stack>
        </Stack>
      </Grid>

      <Box sx={{ width: '100%', padding: 2, mb: 10 }}>
        <Box sx={{ mb: 2, border: '1px solid #F2F2F2', borderRadius: '12px', p: 2 }}>
          <Typography variant="h6">
            <Skeleton width="60%" />
          </Typography>
          <>
            {[...Array(3)].map((_, index1) => (
              <Box key={index1} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Skeleton width="30%" />
                <Skeleton width="20%" />
              </Box>
            ))}
          </>

          <Box sx={{ mt: 3 }}>
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
