import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import React, { useRef, useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import { Box, IconButton, Typography } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { colors } from 'src/utils/colors';

import { useCurrency } from 'src/providers/CurrencyProvider';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';

function OrderItemsCarousel({ orderData }) {
  const rtl = useLayoutStyle();

  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(false);
  const swiperRef = useRef(null);

  const updateArrowVisibility = (swiper) => {
    setShowPrevArrow(swiper.activeIndex > 0);
    setShowNextArrow(!swiper.isEnd);
  };

  useEffect(() => {
    if (swiperRef.current) {
      updateArrowVisibility(swiperRef.current);
    }
  }, [orderData]);

  return (
    <Box className="sub-banner-slider" sx={{ position: 'relative' }}>
      <Swiper
        modules={[Navigation, Autoplay]}
        autoplay={false}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          updateArrowVisibility(swiper);
        }}
        spaceBetween={15}
        navigation={{
          prevEl: '.custom_prev_sub_banner',
          nextEl: '.custom_next_sub_banner',
        }}
        className="custom-class mt-2"
        onSlideChange={(swiper) => updateArrowVisibility(swiper)}
        slidesPerView={1.3}
        breakpoints={{
          400: {
            slidesPerView: 1.3,
          },
          480: {
            slidesPerView: 1.3,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          },
        }}
      >
        {orderData?.map((item, index) => (
          <SwiperSlide key={`${item?.uid}-${index}`}>
            <CarouselItem item={item} />
          </SwiperSlide>
        ))}
      </Swiper>

      {showPrevArrow && (
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            left: '10px',

            zIndex: 9,
            transform: 'translateY(-50%)',
          }}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          {rtl ? <ChevronRightRoundedIcon /> : <KeyboardArrowLeftRoundedIcon />}
        </IconButton>
      )}
      {showNextArrow && (
        <IconButton
          onClick={() => swiperRef.current?.slideNext()}
          sx={{
            position: 'absolute',
            top: '50%',
            zIndex: 9,
            right: '10px',
            transform: 'translateY(-50%)',
          }}
        >
          {rtl ? <KeyboardArrowLeftRoundedIcon /> : <ChevronRightRoundedIcon />}
        </IconButton>
      )}
    </Box>
  );
}

export default OrderItemsCarousel;

OrderItemsCarousel.displayName = 'OrderItemsCarousel';

OrderItemsCarousel.propTypes = {
  orderData: PropTypes.any,
};

const SubBannerImage = ({ item }) => {
  const BannerImage = useImageTransformation(item?.image?.url);
  const BannerLogoAltText = useTranslatedText(item?.image?.alt_text);
  return (
    <Image
      alt={item?.image?.url ? BannerLogoAltText : 'null'}
      src={item?.image?.url ? BannerImage : ''}
      sx={{ maxHeight: '100%' }}
      imageStyle={{
        objectFit: 'cover',
        aspectRatio: '8 / 3',
        borderRadius: '15px',
      }}
    />
  );
};
SubBannerImage.propTypes = {
  item: PropTypes.any,
};

const CarouselItem = ({ item }) => {
  const { currency } = useCurrency();
  const translateCurrencyText = useTranslatedText(currency?.title);
  const { isMobile } = useScreenWidth();
  const getText = useLocalizedText();
  const transformedImage = useImageTransformation(item?.image_url);
  const translatedProductName = useTranslatedText(item?.name);

  const currencyConversionPrice = useCurrencyConversion(item?.product_price);

  return (
    <Stack
      direction="row"
      alignItems={isMobile ? 'center' : 'start'}
      justifyContent="space-between"
    >
      <Stack
        mb={isMobile ? 2 : ''}
        direction="column"
        alignItems="start"
        justifyContent="start"
        gap={0.5}
        backgroundColor="#F7FAFF"
        borderRadius="5px"
        width="100%"
        p={1}
      >
        <Stack direction="row" gap={2}>
          <Stack
            sx={{
              my: 'auto',
              height: '90px',
              width: '90px',
              minWidth: '90px',
              border: '1.421px solid rgba(229, 229, 229, 0.30)',
              background: '#FFF',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '6px',
              overflow: 'hidden',
            }}
          >
            {item?.image_url ? (
              <Image
                alt="translatedText"
                src={transformedImage}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
                imageStyle={{
                  aspectRatio: 1 / 1,
                  objectFit: 'cover',
                  borderRadius: 0,
                }}
                className={`product-image ${item?.image_url && 'hover-image-available'}`}
              />
            ) : (
              <Image
                alt="alt"
                src="/assets/images/default/product.png"
                sx={{}}
                imageStyle={{
                  aspectRatio: 1 / 1,
                  objectFit: 'cover',
                  borderRadius: 0,
                }}
              />
            )}
          </Stack>
          <Stack spacing={1}>
            <TextMaxLine
              variant="body2"
              line={2}
              sx={{ maxWidth: isMobile ? '125px' : '165px', fontWeight: 'fontWeightSemiBold' }}
            >
              {translatedProductName}
            </TextMaxLine>

            <Typography
              variant="caption"
              sx={{
                fontWeight: '400',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {translateCurrencyText}&nbsp;
              <Typography component="span" variant="caption" sx={{ fontWeight: 'bold' }}>
                {currencyConversionPrice}
              </Typography>
            </Typography>
          </Stack>
        </Stack>

        {!isMobile && (
          <Stack
            sx={{
              color: colors.themeBlack,
            }}
            py={1}
            spacing={isMobile ? 2 : 3}
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" width="fit-content" spacing={3}>
              <Stack sx={{ ml: 1 }}>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="caption"
                  fontSize={12}
                  sx={{ fontWeight: 500 }}
                >
                  {getText('qty')} : {item?.quantity}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

CarouselItem.propTypes = {
  item: PropTypes.any,
};
