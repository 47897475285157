import PropTypes from 'prop-types';
import { memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Card,
  List,
  Stack,
  Typography,
  IconButton,
  ListItemText,
  ListItemButton,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';
import { useProductPathname } from 'src/hooks/useProductPathname';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { colors } from 'src/utils/colors';

import { useCurrency } from 'src/providers/CurrencyProvider';
import { getProductState } from 'src/server/selectors/selector';
import { fetchSearchProducts, productFilterObjectState } from 'src/server/slice/productSlice';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import EmptyContent from 'src/components/empty-content';
import SearchNotFound from 'src/components/search-not-found/search-not-found';

// ----------------------------------------------------------------------

function Searchbar({ isFullPage }) {
  const dispatch = useDispatch();
  const search = useBoolean();
  const getText = useLocalizedText();
  const route = useRouter();

  const { productFilterObject, searchedProduct } = useSelector(getProductState);

  const handleClose = useCallback(() => {
    search.onFalse();
    dispatch(
      productFilterObjectState({
        search: '',
      })
    );
  }, [dispatch, search]);

  const handleSingleViewClick = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleSearch = useCallback(
    (event) => {
      search.onTrue();

      dispatch(
        productFilterObjectState({
          search: event.target.value,
        })
      );
    },
    [dispatch, search]
  );

  useEffect(() => {
    if (productFilterObject?.search) {
      const credentials = {
        page: 1,
        limit: 5,
        state: {
          search_text: productFilterObject?.search,
          filter_data: {
            is_deleted: false,
            is_active: true,
          },
        },
      };
      dispatch(fetchSearchProducts(credentials));
    }
  }, [dispatch, productFilterObject]);

  const searchedProductList = searchedProduct?.search_products;

  const notFound = productFilterObject?.search && !searchedProductList?.length;

  const handleBack = () => {
    route.back();
  };

  const renderSearchBar = (
    <InputBase
      fullWidth
      // autoFocus
      placeholder={getText('search_for_products')}
      value={productFilterObject?.search || ''}
      onChange={handleSearch}
      endAdornment={
        productFilterObject?.search ? (
          <CancelOutlinedIcon sx={{ cursor: 'pointer' }} onClick={handleClose} fontSize="small" />
        ) : (
          <Iconify
            icon="uil:search"
            sx={{ color: colors.themeSecondary, height: '25px', width: '25px' }}
          />
        )
      }
      inputProps={{
        sx: { typography: 'p' },
      }}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '4px',
        padding: '7px 10px',
      }}
    />
  );

  const renderFullPageSearch = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      p={3}
      spacing={1}
      sx={{ borderBottom: '1px solid #eaeaea', backgroundColor: '#232B35' }}
    >
      <IconButton onClick={handleBack}>
        <ArrowBackIcon size={27} sx={{ color: 'grey' }} />
      </IconButton>
      {renderSearchBar}
    </Stack>
  );

  const renderNormalSearchComp = (
    <Box sx={{ flex: 1, p: 0, px: '10px', position: 'relative' }}>
      {renderSearchBar}

      {productFilterObject?.search && (
        <ClickAwayListener onClickAway={handleClose}>
          <Card
            sx={{
              borderRadius: '4px',
              position: 'absolute',
              top: '50px',
              width: '97%',
              padding: 0,
              paddingBottom: '10px',
            }}
          >
            {notFound ? <SearchNotFound sx={{ p: 2 }} query={productFilterObject?.search} /> : ''}

            {search?.value && searchedProductList?.length ? (
              <List>
                <Scrollbar sx={{ height: 300, width: '100%', padding: '8px 0' }}>
                  {searchedProductList?.map((result, index) => (
                    <ResultItem handleClick={handleSingleViewClick} product={result} key={index} />
                  ))}
                </Scrollbar>
              </List>
            ) : (
              ''
            )}
          </Card>
        </ClickAwayListener>
      )}
    </Box>
  );

  return (
    <>
      {isFullPage ? (
        <Box>
          {renderFullPageSearch}
          {productFilterObject?.search && searchedProductList?.length ? (
            searchedProductList?.map((result, index) => (
              <ResultItem handleClick={handleSingleViewClick} product={result} key={index} />
            ))
          ) : (
            <EmptyContent description="No results were found for this keyword." />
          )}
        </Box>
      ) : (
        renderNormalSearchComp
      )}
    </>
  );
}

export default memo(Searchbar);

Searchbar.propTypes = {
  isFullPage: PropTypes.any,
};

const ResultItem = ({ product, handleClick }) => {
  const { currency } = useCurrency();
  const getText = useLocalizedText();
  const route = useRouter();

  const { discountPercentage, offerPrice } = useProductOfferPriceCalculator(product);

  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);
  const translateCurrencyText = useTranslatedText(currency?.title);

  const translatedText = useTranslatedText(
    product?.images[0]?.alt_text ? product?.images[0]?.alt_text : {}
  );
  const transformedImage = useImageTransformation(product?.images[0]?.url, 'w_100');

  const translatedName = useTranslatedText(product?.name);

  const { productRoute } = useProductPathname(product);

  const handleClickSingleProduct = () => {
    route.push(productRoute);
    handleClick();
  };

  return (
    <Box
      sx={{ '&:hover': { textDecoration: 'none' }, position: 'unset' }}
      onClick={handleClickSingleProduct}
    >
      <ListItemButton>
        <Image
          alt={translatedText}
          src={transformedImage || '/assets/images/default/product.png'}
          sx={{
            height: '60px',
            width: '60px',
          }}
          imageStyle={{
            borderRadius: '10px',
            height: '60px',
            width: '60px',
            objectFit: 'contain',
            ratio: '1/1',
            minWidth: '60px',
          }}
          className={`product-image ${product?.images[1]?.url && 'hover-image-available'}`}
        />
        <ListItemText
          sx={{ mx: 2 }}
          primaryTypographyProps={{
            typography: 'subtitle2',
            sx: {
              textTransform: 'capitalize',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          secondaryTypographyProps={{ typography: 'body2' }}
          primary={<Box component="span">{translatedName || ''}</Box>}
          secondary={
            <Stack flexDirection="row" display="flex" alignItems="center" spacing={1}>
              {translateCurrencyText}{' '}
              <Typography component="span" variant="subtitle1" sx={{ color: 'black' }}>
                {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
              </Typography>
              {offerPrice && offerPrice < product?.price ? (
                <Box
                  component="span"
                  sx={{
                    color: 'text.disabled',
                    textDecoration: 'line-through',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  {currencyConversionPrice}
                </Box>
              ) : (
                ''
              )}
              {discountPercentage ? (
                <Typography
                  variant="subtitle2"
                  color="#30ae4a"
                  component="span"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {discountPercentage}% {getText('off')}
                </Typography>
              ) : (
                ''
              )}
            </Stack>
          }
        />
      </ListItemButton>
    </Box>
  );
};

ResultItem.propTypes = {
  product: PropTypes.object,
  handleClick: PropTypes.any,
};
