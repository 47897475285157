import React from 'react';
import PropTypes from 'prop-types';

import SouthRoundedIcon from '@mui/icons-material/SouthRounded';
import { Box, Card, Stack, Rating, Typography } from '@mui/material';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';

import { useResponsive } from 'src/hooks/useResponsive';
import useFormatNumber from 'src/hooks/useFormatNumber';
import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';
import { useProductPathname } from 'src/hooks/useProductPathname';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { useCurrency } from 'src/providers/CurrencyProvider';

import Label from 'src/components/label';
import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';

export const product_label_layouts = ['LAYOUT_1', 'LAYOUT_2', 'LAYOUT_3', 'LAYOUT_4', 'LAYOUT_5'];

const ProductCardTwo = ({ product }) => {
  const getText = useLocalizedText();
  const { currency } = useCurrency();
  const { isMobile } = useResponsive();
  const translatedText = useTranslatedText(product?.images[0]?.alt_text);
  const transformedImage = useImageTransformation(product?.images[0]?.url);
  const formattedNumber = useFormatNumber(product?.average_rating_count);
  const { productRoute } = useProductPathname(product);

  const productLabels = product_label_layouts.map((layout) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTranslatedText(product?.product_labels?.find((i) => i.layout === layout)?.title)
  );
  const [productLabelOne, productLabelTwo, productLabelThree, productLabelFour, productLabelFive] =
    productLabels;

  const outOfStock = product?.stock <= 0;

  const { discountPercentage, offerPrice } = useProductOfferPriceCalculator(product);

  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);

  return (
    <OspLink href={productRoute} sx={{ '&:hover': { textDecoration: 'none' } }}>
      <Card
        sx={{
          borderRadius: '0px',
          paddingBottom: isMobile ? '3px' : '',
          border: 'none',
          cursor: 'pointer',
          borderBottom: isMobile ? '1px solid #e4e4e4' : 'none',
          boxShadow: 'none',
        }}
      >
        <Stack direction="row" spacing={1}>
          <Box>
            <Box
              position="relative"
              sx={{
                width: 130,
                // height: '100%',
                borderRadius: '10px',
                border: !isMobile && '1px solid #e4e4e4',
                aspectRatio: 1 / 1,
                p: 1,
              }}
            >
              {product?.images?.length > 0 ? (
                <Image
                  src={transformedImage}
                  alt={translatedText}
                  sx={{
                    height: '100%',
                    width: '100%',
                  }}
                  imageStyle={{
                    objectFit: 'contain',
                    height: '100%',
                    ...(outOfStock && {
                      opacity: 0.48,
                      filter: 'grayscale(1)',
                    }),
                  }}
                />
              ) : (
                <Image
                  src="/assets/images/default/product.png"
                  alt="product"
                  sx={{
                    height: '100%',
                    width: '100%',
                  }}
                  imageStyle={{
                    objectFit: 'contain',
                    height: '100%',
                    ...(!outOfStock && {
                      opacity: 0.48,
                      filter: 'grayscale(1)',
                    }),
                  }}
                />
              )}
              {isMobile && productLabelTwo && (
                <Label
                  variant="filled"
                  sx={{
                    padding: '0 10px 0px 10px',
                    with: 'fit-content',
                    backgroundColor: '#3097AE',
                  }}
                  className="width-fit-content"
                >
                  <TrendingUpRoundedIcon fontSize="small" sx={{ mr: 0.6 }} />
                  <Typography variant="subtitle2" fontSize={13}>
                    {productLabelTwo}
                  </Typography>
                </Label>
              )}
            </Box>
          </Box>
          {productLabelOne && (
            <Label
              variant="filled"
              color="error"
              sx={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                borderRadius: '10px 0px 13px 0px',
                padding: '8px 16px',
              }}
            >
              <Typography variant="subtitle2">{productLabelOne}</Typography>
            </Label>
          )}
          <Stack direction="row" flexDirection="column" justifyContent="space-between">
            <Box>
              <TextMaxLine line={2} fontSize={isMobile ? 15 : 16}>
                {useTranslatedText(product?.name)}
              </TextMaxLine>
              {product?.average_rating ? (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography color="black" fontSize={15}>
                    {product?.average_rating?.toFixed(1)}
                  </Typography>
                  <Rating
                    size="small"
                    value={product?.average_rating}
                    precision={0.1}
                    readOnly
                    sx={{ mr: 1 }}
                  />
                  {product?.average_rating_count ? (
                    <Typography fontSize={15}>({formattedNumber})</Typography>
                  ) : (
                    ''
                  )}
                </Stack>
              ) : (
                ''
              )}
            </Box>
            <Box>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={isMobile ? 'space-between' : 'flex-start'}
              >
                {!isMobile && discountPercentage && (
                  <Typography
                    variant="subtitle2"
                    color="#30ae4a"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowDownwardRoundedIcon
                      fontSize="small"
                      sx={{ mr: 0.6, height: '15px', width: '15px' }}
                    />
                    {discountPercentage}% {getText('off')}
                  </Typography>
                )}
                {isMobile && offerPrice && offerPrice < product?.price ? (
                  <Box
                    component="span"
                    sx={{
                      color: 'text.disabled',
                      textDecoration: 'line-through',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {currency?.symbol} {currencyConversionPrice}
                  </Box>
                ) : (
                  ''
                )}
                <Box>
                  {productLabelFour && (
                    <Label
                      variant="soft"
                      color="error"
                      sx={{
                        padding: '0 14px 0px 10px',
                        with: 'fit-content',
                        color: '#ff0000',
                      }}
                      className="width-fit-content"
                    >
                      <LocalFireDepartmentRoundedIcon
                        fontSize="small"
                        sx={{ mr: 0.6, height: '15px', width: '15px' }}
                      />
                      {productLabelFour}
                    </Label>
                  )}
                </Box>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent={isMobile ? 'space-between' : 'flex-start'}
                mb={1}
              >
                <Typography
                  variant="body1"
                  component="div"
                  display="flex"
                  color="black"
                  alignItems="center"
                  gap={0.5}
                >
                  {currency?.symbol}
                  <Typography variant="h6">
                    {' '}
                    {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
                  </Typography>
                </Typography>
                {isMobile && discountPercentage && (
                  <Typography
                    variant="subtitle2"
                    color="#30ae4a"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowDownwardRoundedIcon
                      fontSize="small"
                      sx={{ mr: 0.6, height: '15px', width: '15px' }}
                    />
                    {discountPercentage}% {getText('off')}
                  </Typography>
                )}
                {!isMobile && offerPrice && offerPrice < product?.price ? (
                  <Box
                    component="span"
                    sx={{
                      color: 'text.disabled',
                      textDecoration: 'line-through',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {currencyConversionPrice}
                  </Box>
                ) : (
                  ''
                )}
              </Stack>
            </Box>
            {isMobile ? (
              <Box>
                {productLabelFive ? (
                  <Label
                    variant="soft"
                    color="success"
                    sx={{
                      padding: '0 14px 0px 10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                      mb: 1,
                    }}
                    className="width-fit-content"
                  >
                    <SouthRoundedIcon
                      fontSize="small"
                      sx={{ mr: 0.6, height: '15px', width: '15px' }}
                    />
                    {productLabelFive}
                  </Label>
                ) : (
                  ''
                )}
                {productLabelThree ? (
                  <Label
                    variant="soft"
                    color="info"
                    sx={{
                      padding: '0 14px 0px 10px',
                      with: 'fit-content',
                    }}
                    className="width-fit-content"
                  >
                    <AutoAwesomeRoundedIcon
                      fontSize="small"
                      sx={{ mr: 0.6, height: '15px', width: '15px' }}
                    />
                    {productLabelThree}
                  </Label>
                ) : (
                  ''
                )}
                {product?.is_free_delivery_product ? (
                  <Typography
                    mt={0.2}
                    variant="subtitle2"
                    color="#30ae4a"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 2px',
                    }}
                  >
                    <LocalShippingRoundedIcon
                      fontSize="small"
                      sx={{ mr: 0.6, height: '15px', width: '15px' }}
                    />
                    {getText('free_delivery')}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
            ) : (
              ''
            )}
          </Stack>
        </Stack>
      </Card>
    </OspLink>
  );
};

export default ProductCardTwo;

ProductCardTwo.propTypes = {
  product: PropTypes.any,
};
