import { paths } from 'src/routes/paths';

export const useProductPathname = (productData) => {
  const formattedName = productData?.name?.english.replace(/ /g, '-');
  const productUrl = productData?.url;

  const productRoute = `${paths.public.productView(productUrl || formattedName)}`;

  return {
    productRoute,
  };
};
