import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Stack } from '@mui/system';
import Container from '@mui/material/Container';
import { Card, Grid, Button, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { colors } from 'src/utils/colors';

import { getCollectionState } from 'src/server/selectors/selector';
import { fetchCollections } from 'src/server/slice/collectionSlice';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';
import EmptyContent from 'src/components/empty-content';
import { useSettingsContext } from 'src/components/settings';

import { CollectionItemSkeleton } from './collection-skeleton';

export default function CollectionListView() {
  const settings = useSettingsContext();
  const dispatch = useDispatch();

  const getText = useLocalizedText();

  const mdDown = useResponsive('down', 'md');
  const { isMobile, screenWidth } = useScreenWidth();

  const { collections, loading } = useSelector(getCollectionState);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: 20,
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchCollections(credentials));
  }, [dispatch]);
  const collectionData = collections?.collections;

  const renderSkeleton = (
    <Grid container spacing={2} sx={{ p: 2 }}>
      {[...Array(16)].map((_, index) => (
        <Grid item key={index} xs={4} sm={3} md={3} lg={1.5}>
          <CollectionItemSkeleton />
        </Grid>
      ))}
    </Grid>
  );

  const renderList = (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mb: 15,
        my: isMobile ? 2 : 4,
      }}
    >
      <Grid container spacing={2}>
        {collectionData?.length >= 1 ? (
          <>
            {collectionData?.map((item, key) => {
              const collectionName = item?.title?.english.replace(/ /g, '-');

              return (
                <Grid item key={key} xs={4} sm={3} md={3} lg={1.5} xl={1.5}>
                  <OspLink
                    sx={{ '&:hover': { textDecoration: 'none' } }}
                    href={`${paths.public.collectionView(collectionName)}`}
                  >
                    <Card
                      sx={{
                        p: isMobile ? 1.5 : 3,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        borderRadius: 2,
                        cursor: 'pointer',
                        aspectRatio: '1 / 1',
                        width: '100%',
                        boxShadow: 'none',
                        border: '1px solid #E5E5E5',
                      }}
                    >
                      <CollectionImages item={item} />
                    </Card>
                  </OspLink>
                </Grid>
              );
            })}
          </>
        ) : (
          <Stack
            sx={{
              height: '50vh',
              width: '100%',
              textAlign: 'center',
              maxWidth: '300px',
              margin: 'auto',
            }}
          >
            <EmptyContent />
            <Typography variant="subtitle2" sx={{ my: 2 }}>
              There is no collections
            </Typography>
            <OspLink href={paths.public.home}>
              <Button
                size="large"
                sx={{
                  px: 6,
                  borderRadius: '4px',
                  width: '100%',
                  backgroundColor: colors.themePrimary,
                  '&:hover': { backgroundColor: '#1968e9' },
                }}
                variant="contained"
              >
                {getText('back_to_shopping')}{' '}
              </Button>
            </OspLink>
          </Stack>
        )}
      </Grid>
    </Container>
  );
  const getMarginTop = () => {
    if (isMobile) return 0;
    if (screenWidth < 900) return 18;
    if (screenWidth < 1200) return 8;
    return 0;
  };

  return (
    <>
      <Box mt={getMarginTop()} py={4} className={`custom-text-brand-div ${mdDown ? 'mt-55' : ''}`}>
        <Box
          className="text-brand-div relative text-center d-flex flex-column justify-content-center align-items-center"
          px={mdDown ? 4 : 5}
          py={mdDown ? 3 : 5}
          my={4}
          gap={mdDown ? 2 : 4}
          borderRadius={1}
          boxShadow={3}
          sx={{ backdropFilter: 'blur(10px)' }}
        >
          <Typography
            className="custom-brand-text "
            sx={{
              lineHeight: 'normal',
              color: 'black',
              fontSize: { xs: '20px', md: '35px', lg: '50px' },
            }}
          >
            {getText('explore_top_collections')}
          </Typography>
          <Typography
            className={`${mdDown ? 'font-size-10' : 'font-size-12'}`}
            sx={{
              color: 'black',
              fontSize: { xs: '10px', md: '12px' },
            }}
          >
            {getText('over_100_collections_from_all_over_the_world')}
          </Typography>
        </Box>
      </Box>

      {loading ? renderSkeleton : renderList}
    </>
  );
}
const CollectionImages = ({ item }) => {
  const CollectionImage = useImageTransformation(item?.logo?.url, 'w_500');
  const CollectionAltText = useTranslatedText(item?.logo?.alt_text);
  const TranslatedText = useTranslatedText(item?.title);
  const { isMobile } = useScreenWidth();

  return item?.logo?.url ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Image
        src={CollectionImage}
        alt={CollectionAltText}
        style={{}}
        imageStyle={{
          objectFit: 'contain',
          maxWidth: '100%',
          aspectRatio: 1 / 1,
        }}
      />
    </Box>
  ) : (
    <TextMaxLine
      variant={isMobile ? 'body2' : 'subtitle2'}
      line={2}
      sx={{ fontWeight: 'fontWeightSemiBold' }}
    >
      {TranslatedText}
    </TextMaxLine>
  );
};

CollectionImages.propTypes = {
  item: PropTypes.object,
};
