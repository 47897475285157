import React from 'react';

import MetaHead from 'src/utils/metaHead';

import LoginView from 'src/sections/authentication/login';

export default function LoginPage() {
  return (
    <>
      <MetaHead
        title="Login"
        description="Access your account securely on Buildex E-Commerce Platform. Log in to manage your orders and enjoy a seamless shopping experience."
        keywords="login, account access, e-commerce, Buildex, user login, sign in"
        openGraph={{
          title: 'Login - Buildex E-Commerce Platform',
          description:
            'Access your account securely on Buildex E-Commerce Platform. Log in to manage your orders and enjoy a seamless shopping experience.',
        }}
        twitter={{
          title: 'Login - Buildex E-Commerce Platform',
          description:
            'Access your account securely on Buildex E-Commerce Platform. Log in to manage your orders and enjoy a seamless shopping experience.',
        }}
      />
      <LoginView />
    </>
  );
}
