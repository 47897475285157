import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'src/routes/hooks';

import MetaHead from 'src/utils/metaHead';

import { useLocales } from 'src/locales';
import { fetchSEO } from 'src/server/slice/settingsSlice';
import { getSettingsState } from 'src/server/selectors/selector';

import { ProductShopDetailsView } from 'src/sections/product/view';

// ----------------------------------------------------------------------

export default function ProductSinglePage() {
  const params = useParams();

  const { id } = params;

  const dispatch = useDispatch();

  const { currentLang } = useLocales();

  const { SEOData } = useSelector(getSettingsState);

  useEffect(() => {
    const credentials = {
      state: { module: 'PRODUCT', module_name: id },
    };

    dispatch(fetchSEO(credentials));
  }, [dispatch, id]);

  const languageKey = currentLang.value === 'ar' ? 'arabic' : 'english';

  return (
    <>
      <MetaHead
        title={SEOData?.title?.[languageKey] || 'Products'}
        description={SEOData?.description?.[languageKey]}
        openGraph={{
          title: SEOData?.og_tag_title?.[languageKey] || 'Products',
          description: SEOData?.og_tag_description?.[languageKey],
          images: SEOData?.og_tag_image?.url,
        }}
        twitter={{
          title: SEOData?.title?.[languageKey] || 'Products',
          description: SEOData?.og_tag_description?.[languageKey],
          image: SEOData?.og_tag_image?.url,
        }}
      />
      <ProductShopDetailsView id={id} />
    </>
  );
}
