import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, Fragment, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import { styled, useTheme } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { IconButton, Typography, useMediaQuery } from '@mui/material';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';

import { useScreenWidth } from 'src/hooks/useScreenWidth';
import { useGetToken } from 'src/hooks/useHandleSessions';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { TOKEN_PREFIX } from 'src/server/http';
import { getProfileState } from 'src/server/selectors/selector';
import { triggerLoginPopup } from 'src/server/slice/authentication';
import { fetchWishListUpdate, fetchIsWishlistCheck } from 'src/server/slice/userSlice';

import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import Lightbox, { useLightBox } from 'src/components/lightbox';
import Carousel, { useCarousel, CarouselArrowIndex } from 'src/components/carousel';

// ----------------------------------------------------------------------

const THUMB_SIZE = 85;

const StyledThumbnailsContainer = styled('div')(({ length, theme }) => ({
  position: 'relative',
  width: '150px',
  '& .slick-slide': {
    lineHeight: 0,
  },
  '& .slick-list': {
    height: '100%',
    maxHeight: '500px',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '300px',
    },
  },
}));

// ----------------------------------------------------------------------

export default function ProductDetailsCarousel({ product, productLabelOne, productLabelTwo }) {
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const { isMobile } = useScreenWidth();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isSuccess, token } = useGetToken(TOKEN_PREFIX);
  const { isWishList } = useSelector(getProfileState);

  const refWishList = useRef();

  // const offset = useOffSetTop(5, {
  //   container: refWishList,
  // });

  useEffect(() => {
    if (product?.uid) {
      const credentials = { productId: product?.uid };
      dispatch(fetchIsWishlistCheck(credentials));
    }
  }, [dispatch, product?.uid]);

  const [selectedSlide, setSelectedSlide] = useState(null);

  const slides = [
    ...(product?.images?.map((img) => ({
      src: img ?? {},
    })) || []),
    {
      src: {
        public_id: product?.video_id ?? '',
        url: product?.video_id ?? '',
      },
      isVideo: Boolean(product?.video_id),
    },
  ];

  const totalActiveSlideItemLength = slides?.filter((item) => item.isVideo !== false)?.length;
  const activeSlideItemList = slides?.filter((item) => item.isVideo !== false);

  const lightbox = useLightBox(activeSlideItemList);

  const carouselLarge = useCarousel({
    rtl: theme.direction === 'rtl',
    draggable: false,
    adaptiveHeight: true,
  });

  const carouselThumb = useCarousel({
    rtl: theme.direction === 'rtl',
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: totalActiveSlideItemLength > 5 ? 5 : totalActiveSlideItemLength,
  });

  useEffect(() => {
    carouselLarge.onSetNav();
    carouselThumb.onSetNav();
  }, [carouselLarge, carouselThumb]);

  useEffect(() => {
    if (lightbox.open) {
      const selectedIndex = lightbox.selected;
      const correctedIndex =
        theme.direction === 'rtl' ? totalActiveSlideItemLength - 1 - selectedIndex : selectedIndex;

      carouselLarge.onTogo(correctedIndex);
    }
  }, [
    carouselLarge,
    lightbox.open,
    lightbox.selected,
    totalActiveSlideItemLength,
    theme.direction,
  ]);

  const handleWishList = (operation) => {
    if (isSuccess && token) {
      const credentials = {
        state: {
          product: product?.uid,
          operation,
        },
        enqueueSnackbar,
        dispatch,
      };
      dispatch(fetchWishListUpdate(credentials));
    } else {
      dispatch(triggerLoginPopup({ state: true }));
    }
  };

  const handleThumbnailClick = (index) => {
    setSelectedSlide(index);
  };

  const renderLargeImg = (
    <Box
      sx={{
        mb: 3,
        p: isMobile ? 2 : 0,
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
      }}
      ref={refWishList}
    >
      <Carousel
        {...carouselLarge.carouselSettings}
        asNavFor={carouselThumb.nav}
        ref={carouselLarge.carouselRef}
      >
        {activeSlideItemList?.map((slide, key) => (
          <Fragment key={key}>
            {slide?.isVideo && selectedSlide === key ? (
              <div
                style={{
                  width: '100%',
                  height: '540px',
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '10px',
                }}
                className="product-detail-vimeo-view-box"
              >
                <ReactPlayer
                  url={`https://www.youtube.com/watch?v=${slide?.src?.url}`}
                  playing
                  loop
                  muted
                  controls={false}
                  width="100%"
                  height="100%"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    pointerEvents: 'none',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ) : (
              <ProductDetailsCarouselImage slide={slide} lightbox={lightbox} />
            )}
          </Fragment>
        ))}
      </Carousel>

      <CarouselArrowIndex
        index={carouselLarge.currentIndex}
        total={totalActiveSlideItemLength}
        onNext={carouselThumb.onNext}
        onPrev={carouselThumb.onPrev}
      />

      <Stack
        sx={{
          position: 'absolute',
          right: isMobile ? '19px' : '10px',
          top: isMobile ? '10px' : '10px',
        }}
      >
        {isWishList?.is_wishlisted ? (
          <IconButton sx={{ backgroundColor: '#fff' }} onClick={() => handleWishList('REMOVE')}>
            <FavoriteIcon fontSize="large" color="error" />
          </IconButton>
        ) : (
          <IconButton sx={{ backgroundColor: '#fff' }} onClick={() => handleWishList('ADD')}>
            <FavoriteBorderIcon fontSize="large" color="disabled" />
          </IconButton>
        )}

        {isMobile && (
          <IconButton sx={{}}>
            <Iconify icon="ooui:share" width={16} />
          </IconButton>
        )}
      </Stack>

      <Stack
        sx={{
          position: 'absolute',
          left: isMobile ? '15px' : '10px',
          top: isMobile ? '15px' : '10px',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'start',
          flexDirection: 'row',
        }}
        spacing={1}
      >
        {/* // label group 2 */}
        {productLabelTwo && (
          <Label
            variant="filled"
            color="default"
            sx={{
              borderRadius: '6px',
              padding: '6px 12px',
              height: isMobile ? '25px' : '27px',
              with: 'fit-content',
              background: '#3097AE',
            }}
            className="width-fit-content"
          >
            <TrendingUpRoundedIcon
              fontSize="small"
              sx={{
                mr: 0.6,
                height: isMobile ? '15px' : '20px',
                width: isMobile ? '15px' : '20px',
              }}
            />
            <Typography sx={{ fontSize: isMobile && '12px' }} variant="subtitle2">
              {productLabelTwo}
            </Typography>
          </Label>
        )}

        {/* // label group 1 */}
        {productLabelOne && (
          <Label
            variant="filled"
            color="default"
            sx={{
              background: '#FF0000',
              borderRadius: '6px',
              padding: '6px 12px',
              height: isMobile ? '25px' : '27px',
              with: 'fit-content',
            }}
          >
            <Typography sx={{ fontSize: isMobile && '12px' }} variant="subtitle2">
              {productLabelOne}
            </Typography>
          </Label>
        )}
      </Stack>
    </Box>
  );

  const renderThumbnails = (
    <StyledThumbnailsContainer length={totalActiveSlideItemLength}>
      <Carousel
        {...carouselThumb.carouselSettings}
        asNavFor={carouselLarge.nav}
        ref={carouselThumb.carouselRef}
        vertical
        className="custom-product-thumb-right-box"
        slidesToShow={totalActiveSlideItemLength > 5 ? 5 : totalActiveSlideItemLength}
        verticalSwiping
      >
        {activeSlideItemList?.map((item, index) => (
          <Fragment key={index}>
            {item?.isVideo === true && item?.src?.url ? (
              <Box
                sx={{ px: 0.5, position: 'relative' }}
                mb={2}
                onClick={() => handleThumbnailClick(index)}
              >
                <Avatar
                  alt="alt"
                  src={`https://img.youtube.com/vi/${item?.src?.url || ''}/maxresdefault.jpg`}
                  variant="rounded"
                  className="custom-avatar"
                  sx={{
                    width: THUMB_SIZE,
                    height: THUMB_SIZE,
                    opacity: 1,
                    cursor: 'pointer',
                    ...(carouselLarge.currentIndex === index && {
                      opacity: 1,
                      border: `solid 2.5px ${theme.palette.primary.main}`,
                    }),
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <PlayCircleIcon sx={{ color: '#cb0001' }} />
                </Box>
              </Box>
            ) : (
              <Box key={item?.src?.public_id} sx={{ px: 0.5 }} mb={2}>
                <ThumbImageComp
                  item={item}
                  carouselLarge={carouselLarge}
                  index={index}
                  theme={theme}
                />
              </Box>
            )}
          </Fragment>
        ))}
      </Carousel>
    </StyledThumbnailsContainer>
  );

  return (
    <Box
      sx={{
        '& .slick-slide': {
          float: theme.direction === 'rtl' ? 'right' : 'left',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {isNonMobile && renderThumbnails}

      {renderLargeImg}

      <Lightbox
        index={lightbox.selected}
        slides={activeSlideItemList}
        open={lightbox.open}
        close={lightbox.onClose}
        onGetCurrentIndex={(index) => lightbox.setSelected(index)}
      />
    </Box>
  );
}

ProductDetailsCarousel.propTypes = {
  product: PropTypes.object,
  productLabelTwo: PropTypes.any,
  productLabelOne: PropTypes.any,
};

const ProductDetailsCarouselImage = ({ slide, lightbox }) => {
  const { isMobile } = useScreenWidth();
  const transformedImage = useImageTransformation(slide?.src?.url, 'w_600');
  return (
    <Image
      // priority
      key={slide?.scr?.public_id}
      alt={useTranslatedText(slide.src?.alt_text)}
      src={transformedImage}
      onClick={() => lightbox.onOpen(slide?.src)}
      sx={{
        cursor: 'zoom-in',
        borderRadius: !isMobile && '15px',
        border: !isMobile && '1px solid #e9e9e9',
      }}
      imageStyle={{
        aspectRatio: 1 / 1,
        objectFit: 'contain',
      }}
    />
  );
};

ProductDetailsCarouselImage.propTypes = {
  slide: PropTypes.object,
  lightbox: PropTypes.any,
};

const ThumbImageComp = ({ item, carouselLarge, index, theme }) => {
  const transformedImage = useImageTransformation(item?.src?.url, 'w_100');

  return (
    <Avatar
      key={item?.src?.public_id}
      alt="alt"
      src={transformedImage}
      variant="rounded"
      className="custom-avatar"
      sx={{
        width: THUMB_SIZE,
        height: THUMB_SIZE,
        opacity: 1,
        cursor: 'pointer',
        ...(carouselLarge.currentIndex === index && {
          opacity: 1,
          border: `solid 2.5px ${theme.palette.primary.main}`,
        }),
      }}
    />
  );
};

ThumbImageComp.propTypes = {
  item: PropTypes.object,
  carouselLarge: PropTypes.any,
  index: PropTypes.any,
  theme: PropTypes.any,
};
