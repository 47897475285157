import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import { MenuItem } from '@mui/material';
import Button from '@mui/material/Button';

import useLocalizedText from 'src/hooks/useLocalizedText';

import { getProductState } from 'src/server/selectors/selector';
import { productFilterObjectState } from 'src/server/slice/productSlice';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------
export const PRODUCT_SORT_OPTIONS = [
  { value: 'featured', label: 'featured' },
  { value: 'new', label: 'new' },
  { value: 'lowToHigh', label: 'low_to_high' },
  { value: 'highToLow', label: 'high_to_low' },
];

export default function ProductSort() {
  const popover = usePopover();
  const dispatch = useDispatch();
  const getText = useLocalizedText();

  const { productFilterObject } = useSelector(getProductState);

  const handleConvertSortToObj = (value) => {
    let str = '';
    if (value?.price === 1) str = 'lowToHigh';
    if (value?.price === -1) str = 'highToLow';
    if (value?.createdAt === -1) str = 'new';
    if (value?.is_featured === -1) str = 'featured';

    return str;
  };

  const sort = handleConvertSortToObj(productFilterObject?.sort_data);

  const handleSortOptions = (value) => {
    switch (value) {
      case 'lowToHigh':
        return {
          price: 1,
        };
      case 'highToLow':
        return {
          price: -1,
        };

      case 'new':
        return {
          createdAt: -1,
        };

      case 'featured':
        return {
          is_featured: -1,
        };

      default:
        return {};
    }
  };

  const handleSortBy = useCallback(
    (newValue) => {
      const currentSort = handleSortOptions(newValue);
      dispatch(
        productFilterObjectState({
          sort_data: currentSort,
        })
      );
    },
    [dispatch]
  );

  const sortLabel = PRODUCT_SORT_OPTIONS.find((option) => option.value === sort)?.label;

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        disableRipple
        color="inherit"
        onClick={popover.onOpen}
        endIcon={
          <Iconify
            icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        }
        sx={{ fontWeight: 'fontWeightSemiBold' }}
      >
        {getText('sort_by')}:
        <Box component="span" sx={{ ml: 0.5, fontWeight: 'fontWeightBold' }}>
          {getText(sortLabel)}
        </Box>
      </Button>

      <CustomPopover
        anchorEl={popover.anchorEl}
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 140 }}
      >
        {PRODUCT_SORT_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === sort}
            onClick={() => {
              popover.onClose();
              handleSortBy(option.value);
            }}
          >
            {getText(option.label)}
          </MenuItem>
        ))}
      </CustomPopover>
    </Box>
  );
}
