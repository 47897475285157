import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Card,
  Radio,
  Button,
  RadioGroup,
  CardHeader,
  Typography,
  FormControl,
  FormControlLabel,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { colors } from 'src/utils/colors';

import { getOrderState } from 'src/server/selectors/selector';
import { createOrder, updateOrder, handleOrderLocalData } from 'src/server/slice/cartOrderSlice';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form';

import CheckoutSummary from '../checkout-summary';

export default function CheckoutPayment({ onBackStep, dataOption }) {
  const checkout = {
    totalItems: 20,
    items: [],
    total: 3000,
    discount: 300,
    subTotal: 3030,
  };
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const route = useRouter();
  const { isMobile } = useScreenWidth();
  const getText = useLocalizedText();

  const { localState } = useSelector(getOrderState);

  const PaymentSchema = Yup.object().shape({
    payment_type: Yup.string(),
  });

  const defaultValues = {
    delivery: checkout.shipping,
    payment_type: '',
  };

  const methods = useForm({
    resolver: yupResolver(PaymentSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const payment_type = localState?.payment_type || 'COD';
  const payment_type = 'COD';
  const deliveryType = localState?.delivery_type;
  const pickupLocation = localState?.pickup_location;

  const customLoading = useBoolean(false);

  const onSubmit = handleSubmit(async (data) => {
    customLoading.onTrue();
    const credentials = {
      state: {
        ...dataOption,
        payment_type,
        delivery_type: deliveryType,
        order_type: 'DIRECT',
        pickup_location: pickupLocation?.uid,
      },
      customNotification: enqueueSnackbar,
      route,
      customLoading,
    };
    dispatch(createOrder(credentials));
  });

  const handleChangePayment = (value) => {
    const credentials = {
      state: {
        ...localState,
        payment_type: value,
        order_type: 'DIRECT',
      },
    };
    dispatch(updateOrder(credentials));
    dispatch(handleOrderLocalData(credentials));
  };

  // useEffect(() => {
  //   const credentials = {
  //     state: {
  //       ...localState,
  //       payment_type,
  //       order_type: 'DIRECT',
  //     },
  //   };
  //   dispatch(updateOrder(credentials));
  //   dispatch(handleOrderLocalData(credentials));
  // }, [dispatch, localState]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          {isMobile && (
            <Grid xs={12} md={4}>
              <CheckoutSummary notShowShipping />
            </Grid>
          )}
          <Card sx={{ mb: 3, mt: { sm: 2, md: 0 } }}>
            <CardHeader title={getText('payment')} />

            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="shipping-method-options"
                sx={{ flexDirection: 'column', padding: 2, px: 4 }}
                value={payment_type}
                onChange={(e) => handleChangePayment(e.target.value)}
              >
                {/* <FormControlLabel
                  value="ONLINE"
                  control={<Radio />}
                  label={getText('online_payment')}
                /> */}

                <FormControlLabel
                  value="COD"
                  control={<Radio />}
                  label={getText('cash_on_delivery')}
                />
              </RadioGroup>
            </FormControl>
          </Card>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {!isMobile && (
              <Button
                size="small"
                color="inherit"
                onClick={onBackStep}
                startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
              >
                {getText('back')}
              </Button>
            )}

            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              sx={{
                px: 8,
                borderRadius: '4px',
                backgroundColor: colors.themePrimary,
                '&:hover': { backgroundColor: '#1968e9' },
              }}
              loading={isSubmitting || customLoading.value}
              className={isMobile && 'checkout-mobile-button'}
            >
              {getText('complete_order')}
            </LoadingButton>
          </Stack>
          <Stack justifyContent="end" alignItems="end" mt={0.5}>
            <Typography variant="caption" color="gray">
              {customLoading.value && getText('gathering_your_order_summary_within_a_few_minuites')}
            </Typography>
          </Stack>
        </Grid>

        {!isMobile && (
          <Grid xs={12} md={4}>
            <CheckoutSummary notShowShipping />
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}

CheckoutPayment.propTypes = {
  onBackStep: PropTypes.any,
  dataOption: PropTypes.any,
};
