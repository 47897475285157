import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { useState, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import BoltIcon from '@mui/icons-material/Bolt';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import {
  Box,
  Paper,
  Table,
  Rating,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  Accordion,
  Typography,
  TableContainer,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { paths } from 'src/routes/paths';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { colors } from 'src/utils/colors';
import { fShortenNumber } from 'src/utils/format-number';

import { useLocales } from 'src/locales';
import { useCurrency } from 'src/providers/CurrencyProvider';
import { getProductState, getProductDetailsSate } from 'src/server/selectors/selector';
import { fetchProductDetails, fetchRelatedProduct } from 'src/server/slice/productSlice';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import useHandleCart from 'src/sections/cart/hooks/useHandleCart';
import ShippingLocation from 'src/sections/cart/shipping-location';

import ProductGroupWrapper from './product-group-wrapper';
import IncrementorButton from '../common/incrementer-button';
import { product_label_layouts } from '../cards/product-card-one';
import RelatedProductCarousel from '../cards/related-product-card';
import ProductDetailsReview from '../review/product-details-review';
import { ProductDetailsSkeleton } from '../common/product-skeleton';
import ProductDetailsCarousel from '../single/product-details-carousel';
import ProductDetailsDescription from '../common/product-details-description';

export default function ProductShopDetailsView({ id }) {
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  const { currency } = useCurrency();
  const getText = useLocalizedText();
  const rtl = useLayoutStyle();
  const { isMobile, screenWidth } = useScreenWidth();
  const { product: serverProduct, detailLoading } = useSelector(getProductDetailsSate);

  const { currentLang } = useLocales();

  const { relatedProduct } = useSelector(getProductState);

  const product = serverProduct;

  // api call
  useEffect(() => {
    if (id) {
      const credentials = { productName: id };
      dispatch(fetchProductDetails(credentials));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (product?.uid) {
      const credentials = { productId: product?.uid };
      dispatch(fetchRelatedProduct(credentials));
    }
  }, [dispatch, product?.uid]);

  const translatedProductName = useTranslatedText(product?.name ? product?.name : '');
  const translatedProductDescription = useTranslatedText(product?.description);
  const translatedWarrantyNote = useTranslatedText(product?.warranty_note);
  const translatedExchangeNote = useTranslatedText(product?.exchange_policy_note);

  const { discountPercentage, offerPrice } = useProductOfferPriceCalculator(product);

  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);

  const currencyConversionPrice = useCurrencyConversion(product?.price);

  const productLabels = product_label_layouts?.map((layout) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTranslatedText(product?.product_labels?.find((i) => i.layout === layout)?.title)
  );

  const [productLabelOne, productLabelTwo, productLabelThree, productLabelFour, productLabelFive] =
    productLabels;

  const specifications =
    currentLang?.value === 'ar'
      ? product?.specifications?.arabic
      : product?.specifications?.english;

  const [isExpanded, setIsExpanded] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);

  const handleReadMoreClick = () => {
    if (isExpanded) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  };

  const translateCurrencyText = useTranslatedText(currency?.title);

  const renderSkeleton = <ProductDetailsSkeleton />;

  const isOutOfStock = product?.stock <= 0;
  const isLowStock = product?.stock <= 5 && product?.stock !== 0;

  // cart functions
  const { handleAddCart } = useHandleCart({ product });

  const handleAddToCart = (action) => {
    handleAddCart(quantity, action);
    if (action === 'buy' && !isOutOfStock) {
      setBuyLoading(true);
    }
  };

  useEffect(() => {
    let timeoutId;

    if (buyLoading) {
      timeoutId = setTimeout(() => {
        setBuyLoading(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [buyLoading]);

  const renderInventoryType = (
    <Box
      component="span"
      sx={{
        whiteSpace: 'nowrap',
        typography: 'overline',
        color: (isOutOfStock && 'error.main') || (isLowStock && 'warning.main') || 'success.main',
      }}
    >
      In Stock
    </Box>
  );

  const [quantity, setQuantity] = useState(1);

  const renderQuantity = (
    <Stack direction="row" alignItems="center" width="fit-content" spacing={3} mt={2.5}>
      <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
        {getText('quantity')}
      </Typography>

      <Stack
        spacing={1}
        sx={{
          backgroundColor: '#EEF0F4',
          borderRadius: '8px',
        }}
      >
        <IncrementorButton
          name="quantity"
          quantity={quantity}
          disabledDecrease={quantity <= 1}
          disabledIncrease={quantity >= product?.stock}
          onIncrease={() => setQuantity(quantity + 1)}
          onDecrease={() => setQuantity(quantity - 1)}
          sx={{
            padding: isMobile ? '2px' : '4px',
          }}
        />
      </Stack>

      <Stack sx={{ flexWrap: 'wrap', width: '100%' }}>
        {product?.stock <= 0 ? (
          <Typography variant="caption" color="#ff0000" sx={{ whiteSpace: 'nowrap' }}>
            {getText('out_of_stock')}
          </Typography>
        ) : (
          <>
            {product?.stock < 10 && product?.stock !== 0 && (
              <Typography variant="caption" color="#1F59B8" sx={{ whiteSpace: 'nowrap' }}>
                {getText('only')} {product?.stock} {getText('left_in_stock')}
              </Typography>
            )}
          </>
        )}
        {product?.stock > 10 && renderInventoryType}
      </Stack>
    </Stack>
  );

  const descriptionVariants = {
    expanded: { height: 'auto', opacity: 1 },
    collapsed: { height: isMobile ? 100 : 100, opacity: 0.8 },
  };

  const renderSubDescription = (
    <Typography component="div" variant="body2" sx={{}}>
      <m.div
        initial={false}
        animate={isExpanded ? 'expanded' : 'collapsed'}
        variants={descriptionVariants}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        style={{ overflow: 'hidden' }}
      >
        {parse(translatedProductDescription)}
      </m.div>

      {translatedProductDescription?.length >= 250 && (
        <Button
          color="inherit"
          variant="text"
          onClick={handleReadMoreClick}
          sx={{
            color: '#1F59B8',
            fontWeight: 400,
            p: 0,
            background: 'transparent',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          {!isExpanded ? getText('read_more') : getText('read_less')}
        </Button>
      )}
    </Typography>
  );

  const childVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: (index) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: index * 0.5,
        duration: 0.5,
        // ease: 'easeInOut',
        type: 'spring',
        stiffness: 100,
        damping: 10,
      },
    }),
  };

  const renderPriceBox = (
    <Stack sx={{ flexDirection: 'row', alignItems: 'end' }} spacing={screenWidth < 600 ? 0.5 : 1}>
      <m.div variants={childVariants} animate="visible" initial="hidden" custom={0}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 400,
            fontSize: isMobile ? '16px' : '16px',
            // marginBottom: isMobile ? '-4px' : '-2px',
            lineHeight: '22px',
          }}
        >
          {translateCurrencyText}
        </Typography>
      </m.div>

      <m.div variants={childVariants} initial="hidden" animate="visible" custom={2}>
        <Typography
          variant="h4"
          sx={{
            fontSize: isMobile && '22px',
            // marginTop: isMobile && '-5px',
            // marginBottom: '-5px',
            lineHeight: isMobile ? '25px' : '28px',
          }}
        >
          {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
        </Typography>
      </m.div>

      {offerPrice && offerPrice < product?.price && (
        <m.div variants={childVariants} initial="hidden" animate="visible" custom={4}>
          <Box
            // component="span"
            sx={{
              color: 'text.black',
              textDecoration: 'line-through',
              mr: 0.5,
              lineHeight: 'normal',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: isMobile && '14px',
              }}
            >
              {currencyConversionPrice}
            </Typography>
          </Box>
        </m.div>
      )}

      {discountPercentage && (
        <m.div variants={childVariants} initial="hidden" animate="visible" custom={5}>
          <Typography mx={1} variant="subtitle1" sx={{ color: colors.themeGreen, fontWeight: 700 }}>
            {discountPercentage}% {getText('off')}
          </Typography>
        </m.div>
      )}

      {/* productLabelFive */}
      {productLabelFive && (
        <Label
          variant="soft"
          color="success"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            padding: '8px 12px',
            height: isMobile ? '25px' : '28px',

            color: '#26883A',
          }}
          className="width-fit-content"
        >
          <Iconify icon="tabler:clock-down" width={15} sx={{ mr: 1 }} />
          <Typography variant="subtitle2" sx={{ fontSize: isMobile && '12px' }}>
            {productLabelFive}
          </Typography>
        </Label>
      )}
    </Stack>
  );

  const renderProductStickyEle = product?.uid && (
    <Grid
      container
      spacing={{ xs: 3, md: 5, lg: 8 }}
      sx={{ margin: 'unset', position: 'relative' }}
    >
      <Grid
        xs={12}
        md={6}
        lg={6}
        sx={{
          position: screenWidth > 900 && 'sticky',
          top: '80px',
          height: '100%',
          p: isMobile && 0,
          mb: screenWidth < 900 ? 5 : 0,
        }}
      >
        <ProductDetailsCarousel
          product={product}
          productLabelOne={productLabelOne}
          productLabelTwo={productLabelTwo}
        />

        <ProductActionButtons
          handleAddToCart={handleAddToCart}
          getText={getText}
          buyLoading={buyLoading}
        />
      </Grid>

      <Grid xs={12} md={6} lg={6} sx={{ pt: 0 }}>
        {!isMobile && ProductBreadcrumb(product, getText, rtl)}

        <FormProvider>
          <Stack spacing={3} sx={{ pt: 1 }}>
            <Stack spacing={2} alignItems="flex-start">
              <Box sx={{ width: '100%' }}>
                <Typography
                  variant="h5"
                  component="h1"
                  sx={{
                    fontSize: isMobile ? '16px' : '18px',
                    fontWeight: 400,
                    mb: isMobile ? 0 : 0.5,
                  }}
                >
                  {translatedProductName}
                </Typography>

                {/* {product?.brands?.map((item, key) => ( */}
                <ProductBrands product={product?.brands[0]} />
                {/* ))} */}

                {(product?.average_rating || productLabelThree) && (
                  <Stack direction="column" alignItems="start" spacing={0}>
                    {product?.average_rating ? (
                      <Stack>
                        <Label
                          variant="soft"
                          color="default"
                          sx={{
                            padding: '8px 0px',
                            paddingTop: 0,
                            borderRadius: '20px',
                            display: 'flex',
                            height: '32px',
                            gap: 1,
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            with: 'fit-content',
                            background: 'transparent',
                          }}
                          className="width-fit-content"
                        >
                          <Typography variant="body2" sx={{ color: 'black' }}>
                            {product?.average_rating?.toFixed(1)}
                          </Typography>
                          <Rating
                            size="small"
                            value={product?.average_rating}
                            precision={0.1}
                            readOnly
                            sx={{ mr: 1 }}
                          />
                          <Typography variant="body2" sx={{ color: colors.themePrimary }}>
                            {`${fShortenNumber(product?.review_count)} ratings`}
                          </Typography>
                        </Label>
                      </Stack>
                    ) : (
                      ''
                    )}

                    {productLabelThree && (
                      <Label
                        variant="soft"
                        color="info"
                        sx={{
                          padding: '8px 12px',
                          with: 'fit-content',
                          height: isMobile ? '30px' : '32px',
                          backgroundColor: '#DDEAFF',
                          color: colors.themePrimary,
                        }}
                        className="width-fit-content"
                      >
                        <AutoAwesomeRoundedIcon
                          fontSize="small"
                          sx={{ mr: 0.6, height: '15px', width: '15px' }}
                        />
                        <Typography variant="subtitle2" sx={{ fontSize: isMobile && '12px' }}>
                          {productLabelThree}
                        </Typography>
                      </Label>
                    )}
                  </Stack>
                )}

                <Divider sx={{ my: 1.3, width: '80%' }} />
              </Box>

              <Stack spacing={0.5}>
                {/* // label group 4 */}
                {productLabelFour && (
                  <Label
                    variant="soft"
                    color="error"
                    sx={{
                      with: 'fit-content',
                      color: '#ff0000',
                      padding: '8px 12px',
                      height: isMobile ? '25px' : '28px',
                      marginBottom: '5px',
                    }}
                    className="width-fit-content"
                  >
                    <LocalFireDepartmentRoundedIcon
                      fontSize="small"
                      sx={{
                        mr: 0.6,
                        height: isMobile ? '15px' : '20px',
                        width: isMobile ? '15px' : '20px',
                      }}
                    />
                    <Typography variant="subtitle2" sx={{ fontSize: isMobile && '12px' }}>
                      {productLabelFour}
                    </Typography>
                  </Label>
                )}

                {renderPriceBox}

                {/* <Stack
                  sx={{ flexDirection: 'row', alignItems: 'center' }}
                  spacing={screenWidth < 600 ? 0.5 : 1}
                >
                  <Typography variant="h4" sx={{ fontWeight: 400, fontSize: isMobile && '16px' }}>
                    {translateCurrencyText}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: isMobile && '22px',
                      marginTop: isMobile && '-5px',
                    }}
                  >
                    {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
                  </Typography>

                  {offerPrice && offerPrice < product?.price && (
                    <Box
                      component="span"
                      sx={{
                        color: 'text.disabled',
                        textDecoration: 'line-through',
                        mr: 0.5,
                        lineHeight: 'normal',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: isMobile && '14px',
                        }}
                      >
                        {currencyConversionPrice}
                      </Typography>
                    </Box>
                  )}

                  {discountPercentage && (
                    <Typography mx={1} variant="subtitle1" color="#26883A" sx={{ fontWeight: 700 }}>
                      {discountPercentage}% {getText('off')}
                    </Typography>
                  )}
                </Stack> */}

                {product?.is_free_delivery_product ? (
                  <Typography
                    mt={0.4}
                    variant="subtitle2"
                    color="#26883A"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 2px',
                      fontWeight: 600,
                    }}
                  >
                    <Iconify
                      icon="la:shipping-fast"
                      sx={{ mr: 1.2, height: '18px', width: '18px' }}
                    />

                    {getText('free_delivery')}
                  </Typography>
                ) : (
                  <ShippingLocation weighCategory={product?.weight_category} />
                )}
              </Stack>

              <Stack sx={{ width: '80%' }}>
                {renderQuantity}
                {/* <Divider sx={{ my: '16px' }} />
                {productVariants(`${getText('size')}`)}
                {screenWidth > 600 ? <Divider sx={{ my: '16px' }} /> : <Box sx={{ my: '8px' }} />}
                {productVariants(`${getText('width')}`)}
                {screenWidth > 600 ? <Divider sx={{ my: '16px' }} /> : <Box />} */}
              </Stack>

              {/* <Stack sx={{ width: '100%' }}>
                <ShippingFee isFullWidth />
              </Stack> */}

              {renderSubDescription}
            </Stack>
          </Stack>
        </FormProvider>
      </Grid>
    </Grid>
  );

  const renderProduct = product?.uid && (
    <Grid container spacing={{ xs: 3, md: 5, lg: 8 }} sx={{ margin: 'unset' }}>
      {product?.stock > 0 && <ProductGroupWrapper product={product} />}
      {isMobile ? (
        <Box
          sx={{
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          {specifications?.length > 0 && (
            <Accordion
              defaultExpanded
              sx={{
                m: 0,
              }}
              className="product-specification-box"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography
                  variant="subtitle1"
                  component="h2"
                  gutterBottom
                  sx={{ fontWeight: 700 }}
                >
                  {getText('specifications')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {ProductSpecificationTable(product, getText, specifications)}
              </AccordionDetails>
            </Accordion>
          )}

          {translatedWarrantyNote && (
            <Accordion
              sx={{
                m: 0,
              }}
              className="product-specification-box"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
                component="h2"
              >
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 700 }}>
                  {getText('warranty_policy')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProductDetailsDescription
                  textColor="#7e7e7e"
                  description={translatedWarrantyNote}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {translatedExchangeNote && (
            <Accordion
              sx={{
                m: 0,
              }}
              className="product-specification-box"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5-content"
                id="panel5-header"
                component="h2"
              >
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 700 }}>
                  {getText('exchange_policy')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProductDetailsDescription
                  textColor="#7e7e7e"
                  description={translatedExchangeNote}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      ) : (
        <Grid container sx={{ width: '100%', margin: 0 }} spacing={{ xs: 3, md: 5, lg: 8 }}>
          {(product?.dimension || product?.weight || specifications?.length > 0) && (
            <Grid xs={12} md={6} lg={6}>
              <Typography variant="h6" gutterBottom component="h2">
                {getText('specifications')}
              </Typography>
              {ProductSpecificationTable(product, getText, specifications)}
            </Grid>
          )}

          <Grid xs={12} md={6} lg={6} mt={0}>
            {(translatedWarrantyNote || translatedWarrantyNote) && (
              <Stack spacing={6} flexDirection="row" alignItems="center">
                <Divider
                  sx={{
                    width: '2.529px',
                    height: '80.705px',
                    background: '#d9d9d9',
                  }}
                />
                <Stack>
                  <Stack>
                    {translatedWarrantyNote && (
                      <Box>
                        <Typography variant="h6">{getText('warranty_policy')}</Typography>
                        <ProductDetailsDescription
                          textColor="#7e7e7e"
                          description={translatedWarrantyNote}
                        />
                      </Box>
                    )}
                  </Stack>
                  <Stack>
                    {translatedExchangeNote && (
                      <Box>
                        <Typography variant="h6">{getText('exchange_policy')}</Typography>
                        <ProductDetailsDescription
                          textColor="#7e7e7e"
                          description={translatedExchangeNote}
                        />
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}

      {product?.uid && (
        <ProductDetailsReview
          totalRatings={product?.average_rating}
          totalReviews={product?.review_count}
          productId={product?.uid}
        />
      )}

      {relatedProduct?.related_products?.length > 0 && (
        <Grid xs={12} md={12} lg={12} mt={0}>
          <Typography
            variant="h6"
            component="h2"
            mb={3}
            sx={{ position: 'relative', fontSize: isMobile && '16px' }}
          >
            {getText('similar_items')}
            <Divider sx={{ mb: 2, mt: 1 }} />
            <Box
              sx={{
                backgroundColor: colors.themeSecondary,
                height: 2,
                width: 150,
                position: 'absolute',
                bottom: 0,
              }}
            />
          </Typography>
          <RelatedProductCarousel products={relatedProduct?.related_products} />
        </Grid>
      )}
    </Grid>
  );

  const handleScreenWidth = () => {
    if (screenWidth < 600) {
      return 0;
    }
    if (screenWidth < 900) {
      return 20;
    }
    if (screenWidth < 1200) {
      return 12;
    }

    return 2;
  };

  return (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mt: handleScreenWidth(),
        mb: 20,
        p: isMobile && 0,
      }}
    >
      {(detailLoading || !product?.uid) && renderSkeleton}
      {renderProductStickyEle}
      {renderProduct}
    </Container>
  );
}

ProductShopDetailsView.propTypes = {
  id: PropTypes.string,
};

function ProductBreadcrumb(product, getText, rtl) {
  const translatedCategoryName = useTranslatedText(
    product?.categories ? product?.categories[0]?.name : ''
  );
  // const getText = useLocalizedText();

  const categoryUrlLink =
    product?.categories?.length > 0
      ? {
          url: product?.categories[0]?.name?.english?.replace(/ /g, '-'),
          uid: product?.categories[0]?.uid,
        }
      : '';

  return (
    <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="start">
      <CustomBreadcrumbs
        links={[
          { name: `${getText('home')}`, href: '/' },
          {
            name: translatedCategoryName,
            href: paths.public.categoryView(categoryUrlLink.url),
          },
          // {
          //   name: `${getText('products')}`,
          //   href: paths.public.products,
          // },
        ]}
        activeLast
        sx={{ mb: 0 }}
      />
      {/* <Stack direction="row" spacing={3} justifyContent="center">
        <OspLink
          variant="subtitle2"
          sx={{
            // color: 'text.secondary',
            display: 'inline-flex',
            alignItems: 'center',
            flexDirection: rtl ? 'row-reverse' : 'row',
          }}
          href={paths.public.home}
        >
          <Iconify icon={rtl ? 'bxs:share' : 'ooui:share'} width={16} sx={{ mr: 1 }} />
          {getText('share')}
        </OspLink>
      </Stack> */}
    </Stack>
  );
}

const ProductActionButtons = ({ handleAddToCart, getText, buyLoading }) => (
  <Stack
    direction="row"
    spacing={0}
    width="100%"
    alignItems="center"
    justifyContent="space-between"
  >
    <Box width="150px" />
    <Stack direction="row" spacing={2} width="100%" className="product-detail-action-button-group">
      <Button
        fullWidth
        // disabled={}
        size="large"
        color="inherit"
        variant="outlined"
        startIcon={<Iconify icon="solar:cart-plus-bold" width={24} />}
        onClick={() => handleAddToCart()}
        sx={{
          whiteSpace: 'nowrap',
          backgroundColor: 'transparent',
          '&:hover': { backgroundColor: '#e4e4e4' },
          borderRadius: '8px',
          border: `1px solid ${colors.themePrimary}`,
          color: colors.themePrimary,
        }}
      >
        {getText('add_to_cart')}
      </Button>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={buyLoading}
        onClick={() => handleAddToCart('buy')}
        startIcon={<BoltIcon />}
        sx={{
          backgroundColor: colors.themePrimary,
          color: 'white',
          '&:hover': { backgroundColor: colors.themePrimary },
          borderRadius: '8px',
        }}
      >
        {getText('buy_now')}
      </LoadingButton>
    </Stack>
  </Stack>
);

ProductActionButtons.propTypes = {
  handleAddToCart: PropTypes.any,
  getText: PropTypes.any,
  buyLoading: PropTypes.any,
};

const ProductBrands = ({ product }) => {
  const translatedBrandText = useTranslatedText(product?.name);

  return (
    <OspLink
      href={paths.public.brandView(product?.name?.english.replace(/ /g, '-')) || ''}
      variant="body2"
      target="_blank"
      rel="noopener"
      sx={{ display: 'table' }}
    >
      <Typography variant="overline">
        {translatedBrandText}
        &nbsp;
      </Typography>
    </OspLink>
  );
};

ProductBrands.propTypes = {
  product: PropTypes.any,
};

function ProductSpecificationTable(product, getText, specifications) {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table sx={{ borderCollapse: 'collapse' }}>
        <TableBody>
          {product?.dimension ? (
            <TableRow className="stand-up product-specification-table">
              <TableCell
                component="th"
                scope="row"
                sx={{ width: '200px', border: 'none', height: '45px', py: 1 }}
              >
                <Typography variant="body2">{getText('dimension')}</Typography>
              </TableCell>
              <TableCell
                className="table-column-dot"
                sx={{ width: '50px', border: 'none', height: '45px', py: 1 }}
              >
                :
              </TableCell>
              <TableCell sx={{ border: 'none', height: '45px', py: 1 }}>
                <Typography variant="body2">{product?.dimension}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            ''
          )}

          {product?.weight ? (
            <TableRow className="stand-up product-specification-table">
              <TableCell sx={{ border: 'none', height: '45px', py: 1 }} component="th" scope="row">
                <Typography variant="body2">{getText('weight')}</Typography>
              </TableCell>
              <TableCell
                sx={{ border: 'none', height: '45px', py: 1 }}
                className="table-column-dot"
              >
                :
              </TableCell>
              <TableCell sx={{ border: 'none', height: '45px', py: 1 }}>
                <Typography variant="body2">{product?.weight}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            ''
          )}

          {specifications?.map((item, key) => (
            <TableRow key={key} className="stand-up product-specification-table">
              <TableCell sx={{ border: 'none', height: '45px', py: 1 }} component="th" scope="row">
                <Typography variant="body2">{item?.title}</Typography>
              </TableCell>
              <TableCell
                sx={{ border: 'none', height: '45px', py: 1 }}
                className="table-column-dot"
              >
                :
              </TableCell>
              <TableCell sx={{ border: 'none', height: '45px', py: 1 }}>
                <Typography variant="body2">{item?.value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// const productVariants = (variant) => (
//   <Stack direction="row" alignItems="center" width="fit-content" spacing={3}>
//     <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
//       {variant || 'Variant'}
//     </Typography>

//     <Button
//       variant="contained"
//       color="inherit"
//       sx={{
//         backgroundColor: '#f3f3fc',
//         color: 'black',
//         '&:hover': { backgroundColor: '#e4e4e4' },
//         height: isMobile && '32px',
//       }}
//     >
//       {getText('white')}
//     </Button>

//     <Button
//       variant="contained"
//       color="inherit"
//       sx={{
//         backgroundColor: '#f3f3fc',
//         color: '#1F59B8',
//         border: '1px solid #1F59B8',
//         '&:hover': { backgroundColor: '#e4e4e4' },
//         height: isMobile && '32px',
//       }}
//     >
//       {getText('yellow')}
//     </Button>
//   </Stack>
// );
