import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Badge, Stack, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useGetToken } from 'src/hooks/useHandleSessions';

import { colors } from 'src/utils/colors';

import { TOKEN_PREFIX } from 'src/server/http';
import { HomeIcon } from 'src/assets/icon/HomeIcon';
import { CartIcon } from 'src/assets/icon/CartIcon';
import { UserIcon } from 'src/assets/icon/UserIcon';
import { ProductIcon } from 'src/assets/icon/ProductIcon';
import { getCartSate } from 'src/server/selectors/selector';
import { HomeActiveIcon } from 'src/assets/icon/HomeActiveIcon';
import { CartActiveIcon } from 'src/assets/icon/CartActiveIcon';
import { UserActiveIcon } from 'src/assets/icon/UserActiveIcon';
import { ProductActiveIcon } from 'src/assets/icon/ProductActiveIcon';

import OspLink from 'src/components/link/osp-link';

const MobileFooterBar = () => {
  const pathname = usePathname();
  const getText = useLocalizedText();
  const { token, isSuccess } = useGetToken(TOKEN_PREFIX);

  const { cart } = useSelector(getCartSate);

  const footerData = [
    {
      title: `${getText('home')}`,
      link: '/',
      icon: (
        <HomeIcon
          sx={{ display: 'flex', alignItems: 'center' }}
          className="footer-icon-color-svg"
        />
      ),
      activeIcon: <HomeActiveIcon className="footer-icon" />,
    },
    {
      title: `${getText('products')}`,
      link: `${paths.public.products}/`,
      icon: (
        <ProductIcon
          className="footer-icon-color-svg"
          sx={{ display: 'flex', alignItems: 'center' }}
        />
      ),
      activeIcon: <ProductActiveIcon className="m-auto d-block" />,
    },
    {
      title: `${getText('cart')}`,
      link: `${paths.public.cart}/`,
      icon: (
        <>
          {cart?.cart_items?.length > 0 ? (
            <Badge badgeContent={cart?.cart_items?.length} color="primary">
              <CartIcon className="footer-icon-color-svg" />
            </Badge>
          ) : (
            <CartIcon className="footer-icon-color-svg" />
          )}
        </>
      ),
      activeIcon: <CartActiveIcon className="m-auto d-block" />,
    },
    {
      title: `${getText('Account')}`,
      link: isSuccess && token ? `${paths.private.my_account.root}/` : `${paths.public.login}/`,
      icon: <UserIcon className="footer-icon-color-svg" />,
      activeIcon: <UserActiveIcon className="m-auto d-block" />,
    },
  ];

  return (
    <footer
      className="main"
      style={{
        backgroundColor: '#232B35',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 2,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" px={2} py={2}>
        {footerData?.map((item, key) => (
          <OspLink key={key} sx={{ '&:hover': { textDecoration: 'none' } }} href={item?.link || ''}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {pathname === item?.link ? item?.activeIcon : item?.icon}
              <Typography color={pathname === item?.link ? 'white' : '#9c9c9c'} fontSize={12}>
                {item?.title}
              </Typography>
              {pathname === item?.link && (
                <Box
                  mb={0}
                  width="100%"
                  sx={{ borderBottom: `2px solid ${colors.themeSecondary}` }}
                />
              )}
            </Box>
          </OspLink>
        ))}
      </Stack>
    </footer>
  );
};

export default MobileFooterBar;
