import React from 'react';

import { useScreenWidth } from 'src/hooks/useScreenWidth';

import HomeView from './home-view';
import HomeViewMobile from './home-view-mobile';

function Home() {
  const { screenWidth } = useScreenWidth();

  return screenWidth < 700 ? <HomeViewMobile /> : <HomeView />;
}

export default Home;
