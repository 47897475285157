import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'src/routes/hooks';

import MetaHead from 'src/utils/metaHead';

import { useLocales } from 'src/locales';
import { fetchSEO } from 'src/server/slice/settingsSlice';
import { getSettingsState } from 'src/server/selectors/selector';

import BrandDetailsView from 'src/sections/brand/view/brand-details-view';

export default function BrandSinglePage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { currentLang } = useLocales();
  const { id } = params;

  const { SEOData } = useSelector(getSettingsState);

  useEffect(() => {
    const credentials = {
      state: { module: 'BRAND', module_name: id },
    };

    dispatch(fetchSEO(credentials));
  }, [dispatch, id]);

  const languageKey = currentLang.value === 'ar' ? 'arabic' : 'english';

  return (
    <>
      <MetaHead
        title={SEOData?.title?.[languageKey] || 'Top Brand'}
        description={SEOData?.description?.[languageKey]}
        openGraph={{
          title: SEOData?.og_tag_title?.[languageKey] || 'Top Brand',
          description: SEOData?.og_tag_description?.[languageKey],
          images: SEOData?.og_tag_image?.url,
        }}
        twitter={{
          title: SEOData?.title?.[languageKey] || 'Top Brand',
          description: SEOData?.og_tag_description?.[languageKey],
          image: SEOData?.og_tag_image?.url,
        }}
      />
      <BrandDetailsView id={id} />
    </>
  );
}
