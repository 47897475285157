import { useSelector } from 'react-redux';

import { getSettingsState } from 'src/server/selectors/selector';

import MyAccountOrderList from 'src/sections/myAccount/order/order-list';
import MyAccountReviewList from 'src/sections/myAccount/review/my-account-review-list';
import MyAccountWishLists from 'src/sections/myAccount/wishlist/my-account-wish-lists';
import MyAccountAddressList from 'src/sections/myAccount/address/my-account-address-list';

export default function MyAccountPage() {
  const { accountTab } = useSelector(getSettingsState);

  switch (accountTab) {
    case 'orders':
      return <MyAccountOrderList />;
    case 'wishlist':
      return <MyAccountWishLists />;
    case 'reviews':
      return <MyAccountReviewList />;
    case 'address':
      return <MyAccountAddressList />;

    default:
      return null;
  }
}
