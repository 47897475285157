import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Box from '@mui/material/Box';
import { Stack, Container } from '@mui/material';

import { fetchGroups } from 'src/server/slice/groupSlice';
import { fetchBanners } from 'src/server/slice/bannerSlice';
import { getGroupState, getBannerState } from 'src/server/selectors/selector';

import Supports from './support-data';
import HeroSlider from './hero-slider';
import ImageGrid from './group-layouts/imageGrid';
import Highlight from './group-layouts/highlight';
import IconCarousal from './group-layouts/IconCarousal';
import { OverlapList } from './group-layouts/overlapList';
import { BannerCarousal } from './group-layouts/bannerCarousal';
import { ListHorizontal } from './group-layouts/listHorrizontal';
import { ProductCarousal } from './group-layouts/productCarousal';
import { SpecialSellCarousal } from './group-layouts/specialSellCarousal';

export default function HomeViewMobile() {
  // const { scrollYProgress } = useScroll();
  const dispatch = useDispatch();

  const { groups } = useSelector(getGroupState);
  const { banners } = useSelector(getBannerState);

  const DefaultModule = 'HOME';

  const mainBannerData = banners?.banners?.filter((item) => item.position === 'MAIN');

  const limit = 5;
  const total = groups?.total;
  const allPages = Math.ceil(total / limit);

  const [allGroups, setAllGroups] = useState([]);
  const [pages, setPages] = useState(1);

  const HandleFetchNextPage = () => {
    if (pages < allPages) {
      setPages((prevPages) => prevPages + 1);
    }
  };

  const paginatedGp = groups?.groups;

  const handleGroupData = useCallback(() => {
    const commonData = paginatedGp?.filter((item) => item?.platform === 'COMMON') || [];
    const mobileData = paginatedGp?.filter((item) => item?.platform === 'MOBILE') || [];
    return [...commonData, ...mobileData];
  }, [paginatedGp]);

  useEffect(() => {
    setAllGroups((prevGroups) => [...prevGroups, ...handleGroupData()]);
  }, [pages, handleGroupData]);

  const ids = allGroups?.map((o) => o.uid);

  const filteredFinalGp = allGroups.filter(({ uid }, index) => !ids.includes(uid, index + 1));

  // const orderedList = filteredFinalGp?.sort((a, b) => a.priority - b.priority);

  useEffect(() => {
    const credentials = {
      page: pages,
      limit,
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchGroups(credentials));
  }, [dispatch, pages]);

  useEffect(() => {
    const credentials = {
      state: {
        module: DefaultModule,
        filter_data: {
          is_deleted: false,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchBanners(credentials));
  }, [dispatch]);

  const handleLayoutWithProduct = (group) => {
    switch (group.layout) {
      case 'IMAGE_GRID':
        return <ImageGrid groupDetail={group} />;
      case 'HIGHLIGHT':
        return <Highlight groupDetail={group} />;
      case 'ICON_CAROUSEL':
        return <IconCarousal groupDetail={group} />;
      case 'CAROUSEL':
        return <ProductCarousal groupDetail={group} />;
      case 'BANNER':
        return <BannerCarousal groupDetail={group} />;
      case 'LIST_HORIZONTAL':
        return <ListHorizontal groupDetail={group} />;
      case 'OVERLAP_LIST':
        return <OverlapList groupDetail={group} />;
      case 'SPECIAL_SELL_CAROUSEL':
        return <SpecialSellCarousal groupDetail={group} />;
      default:
        return '';
    }
  };

  const groupTypeHandler = (group) => {
    switch (group.type) {
      case 'BANNERS':
      case 'PRODUCTS':
      case 'CATEGORIES':
      case 'BRANDS':
        return handleLayoutWithProduct(group);
      default:
        return handleLayoutWithProduct(group);
    }
  };

  // <ScrollProgress scrollYProgress={scrollYProgress} />
  return (
    <Box mt={0} mb={2}>
      <HeroSlider bannerDetails={mainBannerData} />

      <InfiniteScroll
        scrollThreshold={0}
        dataLength={filteredFinalGp?.length ?? 0}
        next={HandleFetchNextPage}
        hasMore={pages < allPages}
        // loader={
        //   <Stack alignItems="center" justifyContent="center" sx={{ my: 10 }}>
        //     <Typography variant="body2">Hang on, loading inventory</Typography>
        //     <CircularProgress color="success" size={20} />
        //   </Stack>
        // }
      >
        <Stack spacing={5}>
          {filteredFinalGp?.map((group, idx) => (
            <Container
              key={idx}
              maxWidth="xl"
              sx={{ px: group?.layout === 'OVERLAP_LIST' ? 0 : 2 }}
            >
              <Box>{groupTypeHandler(group)}</Box>
            </Container>
          ))}
        </Stack>
      </InfiniteScroll>

      <Supports />
    </Box>
  );
}
