import { useState, useEffect } from 'react';

import { useTranslate } from 'src/locales';

const useLocalizedText = () => {
  const { t, ready } = useTranslate();
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  const getText = (key) => {
    if (!ready) return '';
    return mounted ? t(key) : '';
  };

  return getText;
};

export default useLocalizedText;
