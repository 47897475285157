import MetaHead from 'src/utils/metaHead';

import CollectionListView from 'src/sections/collection/collectionList/collection-list';

export default function CollectionPage() {
  return (
    <>
      <MetaHead
        title="Explore Our Collection"
        description="Discover a wide range of products in our collection. Find the best deals and enjoy seamless shopping experience with Buildex."
        keywords="collection, products, shopping, e-commerce, Buildex, featured products, best deals"
        openGraph={{
          title: 'Explore Our Collection - Buildex E-Commerce Platform',
          description:
            'Discover a wide range of products in our collection. Find the best deals and enjoy seamless shopping experience with Buildex.',
        }}
        twitter={{
          title: 'Explore Our Collection - Buildex E-Commerce Platform',
          description:
            'Discover a wide range of products in our collection. Find the best deals and enjoy seamless shopping experience with Buildex.',
        }}
      />
      <CollectionListView />
    </>
  );
}
