import { Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import HomePage from 'src/pages/HomePage';
import CartPage from 'src/pages/CartPage';
import { AuthGuard } from 'src/auth/guard';
import BrandPage from 'src/pages/BrandPage';
import LoginPage from 'src/pages/LoginPage';
import SearchPage from 'src/pages/SearchPage';
import Page403 from 'src/pages/error/Page403';
import Page500 from 'src/pages/error/Page500';
import Page404 from 'src/pages/error/Page404';
import ProductPage from 'src/pages/ProductPage';
import CheckoutPage from 'src/pages/CheckoutPage';
import OrderFailPage from 'src/pages/OrderFailPage';
import CollectionPage from 'src/pages/CollectionPage';
import OfflinePage from 'src/pages/error/OfflinePage';
import BrandSinglePage from 'src/pages/BrandSinglePage';
import OrderSuccessPage from 'src/pages/OrderSuccessPage';
import ProductSinglePage from 'src/pages/ProductSinglePage';
import CategorySinglePage from 'src/pages/CategorySinglePage';
import MyAccountPage from 'src/pages/my-account/MyAccountPage';
import AccountLayout from 'src/pages/my-account/AccountLayout';
import CollectionSinglePage from 'src/pages/CollectionSinglePage';
import PrivacyPolicyPage from 'src/pages/legal/PrivacyPolicyPage';
import TermsAndConditions from 'src/pages/legal/TeamsAndCondition';
import OrderSinglePageScreen from 'src/pages/my-account/OrderSinglePage';

import { SplashScreen } from 'src/components/loading-screen';

const publicRoutes = [
  {
    path: '/',
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: '', element: <HomePage /> },
      { path: 'collections', element: <CollectionPage /> },
      { path: 'collection/:id', element: <CollectionSinglePage /> },
      { path: 'category/:id', element: <CategorySinglePage /> },

      { path: 'brands', element: <BrandPage /> },
      { path: 'brand/:id', element: <BrandSinglePage /> },

      { path: 'products', element: <ProductPage /> },
      { path: 'product/:id', element: <ProductSinglePage /> },

      { path: 'cart', element: <CartPage /> },

      { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
      { path: 'terms-and-conditions', element: <TermsAndConditions /> },
      { path: 'shipping-policy', element: <PrivacyPolicyPage /> },

      { path: 'login', element: <LoginPage /> },
    ],
  },
  {
    path: '/',
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'error/500', element: <Page500 /> },
      { path: 'error/403', element: <Page403 /> },
      { path: 'error/404', element: <Page404 /> },
    ],
  },

  { path: 'search', element: <SearchPage /> },
  { path: 'error/offline', element: <OfflinePage /> },

  {
    path: '/404',
    element: (
      <MainLayout>
        <Page404 />
      </MainLayout>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const authRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),

    children: [
      {
        path: 'my-account',
        element: (
          <AccountLayout>
            <Suspense fallback={<SplashScreen />}>
              <Outlet />
            </Suspense>
          </AccountLayout>
        ),
        children: [
          { path: '', element: <MyAccountPage /> },
          { path: 'orders/:id', element: <OrderSinglePageScreen /> },
        ],
      },

      { path: 'checkout', element: <CheckoutPage /> },
      { path: 'order-fail', element: <OrderFailPage /> },
      { path: 'order-success/:id', element: <OrderSuccessPage /> },
    ],
  },
];

export { authRoutes, publicRoutes };
