import { timeZone } from 'src/server/http';

export const useProductOfferPriceCalculator = (product) => {
  const oldPrice = product?.price;
  const currentPrice = product?.offer_price;

  const diff = Math.abs(oldPrice - currentPrice);

  const currentDateNormalDate = new Date();
  const currentDate = new Date(currentDateNormalDate.toLocaleString('en-US', { timeZone }));

  const offerStartDate = new Date(product?.offer_start_date);
  const offerEndDate = new Date(product?.offer_end_date);

  const offerPrice =
    currentDate >= offerStartDate && currentDate <= offerEndDate ? currentPrice : null;

  const discountPercentage =
    (oldPrice ?? 0) > currentPrice ? Math.floor((diff / oldPrice) * 100) : '';

  return {
    discountPercentage: offerPrice !== null && discountPercentage,
    offerPrice,
  };
};
