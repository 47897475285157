// ----------------------------------------------------------------------

import MetaHead from 'src/utils/metaHead';

import { OrderSuccess } from 'src/sections/checkout/order-success/success';

export default function OrderSuccessPage() {
  return (
    <>
      <MetaHead
        title="Order Successful"
        description="Thank you for shopping with Buildex! Your order was successful. Check your email for order details and tracking information."
        keywords="Buildex, order successful, order confirmation, order tracking, thank you for shopping"
        openGraph={{
          title: 'Order Successful - Buildex E-Commerce Platform',
          description:
            'Your order has been placed successfully! Check your email for confirmation and tracking details. Thank you for choosing Buildex.',
        }}
        twitter={{
          title: 'Order Successful - Buildex E-Commerce Platform',
          description:
            'Thank you for your order! View details and track your shipment easily with Buildex.',
        }}
      />
      <OrderSuccess />
    </>
  );
}
