import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import { Box, Stack } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import { useGetToken } from 'src/hooks/useHandleSessions';
import useTranslatedText from 'src/hooks/useTranslatedText';
import { useProductPathname } from 'src/hooks/useProductPathname';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { TOKEN_PREFIX } from 'src/server/http';
import { useCurrency } from 'src/providers/CurrencyProvider';
import { fetchWishListUpdate } from 'src/server/slice/userSlice';
import { triggerLoginPopup } from 'src/server/slice/authentication';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';

import useHandleCart from './hooks/useHandleCart';
import IncrementorButton from '../myAccount/common/incrementer-button';

export default function CartProductList({ cartItem }) {
  const getText = useLocalizedText();
  const { currency } = useCurrency();
  const { isMobile, screenWidth } = useScreenWidth();
  const { isSuccess, token } = useGetToken(TOKEN_PREFIX);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isSmallDevice = screenWidth < 670;

  const product = cartItem?.product;

  const translatedText = useTranslatedText(
    product?.images[0]?.alt_text ? product?.images[0]?.alt_text : {}
  );
  const transformedImage = useImageTransformation(product?.images[0]?.url, 'w_400');

  const isOutOfStock = product?.stock <= 0;
  const isLowStock = product?.stock <= 5 && product?.stock !== 0;

  const { discountPercentage, offerPrice } = useProductOfferPriceCalculator(product);

  const translatedProductName = useTranslatedText(product?.name);
  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);
  const translateCurrencyText = useTranslatedText(currency?.title);

  const quantity = cartItem?.quantity;

  const { handleAddCart, handleRemoveCart } = useHandleCart({ product });

  const handleRemove = () => {
    handleRemoveCart(quantity);
  };

  const handleWishList = (operation) => {
    if (isSuccess && token) {
      const credentials = {
        state: {
          product: product?.uid,
          operation,
        },
        enqueueSnackbar,
        dispatch,
        handleRemove,
      };
      dispatch(fetchWishListUpdate(credentials));
    } else {
      dispatch(triggerLoginPopup({ state: true }));
    }
  };

  const ProductImage = (
    <Stack sx={{ border: '1px solid #e9e9e9', borderRadius: '10px' }}>
      {product?.images?.length > 0 ? (
        <Image
          alt={translatedText}
          src={transformedImage}
          sx={{}}
          imageStyle={{
            width: isSmallDevice ? '110px' : '200px',
            Height: isSmallDevice ? '110px' : '200px',
            aspectRatio: 1 / 1,
            borderRadius: '10px',
            maxWidth: '200px',
          }}
          className={`product-image `}
        />
      ) : (
        <Image
          alt="alt"
          src="/assets/images/default/product.png"
          imageStyle={{
            width: isSmallDevice ? '110px' : '200px',
            Height: isSmallDevice ? '110px' : '200px',
            aspectRatio: 1 / 1,
            borderRadius: '10px',
            maxWidth: '200px',
          }}
        />
      )}
    </Stack>
  );

  const { productRoute } = useProductPathname(product);

  const renderCartItemWeb = (
    <Stack direction="row" alignItems="start" justifyContent="space-between">
      <Stack direction="row" alignItems="start" justifyContent="start">
        <Stack sx={{ padding: 2, pt: 0 }}>
          <OspLink
            href={productRoute}
            sx={{ '&:hover': { textDecoration: 'none' }, position: 'relative' }}
          >
            {ProductImage}
          </OspLink>
        </Stack>
        <Stack spacing={isMobile ? 5 : 9}>
          <Stack>
            <OspLink
              href={productRoute}
              sx={{ '&:hover': { textDecoration: 'none' }, position: 'relative' }}
            >
              <TextMaxLine variant="subtitle1" line={2} sx={{ fontWeight: 'fontWeightSemiBold' }}>
                {translatedProductName}
              </TextMaxLine>
            </OspLink>

            <Stack>
              <Box
                component="span"
                sx={{
                  typography: 'caption',
                  textTransform: 'capitalize',
                  color:
                    (isOutOfStock && 'error.main') ||
                    (isLowStock && 'warning.main') ||
                    'success.main',
                }}
              >
                {(isOutOfStock && getText('out_of_stock')) ||
                  (isLowStock && (
                    <>
                      {getText('only')} {product?.stock} {getText('left_in_stock')}
                    </>
                  )) ||
                  getText('in_stock')}
              </Box>

              {/* // todo : variant */}
              {/* <Typography variant="body2" sx={{ fontWeight: 300 }}>
              Color : White
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 300 }}>
              Size : {`5"W x 7"H x 5"D`}
            </Typography> */}
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" width="fit-content" spacing={3}>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {getText('quantity')}
            </Typography>

            <Stack
              spacing={1}
              sx={{
                backgroundColor: '#EEF0F4',
                borderRadius: '8px',
              }}
            >
              <IncrementorButton
                name="quantity"
                quantity={quantity}
                disabledDecrease={quantity <= 1}
                disabledIncrease={quantity >= product?.stock}
                onIncrease={(e) => handleAddCart(e.target.value)}
                onDecrease={(e) => handleRemoveCart(e.target.value)}
                sx={{
                  padding: isMobile ? '2px' : '4px',
                }}
                // loading={cartUpdateLoading}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          padding: 2,
          pt: 0,
          textAlign: 'end',
          height: '100%',
          justifyContent: 'space-between',
        }}
        spacing={8}
      >
        <Stack>
          {discountPercentage && (
            <Typography mx={1} variant="subtitle1" color="#26883A" sx={{ fontWeight: 700 }}>
              {discountPercentage}% {getText('off')}
            </Typography>
          )}

          <Typography
            variant="h5"
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 0.8,
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 400, marginBottom: '-4px', fontSize: '14px' }}
            >
              {translateCurrencyText}
            </Typography>

            {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}

            {offerPrice && offerPrice < product?.price && (
              <Box
                component="span"
                sx={{
                  color: 'text.disabled',
                  textDecoration: 'line-through',
                  lineHeight: 'normal',
                  marginBottom: '-4px',
                }}
              >
                <Typography variant="subtitle1">{currencyConversionPrice}</Typography>
              </Box>
            )}
          </Typography>

          {product?.is_free_delivery_product ? (
            <Typography
              mt={0.4}
              component="div"
              variant="subtitle2"
              color="#26883A"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0 2px',
                fontWeight: 600,
              }}
            >
              <Iconify icon="la:shipping-fast" sx={{ mr: 1.2, height: '18px', width: '18px' }} />

              {getText('free_delivery')}
            </Typography>
          ) : (
            ''
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <IconButton
            sx={{ borderRadius: 1, color: '#30AE4A' }}
            size="small"
            onClick={() => handleWishList('ADD')}
          >
            <AddRoundedIcon fontSize="small" />
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {getText('save_for_later')}
            </Typography>
          </IconButton>
          <IconButton
            onClick={() => handleRemoveCart(quantity)}
            size="small"
            sx={{ ml: 1, borderRadius: 1, border: '1px solid #f2f2f2' }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );

  const renderCartItemMobile = (
    <Stack direction="row" alignItems="start" justifyContent="space-between">
      <Stack direction="row" alignItems="start" justifyContent="start" width="100%">
        <Stack sx={{ padding: 2, pt: 0 }}>
          <OspLink
            href={productRoute}
            sx={{ '&:hover': { textDecoration: 'none' }, position: 'relative' }}
          >
            {ProductImage}
          </OspLink>
          <Stack direction="row" alignItems="center" width="100%" spacing={3} mt={1}>
            <Stack
              spacing={1}
              sx={{
                backgroundColor: '#EEF0F4',
                borderRadius: '8px',
              }}
            >
              <IncrementorButton
                name="quantity"
                quantity={quantity}
                disabledDecrease={quantity <= 1}
                disabledIncrease={quantity >= product?.stock}
                onIncrease={(e) => handleAddCart(e.target.value)}
                onDecrease={(e) => handleRemoveCart(e.target.value)}
                sx={{
                  padding: isMobile ? '2px' : '4px',
                  border: 'none',
                  background: '#fff',
                  gap: '5px',
                  width: '110px',
                }}
                isMobile
                // loading={cartUpdateLoading}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={1} width="100%">
          <Stack>
            <OspLink
              href={productRoute}
              sx={{ '&:hover': { textDecoration: 'none' }, position: 'relative' }}
            >
              <TextMaxLine
                variant="body2"
                line={2}
                sx={{ fontWeight: 'fontWeightSemiBold', mr: 1.5 }}
              >
                {translatedProductName}
              </TextMaxLine>
            </OspLink>
            <Box
              component="span"
              sx={{
                typography: 'caption',
                textTransform: 'capitalize',
                color:
                  (isOutOfStock && 'error.main') ||
                  (isLowStock && 'warning.main') ||
                  'success.main',
              }}
            >
              {(isOutOfStock && getText('out_of_stock')) ||
                (isLowStock && (
                  <>
                    {getText('only')} {product?.stock} {getText('left_in_stock')}
                  </>
                )) ||
                getText('in_stock')}
            </Box>

            {/* // todo : variant */}
            {/* <Typography variant="body2" sx={{ fontWeight: 300 }}>
              Color : White
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 300 }}>
              Size : {`5"W x 7"H x 5"D`}
            </Typography> */}
          </Stack>
          <Stack>
            <Typography
              variant="h5"
              sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                gap: 0.8,
                fontSize: '16px',
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                {translateCurrencyText}
              </Typography>

              {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}

              {screenWidth > 450 && (
                <>
                  {offerPrice && offerPrice < product?.price && (
                    <Box
                      component="span"
                      sx={{
                        color: 'text.disabled',
                        textDecoration: 'line-through',
                        lineHeight: 'normal',
                      }}
                    >
                      <Typography variant="body1">{currencyConversionPrice}</Typography>
                    </Box>
                  )}
                </>
              )}

              {discountPercentage && (
                <Typography mx={1} variant="body1" color="#26883A" sx={{ fontWeight: 700 }}>
                  {discountPercentage}% {getText('off')}
                </Typography>
              )}
            </Typography>

            {!product?.is_free_delivery_product ? (
              <Typography
                mt={0.4}
                component="div"
                variant="subtitle2"
                color="#26883A"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '0 2px',
                  fontWeight: 600,
                }}
              >
                <Iconify icon="la:shipping-fast" sx={{ mr: 1.2, height: '18px', width: '18px' }} />

                {getText('free_delivery')}
              </Typography>
            ) : (
              ''
            )}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" my={2} mr={3}>
            <IconButton
              sx={{ borderRadius: 0.5, color: '#30AE4A', border: '1px solid #f2f2f2', px: 2 }}
              size="small"
              onClick={() => handleWishList('ADD')}
            >
              <AddRoundedIcon fontSize="small" sx={{ width: '14px', height: '14px' }} />
              <Typography variant="caption" sx={{ ml: 0.5 }}>
                {getText('save_for_later')}
              </Typography>
            </IconButton>
            <IconButton
              onClick={() => handleRemoveCart(quantity)}
              size="small"
              sx={{ ml: 1, borderRadius: 0.5, border: '1px solid #f2f2f2' }}
            >
              <DeleteIcon fontSize="small" sx={{ width: '18px', height: '18px' }} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );

  return screenWidth < 670 ? renderCartItemMobile : renderCartItemWeb;
}

CartProductList.propTypes = {
  cartItem: PropTypes.object,
};
