import MetaHead from 'src/utils/metaHead';

import { OrderFail } from 'src/sections/checkout/order-fail/fail';

export default function OrderFailPage() {
  return (
    <>
      <MetaHead
        title="Order Failed "
        description="Oops! Your order could not be processed. Learn more about what went wrong and how you can complete your purchase successfully."
        keywords="Buildex, order failed, payment issue, transaction error, shopping support"
        openGraph={{
          title: 'Order Failed  - Buildex E-Commerce Platform',
          description:
            'We encountered an issue processing your order. Find out how to resolve payment or transaction errors and retry your purchase.',
        }}
        twitter={{
          title: 'Order Failed  - Buildex E-Commerce Platform',
          description:
            'An error occurred with your order. Learn how to retry your purchase or get support from Buildex.',
        }}
      />
      <OrderFail />
    </>
  );
}
