import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import SouthRoundedIcon from '@mui/icons-material/SouthRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import { Box, Card, Stack, Button, Rating, Typography } from '@mui/material';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';

import useFormatNumber from 'src/hooks/useFormatNumber';
import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';
import { useProductPathname } from 'src/hooks/useProductPathname';
import useCurrencyConversion from 'src/hooks/useCurrencyConversion';
import useImageTransformation from 'src/hooks/useImageTransformation';
import { useProductOfferPriceCalculator } from 'src/hooks/useProductOfferPriceCalculator';

import { useCurrency } from 'src/providers/CurrencyProvider';

import Image from 'src/components/image';
import Label from 'src/components/label';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';

import useHandleCart from 'src/sections/cart/hooks/useHandleCart';

export const product_label_layouts = ['LAYOUT_1', 'LAYOUT_2', 'LAYOUT_4', 'LAYOUT_5'];

const ProductCardThreeMobile = ({ product }) => {
  const getText = useLocalizedText();
  const { currency } = useCurrency();
  const { productRoute } = useProductPathname(product);
  const { handleAddCart } = useHandleCart({ product });

  const handleAddToCart = () => {
    handleAddCart();
  };

  const translatedText = useTranslatedText(product?.images[0]?.alt_text);
  const transformedImage = useImageTransformation(product?.images[0]?.url);

  const translatedDescription = useTranslatedText(product?.description);
  const translatedProdName = useTranslatedText(product?.name);

  const productLabels = product_label_layouts.map((layout) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTranslatedText(product?.product_labels?.find((i) => i.layout === layout)?.title)
  );

  const [productLabelOne, productLabelTwo, productLabelFour, productLabelFive] = productLabels;

  const formattedNumber = useFormatNumber(product?.average_rating_count);

  const { discountPercentage, offerPrice } = useProductOfferPriceCalculator(product);

  const currencyConversionOfferPrice = useCurrencyConversion(offerPrice);
  const currencyConversionPrice = useCurrencyConversion(product?.price);

  return (
    <Card
      sx={{
        border: 'none',
        borderBottom: '1px solid #e4e4e4',
        borderRadius: 0,
        paddingBottom: '20px',
        position: 'relative',
        boxShadow: 'none',
      }}
    >
      <OspLink href={productRoute} sx={{ '&:hover': { textDecoration: 'none' } }}>
        <Stack direction="row" spacing={2}>
          <Box width="50%">
            <Box
              position="relative"
              width="100%"
              borderRadius="15px"
              p={1}
              style={{
                border: '1px solid #e4e4e4',
              }}
            >
              {productLabelOne && (
                <Label
                  variant="filled"
                  color="error"
                  sx={{
                    position: 'absolute',
                    left: '0px',
                    top: '0px',
                    borderRadius: '10px 0px 10px 0px',
                    px: 4,
                    fontSize: 15,
                    height: 30,
                    zIndex: 1,
                  }}
                >
                  {productLabelOne}
                </Label>
              )}
              <Image
                src={transformedImage}
                alt={translatedText}
                style={{}}
                imageStyle={{
                  objectFit: 'contain',
                  aspectRatio: 1 / 1,
                }}
              />
              {productLabelTwo && (
                <Label
                  variant="filled"
                  sx={{
                    padding: '0 14px 0px 10px',
                    with: 'fit-content',
                    backgroundColor: '#3097ae',
                    position: 'absolute',
                    bottom: productLabelFive ? 45 : 15,
                    left: 10,
                  }}
                  className="width-fit-content"
                >
                  <TrendingUpRoundedIcon fontSize="small" sx={{ mr: 0.6 }} />
                  {productLabelTwo}
                </Label>
              )}
              {productLabelFive && (
                <Label
                  variant="soft"
                  color="success"
                  sx={{
                    padding: '0px 14px 0px 10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    position: 'absolute',
                    bottom: 15,
                    left: 10,
                  }}
                  className="width-fit-content"
                >
                  <SouthRoundedIcon
                    fontSize="small"
                    sx={{ mr: 0.6, height: '15px', width: '15px' }}
                  />
                  {productLabelFive}
                </Label>
              )}
            </Box>
          </Box>
          <Stack direction="column" width="50%">
            <Box>
              <TextMaxLine color="black" line={2} sx={{ fontSize: 15 }}>
                {translatedProdName}
              </TextMaxLine>
              {product?.average_rating ? (
                <Stack direction="row" alignItems="center" spacing={2} py={0.5}>
                  {product?.average_rating ? (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography color="black" fontSize={15}>
                        {product?.average_rating?.toFixed(1)}
                      </Typography>
                      <Rating
                        size="small"
                        value={product?.average_rating}
                        precision={0.1}
                        readOnly
                        sx={{ mr: 1 }}
                      />
                      {formattedNumber > 0 && (
                        <Typography style={{ fontSize: 15 }}>({formattedNumber})</Typography>
                      )}
                    </Box>
                  ) : (
                    ''
                  )}
                </Stack>
              ) : (
                ''
              )}
              {offerPrice && offerPrice < product?.price ? (
                <Box
                  component="span"
                  sx={{
                    color: 'text.disabled',
                    textDecoration: 'line-through',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  {currency?.symbol} {currencyConversionPrice}
                </Box>
              ) : (
                ''
              )}
              <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography
                    color="black"
                    variant="h5"
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    component="div"
                  >
                    <Typography variant="body2" color="black">
                      {currency?.symbol}
                    </Typography>
                    {offerPrice ? currencyConversionOfferPrice : currencyConversionPrice}
                  </Typography>
                </Box>
                {discountPercentage && (
                  <Typography
                    variant="subtitle2"
                    color="#30ae4a"
                    fontWeight={700}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowDownwardRoundedIcon
                      fontSize="small"
                      sx={{ height: '15px', width: '15px', fontWeight: 700 }}
                    />
                    {discountPercentage}% {getText('off')}
                  </Typography>
                )}
              </Stack>
              {product?.is_free_delivery_product ? (
                <Typography
                  mt={0.2}
                  variant="subtitle2"
                  color="#30ae4a"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 2px',
                  }}
                >
                  <LocalShippingRoundedIcon
                    fontSize="small"
                    sx={{ mr: 0.6, height: '15px', width: '15px' }}
                  />
                  {getText('free_delivery')}
                </Typography>
              ) : (
                ''
              )}
            </Box>
            <Box
              component="div"
              sx={{
                color: '#919EAB',
                fontSize: '14px',
              }}
            >
              {parse(translatedDescription?.slice(0, 100))}
            </Box>
          </Stack>
        </Stack>
      </OspLink>

      <Stack mt={3} direction="row" spacing={2}>
        <Box width="50%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
            <Typography fontWeight={700}>{getText('hurry_up')}</Typography>
            {productLabelFour && (
              <Label
                variant="soft"
                color="error"
                sx={{
                  padding: '0 5px 0 5px',
                  with: 'fit-content',
                  color: '#ff0000',
                }}
                className="width-fit-content"
              >
                <LocalFireDepartmentRoundedIcon
                  fontSize="small"
                  sx={{ mr: 0.6, height: '15px', width: '15px' }}
                />
                {productLabelFour}
              </Label>
            )}
          </Stack>
          <Typography
            display="flex"
            alignItems="center"
            gap={0.5}
            variant="body2"
            color="text.disabled"
            component="div"
          >
            <Typography color="#FC404A" variant="body2">
              {getText('offer_ends_in')}&nbsp;{getText('soon')}
            </Typography>
          </Typography>
        </Box>
        <Box width="50%">
          <Button
            size="medium"
            onClick={handleAddToCart}
            fullWidth
            sx={{
              backgroundColor: '#3258DD',
              px: 4,
              py: 1,
              color: 'white',
              borderRadius: '4px',
              '&:hover': { backgroundColor: '#3258DD', color: 'white' },
            }}
          >
            {getText('add_to_cart')}
          </Button>
        </Box>
      </Stack>
    </Card>
  );
};

export default ProductCardThreeMobile;

ProductCardThreeMobile.propTypes = {
  product: PropTypes.any,
};
