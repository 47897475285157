import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';

const CollectionOrSaleData = ({ data, type }) => {
  const formattedImage = useImageTransformation(data?.logo?.url, 'w_500');
  const formattedAltText = useTranslatedText(data?.logo?.alt_text);
  const formattedCatName = useTranslatedText(data?.title);
  return (
    <OspLink
      href={paths.public.collectionView(data?.title?.english.replace(/ /g, '-'))}
      sx={{ '&:hover': { textDecoration: 'none' } }}
    >
      <Stack direction="row" flexDirection="column" alignItems="center">
        <Image
          src={formattedImage}
          alt={formattedAltText}
          sx={{ cursor: 'pointer', maxWidth: '100%' }}
          imageStyle={{
            objectFit: 'contain',
            aspectRatio: 1 / 1,
          }}
        />
        <Typography textAlign="center" color="black" fontSize={12}>
          {formattedCatName}
        </Typography>
      </Stack>
    </OspLink>
  );
};

export default CollectionOrSaleData;

CollectionOrSaleData.propTypes = {
  data: PropTypes.any,
  type: PropTypes.any,
};
